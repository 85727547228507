.header-callout-button {
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    font-family: futura-pt;
    font-weight: bold;
    font-size: 16px;
    
    @media(max-width: $bp-max-medium) {
        
    }
}
// quick link

.header-callout-button__link {
    color: $denim-blue;
    border: 2px solid $denim-blue;
    text-align: center;
    padding: 8px 12px;

    &:hover {
        color: white;
        background-color: $denim-blue;
        text-decoration: none;
    }

    @media(max-width: $bp-max-medium) {
        margin-bottom: 10px;
        min-width: 65px;
        min-height: 20px;
        padding: 10px 20px !important;
        margin-left: 0;
        border: 1px solid $white;
    }
}
