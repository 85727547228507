.highlight-book-topics {
	max-width: 856px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	margin-bottom: 60px;

	@media (max-width: 767px) {
		margin-bottom: 40px;
	}

	&__header {
		margin-bottom: 30px;
	}

	&__header-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 767px) {
			flex-direction: column;
			align-items: normal;
			margin-bottom: 16px;
		}
	}

	&__header-title {
		font-size: 40px;
		line-height: 50px;
		margin-bottom: 8px;
	}

	&__header-link {
		@include font(futura);
		text-transform: uppercase;
		font-weight: 600;
		font-size: 18px;
		color: $fun-blue;
		cursor: pointer;

		&:after {
			content: "\2192";
			padding-left: 8px;
			color: $black;
		}
	}

	&__header-content {
		font-size: 18px;
		line-height: 30px;
	}

	&__items-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 50px;

		@media (max-width: 767px) {
			grid-template-columns: 1fr;
			grid-gap: 16px;
		}
	}

	&__item {
		background-color: #e6f1f7;
		height: 137px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__title {
		@include font(futura);
		font-size: 25px;
		color: #044a7e;
		line-height: 29px;
		font-weight: 500;
		width: 69%;
		padding-left: 24px;
		padding-right: 8px;

		@media (max-width: 767px) {
			font-size: 18px;
		}
	}

	&__image {
		height: 100%;
		width: 80px;
		object-fit: cover;
	}
}
