// global-header.scss
// Define global header styles here
$global-header__bottom-space: 30px;
// base
.global-header {
  font-size: 16px;

  @media (max-width: $bp-max-medium) {
    .container--bleed {
      background-color: transparent;
    }
  }

  .js-search-toggle {
    margin-right: 24px;
  }

  .search-box {
    width: 197px;
    float: right;

    @media (max-width: $bp-max-large) {
      // width: 150px;
      width: 100%;
    }
    @media print {
      display: none;
    }
  }

  .search-box__input {
    @media (max-width: $bp-max-medium) {
      border: 0;
    }
  }

  .container:not(.is-adjacent) {
    margin-bottom: $global-header__bottom-space;
  }

  .container--bleed {
    .container__col {
      @include outer-container();

      @media (max-width: $bp-max-medium) {
        position: static;
      }
    }
  }

  .container {
    @media (max-width: $bp-max-medium) {
      position: static;
    }
  }
}

.global-header__top {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding-top: 30px;

  @media (max-width: $bp-max-medium) {
    margin-bottom: calc(
      1rem + 48px
    ); // adding space of the search box that position absolute;
    padding-top: 10px;
    position: relative;
    text-align: center;
    width: 100%;
  }
}

.global-header__top-right {
  align-items: center;
  display: flex;
  padding-left: 20px;

  .utility-nav {
    margin-right: 29px;

    @media (max-width: $bp-max-medium) {
      display: none;
    }
  }

  .utility-nav__list {
    float: right;
  }

  .utility-callout {
    margin-right: 25px;

    @media (max-width: $bp-max-medium) {
      display: none;
    }
  }
}

.global-header__top-left {
  float: left;

  @media (max-width: $bp-max-medium) {
    display: inline-block;
    float: none;
    margin-bottom: 20px;
  }
}

.global-header__bottom {
  display: flex;
  position: relative;
  padding-bottom: 18px;
  padding-top: 18px;

  @media (max-width: $bp-max-medium) {
    position: static;
  }

  @media (max-width: $bp-max-medium) {
    .search-box {
      display: block;
      position: absolute;
      right: 0;
      top: rem(22);
    }
  }
}

.global-header__search-form {
  margin-left: rem(7);

  // responsive layout
  @media (max-width: 740px) {
    display: block;
    margin-left: 0;
    margin-bottom: 12px;
  }
  @media (max-width: 560px) {
    margin: 12px auto;
    width: 90%;
  }
}

.global-header__cta {
  @include font(futura, demi);
  color: $fun-blue;
  font-weight: 700;
  text-decoration: none;

  @media (max-width: $bp-max-medium) {
    color: $white;
  }
}
