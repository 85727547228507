$micro-nav-left: 30px;
$micro-nav-right: 17px;

.health-exec {
	.microsite-nav {
		background: $rhino;
		margin-bottom: 30px;

		&:before {
			background: $rhino;
		}

		&:after {
			background: $black-squeeze-blue;
				left: 20px;

		}
	}
	.container--70-30 {
		.microsite-nav:after {
			left: 33px;
		}
	}

	.microsite-nav__heading {
		@include font(garamond, regular);
		text-transform: uppercase;
		margin-bottom: 25px;
	}

	.microsite-nav__item {
		font-size: 18px;

		.microsite-nav__link,
		a {
			@include font(open-sans, bold);
			letter-spacing: 0;

			&.is-active,
			&:hover {
				color: $black-squeeze-blue;
			}
		}
	}
}
