// $container-outer-gutter referenced from container sass file
//$max-width referenced from grid sass file
// get to the actual component code already geez
.job-feed-container {
  width: 100%;
  position: relative;

  .slick-arrow {
    background: $saffron;
    height: 50px;
    overflow: hidden;
    width: 50px;
    z-index: 1;
    &.slick-prev {
      left: 0;
    }
    &.slick-next {
      right: 0;
    }
    @media (max-width: 1024px) {
      height: 60px;
      width: 60px;
    }
    @media (max-width: 768px) {
      height: 40px;
      width: 40px;
    }

    &:before {
      display: none;
    }

    svg {
      height: 40px;
      width: 40px;
      @media (max-width: 1024px) {
        height: 50px;
        width: 50px;
      }
      @media (max-width: 768px) {
        height: 30px;
        width: 30px;
      }
    }
  }
}

.job-feed {
  margin: 0;
}

.job-feed,
.job-feed__slide-container {
  @include component-whitespace;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 60px;
  margin: 0;

  @media (min-width: 768px) and (max-width: 910px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.job-feed.slick-initialized {
  display: block;
  padding-inline: 60px;
}

.job-feed__item {
  list-style-type: none;
  background-color: $white;

  div {
    display: block;
  }
}

.job-feed__title {
  @include font(futura, demi);
  font-size: 22px;
}

.job-feed__description {
  color: $olive;
}

.job-feed__description,
.job-feed__location {
  @include font(futura, light);
  font-size: 18px;
  line-height: 1.31;
}
