.text-promo {
  display: block;
  margin-bottom: 30px;
  margin-top: 30px;
  overflow: hidden;
  padding: 30px 40px;
  position: relative;
  width: 100%;

  &, &--sky-blue {
    background-color: $black-squeeze-blue;
    a {
      color: $rhino;
    }
  }

  &--periwinkle {
    background-color: $white-lilac;
    a {
      color: $rouge;
    }
  }
}

.text-promo__border {
  background-color: $rouge;
  height: 30px;
  position: absolute;
  top: 35px;
  left: 0;
  width: 10px;
}

.text-promo__title {
  @include font(proxima-nova, regular);
  color: $rhino;
  font-size: 30px;
}

.text-promo__content {
  @include font(open-sans, regular);
  font-size: 18px;
  line-height: 30px;
  color: $tundora;



  ul {
    @include font(open-sans, semibold);
    list-style-type: none;
    margin-left: 0;
  }
}
