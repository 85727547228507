.qna {}
//these styles are renamed in the RTE stylesheet to be more user friendly. I'm keeping these here so that QA content created before the change will retain styles
.qna__section-header {

	&:after {
		background: $fun-blue;
		content: "";
		display: block;
		height: 3px;
		margin-bottom: 50px;
		margin-top: 15px;
		width: 105px;
	}
}

.qna__question-header {
	@include font(futura, demi);
	color: $dove-gray;
	font-size: 20px;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.qna__question,
.rich-text .qna__question {
	@include font(futura, extra-bold);
	font-size: 20px;
	margin-bottom: 40px;
}

.qna__answer {
	@include font(georgia, regular);
	display: block;
	border-bottom: 2px dashed $dusty-gray;
	padding-bottom: 40px;
	margin-bottom: 40px;
	font-size:18px;

	&:last-of-type {
		// border-bottom: 0;
	}
}
