$health-bar: 80px;
$health-bar-margin: 25px;

.headline.scEnabledChrome {
  overflow: visible;
}

.health-exec {
  /*.headline {
    font-size: 0;
    text-align: left;
    //
    &:before {
      content: "";
      background-color: $rouge;
      display: inline-block;
      height: 5px;
      width: $health-bar;
      margin-right: $health-bar-margin;
      @media(max-width: $bp-max-small) {
        display: none;
      }
    }

    .is-page-editor & {
      font-size: inherit;
    }
  }

  .headline__title {
    @include font(garamond,bold);
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 0;
    font-size: 45px;
    @media(max-width: $bp-max-small) {
      display: block;
    }
  }

  .headline__link {
    @include font(open-sans, bold);
    display: block;
    color: $chetwode-blue;
    font-size: 18px;
    margin-left: calc(#{$health-bar} + #{$health-bar-margin});

    &:after {
      padding: 0 5px 6px;
      display: inline-block;
      vertical-align: middle;
    }
    @media(max-width: $bp-max-small) {
      margin-left: 0;
    }
  }*/

  .is-page-editor & {
    .scTextWrapper.scEnabledChrome {
      display: block;
    }
  }
}
