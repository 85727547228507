//overrides book-details styles and adds specific styles to related book component

.related-book {
  .book-details__upper {
    @media (max-width: $bp-max-small) {
      flex-direction: column;
    }
  }
  .book-details__left {
    a {
      display: block;
    }
    .book-details__image {
      margin-bottom: 0;
      @media (max-width: $bp-max-small) {
        max-width: 200px;
        margin: 0 auto;
        display: block;
      }
    }
    margin-bottom: 0;
    @media (max-width: $bp-max-small) {
      width: 100%;
    }
  }
  .book-details__right {
    a {
      color: $tundora;
      &.btn--link { color: $fun-blue; }
    }
    .book-details__title {
      font-size: 36px;
      line-height: 45px;
    }
    .book-details__description {
      @include font(georgia);
      font-size: 18px;
      line-height: 30px;
      margin-top: 25px;
      margin-bottom: 25px;
      
      p {
        font-size: inherit;
        line-height: inherit;
      }
    }
    @media (max-width: $bp-max-small) {
      width: 100%;
      margin-left: 0;
    }
  }
}
