.separating-rule {
  border: 0;
  border-top: 5px solid #e6e7e8;
  margin: 80px auto;
  max-width: 1290px;
  @media (max-width: $bp-max-small) {
    margin: 55px auto;
    width: calc(100% - 60px);
  }
}
