$promo-media-offset: 50px;

.image-promo {
  @include component-whitespace();

  .image-promo__media {
    position: relative;

    picture {
      display: block;
      line-height: 0;

      img {
        width: 100%;
        height: auto;
      }
    }

    figcaption {
      background-color: $fun-blue;
      position: absolute;
      bottom: 0;
      color: $white;
      padding: 40px;
      max-width: 75%;

      a {
        color: inherit;

        &:after {
          color: inherit;
        }
      }

      .btn--link {
        font-weight: 500;
      }
      @media(max-width: $bp-max-medium) {
        bottom: auto;
        display: block;
        padding: 20px;
        position: relative;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .image-promo__title {
    font-weight: 500;
  }

  .image-promo__metadata {
    @include font(futura);
    display: block;
    margin-bottom: 15px;
  }
}
