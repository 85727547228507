.global-footer {
  @include font(futura);
  font-size: 16px;
  padding-bottom: 30px;
  padding-top: 30px;

  a {
    font-weight: 600;
    text-decoration: none;
  }

  .site-logo {
    margin-bottom: 35px;
  }

  .has-border {
    border-top: 5px solid $saffron;
    padding-top: 30px;
  }

  .container--30-70 .container__col:first-child {
    @media (max-width: $bp-max-medium) {
      margin-bottom: 0;
    }
  }

  .connect {
    @media (min-width: $bp-min-large) {
      flex-basis: 300px;
      flex-grow: 1;
      flex-shrink: 0;
    }
  }

  .footer-top-nav {
    background-color: $fun-blue;
    padding-top: 12px;
    padding-bottom: 12px;

    @media (max-width: $bp-max-medium) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .container {
      margin-bottom: 0;
    }

    .footer-top-nav__menu {
      @include zero-list;
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @media (max-width: $bp-max-medium) {
        flex-direction: column;
      }

      .footer-top-nav__item {
        margin: 0;
        padding: 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.2;

        & + .footer-top-nav__item {
          @media (max-width: $bp-max-medium) {
            margin-top: 6px;
          }
        }

        a {
          @include font(futura, bold);
          color: $white;
          display: inline-block;
          font-size: 16px;
          line-height: 1.6925;
          text-transform: uppercase;
          padding: 10px 16px;
          white-space: nowrap;

          @media (max-width: $bp-max-large) {
            padding: 10px 5px;
          }

          @media (max-width: $bp-max-medium) {
            padding-left: 0;
            padding-right: 0;
          }

          &:hover {
            color: rgba(255, 255, 255, 0.7);
          }
        }

        &:first-child {
          a {
            padding-left: 0;
          }
        }

        &:last-child {
          a {
            padding-right: 0;
          }
        }

        // responsive layout
        @media (max-width: 510px) {
          display: block;
        }
      }
    }
  }

  .view-sitemap {
    margin-bottom: 10px;
    text-align: right;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.778;
    @media (max-width: $bp-max-small) {
      text-align: left;
      margin-bottom: 20px;
    }
  }

  .container--70-30 > .container__col {
    margin-bottom: 0;
  }
}

.global-footer__inner {
  display: flex;
  justify-content: space-between;
  padding-top: 28px;

  @media (max-width: $bp-max-medium) {
    padding-top: 33px;
    display: block;
  }

  .copyright {
    flex-grow: 17;
  }

  .footer-links {
    flex-grow: 2;
    ul {
      text-align: left;
    }
    @media (max-width: $bp-max-medium) {
      margin-top: 10px;
      li {
        margin-left: 0;
      }
    }
  }
}

.global-footer__column {
  &--connect {
    flex-basis: 300px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &--nav {
    flex-basis: 515px;
    flex-grow: 0;
    margin-right: 30px;
    flex-shrink: 1;
  }
}

.global-footer__cta {
  font-size: 16px;
  margin-bottom: 45px;
  margin-top: 30px;

  p {
    font-size: inherit;
  }

  @media (max-width: $bp-max-medium) {
    margin-bottom: 0;
  }
}
