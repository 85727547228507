// Slick Slider customization should happen per-component,
// however, there are some global settings that can be set in
// base/_slick-settings.scss
$slideshow-arrow-size: 90px;
$slideshow-arrow-small-size: 45px;
$slideshow-dots-size: 45px;

.slideshow {
	// hide all slides except first until
	// slick slider initializes
	.slideshow__slider {
		right: -1px;
		position: relative;
	}

	.image-block {
		opacity: 0;
		margin-bottom: 0;
	}

	.slick-current.image-block {
		opacity: 1;
	}

	.image-block__title {
		display: none;
	}

	.image-block__media {
		border-bottom: none;

		figcaption {
			border-bottom: 0;
			max-width: 700px;
			padding-top: 40px;
			padding-bottom: 35px;
			@media(max-width: $bp-max-small) {
				padding-left: 40px;
				padding-right: 40px;
			}
		}
	}
	// SlickSlider customizations
	.slick-next,
	.slick-prev {
		z-index: 100;
		display: block;
		transition: background-color 0.3s;
		background-color: $buttercup-yellow;
		color: $slick-arrow-color;
		border-radius: 0 !important;
		top: calc(50% - 50px);
		// the button, the icon container
		// and svg need to have dimenions
		// explicitly defined
		&,
		& .icon,
		& svg {
			width: $slideshow-arrow-size;
			height: $slideshow-arrow-size;
			@media (max-width: $bp-max-medium) {
				width: $slideshow-arrow-small-size;
				height: $slideshow-arrow-small-size;
			}
			@media (max-width: $bp-max-small) {
				top: calc(40% - 50px);
			}
		}

		&:focus,
		&:hover {
			// background-color: $grey-400;
		}

		&::before {
			content: "";
		}
	}

	.slick-next {
		right: 0;
		@media (max-width: $bp-max-medium) {
			right: -15px;

			.container--bleed & {
				right: 0;
			}
		}
	}

	.slick-prev {
		left: 0;
		@media (max-width: $bp-max-medium) {
			left: -15px;

			.container--bleed & {
				left: 0;
			}
		}
		@media (min-width: 769px) {
			// matches breakpoint in imageslider.js
			display: none;
		}
	}

	.slick-dots {
		position: static;

		li {
			margin: 0 8px;
			// match specificy in origin css
			button {
				&::before {
					content: "";
					display: none;
				}
			}

			&:focus {
				// add another box shadow to show
				// rounded outline
				box-shadow: 0 0 0 2px $white, 0 0 0 4px $base-border-color, 0 0 0 6px #5B9DD9;
			}
		}

		button,
		li {
			width: $slideshow-dots-size;
			height: 5px;
		}

		button {
			// width: 10px;
			// height: 10px;
			margin: 0 auto;
			padding: 4px;
			background-color: $grey-400;
			transition: background-color 0.3s;
		}

		.slick-active {
			button {
				background-color: $fun-blue;
			}
		}
	}
}
