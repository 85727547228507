$promo-media-offset: 50px;

.health-exec {
  .image-promo {
    @include component-whitespace();

    .image-promo__media {
      position: relative;

      figcaption {
        background-color: $rouge;
        padding: 40px 42px;
        
        .btn--link {
          @include font(garamond);
          font-weight: 500;
          padding-bottom: 0;
          margin-top: 31px;
        }
      }
    }

    .image-promo__title {
      @include font(garamond, bold);
      line-height: 100%;
      margin-bottom: 0;
    }

    .image-promo__metadata {
      @include font(garamond);
      margin-bottom: 0;
    }
  }
}
