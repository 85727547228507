// colors.scss
// Define color variables here
// Color variables named with https://colorna.me

/// wireframe colors
/// @author Google
/// @link https://www.google.com/design/spec/style/color.html
/// NOTE: this uses Google's Material Design color scheme - "Grey"
$grey-50: #fafafa !default;
$grey-100: #f5f5f5 !default;
$grey-200: #eeeeee !default;
$grey-300: #e0e0e0 !default;
$grey-400: #bdbdbd !default;
$grey-500: #9e9e9e !default;
$grey-600: #757575 !default;
$grey-700: #616161 !default;
$grey-800: #424242 !default;
$grey-900: #212121 !default;

// base colors

// variables
$tundora: #4a4a4a;
$fun-blue: #1970b1;
$saffron: #f4ad3d;
$pickled-bluewood: #304958;
$white: #fff !default;
$black: $tundora !default;
$iron: #e6e7e8;
$dusty-gray: #9b9b9b;
$dove-gray: #737373;
$christi-green: #6fb40f;
$buttercup-yellow: #f1c40f;
$denim-blue: #0f6eb4;
$half-baked-blue: #86b6d9;
$black-squeeze-blue: #e7f0f7;
$sea-buckthorn-orange: #f6ae2c;
$link-water: #e7f1f8;
$tory-blue: #115c91;
$boulder: #787878;
$limed-spruce: #37474f;
$havelock-blue: #4a90e2;
$gold: #f1d302;
// borders
$base-border-color: $grey-400 !default;
$silver: #c6c6c6;
$olive: #6f9702;

// bgs
$base-background-color: $white !default;

// social colors
$twitter: #55acee;
$facebook: #3b5998;
$youtube: #e52d27;
$linkedin: #0976b4;
$google-plus: #dd4b39;
$rss: #f26522;
$instagram: #a63d97;

//HealthcareExecutives colors
$rhino: #282e53;
$rouge: #a63c63;
$white-lilac: #f4f6fb;
$moody-blue: #6d81d3;
$chetwode-blue: #8193d6;
