.health-exec {
  @include font(open-sans, regular);
  font-size: 20px;
  line-height: 35px;

  .txt-h1,
  .txt-h2,
  .txt-h3,
  .txt-h4,
  .txt-h5,
  .txt-h6,
  h1:not(.headline__title),
  h2:not(.headline__title),
  h3:not(.headline__title),
  h4:not(.headline__title),
  h5:not(.headline__title),
  h6:not(.headline__title) {
    @include font(garamond, bold);
  }

  .btn--link {
    @include font(open-sans, bold);
    color: $rhino;
  }
}
