.highlight-content-duo {
	max-width: 856px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	margin-bottom: 60px;

	@media (max-width: 767px) {
		margin-bottom: 40px;
	}

	&__header {
		margin-bottom: 30px;
	}

	&__header-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 767px) {
			flex-direction: column;
			align-items: normal;
			margin-bottom: 16px;
		}
	}

	&__header-title {
		font-size: 40px;
		line-height: 50px;
		margin-bottom: 8px;
	}

	&__header-link {
		@include font(futura);
		text-transform: uppercase;
		font-weight: 600;
		font-size: 18px;
		color: $fun-blue;
		cursor: pointer;

		&:after {
			content: "\2192";
			padding-left: 8px;
			color: $black;
		}
	}

	&__header-content {
		font-size: 18px;
		line-height: 30px;
	}

	&__items-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 40px;

		@media (max-width: 767px) {
			grid-template-columns: 1fr;
		}
	}

	&__image {
		width: 100%;
		height: auto;
		margin-bottom: 38px;

		img {
			width: 100%;
			height: 180px;
			object-fit: cover;
		}

		@media (max-width: 767px) {
			margin-bottom: 16px;
		}
	}

	&__title {
		font-size: 25px;
		line-height: 29px;
		font-weight: 600;
		color: $fun-blue;
		margin-bottom: 15px;
	}

	&__description {
		font-size: 18px;
		line-height: 24px;
		font-weight: 400;
		display: block;
		margin-bottom: 15px;
	}

	&__button {
		@include font("futura", demi);
		border: 2px $denim-blue solid;
		font-size: 16px;
		padding: 10px 18px;
		text-transform: uppercase;
		text-align: center;
		width: 100%;
		letter-spacing: 1px;
		background-color: $denim-blue;
		color: $black-squeeze-blue;
		display: block;
		width: fit-content;

		&:hover {
			color: $black-squeeze-blue;
		}
	}
}
