.promo-cta-grid {
  margin-bottom: $base-component-whitespace;
  overflow: hidden;
  text-align: center;
}

.promo-cta-grid__list {
  @include zero-list();

  @media(min-width: $bp-min-medium) {
    display: flex;
    flex-wrap: wrap;
    margin: -#{$base-component-whitespace};
  }
}

.promo-cta-grid__item {
  margin-bottom: $base-component-whitespace;

  @media(min-width: $bp-min-medium) {
    margin: $base-component-whitespace;
    width: calc(50% - (2 * #{$base-component-whitespace}));
  }

  @media(min-width: $bp-min-large) {
    width: calc((100% / 3) - (2 * #{$base-component-whitespace}));
  }

  /* This is hacky, but about the only way to make this work... */
  @media(min-width: $bp-max-large) {
    .container--100 > .container__inner > .promo-cta-grid & {
      width: calc(25% - (2 * #{$base-component-whitespace}));
    }
  }
}

.promo-cta-grid__item-title {
  @extend .txt-h5;

  margin-bottom: 10px;
}

.promo-cta-grid__image {
  display: block;
  margin-bottom: 18px;
  width: 100%;

  picture,
  img {
    height: auto;
    max-width: 100%;
  }
}


.promo-cta-grid__desc {
  text-align: left;
}
