.health-exec {
  .content-detail-banner {
    background-color: $black-squeeze-blue;
    padding: 40px 100px;
    width: 100%;
    @include font(open-sans);
    @media(max-width: $bp-max-medium) {
      padding: 20px;
    }
    @media(max-width: $bp-max-small) {
      padding: 10px;
    }

    .content-detail-banner__content-type {
      @include font(open-sans,semibold);
      color: $rouge;
      font-size: 16px;
      letter-spacing: .5px;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    .content-detail-banner__title {
      color: $rhino;
    }

    .content-detail-banner__byline {
      display: flex;
      font-size: 20px;
      font-weight: 500;
      color: $tundora;
      margin-bottom: 30px;
      flex-wrap: wrap;
      @media(max-width: $bp-max-medium) {
        flex-direction: column;
      }
    }

    .content-detail-banner__author {
      @include font(open-sans, semibold);
      flex-basis: 50%;
      flex-grow: 0;
      flex-shrink: 0;
      margin-bottom: 10px;
      color: $denim-blue;
      @media(max-width: $bp-max-medium) {
        flex-basis: 1;
      }
    }

    .content-detail-banner__collection,
    .content-detail-banner__topics {
      font-size: 16px;
      font-weight: 600;
      color: $rhino;

      span {
        background-color: $white;
        border-radius: 5px;
        padding: 2px 15px;
        color: $tundora;
        margin-right: 15px;
        font-weight: 600;
        font-size: 13px;
        white-space: nowrap;
        margin-bottom: 15px;
        display: inline-block;

        a {
          color: $tundora;
        }

        &:first-of-type {
          margin-left: 15px;
        }
      }
    }

    .scEnabledChrome {
      // fix for EE
      display: block;
    }
  }

  .container--30-70 {
    .content-detail-banner {
      margin-left: -3.52558%;
      width: calc(100% + 3.52558%);
      @media(max-width: $bp-max-medium) {
        margin-left: auto;
        width: 100%;
      }
    }
  }
}
