// web forms for marketers overrides.

form[data-wffm] {
  @include font(georgia);
  font-size: 18px;
  color: $tundora;

  .page-header {
    @include font(futura, demi);
    text-transform: uppercase;
    color: $tundora;

    h1 {
      font-size: 20px;
    }
  }

  legend {
    @include font(futura);
    color: $tundora;
    border-bottom: none;
  }

  input,
  select {
    @include font(futura);
    border: 2px solid $fun-blue;
    border-radius: 0;
    color: $tundora;
  }

  input[type=file],
  input[type=submit] {
    background-color: $fun-blue;
    color: $white;
    padding: 7px 29px;
    border-radius: 0;
    text-transform: uppercase;
    border: 0;
    position: relative;
  }

  input[type=file]:after {
    content: "choose File";
    background-color: #1970b1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 7px 10px;
    width: 143px;
  }

  select {
    background-image: url("../../img/wffm/icon---selector.png");
    background-repeat: no-repeat;
    background-position: center right 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    @media(max-width: $bp-max-medium) {
      width: 100%;
    }
  }

  option {
    border-bottom: $fun-blue 2px solid;
    position: relative;
    font-size: 18px;
    &:last-child {
      border-bottom: 0;
    }

    &[selected=selected] {
      color: $fun-blue;

      &:after {
        content: "";
        position: absolute;
        right: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid $fun-blue;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  .help-block {
    @include font(futura)
  }

  label {
    @include font(futura, medium);
    color: $tundora;
    font-size: 18px;
  }

  .required-field {
    .checkbox:after,
    .control-label:before {
      @include font(futura, medium);
      color: $tundora;
      content: "*";
    }
    .radio,
    .checkbox {
      padding-left: 20px;
      label {
        padding-left: 0;
        margin-right: 20px;
      }
    }
  }

  .js-wffm__radio {
    background-color: $white;
    border: 2px solid $fun-blue;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: inline-block;
    position: relative;
    border-radius: 50%;
    vertical-align: sub;
    margin-right: 5px;

    &:after {
      border-radius: 50%;
      background: $fun-blue;
      display: none;
      content: "";
      top: 3px;
      left: 3.5px;
      width: 9px;
      height: 9px;
      position: absolute;
    }
  }

  .js-wffm__checkbox {
    background-color: $white;
    border: 2px solid $fun-blue;
    height: 20px;
    width: 20px;
    display: inline-block;
    position: relative;
    vertical-align: sub;
    cursor: pointer;
    margin-right: 5px;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid $white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input[type=checkbox],
  input[type=radio] {
    height: 24px;
    width: 24px;
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  input[type=checkbox]:checked ~ .js-wffm__checkbox {
    background-color: $fun-blue;
  }

  input[type=checkbox]:checked ~ .js-wffm__checkbox:after,
  input[type=radio]:checked ~ .js-wffm__radio:after {
    display: block;
  }

  .col-md-12 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .form-group {
    margin-bottom: 61px;
    width: 100%;
  }

  .thirdAvailableWidth {
    width: 32%;
  }

  td,
  th {
    border: 0;
  }

  tr:not(:last-child) {
    margin-bottom: 25px;
  }
}
