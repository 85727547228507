.text-banner {
	@include component-whitespace();
	padding: 30px 15px;
	display: flex;
	justify-content: space-between;
	@media(max-width: $bp-max-small) {
		display: block;
	}
}

.text-banner__title {
	font-size: 35px;
	width: 50%; // needed for IE

	&::before {
		content: " ";
		border-top: $denim-blue 5px solid;
		width: 55px;
		height: 100%;
		display: inline-block;
		margin-top: 20px;
		margin-right: 15px;
		float: left;
		@media(max-width: $bp-max-small) {
			margin-right: 5px;
			width: 23px;
			float: none;
			margin-top: -5px;
			vertical-align: middle;
		}
	}

	& > span {
		width: calc(100% - 70px);
		display: inline-block;
		@media(max-width: $bp-max-small) {
			width: 100%;
			display: inline;
		}
	}
	@media(max-width: $bp-max-small) {
		width: 100%;
	}
}

.text-banner__details-container {
	margin-left: 15px;
	margin-top: 0;
	width: calc(50% - 15px);
	@media(max-width: $bp-max-small) {
		width: calc(100% - 15px);
		margin: 0;
	}
}

.text-banner__description {
	font-size: 18px;
	line-height: 30px;
	// @include font(futura);
	font-weight: 500;
}

.text-banner__cta {
	transition: opacity 0.28s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow
	padding-left: 0;
	font-size: 18px;
	color: $denim-blue;
	display: block;
	margin-top: 10px;
}
