$cover-width: 220px;
$cover-gutter: 29px;

.book-details {
  .container--100,
  .container--70-30 {
    margin-right: 0;
    margin-left: 0;
  }
  @media (max-width: $bp-max-medium) and (min-width: $bp-min-small) {
    margin-top: 10px;
  }
}

.book-details__upper {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $bp-max-small) {
    flex-direction: column;
  }
}

.book-details__left {
    width: $cover-width;
    margin-bottom: 55px;

    @media (max-width: $bp-max-small) {
        width: 100%;
    }

    .book-details__image {
        margin-bottom: 20px;
        width: 100%;
        height: auto;
        min-height: 1px;

        @media (max-width: $bp-max-small) {
            max-width: $cover-width;
            margin: 0 auto 20px auto;
            display: block;
        }
    }

    .book-details__price {
        @include font(futura, demi);
        font-size: 36px;
        margin-bottom: 20px;
    }

    .book-details__button {
        width: 100%;
        padding: 14px;
        text-align: center;

        &:hover {
            text-decoration: none;
        }
    }

    .book-details__button--disabled {
        background-color: $grey-600;
        border-color: $grey-600;
        &:hover {
          color: $white;
        }
    }

    .book-details__exam-copy {
        padding-top: 14px;
    }
}

.book-details__right {
  margin-left: $cover-gutter;
  width: calc(100% - #{$cover-gutter} - #{$cover-width});
  @media (max-width: $bp-max-small) {
    width: 100%;
    margin: 0 auto;
  }
  .book-details__title {
    @include font(futura, demi);
    font-size: 45px;
    line-height: 51px;
    margin-top: -11px;
  }
  .book-details__pricing {
    margin: 30px 0;

    ul {
      margin-left: 0;
      margin-bottom: 0;
      li {
        @include font(futura, demi);
        list-style-type: none;
        font-size: 20px;
        margin: 0;
        line-height: 34px;
        padding: 0;
      }
    }
  }
  .book-details__author {
    @include font(futura);
    font-size: 20px;
    font-weight: 500;
    line-height: 34px;
    color: $tundora;
    margin-bottom: 0;
    span:after {
      content: ", ";
    }
    span:last-of-type:after {
      content: "";
    }
    a {
      color: $tundora;
    }
  }

  .book-details__detail {
    @include font(futura, light);
    font-size: 20px;
    line-height: 34px;
  }

  @media print {
    width: 100%;
    margin-left: 0;
  }
}

.book-details__labels {
    @include font(futura, medium);
    display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.book-details__labels__label {
  color: $boulder;
  font-size: 20px;
  padding-top: 4px;
  min-width: 80px
}

.book-details__labels__list {
  @include zero-list;
  flex-grow: 2;
  margin-bottom: -8px;

  li {
    background-color: $black-squeeze-blue;
    color: $tundora;
    display: inline-block;
    font-size: 16px;
    margin: 0 8px 8px 8px;
    list-style-type: none;
    padding: 5px 11px;
    border-radius: 5px;

    a {
      color: $tundora;
    }
  }
}

.book-details__lower {
  margin-top: 40px;

  .book-details__description,
  .book-details__testimonial {
    @include font(georgia);
    font-size: 18px;
    line-height: 30px;

    p {
      font-size: inherit;
      line-height: inherit;
    }
  }
  .book-details__description__text {
    transition: all 1s;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-image: linear-gradient(rgba(255, 255, 255, 0.00) 70%, white);
      
      @media print {
        display: none;
      }
    }
  }
  .book-details__read-more {
    @include font(futura, demi);
    display: block;
    margin: 0 auto;
    max-width: 200px;
    background-color: $black-squeeze-blue;
    color: $tundora;
    border: 0;
    padding: 10px;
    visibility: hidden; //hidden by default but make visible if description longer than 250 characters.
    &:hover, &:active, &:focus {
      color: $denim-blue;
    }
    .book-details__text--closed {
      display: inline;
    }
    .book-details__text--open {
      display: none;
    }
  }
}

.is-open {
  .book-details__description__text:before {
    display: none;
  }
  .book-details__read-more {
    svg {
      transform: rotate(180deg);
    }
    .book-details__text--closed {
      display: none;
    }
    .book-details__text--open {
      display: inline;
    }
  }
}

.course-taxonomy {
    .book-details__labels {
        margin-top: 30px;
    }
}