.breadcrumbs {
  @include component-whitespace();
  @include font(futura);
  font-weight: 500;
  padding-bottom: 10px;
}

.breadcrumbs__list {
  @include zero-list;
  font-size: 14px;
}
// breadcrumbs item

.breadcrumbs__item {
  display: inline-block;
  margin-bottom: 0;
  color: $tundora;

  a {
    color: $denim-blue;
  }
}

.breadcrumbs__divider {
  color: $grey-500;

  .icon {
    fill: currentColor;
  }
}
