.ad {
  text-align: center;
  margin-bottom: 30px;

  img {
    max-width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.ad__content {
  display: inline-block;
  position: relative;
}

.ad__text {
  @include font(futura);
  align-items: center;
  color: $dusty-gray;
  display: flex;
  font-size: 13px;

  &:before,
  &:after {
    background-color: $dusty-gray;
    content: "";
    display: inline-block;
    flex-grow: 1;
    height: 1px;
    position: relative;
    vertical-align: middle;
  }

  &:before {
    margin-right: 10px;
  }

  &:after {
    margin-left: 10px;
  }
}

.ad__close {
  background-color: $fun-blue;
  border: 1px solid $dusty-gray;
  height: 15px;
  right: 0;
  top: 35px;
  width: 15px;
  position: absolute;

  svg {
    fill: $white;
    height: 9px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 9px;
  }
}

.ad--wide {
  @media (max-width: $bp-max-medium) {
    display: none;
  }
}

.ad--mobile {
  display: none;

  @media (max-width: $bp-max-medium) {
    display: block;
  }
}

.ad--mobile-interstitial {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: none;
  left: 0;
  margin-bottom: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 101;

  .ad__close {
    height: 20px;
    top: 0;
    width: 20px;
  }

  @media (max-width: $bp-max-medium) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
