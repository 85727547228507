.promo-cards {
  .promo-cards__wrapper {
    display: flex;
    flex-wrap: wrap;

    .promo-cards__col {
      width: 33.3333%;
      padding-left: 15px;
      padding-right: 15px;

      &:last-child {
        padding-left: 30px;
        padding-right: 0;
      }

      &:first-child {
        padding-left: 0px;
        padding-right: 30px;
      }

      @media (max-width: $bp-max-medium) {
        width: 50%;
        margin: 0 auto;
        padding-right: 0;

        &:first-child {
          padding-right: 15px;
        }

        &:last-child {
          .promo-cards__card {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.is-overlap {
    margin-top: -1 * ($container-bottom-space + 45px);
    @media (max-width: $bp-max-medium) {
      margin-top: 0;
    }
  }
}

.promo-cards__card {
  background: $fun-blue;
  color: $white;
  height: 100%;
  display: block;
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: $bp-max-medium) {
    min-height: 80px;
    display: flex;
    height: auto;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto 15px;
  }

  & > *:nth-last-child(2) {
    @media (min-width: $bp-min-large) {
      padding-bottom: 30px;
    }
  }

  .promo-cards__card-title {
    @include font(futura, heavy);
    @include zero;
    font-size: 50px;
    line-height: 1.3;
    text-transform: uppercase;
    text-align: center;
    padding: 30px 0 0;

    @media (max-width: $bp-max-medium) {
      font-size: 35px;
      line-height: 1.143;
      padding: 0;
    }
  }

  .promo-cards__card-description {
    @include font(futura, regular);
    font-size: 22px;
    line-height: 1.455;
    text-align: center;
    width: calc(100% - 50px);
    margin: 0 auto;

    @media (max-width: 1199px) {
      font-size: 19px;
    }

    @media (max-width: $bp-max-medium) {
      display: none;
    }
  }

  .promo-cards__card-cta {
    background: $tory-blue;
    padding: 16px 32px;
    width: 100%;
    margin-top: auto;

    .btn--link {
      color: $white;
      padding-top: 0;
      padding-bottom: 0;

      &::after {
        color: $white;
      }

      @media (max-width: 1199px) {
        font-size: 16px;
      }
    }

    @media (max-width: $bp-max-medium) {
      display: none;
    }
  }

  &:hover {
    color: $white;
    .promo-cards__card-cta {
      background: $havelock-blue;
    }

    @media (max-width: $bp-max-medium) {
      background: $havelock-blue;
    }
  }
}
