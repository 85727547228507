$page-banner__caption-offset: 30;

.page-banner {
	@include component-whitespace();
	overflow: hidden;
	position: relative;
	color: $tundora;
	display: flex;

	header {
		width: calc(100% - 96px);
		@media(max-width: $bp-max-small) {
			width: 100%;
		}
	}

	&:before {
		content: "";
		background-color: $christi-green;
		width: 76px;
		height: 5px;
		display: block;
		margin-right: 20px;
		margin-top: 26px;
		@media(max-width: $bp-max-medium) {
			margin-top: 18px;
			width: 60px;
		}
		@media(max-width: $bp-max-small) {
			display: none;
		}
	}

	h1:before {
		@media(max-width: $bp-max-small) {
			background-color: $christi-green;
			content: "";
			display: block;
			float:left;
			height: 5px;
			margin-top: 12px;
			margin-right: 20px;
			width: 23px;
		}
	}
}

.page-banner__figure {
	margin: 0;

	img {
		width: 100%;
		display: block;
		margin: 0 auto;
		object-fit: cover;
	}

	video {
		width: 100%;
		display: block;
		margin: 0 auto;
		object-fit: cover;
	}
}

.has-height .page-banner__figure {
	width: 100%;
	height: 100%;

	picture {
		height: 100%;
	}

	img {
		height: 100%;
	}

	video {
		height: 0;
	}
}

.page-banner__caption {
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	max-width: $max-width;
	width: 100%;
}

.page-banner__caption-inner {
	position: absolute;
	bottom: rem($page-banner__caption-offset);
	left: 0;

	p {
		margin-bottom: 0;
	}
	@media(max-width: $bp-max-large) {
		left: rem($page-banner__caption-offset);
		bottom: rem($page-banner__caption-offset);
	}
}

.page-banner__eyebrow {
	text-transform: uppercase;
	font-size: 1.125rem;
	margin-bottom: 0;
}

.page-banner__title {
	color: $tundora;
	font-size: 50px;
	@media(max-width: $bp-max-medium) {
		font-size: 45px;
	}
}

.page-banner__subtitle {
	font-size: 18px;
	line-height: 30px;
	margin-bottom: 40px;
	@media(max-width: $bp-max-medium) {
		font-size: 18px;
	}
}

.page-banner__action {
	display: inline-block;
	color: $white;
	transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1), color 0.28s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In
	background-color: darken($white, 10);
	text-decoration: none;
	padding: 10px 20px;

	&:hover {
		background-color: darken($white, 45);
		color: $white;
	}
}

.container--100 {
	.page-banner__caption-inner {
		left: rem($page-banner__caption-offset);
	}
}
