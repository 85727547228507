.primary-nav {
    width: 100%;
    color: $fun-blue;

    li {
        @include zero;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.2;

        &:first-child a {
            padding-left: 0;
        }

        &:last-child a {
            padding-right: 0;
        }
        // responsive layout
        @media (max-width: 510px) {
            display: block;
        }
    }

    a,
    li > span {
        @include font(futura, demi);
        display: inline-block;
        padding: 10px 16px;
        text-decoration: none;
        white-space: nowrap;
        cursor: pointer;
        width: 100%;
        line-height: 1.281;
        transition: color 0.28s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In
        text-transform: uppercase;

        @media (max-width: $bp-max-large) {
            padding: 10px 5px;
        }

        @media (max-width: $bp-max-medium) {
            color: $white;
        }
    }

    li > span {
        @media (max-width: $bp-max-medium) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .login-form {
        display: none;

        @media (max-width: $bp-max-medium) {
            display: block;
        }

        .login-form__list {
            margin: 0;
        }

        .login-form__trigger {
            @media (max-width: $bp-max-medium) {
                display: none;
            }
        }
    }

    .utility-nav {
        @media (max-width: $bp-max-medium) {
            border-top: 1px solid $white;
            margin-top: 20px;
            padding: 20px 0 0;
        }
    }

    .utility-nav__list {
        @include zero-list;
        display: none;

        @media (max-width: $bp-max-medium) {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
        }
    }

    .global-header__cta {
        display: none;

        @media (max-width: $bp-max-medium) {
            display: block;
            padding: 0 0 20px;
        }
    }

    .utility-nav__item {
        display: block;
        font-size: 0.875rem;

        .cart-icon {
            @media (max-width: $bp-max-medium) {
                filter: brightness(1000);
            }
        }

        a {
            padding-top: 6px;
            padding-bottom: 5px;

            &:hover {
                color: $grey-900;
            }
        }
        // &:last-child {
        //     margin-bottom: 8px;
        // }
        @media (max-width: $bp-max-medium) {
            font-size: 1rem;
        }
    }

    .cart-icon-mobile {
        @media (max-width: $bp-max-medium) {
            margin-bottom: -18px;
			margin-top: -15px;
        }
    }
}

.primary-nav__menu-wrap {
    display: block;

    @media (max-width: $bp-max-medium) {
        background: $fun-blue;
        display: none;
        min-height: 100vh;
        left: 0;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 80px;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 100;
    }
}

.primary-nav__menu {
    @include zero-list;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: $bp-max-medium) {
        flex-direction: column;
    }
}

.primary-nav__item {
    position: relative;
    transition: background-color 0.36s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In

    &.is-open,
    &.is-active,
    &:hover {
        color: $tundora;

        & > a {
            color: $tundora;
        }

        .icon {
            fill: $tundora;

            @media (max-width: $bp-max-medium) {
                fill: $white;
            }
        }
    }

    &.is-open {
        .primary-nav__submenu {
            height: auto;
            opacity: 1;
        }

        .icon {
            transform: rotate(180deg);
        }
    }

    .icon {
        margin-left: 8px;
        vertical-align: middle;
        transition: transform 0.28s;
    }

    .icon--left {
        display: none;
    }

    @media (max-width: $bp-max-medium) {
        width: 100%;

        .icon {
            height: 2rem;
            margin-left: -2rem;
            margin-right: -4px;
            width: 2rem;
            display: none;
        }

        .icon--left {
            display: inline;
        }
    }

    a,
    & > span {
        @media (max-width: $bp-max-medium) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.primary-nav__submenu {
    @include zero-list;
    position: absolute;
    display: block;
    background-color: $white;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.4s ease-out;
    z-index: 100;

	.menu-item-title {
		font-family: futura-pt, sans-serif;
		font-size: 16px;
		line-height: 12px;
		margin-top: 10px;
		margin-bottom: 12px;
		text-transform: uppercase;
		font-weight: 600;
	}

	.menu-item-description {
		font-size: 12px;
		line-height: 20px;
		margin-bottom: 12px;
		text-transform: none;
		white-space: normal;
		font-weight: normal;
		font-family: Georgia, "Times New Roman", Times, serif;
	}

    li {
        border-bottom: 1px solid $grey-400;
        display: block;
        font-size: 1rem;

        &:hover {
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    @media (max-width: $bp-max-medium) {
        width: 100%;
        display: none;
        position: static;
        transition: none;
        height: auto;
        opacity: 1;
    }
}

.primary-nav__subitem {
    display: block;
    transition: background-color 0.36s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In

    &:hover {
        // background-color: $grey-800;
    }

    a {
        color: $fun-blue;
    }

    @media (max-width: $bp-max-medium) {
        width: 100%;
    }
}

.primary-nav__label-open,
.primary-nav__label-close {
	@include font(montserrat, bold);
	font-size: 0.75rem;
	text-transform: uppercase;
	padding-top: 4px;
	color: $white;
}

.primary-nav__label-open {
	display: none;

	@media (max-width: $bp-max-medium) {
		display: block;
	}
}

.primary-nav.is-open .primary-nav__label-open {
	display: none;
}

.primary-nav__label-close {
	display: none;
}

.primary-nav.is-open .primary-nav__label-close {
	@media (max-width: $bp-max-medium) {
		display: block;
	}
}

.primary-nav__toggle {
	@include font(futura);
	background: $fun-blue;
	border: 0;
	cursor: pointer;
	display: none;
	margin-left: 20px;
	padding-bottom: 13px;
	padding-left: 12px;
	padding-right: 12px;
	padding-top: 15px;
	position: relative;
	z-index: 101;

	@media (max-width: $bp-max-medium) {
		display: block;
	}

	&.is-open {
		background-color: transparent;
		border-color: transparent;
	}
}
// Icon style and animation code cribbed from
// http://codepen.io/pedrocampos/pen/gufrx/
.primary-nav__toggle-icon {
	position: relative;
	display: inline-block;
	cursor: pointer;
	width: 35px;
	height: 24px;

	span {
		margin: 0 auto;
		position: relative;
		top: 10px;
		transition-duration: 0s;
		transition-delay: 0.2s;

		&::before,
		&::after {
			position: absolute;
			content: "";
			transition-property: margin, transform;
			transition-duration: 0.2s;
			transition-delay: 0.2s, 0s;
		}

		&,
		&::before,
		&::after {
			display: block;
			width: 30px;
			height: 3px;
			background-color: $white;
		}

		&::before {
			margin-top: -10px;
		}

		&::after {
			margin-top: 10px;
		}
	}
}

.is-open .primary-nav__toggle-icon {
	span {
		background-color: rgba(0, 0, 0, 0);
		transition-delay: 0.2s;

		&::before,
		&::after {
			margin-top: 0;
			transition-duration: 0.2s;
			transition-delay: 0.2s, 0s;
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}
	}
}

// DESKTOP NAV DROPDOWN

@media (min-width: $bp-max-medium) {
	.primary-nav__item.js-nav-item {
		.dropdown-enabled {
			color: #1970b1;
			font-family: futura-pt, sans-serif;
			font-weight: 550;
			font-style: normal;
			position: relative;
			background-color: white;
			width: 200px;
			left: 695px;
			top: 133px;
		}

		span {
			padding: 0 !important;

			a {
				width: auto !important;
			}
		}

		.dropdown-enabled:hover {
			color: #4a4a4a;
		}

		.down-arrow {
			padding: 4px;
			font-size: 22px;
			position: relative;
			top: -4px;
		}

		.nav-dropdown {
			display: flex;
			width: fit-content;
			height: auto;

			a {
				font-weight: normal;
				text-transform: none;
			}

			li {
				border-bottom: none;
			}
		}

		a {
			color: inherit;
			text-decoration: none;
			font-style: normal;
			display: inline-block;
			padding: 0;
			cursor: pointer;
			width: 100%;
			line-height: normal;
			white-space: normal;
		}

		h1 {
			font-family: futura-pt, sans-serif;
			font-size: 16px;
			line-height: 12px;
			margin-top: 10px;
			text-transform: uppercase;
		}

		h2 {
			font-family: Georgia, "Times New Roman", Times, serif;
			font-size: 12px;
			font-weight: 400;
			line-height: 20px;
		}

		li {
			list-style-type: none;
			color: white;
			font-family: futura-pt, sans-serif;
			font-size: 16px;
			line-height: 34px;
			font-weight: 250;
		}

		.column-2-list-item {
			margin-bottom: 0;
		}

		.column-2-list-item:hover {
			font-weight: 400;
			color: #f1c40f;
		}

		.column-1 {
			background-color: white;
		}

		.column-1-menu-item {
			width: 286px;
			padding: 22px 25px;
			color: #4a4a4a;
			border-left: 5px solid white;
		}

		.active {
			background-color: #1970b1;
			border-left: 5px solid #f4ad3d;
			color: white;
		}

		.column-2 {
			width: 340px;
			background-color: #135285;
			padding: 3px 40px 3px 0px;
			display: none;

			ul {
				padding-left: 40px;
				margin-left: 0;
			}
		}

		.column-2-list-1 {
			width: 300px;
			margin-left: 0;
			margin-bottom: 0;
		}

		.column-2-list-2 {
			display: none;
		}

		.column-2-list-3 {
			display: none;
		}

		.column-3 {
			display: none;
			flex-direction: row;
			background-color: #d0e3f0;
			padding: 10px;
			max-width: 281px;

			img {
				max-width: 100%;
				max-height: 100%;
				object-fit: cover;
			}
		}

		.hero-image {
			width: 261px;
		}

		.hero-button {
			display: inline-block;
			padding: 6px 16px;
			text-decoration: none;
			background: #1970b1;
			color: white;
			font-family: futura-pt, sans-serif;
			font-size: 16px;
			cursor: pointer;
			text-align: center;
			transition: background 250ms ease-in-out, transform 150ms ease;
			-webkit-appearance: none;
			-moz-appearance: none;
			max-width: 50%;
			font-weight: 600;
			line-height: 34px;
		}

		.hero-button:hover,
		.hero-button:focus {
			background: #135285;
		}

		.hero-button:active {
			transform: scale(0.99);
		}
	}

	.mobile-version {
		display: none;
	}
}

// MOBILE NAV DROPDOWN

@media (max-width: $bp-max-medium) {
	.primary-nav__item.js-nav-item {
		padding-left: 30px;
		padding-right: 30px;

		span {
			a {
				width: auto !important;
			}
		}

		&.is-open {
			background-color: #135285;
			border-left: 2px solid #f1c40f;
			padding-top: 6px;
			padding-bottom: 12px;

			.mobile-version {
				padding-bottom: 10px;
			}

			.mobile-version {
				display: flex;
				align-items: baseline;

				.dropdown-item {
					display: flex;
					align-items: center;
					flex-basis: 82%;

					svg.icon {
						margin-left: 8px;
					}
				}

				.nav-item-link {
					display: flex;
					justify-content: flex-end;
					flex-basis: 18%;
				}
			}
		}

		.primary-nav__submenu {
			background-color: #135285;

			h1 {
				font-size: 16px;
				line-height: 20px;
				margin-bottom: 0;
			}

			h2 {
				font-size: 12px;
				line-height: 20px;
				margin-bottom: 0;
				text-transform: none;
				white-space: normal;
				font-weight: normal;
				font-family: Georgia, "Times New Roman", Times, serif;
				letter-spacing: 0.3px;
			}

			.menu-item-title {
				font-size: 16px;
				line-height: 20px;
				margin-bottom: 0px;
				margin-top: 0px;
			}

			.menu-item-description {
				font-size: 12px;
				line-height: 20px;
				margin-bottom: 0;
				text-transform: none;
				white-space: normal;
				font-weight: normal;
				font-family: Georgia, "Times New Roman", Times, serif;
				letter-spacing: 0.3px;
			}

			.column-2,
			.column-3 {
				display: none !important;
			}
		}

		.icon {
			margin-left: 0;
		}

		.mobile-version {
			.nav-item-link {
				display: none;
			}

			span {
				font-family: "futura-pt", Helvetica, Arial, sans-serif;
				font-weight: 600;
				font-style: normal;
				display: inline-block;
				text-decoration: none;
				white-space: nowrap;
				cursor: pointer;
				width: 100%;
				line-height: 1.281;
				transition: color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
				text-transform: uppercase;
				color: white;
				padding: 10px 0;
			}
		}

		.desktop-version {
			display: none;
		}
	}
}
