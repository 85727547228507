.footer-nav {
  @include font(futura);

  .footer-nav__menu {
    @include zero-list;

    li {
      border-right: 2px solid $fun-blue;
      display: inline-block;
      line-height: 1;
      margin-bottom: 16px;
      margin-top: 7px;
      margin-right: 15px;
      padding-right: 15px;

      @media (max-width: $bp-max-medium) {
        margin-top: 0;
        margin-bottom: 15px;
      }

      &:last-child {
        padding-right: 0;
        margin-right: 0;
        border-right: none;
      }

      a {
        font-size: 18px;
        line-height: 1;
      }
    }
  }
  /*@include font(futura);
	display: flex;
	flex-wrap: wrap;

	@media(min-width: $bp-min-large) {
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 1;
	}

    @media(max-width: $bp-max-medium) {
    	display: block;
	}

	li {
		margin-bottom: 30px;
	}

	a {
		@include font(futura, demi);
		text-decoration: none;
	}*/
}
/*
.footer-nav__section {
	flex-basis: 50%;
}

.footer-nav__col {
	list-style-type: none;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 15px;
}
*/
