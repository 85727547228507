.sessions-sidebar {
	margin-bottom: 30px;
	max-width: 100%;

	&__title {
		font-size: 25px;
		line-height: 45px;
		margin-bottom: 8px;
	}

	&__description {
		font-size: 18px;
		line-height: 28px;
		margin-bottom: 22px;
	}

	&__session {
		padding: 20px;
		border: 1px solid #d9d9d9;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__session-title {
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 12px;
	}

	&__session-date {
		@include font(futura);
		font-size: 14px;
		line-height: 17px;
		font-weight: 500;
		margin-right: 8px;

		&:last-child {
			margin-right: 0;
			margin-left: 8px;
		}
	}
}
