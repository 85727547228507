$multi-promo__bp-stack: 600px;
$multi-promo__promo-space: 30px;
.multi-promo {
	@include component-whitespace;
	display: flex;
	flex-wrap: wrap;
	// fix IE flexbox bug to prevent text overflow
	& .promo {
		display: block;
		width: 100%;
	}
}

.multi-promo__promo {
	width: 100%;
	padding-right: $multi-promo__promo-space;

	@media(min-width: $bp-min-large) {
		width: 50%;
		@include exactly(3) {
			width: 33.333%;
		}
	}

	@media(min-width: $bp-min-medium) {
		width: 50%;
	}

	@media(width: $multi-image__bp-stack) {
		width: 100%;
	}
}

// 70-column
.container--70-30 .container__col:first-child,
.container--30-70 .container__col:last-child {

	.multi-promo__promo {
		@media(width: $bp-max-large) {
			width: 100%;
		}
	}
}

// 30-column and 50-column
.container--70-30 .container__col:last-child,
.container--30-70 .container__col:first-child,
.container--50-50 .container__col {
	.multi-promo__promo {
		width: 100%;
	}
}
