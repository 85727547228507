
.health-exec {
  // title

  .video-block__title {
    color: $tundora;
    font-size: 24px;
  }
  // figcaption

  .video-block__caption {
    color: $boulder;
    font-size: 20px;
  }
}
