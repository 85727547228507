$promo-media-offset: 50px;

.promo-cta-grid{
    min-height: 30px;
}

.promo {
  @include component-whitespace();
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 2.75rem;

  &.is-left,
  &.is-right {}

  &.is-left {
    flex-direction: row;

    .promo__media {
      margin-right: -$promo-media-offset;
      margin-bottom: 0;
      width: 50%;
      z-index: 1;
    }

    .promo__cta {
      text-align: left;
    }
  }

  &.is-right {
    flex-direction: row-reverse;

    .promo__media {
      margin-left: -$promo-media-offset;
      margin-bottom: 0;
      width: 50%;
      z-index: 1;
    }
  }

  img {
    width: 100%;
  }
  @media(max-width: $bp-max-medium) {
    &.is-left,
    &.is-right {
      align-items: flex-start;
    }
  }
  @media(max-width: $bp-max-medium) {
    &.is-left,
    &.is-right {
      flex-direction: column;

      .promo__media {
        font-size: 0;
        margin: 0;
        width: 100%;
      }
    }
  }
}

.promo__description {
  font-size: 18px;
  line-height: 1.6;
  max-width: 800px;
  @media(max-width: $bp-max-medium) {
    font-size: 16px;
  }
}

.promo__media {
  margin-bottom: 1.125rem;
  margin-top: $promo-media-offset;
}

.promo__content {
  background: $white;
  box-shadow: 0 0 10px 1px rgba($black, 0.4);
  padding: 50px 45px;
  z-index: 2;
  flex: 1;
}

.promo__title {
  @include font(futura, demi);
  font-size: 35px;
  line-height: 42px;
  @media(max-width: $bp-max-large) {
    font-size: 40px;
  }
  @media(max-width: $bp-max-medium) {
    font-size: 36px;
  }
}

.promo__subtitle {
  @include font(georgia, bold);
  font-size: 18px;
  line-height: 30px;
}

.promo__footer {
  display: flex;
  justify-content: space-between;
}

.promo__cta {
  padding-bottom: 0;
}

.promo__cta--alt {
  color: $dove-gray;

  &:after {
    display: none;
  }
}
//small promo variation
.promo--small {
  display: block; //needed for IE11.
  .container--70-30 .container__col:nth-of-type(2) & {
    margin-bottom: 38px;
  }
  .promo__media {
    width: 100%;
  }
  .promo__content {
    box-shadow: none;
    padding-left: 0;
    padding-top: 0;
  }

  figure {
    picture {
      display: block;
      line-height: 0;

      &:after {
        content: "";
        background-color: $buttercup-yellow;
        height: 5px;
        display: block;
        width: 72px;
      }
    }
  }
}

// 30-column and 50-column
.container--30-70 .container__col:first-child {
	.promo {
    padding-right: 20px;
    @media (max-width: $bp-max-medium) {
      padding-right: 0;
    }
  }
}