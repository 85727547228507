.search-box {
  @include font(futura);
  width: 100%;

  @media(max-width: $bp-max-medium) {
    bottom: -50px;
    height: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
  }

  @media(max-width: $bp-max-medium) {
    height: 48px;
  }
}

.search-box__container {
  height: 300px;
}

.search-box__dropdown {
  display: none;
}

.search-box__dropdown__active {
  background-color: #e7f0f7;
  z-index: 2;
  position: relative;
  left: 2px;
  width: calc(100% - 194px);
  margin-right: 0px;
  box-shadow: 0px 3px 4px 0px rgba($color: $fun-blue, $alpha: 0.2);

  .suggested-links__text {
    @include font(georgia);
    color: $black;
    margin: 0px 0px 12px 24px;
    padding-top: 12px;
    font-size: 16px;
  }

  .suggested-links__list {
    list-style: none;
    margin: 4px 0px 4px 0;
    padding-bottom: 8px;

    li {
      padding: 4px 0 4px 24px;

      a:hover {
        &.btn--link {
          color: $black;
        }
      }
    }
  }

  @media(max-width: $bp-max-medium) {
    display: none;
  }
}

.search-box__input {
  position: relative;

  input {
    background: $iron;
    box-shadow: none;
    font-size: 16px;
    padding: 15px 32px 15px 15px;
    width: 100%;
    border: 2px solid $fun-blue;
    border-radius: 3px;

    @media(max-width: $bp-max-medium) {
      border: 0;
      border-radius: 0;
    }
  }

  .search__query-mount & input {
    height: 70px;

    @media(max-width: $bp-max-medium) {
      height: auto;
    }
  }
}

.search-box__button {
  position: absolute;
  right: 15px;
  top: 6px;
  padding: 8px 0;
  border: 0;
  background: transparent;
  cursor: pointer;

  @media print {
    display: none;
  }

  .search__query-mount &,
  .featured-search__search-bar & {
    padding: 24px 65px;
    color: $white;
    background: $fun-blue;
    cursor: pointer;
    height: 70px;
    text-transform: uppercase;
    right: 0;
    top: 0;
    display: block;

    @media(max-width: $bp-max-medium) {
      padding-top: 5px;

    }

    @media(max-width: $bp-max-small) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  svg {
    fill: currentColor;
    width: 20px;
    height: 20px;
  }

  &--toggle {
    display: none;
    margin-right: 10px;
    position: static;

    @media(max-width: $bp-max-medium) {
      display: block;
    }

    svg {
      fill: $fun-blue;
      width: 30px;
      height: 30px;
    }
  }
}

.search-box__button-null {
  position: absolute;
  right: 15px;
  top: 6px;
  padding: 8px 0;
  border: 0;
  background: transparent;
  cursor: pointer;

  @media print {
    display: none;
  }

  .search__query-mount &,
  .featured-search__search-bar & {
    padding: 24px 65px;
    color: $white;
    background: $tundora;
    cursor: pointer;
    height: 70px;
    text-transform: uppercase;
    right: 0;
    top: 0;
    display: block;

    @media(max-width: $bp-max-medium) {
      padding-top: 5px;

    }

    @media(max-width: $bp-max-small) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  svg {
    fill: currentColor;
    width: 20px;
    height: 20px;
  }

  &--toggle {
    display: none;
    margin-right: 10px;
    position: static;

    @media(max-width: $bp-max-medium) {
      display: block;
    }

    svg {
      fill: $fun-blue;
      width: 30px;
      height: 30px;
    }
  }
}

.search-box__loader {
  .search-results__spinner {
    position: absolute;
    top: 0;
    right: 45px;
    left: auto;
    text-align: left;

    img {
      margin-top: 4px;
      width: 32px;
      height: 32px;
    }
  }
}