.social-links {
	ul {
		list-style: none;
		margin: 0;
		display: flex;
	}

	li {
		margin-right: 8px;
		margin-bottom: 0;

		&:last-child {
			margin-right: 0;
		}
	}

	a {
		&[href*="youtube"] {
			color: $youtube;
		}

		&[href*="twitter"] {
			color: $twitter;
		}

		&[href*="instagram"] {
			color: $instagram;
		}

		&[href*="facebook"] {
			color: $facebook;
		}

		&[href*="google"] {
			color: $google-plus;
		}

		&[href*="rss"] {
			color: $rss;
		}
	}
}
