.agenda {
  .agenda__title {
    @include font(futura, demi);
    font-size: 36px;
    text-align: center;
  }

  .agenda__navigation {
    justify-content: space-between;
    margin-top: 86px;
    flex-wrap: nowrap; 
    .agenda__tab {
      display: block;
      padding: 25px 20px;
      position: relative;

      &:hover {
        cursor: pointer;
      }

      &.is-active,
      &:hover {
        @media (max-width: $tabs-mobile-breakpoint) {
          background: $fun-blue;
          color: $white;
          display: block;
        }

        &:before {
          @media (min-width: $tabs-mobile-breakpoint + 1) {
            background: $christi-green;
            bottom: 0;
            content: "";
            height: 10px;
            left: 0;
            position: absolute;
            width: 100%;
          }
        }
      }

      .agenda__tab__title {
        @include font(futura, demi);
        color: $tundora;
        display: block;
        font-size: 20px;
        letter-spacing: 1px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
      }

      .agenda__tab__end-date,
      .agenda__tab__start-date {
        @include font(futura, medium);
        color: $tundora;
        display: inline;
        font-size: 16px;
        letter-spacing: 1px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
      }

      &.is-open {
        display: block;
      }
    }
    @media print {
      flex-direction: row;
    }
  }
  .is-active .agenda__tab__title,
  .is-active .agenda__tab__end-date,
  .is-active .agenda__tab__start-date,
  .agenda__tab:hover .agenda__tab__start-date, 
  .agenda__tab:hover .agenda__tab__end-date
  .agenda__tab:hover .agenda__tab__title {
    @media (max-width: $tabs-mobile-breakpoint) {
      color: $white !important;
    }
  }
  .agenda__session__start + .agenda__session__end:before {
    content: " - ";
    display: inline;
  }

  .agenda__session__meta + .agenda__session__body {
    margin-left: 67px;
   width: calc(100% - 167px - 67px); //explicit width for IE
    @media(max-width: $tabs-mobile-breakpoint){
      margin-left: 0 !important;
      width: 100% !important;
    }
  }

  .agenda__session {
    margin-top: 81px;
    display: flex;

    @media (max-width: $tabs-mobile-breakpoint) {
      flex-direction: column;
    }
    .agenda__session__room {
      @include font(futura, demi);
      display: block;
      font-size: 18px;
    }

    .agenda__session__meta {
      @include font(futura, demi);
      width: 160px;
      text-align: right;
      @media (max-width: $tabs-mobile-breakpoint) {
        width: 100% !important;
        text-align: left !important;
      }
      .agenda__session__time {
        width: inherit;
        font-size: 24px;
        margin-bottom: 12px;

        span {
          display: inline;
        }
      }
    }

    .agenda__session__body {
      h4 {
        @include font(futura, demi);
        font-size: 24px;
        margin-bottom: 12px;
      }

      p {
        @include font(georgia);
        font-size: 18px;
      }

      .agenda__session__room {
        margin-bottom: 12px;
      }
    }
  }
  @media print {
    .agenda__navigation .agenda__tab.is-active {
      background: transparent;
      color: $tundora;
      .agenda__tab__start-date,
      .agenda__tab__title { color: $tundora !important; }
    }
    .agenda__tab.is-active:before {
      display: none;
    }
  }
}
