// typography.scss
// Define typography styles here

// typographic variables

/// '$helvetica' variable overrides bourbon's font-stack variable of the same name
/// @author thoughtbot
/// @link https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/addons/_font-stacks.scss
$futura: "futura-pt", Helvetica, Arial, sans-serif;
$georgia: Georgia, "Times New Roman", Times, serif;
$open-sans: "Open Sans", Arial, sans-serif;
$garamond: "adobe-garamond-pro", Times, serif;
$proxima: "proxima-nova", Arial,  sans-serif;
/// Font Stack Map to define wireframe font-stack
/// @author Hmphry
/// @link http://hmphry.com/useful-sass-mixins
///
/// @example scss - usage
///     h1 {
///         @include font(helvetica);
///     }
///
///     p {
///         @include font(helvetica, bold);
///     }
/// @example css - CSS output
///    h1 {
///        font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
///        font-weight: 400;
///        font-style: normal;
///    }
///
///    p {
///        font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
///        font-weight: 600;
///        font-style: normal;
///    }
///
/// TODO: Update with your font-stack scheme and call the font() function too!
/// NOTE: Needs a trailing semi-colon on the last declaration!
$font-stack:
    (group: 'futura', id: light, font: ($futura), weight: 300, style: normal),
    (group: 'futura', id: regular, font: ($futura), weight: 400, style: normal),
    (group: 'futura', id: demi, font: ($futura), weight: 600, style: normal),
    (group: 'futura', id: medium, font: ($futura), weight: 500, style: normal),
    (group: 'futura', id: heavy, font: ($futura), weight: 700, style: normal),
    (group: 'futura', id: extra-bold, font: ($futura), weight: 900, style: normal),
	(group: 'georgia', id: regular, font: ($georgia), weight: 400, style: normal),
	(group: 'georgia', id: regular-italic, font: ($georgia), weight: 400, style: italic),
	(group: 'georgia', id: bold, font: ($georgia), weight: 700, style: normal),
    (group: 'garamond', id: regular, font: ($garamond), weight: 400, style: normal),
    (group: 'garamond', id: regular-italic, font: ($garamond), weight: 400, style: italic),
    (group: 'garamond', id: bold, font: ($garamond), weight: 700, style: normal),
    (group: 'open-sans', id: regular, font: ($open-sans), weight: 400, style: normal),
    (group: 'open-sans', id: semibold, font: ($open-sans), weight: 600, style: semibold),
    (group: 'open-sans', id: bold, font: ($open-sans), weight: 700, style: bold),
    (group: 'proxima-nova', id: regular, font: ($proxima), weight: 400, style: normal);


@mixin font($group, $id:regular) {
    @each $font in $font-stack {
        @if ($group == map-get($font, group) and $id == map-get($font, id)) {
            font-family: map-get($font, font);
            font-weight: map-get($font, weight);
            font-style: map-get($font, style);
        }
    }
}

// color variables
$base-body-color: $tundora !default;

// base styles
body {
    @include font(georgia);
    font-size: 18px;
    line-height: 1.25;
    color: $base-body-color;
    -webkit-font-smoothing: antialiased;
}

// headings
h1, h2, h3, h4, h5, h6,
.txt-h1, .txt-h2, .txt-h3, .txt-h4, .txt-h5, .txt-h6 {
    @include font(futura);
    @include zero;
    line-height: 1.3;
}

h1, h2, h3 {
    margin-bottom: 12px;
    font-weight: 600;
}

h4, h5, h6 {
    margin-bottom: rem(9);
    font-weight: 700;
}

h1,
.txt-h1 {
    font-size: 50px;
    line-height: 62px;

    @media(max-width: $bp-max-medium) {
      font-size: 2.2rem;
    }

    @media(max-width: $bp-max-small) {
        font-size: 1.8rem;
        line-height: 1.1;
    }
}

h2,
.txt-h2 {
    font-size: 35px;
    line-height: 45px;
}

h3,
.txt-h3 {
    font-size: 27px;
    line-height: 37px;
}

h4,
.txt-h4 {
    font-size: 22px;
}

h5,
.txt-h5 {
    font-size: 20px;
}

h6,
.txt-h6 {
    font-size: 18px;
    letter-spacing: .02em;
    text-transform: uppercase;
}

// paragraphs
p {
    @include zero;
    margin-bottom: 18px;
    font-size: 18px;
    line-height: 1.6;
}

// small text
.txt-small{
    font-size: 14px;
}
