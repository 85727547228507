// content-list.scss
// Define content list styles here
// base

.content-list {
  @include component-whitespace();
}

.content-list__heading {
  @include font("garamond", regular);
  margin-bottom: 30px;
  font-size: 45px;
  color: $rhino;

  &:before {
    content: "";
    background-color: $rouge;
    display: inline-block;
    height: 5px;
    width: $health-bar;
    margin-right: $health-bar-margin;
    @media(max-width: $bp-max-small){
      display: none;
    }
  }
}

.content-list__overflow {
  display: none;
}
// list

.content-list__list {
  @include zero-list;
}
// items

.content-list__item {
  overflow: hidden;
  margin-bottom: 45px;
}

.content-list__more {
  text-align: center;
}

.blog-list {
    @include component-whitespace();
        .list-item__eyebrow {
        color: $christi-green !important;
    }
}

.blog-list__heading {
    font-family: "futura-pt", Helvetica, Arial, sans-serif;
    margin-bottom: 30px;
    font-size: 45px;
    color: $rhino;

    &:before {
        content: "";
        background-color: $christi-green;
        display: inline-block;
        height: 5px;
        width: $health-bar;
        margin-right: $health-bar-margin;

        @media(max-width: $bp-max-small) {
            display: none;
        }
    }
}

.blog-list__overflow {
    display: none;
}
// list

.blog-list__list {
    @include zero-list;
}
// items

.blog-list__item {
    overflow: hidden;
    margin-bottom: 45px;
}

.blog-list__more {
    text-align: center;

    .btn
    {
      font-family: "futura-pt", Helvetica, Arial, sans-serif;
      font-weight: 600;
      font-style: normal;
      display: block;
      margin: auto;
      max-width: 200px;
      background-color: #e7f0f7;
      color: #4a4a4a;
      border: 0;
      padding: 16px;
      font-size: 16px;
    }

    &:hover {
        color: #0f6eb4;
    }
}
