$box-shadow-padding: 15px;
$news-item-slider-breakpoint-medium: 1024px; // this value is used in EventSlider.js, and needs to be changed in both places
$news-item-slider-breakpoint-small: 768px;

.news-item {
	margin-right: auto;
	margin-left: auto;
	max-width: 977px;
	// padding-left: $box-shadow-padding;
	position: relative;
	left: 0;
	padding-bottom: $box-shadow-padding;
	@media(max-width: $news-item-slider-breakpoint-medium) {
		// padding-right: $box-shadow-padding;
		// margin-right: 0;
		// left: 25px;
		max-width: 800px;
		padding-left: 10px;
		padding-right: 10px;
	}
}

.news-item__container {
	display: flex;
	min-height: 261px;
	@media(max-width: $news-item-slider-breakpoint-small) {
		flex-direction: column-reverse;
		min-height: auto;
	}
}

.news-item__details {
	background: $white;
	box-shadow: 0 0 $box-shadow-padding 1px rgba($black, 0.4);
	color: $black;
	padding: 25px 60px;
	position: relative;
	margin-right: -60px;
	max-width: 100%;

	.is-page-editor & {
		margin-right: 0;
	}
	@media(max-width: $news-item-slider-breakpoint-small) {
		margin-right: 0;
		max-width: 100%;
	}

	.btn--link.news-item__cta:last-child,
	p:last-child {
		padding-bottom: 0;
		margin-bottom: 0;
		margin-top: 10px;
	}
}

.news-item__time {
	@include font(futura);
	display: inline-flex;
	margin-bottom: 20px;
	margin-top: 20px;
	text-transform: uppercase;
}

.news-item__time__day {
	font-size: 36px;
	margin-right: 3px;
}

.news-item__time__group {
	display: flex;
	flex-direction: column;
	font-size: 14px;
	justify-content: center;
	line-height: 1;
}

.news-item__image {
	flex-basis: 42%;
	flex-grow: 1;
	flex-shrink: 0;
	position: relative;
	width: 42%;
	z-index: -1;

	.is-page-editor & {
		z-index: 0;
	}
	@media(max-width: $news-item-slider-breakpoint-small) {
		flex-basis: auto;
		width: 100%;
		z-index: 0;
	}

	img {
		bottom: 0;
		height: calc(100% + 60px);
		object-fit: cover;
		position: absolute;
		right: 0;
		width: 100%;
		@media(max-width: $news-item-slider-breakpoint-small) {
			position: static;
			height: auto;
			max-height: 300px;
		}
	}
}

.news-item__eyebrow {
	@include font(futura);
	color: $dove-gray;
	font-size: 20px;
	font-weight: 500;
	line-height: 1.6;
	margin-bottom: 10px;
	text-transform: uppercase;
	@media(max-width: $news-item-slider-breakpoint-small) {
		font-size: 16px;
	}

	&:before {
		background: $christi-green;
		content: "";
		height: 29px;
		left: 0;
		position: absolute;
		top: 25px;
		width: 5px;
	}
}

.news-item__title {
	@include font(futura, demi);
	font-size: 27px;
	line-height: 37px;
}

.news-item__description {
	font-size: 18px;
	line-height: 30px;
}
