.homepage-hero {
}

.homepage-hero__promos {
  @include outer-container();

  @media (max-width: $bp-max-large) {
    margin-left: $container-outer-gutter;
    margin-right: $container-outer-gutter;
  }
}

.homepage-hero__promos--top {
  margin-bottom: $container-mobile-bottom-space;

  @media (min-width: $bp-min-large) {
    display: none;
  }
}

.homepage-hero__promos--bottom {
  @media (max-width: $bp-max-medium) {
    display: none;
  }
}

.homepage-hero__hero {
  margin-bottom: $container-bottom-space;

  /*&.is-overlap .full-hero__details {
    @media (min-width: $bp-min-large) {
      padding-top: $container-bottom-space * 2;
      padding-bottom: $container-bottom-space * 2;
    }
  }*/

  .container {
    margin-bottom: 0;
  }
}
