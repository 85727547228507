.author-spotlight {
	max-width: 850px;
	margin: 0 auto;
	margin-bottom: 60px;

	&__title {
		font-size: 40px;
		line-height: 50px;
		margin-bottom: 32px;
	}

	&__author-wrapper {
		display: flex;
		margin-bottom: 32px;

		@media (max-width: 767px) {
			display: block;
		}
	}

	&__author-image {
		margin-right: 32px;

		img {
			height: 102px;
			width: 73px;
			object-fit: cover;
		}

		@media (max-width: 767px) {
			text-align: center;
			margin-bottom: 16px;
		}
	}

	&__author-title {
		@include font(futura);
		font-size: 24px;
		line-height: 28px;
		font-weight: 600;
		color: $fun-blue;
		display: block;
		margin-bottom: 8px;
	}

	&__author-description {
		@include font(georgia);
		font-size: 18px;
		line-height: 28px;
		font-weight: 400;
		color: $tundora;
		margin-bottom: 0;
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box !important;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		white-space: normal;

		/*safari*/
		@supports (-webkit-backdrop-filter: blur(1px)) {
			-webkit-box-orient: horizontal !important;
			margin-bottom: 8px !important;
		}
	}

	&__author-link {
		@include font(futura);
		font-size: 20px;
		font-weight: 600;
	}

	&__button {
		width: 100%;
		display: flex;
		justify-content: center;

		a {
			@include font(futura);
			cursor: pointer;
			text-transform: uppercase;
			color: $fun-blue;
			font-size: 18px;
			font-weight: 500;
			display: inline-flex;
			align-items: center;
			margin-top: 32px;

			&:after {
				background-image: url("/assets/img/plus-icon.png");
				background-repeat: no-repeat;
				background-size: cover;
				content: "";
				color: black;
				height: 16px;
				width: 16px;
				margin-left: 10px;
			}

			@media (max-width: 767px) {
				font-size: 14px;
			}
		}
	}
}

.single-books {
	max-width: 100%;
	margin: 0 auto;
	margin-bottom: 70px;

	&__wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 40px;

		@media (max-width: 767px) {
			grid-template-columns: 1fr;
			gap: 20px;
		}

		&.showMoreBooks {
			margin-top: 40px;

			@media (max-width: 767px) {
				margin-top: 20px;
			}
		}
	}
}

.single-book {
	padding: 40px 40px 30px 40px;
	max-width: 405px;
	border: 1px solid #d9d9d9;

	@media (max-width: 767px) {
		padding: 30px;
		max-width: 100%;
	}

	&__image {
		width: auto;
		height: auto;
		display: flex;
		justify-content: center;
		margin-bottom: 36px;

		img {
			width: auto;
			height: 172px;

			@media (max-width: 767px) {
				width: auto;
				height: 134px;
			}
		}
	}

	&__header {
		margin-bottom: 20px;

		@media (max-width: 767px) {
			margin-bottom: 48px;
		}
	}

	&__collection {
		@include font(futura);
		font-size: 14px;
		font-weight: 600;
		display: block;
		line-height: 20px;
		text-transform: uppercase;
		margin-bottom: 10px;
	}

	&__title {
		font-size: 18px;
		line-height: 25px;
		height: 75px;
		font-weight: 500;
		color: $fun-blue;
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box !important;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		white-space: normal;

		@media (max-width: 767px) {
			height: auto;
		}
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__footer-side {
		display: flex;
		flex-direction: column;
	}

	&__tag {
		font-size: 16px;
		font-weight: 400;
		line-height: 21px;
		color: #767676;
	}

	&__tag-value {
		@include font(futura);
		font-size: 18px;
		font-weight: 500;
		line-height: 22px;
		color: $tundora;
	}

	&__footer-button {
		@include font("futura", demi);
		border: 2px $denim-blue solid;
		font-size: 16px;
		display: block;
		max-width: 310px;
		padding: 10px;
		text-transform: uppercase;
		text-align: center;
		width: 100%;
		letter-spacing: 1px;
		background-color: $denim-blue;
		color: $black-squeeze-blue;

		&:hover {
			color: $black-squeeze-blue;
		}
	}
}
