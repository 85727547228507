.accordion-list{

	.scLooseFrameZone{
		display: block;
	}

}

.accordion-list__toggle-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 45px;
}

.accordion-list__toggle-all{
    @include font(futura, medium);
    font-weight: 500;
    line-height: 1.2;
    text-transform: none;
    border: 2px solid $denim-blue;
    padding: 8px 20px;

    &::after{
        content: none;
        display: none;
    }

    .health-exec &.btn--link {
        color: $denim-blue;
        font-weight: 500;
        font-size: 16px;
    }
}

.accordion-list__accordion {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 32px;
    border-bottom: 2px solid $denim-blue;

    &.is-open {
        background-color: $black-squeeze-blue;
    }

    &:not(:first-child) {
        padding-top: 24px;
    }

    &.is-open {
        .accordion-list__accordion-link {
            &:after{
                transform: rotate(180deg);
                top: 30%;
            }
        }
    }

    img {
        width: 100%;
        object-fit: contain;
    }
}

.accordion-list__accordion-link {
    background-color: transparent; 
    border: 0;
    padding: 0;
    text-align: left;
    position: relative;

    h2 {
        @include font(futura, medium);
        color: $denim-blue;
        font-weight: 500;
        font-size: 22px;
        margin-bottom: 0;
        line-height: 1.2;
        width: calc(100% - 20px);
    }

    &:after{
        content:"";
        position: absolute;
        top: 33%;
        right: 0;
        border-left: solid 8px transparent;
        border-right: solid 8px transparent;
        border-top: solid 8px $denim-blue;
    }
}

.accordion-list__accordion-content {
    text-align: left;
}

.accordion-wrapper {
    & > .js-accordion-content {
        margin-top: 24px;
    }
}