$img-width: 125px;
$img-margin: 30px;
$img-width-small: 30%;

.profile-browser__header {
  margin-bottom: 50px;
}

.profile-browser__list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 18px;
  justify-content: space-around;
  list-style-type: none;
  margin: 0;
  padding: 0 0 10px;
}

.profile-browser__profile {
  border-bottom: 2px dashed $iron;
  list-style-type: none;
  padding-bottom: 40px;
  padding-top: 40px;
 article {
   overflow: hidden;
 }
  header {
    overflow: hidden;
  }

  h3 {
    margin-bottom: 10px;
    line-height: 100%;
  }

  .profile-browser__img {
    width: $img-width;
    float: left;
    margin-right: $img-margin;
    display: block;

    img {
      width: 100%;
      height: auto;
      max-width: $img-width;
    }
    @media (max-width: $bp-max-small) {
      width: $img-width-small;
    }
  }

  .profile-browser__profile__content {
    float: right;
    width: calc(100% - #{$img-width} - #{$img-margin});
    @media (max-width: $bp-max-small) {
      width: calc(100% - #{$img-width-small} - #{$img-margin});
    }
    .profile-browser__profile__name {
      font-size: 30px;
      color: $denim-blue;
      line-height: 30px;
      font-weight: 700;
    }
    .profile-browser__profile__primary-title {
      @include font(futura, demi);
      color: $dove-gray;
      text-transform: uppercase;
      font-size: 20px;
      margin-bottom: 10px;
    }
    .profile-browser__biography {
      line-height: 30px;
      font-size: 18px;
    }
    h3 {
      font-size: 30px;
    }
  }
}
.profile-browser {
  .profile-browser__img {
    width: $img-width;
    float: left;
    margin-right: $img-margin;
    display: block;

    img {
      width: 100%;
      height: auto;
      max-width: $img-width;
    }
    @media(max-width: $bp-max-small) {
      width: $img-width-small;
    }
  }
  address {
    font-style: normal;
    line-height: 30px;
    margin-top: 15px;

    .profile-browser__contact-header {
      @include font(futura, demi);
      display: block;
      text-transform: uppercase;
      color: $dove-gray;
      font-size: 20px;
    }
  }
}
