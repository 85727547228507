.health-exec {
  .people-details {
    font-size: 18px;
    .people-details__header {
      color: $rhino;
    }
    .people-details__section-header {
      @include font("open-sans", semibold);
      color: $tundora;
    }
    .people-details__bio-role {
      @include font("open-sans", semibold);
      color: $rouge;
      margin-top: 0;
    }
  }
}
