//Error CSS also included in RTE CSS
.error {
  max-width: 629px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.error__main {
  @include font(futura, demi);
  color: $fun-blue;
  font-size: 200px;
  margin-bottom: 50px;
  line-height: 110%;

  @media (max-width: $bp-max-medium) {
    font-size: 100px !important;
  }
}

.error__sub {
  @include font(futura, demi);
  color: $fun-blue;
  font-size: 70px;
  line-height: 110%;
  @media (max-width: $bp-max-medium) {
    font-size: 50px !important;
  }
}

.error__message {
  @include font(futura, demi);
  color: $fun-blue;
  font-size: 45px;
  line-height: 110%;
  margin-top: 40px;
  @media (max-width: $bp-max-medium) {
    font-size: 30px !important;

  }
}

.error__go-back {
  @include font(futura, demi);
  color: $fun-blue;
  font-size: 18px;
  letter-spacing: 1px;
  margin-top: 70px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-top: 15px;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  &:before {
    content: "←";
    color: $black;
    padding-right: 20px;
    font-family: serif;
  }
}
