.at-a-glance-sidebar {
	max-width: 341px;
	width: 100%;
	padding: 40px 30px 35px 30px;
	background-color: #f3f6fb;
	border-top: 5px solid $fun-blue;
	margin-bottom: 38px;

	&__title {
		@include font(futura);
		color: $tundora;
		display: block;
		font-size: 30px;
		font-weight: 600;
		line-height: 36px;
	}

	&__text-bottom {
		padding-bottom: 18px;
		border-bottom: 1px solid rgba(25, 112, 177, 0.51);
		margin-bottom: 24px;
	}

	&__subtitle {
		font-size: 16px;
		font-weight: 400;
		color: #767676;
		line-height: 22px;
		display: block;
		margin-top: 22px;
	}

	&__tag {
		@include font(futura);
		font-size: 18px;
		line-height: 22px;
		font-weight: 500;
	}

	&__value {
		@include font(futura);
		color: $fun-blue;
		font-size: 18px;
		line-height: 22px;
		font-weight: 500;
	}

	&__icons-details {
		margin-bottom: 20px;
	}

	&__details {
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		
		&.date-image {
			align-items: baseline;
		}
	}

	&__details-value {
		@include font(futura);
		color: $tundora;
		font-size: 18px;
		line-height: 22px;
		font-weight: 500;
		margin-left: 8px;
		padding-right: 56px;
	}

	&__buttons {
		a {
			width: 100%;
			text-align: center;
			font-size: 16px;
			padding: 10px 20px;
			margin-bottom: 10px;
			max-width: 100%;

			&:last-child {
				margin-bottom: 0;
			}

			&:after {
				display: none;
			}

			&.headline__link {
				border: 1px solid $fun-blue;
			}
			
			&.disabled {
				background-color: #4A4A4A;
				opacity: 0.5;
				&:hover {
					color: white;
					cursor: not-allowed;
				}
			}
		}
	}
}
