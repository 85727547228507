.search {}

.search-facets {
	background-color: $fun-blue;

	input {
		color: $tundora;
	}
}

.search__query-mount {
	@include clearfix;
	width: 65.89147%;
	float: right;
	margin-bottom: 0;
	height: 70px;

	.search-box {
		max-width: 900px;
	}
	@media (max-width: $bp-max-medium) {
		.search-box {
			max-width: 100%;
			width: 100%;
			float: none;
		}
	}
}

.search__facets-wrapper {
	margin-top: -130px;
}
@media (max-width: $bp-max-medium) {
	.search__facets-wrapper {
		position: relative;
		margin: 0;

		&.is-left,
		&.is-right {
			position: absolute;
			background-color: $fun-blue;
			width: 85vw;
			top: 0;
			transition: transform 0.3s ease-in;
			margin: 0;
			// padding: 30px;
			padding: 15px;
			z-index: 99;

			&.is-open {
				transform: translateX(0%);
			}
		}

		&.is-left {
			left: -30px;
			transform: translateX(-100%);
		}

		&.is-right {
			right: -30px;
			transform: translateX(100%);
			transition: transform 0.3s ease-in;
		}

		&.is-open {
			border: 1px solid $grey-400;
			border-left: 0;
			box-shadow: -1px 4px 12px 1px $grey-500;
		}

		.search-facets__group {
			// background-color: $white;
		}
	}
}
