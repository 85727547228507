.logo-grid {
	@include font(futura, heavy);
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
}

.logo-grid {
	.image-block__media {
		filter: grayscale(100%);
		transition: filter 1s;
		margin: 0; //override vspace and hspace.
		max-width: 223px;
		max-height: 70px;
		margin: 0 auto;
		@media(max-width: $bp-max-small) {
			width: 95%;
			margin-right: auto;
			margin-left: auto;
			margin-bottom: 70px;
		}

		picture {
			display: block;
			max-height: inherit;
		}

		img {
			margin: 0;
			object-fit: contain;
			max-height: inherit;
		}

		&:hover {
			filter: grayscale(0%);
		}
	}
}

.logo-grid__block {
	width: 33%;
	margin-top: 0.5%;
	margin-bottom: 7%;

	&:last-child:nth-of-type(4),
	&:last-of-type:nth-of-type(10),
	&:last-of-type:nth-of-type(7) {
		margin-right: auto;
		margin-left: auto;
	}
	@media(max-width: $bp-max-small) {
		width: 95%;
		margin-right: auto;
		margin-left: auto;
		padding-left: 10px;
		padding-right: 10px;
	}
}

.is-page-editor {
	.logo-grid {
		display: block;

		& > div {
			//prevent flex
			display: block;
		}

		.logo-grid__block {
			display: block;
			max-width: 100%;
			width: auto; // prevent picture from stretching out of control in EE
			height: auto;
			margin: 0 auto;

			.image-block__media img {
				width: auto !important;
				height: auto;
			}
		}
	}
}
