.at-a-glance-content {
	max-width: 834px;
	width: 100%;
	padding: 22px 30px 22px 38px;
	background-color: #f3f6fb;
	border-top: 5px solid $fun-blue;
	margin-bottom: 60px;

	@media (max-width: 768px) {
		margin-bottom: 40px;
		padding: 24px 16px;
	}

	&__row {
		display: flex;
		gap: 12px;

		@media (max-width: 768px) {
			display: block;
		}

		&--second {
			margin-top: 20px;
			@media (max-width: 768px) {
				margin-top: 0;
			}

			.at-a-glance-content__item {
				width: 25%;

				@media (max-width: 768px) {
					width: 100%;
				}
			}
		}
	}

	&__item {
		width: 50%;

		@media (max-width: 768px) {
			width: 100%;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__subtitle {
		font-size: 16px;
		font-weight: 400;
		color: #767676;
		line-height: 22px;
		display: block;
	}

	&__content {
		display: flex;

		@media (max-width: 768px) {
			display: block;
		}
	}

	&__text {
		display: flex;
		align-items: center;

		&:first-child {
			margin-right: 16px;
		}

		img {
			margin-right: 10px;
		}
	}

	&__tag {
		@include font(futura);
		font-size: 18px;
		line-height: 22px;
		font-weight: 500;
	}

	&__value {
		@include font(futura);
		color: $fun-blue;
		font-size: 18px;
		line-height: 22px;
		font-weight: 500;
		margin-left: 2px;

		&:first-child {
			margin-right: 12px;
		}
	}

	&__icons-details {
		margin-bottom: 20px;
	}

	&__details {
		margin-bottom: 10px;
		display: flex;
		align-items: baseline;
	}

	&__details-value {
		@include font(futura);
		color: $tundora;
		font-size: 18px;
		line-height: 22px;
		font-weight: 500;
		margin-left: 8px;
		padding-right: 56px;
	}
}
