@charset "UTF-8";
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

html {
  background: #fff;
  color: #000;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

body {
  margin: 0;
}

a {
  background: transparent;
}

a:focus {
  outline: thin dotted;
}

a:hover, a:active {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

h4 {
  font-size: 1em;
  margin: 1.33em 0;
}

h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: "\201C" "\201D" "\2018" "\2019";
}

q:before,
q:after {
  content: '';
  content: none;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 1 */
/* 2 */
/* 3 */
/* 4 */
/* 1 */
/* 2 */
/* 3 */
/* 4 */
html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

body:before {
  background-image: -webkit-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 6.20155%, transparent 6.20155%, transparent 8.52713%, #F44336 8.52713%, #F44336 14.72868%, transparent 14.72868%, transparent 17.05426%, #ea1c0d 17.05426%, #ea1c0d 23.25581%, transparent 23.25581%, transparent 25.5814%, #F44336 25.5814%, #F44336 31.78295%, transparent 31.78295%, transparent 34.10853%, #ea1c0d 34.10853%, #ea1c0d 40.31008%, transparent 40.31008%, transparent 42.63566%, #F44336 42.63566%, #F44336 48.83721%, transparent 48.83721%, transparent 51.16279%, #ea1c0d 51.16279%, #ea1c0d 57.36434%, transparent 57.36434%, transparent 59.68992%, #F44336 59.68992%, #F44336 65.89147%, transparent 65.89147%, transparent 68.21705%, #ea1c0d 68.21705%, #ea1c0d 74.4186%, transparent 74.4186%, transparent 76.74419%, #F44336 76.74419%, #F44336 82.94574%, transparent 82.94574%, transparent 85.27132%, #ea1c0d 85.27132%, #ea1c0d 91.47287%, transparent 91.47287%, transparent 93.79845%, #F44336 93.79845%, #F44336 100%, transparent 100%);
  background-image: -moz-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 6.20155%, transparent 6.20155%, transparent 8.52713%, #F44336 8.52713%, #F44336 14.72868%, transparent 14.72868%, transparent 17.05426%, #ea1c0d 17.05426%, #ea1c0d 23.25581%, transparent 23.25581%, transparent 25.5814%, #F44336 25.5814%, #F44336 31.78295%, transparent 31.78295%, transparent 34.10853%, #ea1c0d 34.10853%, #ea1c0d 40.31008%, transparent 40.31008%, transparent 42.63566%, #F44336 42.63566%, #F44336 48.83721%, transparent 48.83721%, transparent 51.16279%, #ea1c0d 51.16279%, #ea1c0d 57.36434%, transparent 57.36434%, transparent 59.68992%, #F44336 59.68992%, #F44336 65.89147%, transparent 65.89147%, transparent 68.21705%, #ea1c0d 68.21705%, #ea1c0d 74.4186%, transparent 74.4186%, transparent 76.74419%, #F44336 76.74419%, #F44336 82.94574%, transparent 82.94574%, transparent 85.27132%, #ea1c0d 85.27132%, #ea1c0d 91.47287%, transparent 91.47287%, transparent 93.79845%, #F44336 93.79845%, #F44336 100%, transparent 100%);
  background-image: -ms-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 6.20155%, transparent 6.20155%, transparent 8.52713%, #F44336 8.52713%, #F44336 14.72868%, transparent 14.72868%, transparent 17.05426%, #ea1c0d 17.05426%, #ea1c0d 23.25581%, transparent 23.25581%, transparent 25.5814%, #F44336 25.5814%, #F44336 31.78295%, transparent 31.78295%, transparent 34.10853%, #ea1c0d 34.10853%, #ea1c0d 40.31008%, transparent 40.31008%, transparent 42.63566%, #F44336 42.63566%, #F44336 48.83721%, transparent 48.83721%, transparent 51.16279%, #ea1c0d 51.16279%, #ea1c0d 57.36434%, transparent 57.36434%, transparent 59.68992%, #F44336 59.68992%, #F44336 65.89147%, transparent 65.89147%, transparent 68.21705%, #ea1c0d 68.21705%, #ea1c0d 74.4186%, transparent 74.4186%, transparent 76.74419%, #F44336 76.74419%, #F44336 82.94574%, transparent 82.94574%, transparent 85.27132%, #ea1c0d 85.27132%, #ea1c0d 91.47287%, transparent 91.47287%, transparent 93.79845%, #F44336 93.79845%, #F44336 100%, transparent 100%);
  background-image: -o-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 6.20155%, transparent 6.20155%, transparent 8.52713%, #F44336 8.52713%, #F44336 14.72868%, transparent 14.72868%, transparent 17.05426%, #ea1c0d 17.05426%, #ea1c0d 23.25581%, transparent 23.25581%, transparent 25.5814%, #F44336 25.5814%, #F44336 31.78295%, transparent 31.78295%, transparent 34.10853%, #ea1c0d 34.10853%, #ea1c0d 40.31008%, transparent 40.31008%, transparent 42.63566%, #F44336 42.63566%, #F44336 48.83721%, transparent 48.83721%, transparent 51.16279%, #ea1c0d 51.16279%, #ea1c0d 57.36434%, transparent 57.36434%, transparent 59.68992%, #F44336 59.68992%, #F44336 65.89147%, transparent 65.89147%, transparent 68.21705%, #ea1c0d 68.21705%, #ea1c0d 74.4186%, transparent 74.4186%, transparent 76.74419%, #F44336 76.74419%, #F44336 82.94574%, transparent 82.94574%, transparent 85.27132%, #ea1c0d 85.27132%, #ea1c0d 91.47287%, transparent 91.47287%, transparent 93.79845%, #F44336 93.79845%, #F44336 100%, transparent 100%);
  background-image: linear-gradient(to left, transparent 0, #ea1c0d 0, #ea1c0d 6.20155%, transparent 6.20155%, transparent 8.52713%, #F44336 8.52713%, #F44336 14.72868%, transparent 14.72868%, transparent 17.05426%, #ea1c0d 17.05426%, #ea1c0d 23.25581%, transparent 23.25581%, transparent 25.5814%, #F44336 25.5814%, #F44336 31.78295%, transparent 31.78295%, transparent 34.10853%, #ea1c0d 34.10853%, #ea1c0d 40.31008%, transparent 40.31008%, transparent 42.63566%, #F44336 42.63566%, #F44336 48.83721%, transparent 48.83721%, transparent 51.16279%, #ea1c0d 51.16279%, #ea1c0d 57.36434%, transparent 57.36434%, transparent 59.68992%, #F44336 59.68992%, #F44336 65.89147%, transparent 65.89147%, transparent 68.21705%, #ea1c0d 68.21705%, #ea1c0d 74.4186%, transparent 74.4186%, transparent 76.74419%, #F44336 76.74419%, #F44336 82.94574%, transparent 82.94574%, transparent 85.27132%, #ea1c0d 85.27132%, #ea1c0d 91.47287%, transparent 91.47287%, transparent 93.79845%, #F44336 93.79845%, #F44336 100%, transparent 100%);
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  margin: 0 auto;
  max-width: 1290px;
  opacity: 0.08;
  pointer-events: none;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 9999;
}

@media screen and (max-width: 80.625em) {
  body:before {
    background-image: -webkit-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 6.20155%, transparent 6.20155%, transparent 8.52713%, #F44336 8.52713%, #F44336 14.72868%, transparent 14.72868%, transparent 17.05426%, #ea1c0d 17.05426%, #ea1c0d 23.25581%, transparent 23.25581%, transparent 25.5814%, #F44336 25.5814%, #F44336 31.78295%, transparent 31.78295%, transparent 34.10853%, #ea1c0d 34.10853%, #ea1c0d 40.31008%, transparent 40.31008%, transparent 42.63566%, #F44336 42.63566%, #F44336 48.83721%, transparent 48.83721%, transparent 51.16279%, #ea1c0d 51.16279%, #ea1c0d 57.36434%, transparent 57.36434%, transparent 59.68992%, #F44336 59.68992%, #F44336 65.89147%, transparent 65.89147%, transparent 68.21705%, #ea1c0d 68.21705%, #ea1c0d 74.4186%, transparent 74.4186%, transparent 76.74419%, #F44336 76.74419%, #F44336 82.94574%, transparent 82.94574%, transparent 85.27132%, #ea1c0d 85.27132%, #ea1c0d 91.47287%, transparent 91.47287%, transparent 93.79845%, #F44336 93.79845%, #F44336 100%, transparent 100%);
    background-image: -moz-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 6.20155%, transparent 6.20155%, transparent 8.52713%, #F44336 8.52713%, #F44336 14.72868%, transparent 14.72868%, transparent 17.05426%, #ea1c0d 17.05426%, #ea1c0d 23.25581%, transparent 23.25581%, transparent 25.5814%, #F44336 25.5814%, #F44336 31.78295%, transparent 31.78295%, transparent 34.10853%, #ea1c0d 34.10853%, #ea1c0d 40.31008%, transparent 40.31008%, transparent 42.63566%, #F44336 42.63566%, #F44336 48.83721%, transparent 48.83721%, transparent 51.16279%, #ea1c0d 51.16279%, #ea1c0d 57.36434%, transparent 57.36434%, transparent 59.68992%, #F44336 59.68992%, #F44336 65.89147%, transparent 65.89147%, transparent 68.21705%, #ea1c0d 68.21705%, #ea1c0d 74.4186%, transparent 74.4186%, transparent 76.74419%, #F44336 76.74419%, #F44336 82.94574%, transparent 82.94574%, transparent 85.27132%, #ea1c0d 85.27132%, #ea1c0d 91.47287%, transparent 91.47287%, transparent 93.79845%, #F44336 93.79845%, #F44336 100%, transparent 100%);
    background-image: -ms-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 6.20155%, transparent 6.20155%, transparent 8.52713%, #F44336 8.52713%, #F44336 14.72868%, transparent 14.72868%, transparent 17.05426%, #ea1c0d 17.05426%, #ea1c0d 23.25581%, transparent 23.25581%, transparent 25.5814%, #F44336 25.5814%, #F44336 31.78295%, transparent 31.78295%, transparent 34.10853%, #ea1c0d 34.10853%, #ea1c0d 40.31008%, transparent 40.31008%, transparent 42.63566%, #F44336 42.63566%, #F44336 48.83721%, transparent 48.83721%, transparent 51.16279%, #ea1c0d 51.16279%, #ea1c0d 57.36434%, transparent 57.36434%, transparent 59.68992%, #F44336 59.68992%, #F44336 65.89147%, transparent 65.89147%, transparent 68.21705%, #ea1c0d 68.21705%, #ea1c0d 74.4186%, transparent 74.4186%, transparent 76.74419%, #F44336 76.74419%, #F44336 82.94574%, transparent 82.94574%, transparent 85.27132%, #ea1c0d 85.27132%, #ea1c0d 91.47287%, transparent 91.47287%, transparent 93.79845%, #F44336 93.79845%, #F44336 100%, transparent 100%);
    background-image: -o-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 6.20155%, transparent 6.20155%, transparent 8.52713%, #F44336 8.52713%, #F44336 14.72868%, transparent 14.72868%, transparent 17.05426%, #ea1c0d 17.05426%, #ea1c0d 23.25581%, transparent 23.25581%, transparent 25.5814%, #F44336 25.5814%, #F44336 31.78295%, transparent 31.78295%, transparent 34.10853%, #ea1c0d 34.10853%, #ea1c0d 40.31008%, transparent 40.31008%, transparent 42.63566%, #F44336 42.63566%, #F44336 48.83721%, transparent 48.83721%, transparent 51.16279%, #ea1c0d 51.16279%, #ea1c0d 57.36434%, transparent 57.36434%, transparent 59.68992%, #F44336 59.68992%, #F44336 65.89147%, transparent 65.89147%, transparent 68.21705%, #ea1c0d 68.21705%, #ea1c0d 74.4186%, transparent 74.4186%, transparent 76.74419%, #F44336 76.74419%, #F44336 82.94574%, transparent 82.94574%, transparent 85.27132%, #ea1c0d 85.27132%, #ea1c0d 91.47287%, transparent 91.47287%, transparent 93.79845%, #F44336 93.79845%, #F44336 100%, transparent 100%);
    background-image: linear-gradient(to left, transparent 0, #ea1c0d 0, #ea1c0d 6.20155%, transparent 6.20155%, transparent 8.52713%, #F44336 8.52713%, #F44336 14.72868%, transparent 14.72868%, transparent 17.05426%, #ea1c0d 17.05426%, #ea1c0d 23.25581%, transparent 23.25581%, transparent 25.5814%, #F44336 25.5814%, #F44336 31.78295%, transparent 31.78295%, transparent 34.10853%, #ea1c0d 34.10853%, #ea1c0d 40.31008%, transparent 40.31008%, transparent 42.63566%, #F44336 42.63566%, #F44336 48.83721%, transparent 48.83721%, transparent 51.16279%, #ea1c0d 51.16279%, #ea1c0d 57.36434%, transparent 57.36434%, transparent 59.68992%, #F44336 59.68992%, #F44336 65.89147%, transparent 65.89147%, transparent 68.21705%, #ea1c0d 68.21705%, #ea1c0d 74.4186%, transparent 74.4186%, transparent 76.74419%, #F44336 76.74419%, #F44336 82.94574%, transparent 82.94574%, transparent 85.27132%, #ea1c0d 85.27132%, #ea1c0d 91.47287%, transparent 91.47287%, transparent 93.79845%, #F44336 93.79845%, #F44336 100%, transparent 100%);
  }
}

@media screen and (min-width: 56.9375em) {
  body:before {
    background-image: -webkit-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 6.20155%, transparent 6.20155%, transparent 8.52713%, #F44336 8.52713%, #F44336 14.72868%, transparent 14.72868%, transparent 17.05426%, #ea1c0d 17.05426%, #ea1c0d 23.25581%, transparent 23.25581%, transparent 25.5814%, #F44336 25.5814%, #F44336 31.78295%, transparent 31.78295%, transparent 34.10853%, #ea1c0d 34.10853%, #ea1c0d 40.31008%, transparent 40.31008%, transparent 42.63566%, #F44336 42.63566%, #F44336 48.83721%, transparent 48.83721%, transparent 51.16279%, #ea1c0d 51.16279%, #ea1c0d 57.36434%, transparent 57.36434%, transparent 59.68992%, #F44336 59.68992%, #F44336 65.89147%, transparent 65.89147%, transparent 68.21705%, #ea1c0d 68.21705%, #ea1c0d 74.4186%, transparent 74.4186%, transparent 76.74419%, #F44336 76.74419%, #F44336 82.94574%, transparent 82.94574%, transparent 85.27132%, #ea1c0d 85.27132%, #ea1c0d 91.47287%, transparent 91.47287%, transparent 93.79845%, #F44336 93.79845%, #F44336 100%, transparent 100%);
    background-image: -moz-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 6.20155%, transparent 6.20155%, transparent 8.52713%, #F44336 8.52713%, #F44336 14.72868%, transparent 14.72868%, transparent 17.05426%, #ea1c0d 17.05426%, #ea1c0d 23.25581%, transparent 23.25581%, transparent 25.5814%, #F44336 25.5814%, #F44336 31.78295%, transparent 31.78295%, transparent 34.10853%, #ea1c0d 34.10853%, #ea1c0d 40.31008%, transparent 40.31008%, transparent 42.63566%, #F44336 42.63566%, #F44336 48.83721%, transparent 48.83721%, transparent 51.16279%, #ea1c0d 51.16279%, #ea1c0d 57.36434%, transparent 57.36434%, transparent 59.68992%, #F44336 59.68992%, #F44336 65.89147%, transparent 65.89147%, transparent 68.21705%, #ea1c0d 68.21705%, #ea1c0d 74.4186%, transparent 74.4186%, transparent 76.74419%, #F44336 76.74419%, #F44336 82.94574%, transparent 82.94574%, transparent 85.27132%, #ea1c0d 85.27132%, #ea1c0d 91.47287%, transparent 91.47287%, transparent 93.79845%, #F44336 93.79845%, #F44336 100%, transparent 100%);
    background-image: -ms-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 6.20155%, transparent 6.20155%, transparent 8.52713%, #F44336 8.52713%, #F44336 14.72868%, transparent 14.72868%, transparent 17.05426%, #ea1c0d 17.05426%, #ea1c0d 23.25581%, transparent 23.25581%, transparent 25.5814%, #F44336 25.5814%, #F44336 31.78295%, transparent 31.78295%, transparent 34.10853%, #ea1c0d 34.10853%, #ea1c0d 40.31008%, transparent 40.31008%, transparent 42.63566%, #F44336 42.63566%, #F44336 48.83721%, transparent 48.83721%, transparent 51.16279%, #ea1c0d 51.16279%, #ea1c0d 57.36434%, transparent 57.36434%, transparent 59.68992%, #F44336 59.68992%, #F44336 65.89147%, transparent 65.89147%, transparent 68.21705%, #ea1c0d 68.21705%, #ea1c0d 74.4186%, transparent 74.4186%, transparent 76.74419%, #F44336 76.74419%, #F44336 82.94574%, transparent 82.94574%, transparent 85.27132%, #ea1c0d 85.27132%, #ea1c0d 91.47287%, transparent 91.47287%, transparent 93.79845%, #F44336 93.79845%, #F44336 100%, transparent 100%);
    background-image: -o-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 6.20155%, transparent 6.20155%, transparent 8.52713%, #F44336 8.52713%, #F44336 14.72868%, transparent 14.72868%, transparent 17.05426%, #ea1c0d 17.05426%, #ea1c0d 23.25581%, transparent 23.25581%, transparent 25.5814%, #F44336 25.5814%, #F44336 31.78295%, transparent 31.78295%, transparent 34.10853%, #ea1c0d 34.10853%, #ea1c0d 40.31008%, transparent 40.31008%, transparent 42.63566%, #F44336 42.63566%, #F44336 48.83721%, transparent 48.83721%, transparent 51.16279%, #ea1c0d 51.16279%, #ea1c0d 57.36434%, transparent 57.36434%, transparent 59.68992%, #F44336 59.68992%, #F44336 65.89147%, transparent 65.89147%, transparent 68.21705%, #ea1c0d 68.21705%, #ea1c0d 74.4186%, transparent 74.4186%, transparent 76.74419%, #F44336 76.74419%, #F44336 82.94574%, transparent 82.94574%, transparent 85.27132%, #ea1c0d 85.27132%, #ea1c0d 91.47287%, transparent 91.47287%, transparent 93.79845%, #F44336 93.79845%, #F44336 100%, transparent 100%);
    background-image: linear-gradient(to left, transparent 0, #ea1c0d 0, #ea1c0d 6.20155%, transparent 6.20155%, transparent 8.52713%, #F44336 8.52713%, #F44336 14.72868%, transparent 14.72868%, transparent 17.05426%, #ea1c0d 17.05426%, #ea1c0d 23.25581%, transparent 23.25581%, transparent 25.5814%, #F44336 25.5814%, #F44336 31.78295%, transparent 31.78295%, transparent 34.10853%, #ea1c0d 34.10853%, #ea1c0d 40.31008%, transparent 40.31008%, transparent 42.63566%, #F44336 42.63566%, #F44336 48.83721%, transparent 48.83721%, transparent 51.16279%, #ea1c0d 51.16279%, #ea1c0d 57.36434%, transparent 57.36434%, transparent 59.68992%, #F44336 59.68992%, #F44336 65.89147%, transparent 65.89147%, transparent 68.21705%, #ea1c0d 68.21705%, #ea1c0d 74.4186%, transparent 74.4186%, transparent 76.74419%, #F44336 76.74419%, #F44336 82.94574%, transparent 82.94574%, transparent 85.27132%, #ea1c0d 85.27132%, #ea1c0d 91.47287%, transparent 91.47287%, transparent 93.79845%, #F44336 93.79845%, #F44336 100%, transparent 100%);
  }
}

@media screen and (max-width: 56.875em) {
  body:before {
    background-image: -webkit-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 9.41176%, transparent 9.41176%, transparent 12.94118%, #F44336 12.94118%, #F44336 22.35294%, transparent 22.35294%, transparent 25.88235%, #ea1c0d 25.88235%, #ea1c0d 35.29412%, transparent 35.29412%, transparent 38.82353%, #F44336 38.82353%, #F44336 48.23529%, transparent 48.23529%, transparent 51.76471%, #ea1c0d 51.76471%, #ea1c0d 61.17647%, transparent 61.17647%, transparent 64.70588%, #F44336 64.70588%, #F44336 74.11765%, transparent 74.11765%, transparent 77.64706%, #ea1c0d 77.64706%, #ea1c0d 87.05882%, transparent 87.05882%, transparent 90.58824%, #F44336 90.58824%, #F44336 100%, transparent 100%);
    background-image: -moz-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 9.41176%, transparent 9.41176%, transparent 12.94118%, #F44336 12.94118%, #F44336 22.35294%, transparent 22.35294%, transparent 25.88235%, #ea1c0d 25.88235%, #ea1c0d 35.29412%, transparent 35.29412%, transparent 38.82353%, #F44336 38.82353%, #F44336 48.23529%, transparent 48.23529%, transparent 51.76471%, #ea1c0d 51.76471%, #ea1c0d 61.17647%, transparent 61.17647%, transparent 64.70588%, #F44336 64.70588%, #F44336 74.11765%, transparent 74.11765%, transparent 77.64706%, #ea1c0d 77.64706%, #ea1c0d 87.05882%, transparent 87.05882%, transparent 90.58824%, #F44336 90.58824%, #F44336 100%, transparent 100%);
    background-image: -ms-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 9.41176%, transparent 9.41176%, transparent 12.94118%, #F44336 12.94118%, #F44336 22.35294%, transparent 22.35294%, transparent 25.88235%, #ea1c0d 25.88235%, #ea1c0d 35.29412%, transparent 35.29412%, transparent 38.82353%, #F44336 38.82353%, #F44336 48.23529%, transparent 48.23529%, transparent 51.76471%, #ea1c0d 51.76471%, #ea1c0d 61.17647%, transparent 61.17647%, transparent 64.70588%, #F44336 64.70588%, #F44336 74.11765%, transparent 74.11765%, transparent 77.64706%, #ea1c0d 77.64706%, #ea1c0d 87.05882%, transparent 87.05882%, transparent 90.58824%, #F44336 90.58824%, #F44336 100%, transparent 100%);
    background-image: -o-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 9.41176%, transparent 9.41176%, transparent 12.94118%, #F44336 12.94118%, #F44336 22.35294%, transparent 22.35294%, transparent 25.88235%, #ea1c0d 25.88235%, #ea1c0d 35.29412%, transparent 35.29412%, transparent 38.82353%, #F44336 38.82353%, #F44336 48.23529%, transparent 48.23529%, transparent 51.76471%, #ea1c0d 51.76471%, #ea1c0d 61.17647%, transparent 61.17647%, transparent 64.70588%, #F44336 64.70588%, #F44336 74.11765%, transparent 74.11765%, transparent 77.64706%, #ea1c0d 77.64706%, #ea1c0d 87.05882%, transparent 87.05882%, transparent 90.58824%, #F44336 90.58824%, #F44336 100%, transparent 100%);
    background-image: linear-gradient(to left, transparent 0, #ea1c0d 0, #ea1c0d 9.41176%, transparent 9.41176%, transparent 12.94118%, #F44336 12.94118%, #F44336 22.35294%, transparent 22.35294%, transparent 25.88235%, #ea1c0d 25.88235%, #ea1c0d 35.29412%, transparent 35.29412%, transparent 38.82353%, #F44336 38.82353%, #F44336 48.23529%, transparent 48.23529%, transparent 51.76471%, #ea1c0d 51.76471%, #ea1c0d 61.17647%, transparent 61.17647%, transparent 64.70588%, #F44336 64.70588%, #F44336 74.11765%, transparent 74.11765%, transparent 77.64706%, #ea1c0d 77.64706%, #ea1c0d 87.05882%, transparent 87.05882%, transparent 90.58824%, #F44336 90.58824%, #F44336 100%, transparent 100%);
  }
}

@media screen and (min-width: 29.4375em) {
  body:before {
    background-image: -webkit-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 9.41176%, transparent 9.41176%, transparent 12.94118%, #F44336 12.94118%, #F44336 22.35294%, transparent 22.35294%, transparent 25.88235%, #ea1c0d 25.88235%, #ea1c0d 35.29412%, transparent 35.29412%, transparent 38.82353%, #F44336 38.82353%, #F44336 48.23529%, transparent 48.23529%, transparent 51.76471%, #ea1c0d 51.76471%, #ea1c0d 61.17647%, transparent 61.17647%, transparent 64.70588%, #F44336 64.70588%, #F44336 74.11765%, transparent 74.11765%, transparent 77.64706%, #ea1c0d 77.64706%, #ea1c0d 87.05882%, transparent 87.05882%, transparent 90.58824%, #F44336 90.58824%, #F44336 100%, transparent 100%);
    background-image: -moz-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 9.41176%, transparent 9.41176%, transparent 12.94118%, #F44336 12.94118%, #F44336 22.35294%, transparent 22.35294%, transparent 25.88235%, #ea1c0d 25.88235%, #ea1c0d 35.29412%, transparent 35.29412%, transparent 38.82353%, #F44336 38.82353%, #F44336 48.23529%, transparent 48.23529%, transparent 51.76471%, #ea1c0d 51.76471%, #ea1c0d 61.17647%, transparent 61.17647%, transparent 64.70588%, #F44336 64.70588%, #F44336 74.11765%, transparent 74.11765%, transparent 77.64706%, #ea1c0d 77.64706%, #ea1c0d 87.05882%, transparent 87.05882%, transparent 90.58824%, #F44336 90.58824%, #F44336 100%, transparent 100%);
    background-image: -ms-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 9.41176%, transparent 9.41176%, transparent 12.94118%, #F44336 12.94118%, #F44336 22.35294%, transparent 22.35294%, transparent 25.88235%, #ea1c0d 25.88235%, #ea1c0d 35.29412%, transparent 35.29412%, transparent 38.82353%, #F44336 38.82353%, #F44336 48.23529%, transparent 48.23529%, transparent 51.76471%, #ea1c0d 51.76471%, #ea1c0d 61.17647%, transparent 61.17647%, transparent 64.70588%, #F44336 64.70588%, #F44336 74.11765%, transparent 74.11765%, transparent 77.64706%, #ea1c0d 77.64706%, #ea1c0d 87.05882%, transparent 87.05882%, transparent 90.58824%, #F44336 90.58824%, #F44336 100%, transparent 100%);
    background-image: -o-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 9.41176%, transparent 9.41176%, transparent 12.94118%, #F44336 12.94118%, #F44336 22.35294%, transparent 22.35294%, transparent 25.88235%, #ea1c0d 25.88235%, #ea1c0d 35.29412%, transparent 35.29412%, transparent 38.82353%, #F44336 38.82353%, #F44336 48.23529%, transparent 48.23529%, transparent 51.76471%, #ea1c0d 51.76471%, #ea1c0d 61.17647%, transparent 61.17647%, transparent 64.70588%, #F44336 64.70588%, #F44336 74.11765%, transparent 74.11765%, transparent 77.64706%, #ea1c0d 77.64706%, #ea1c0d 87.05882%, transparent 87.05882%, transparent 90.58824%, #F44336 90.58824%, #F44336 100%, transparent 100%);
    background-image: linear-gradient(to left, transparent 0, #ea1c0d 0, #ea1c0d 9.41176%, transparent 9.41176%, transparent 12.94118%, #F44336 12.94118%, #F44336 22.35294%, transparent 22.35294%, transparent 25.88235%, #ea1c0d 25.88235%, #ea1c0d 35.29412%, transparent 35.29412%, transparent 38.82353%, #F44336 38.82353%, #F44336 48.23529%, transparent 48.23529%, transparent 51.76471%, #ea1c0d 51.76471%, #ea1c0d 61.17647%, transparent 61.17647%, transparent 64.70588%, #F44336 64.70588%, #F44336 74.11765%, transparent 74.11765%, transparent 77.64706%, #ea1c0d 77.64706%, #ea1c0d 87.05882%, transparent 87.05882%, transparent 90.58824%, #F44336 90.58824%, #F44336 100%, transparent 100%);
  }
}

@media screen and (max-width: 29.375em) {
  body:before {
    background-image: -webkit-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 19.5122%, transparent 19.5122%, transparent 26.82927%, #F44336 26.82927%, #F44336 46.34146%, transparent 46.34146%, transparent 53.65854%, #ea1c0d 53.65854%, #ea1c0d 73.17073%, transparent 73.17073%, transparent 80.4878%, #F44336 80.4878%, #F44336 100%, transparent 100%);
    background-image: -moz-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 19.5122%, transparent 19.5122%, transparent 26.82927%, #F44336 26.82927%, #F44336 46.34146%, transparent 46.34146%, transparent 53.65854%, #ea1c0d 53.65854%, #ea1c0d 73.17073%, transparent 73.17073%, transparent 80.4878%, #F44336 80.4878%, #F44336 100%, transparent 100%);
    background-image: -ms-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 19.5122%, transparent 19.5122%, transparent 26.82927%, #F44336 26.82927%, #F44336 46.34146%, transparent 46.34146%, transparent 53.65854%, #ea1c0d 53.65854%, #ea1c0d 73.17073%, transparent 73.17073%, transparent 80.4878%, #F44336 80.4878%, #F44336 100%, transparent 100%);
    background-image: -o-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 19.5122%, transparent 19.5122%, transparent 26.82927%, #F44336 26.82927%, #F44336 46.34146%, transparent 46.34146%, transparent 53.65854%, #ea1c0d 53.65854%, #ea1c0d 73.17073%, transparent 73.17073%, transparent 80.4878%, #F44336 80.4878%, #F44336 100%, transparent 100%);
    background-image: linear-gradient(to left, transparent 0, #ea1c0d 0, #ea1c0d 19.5122%, transparent 19.5122%, transparent 26.82927%, #F44336 26.82927%, #F44336 46.34146%, transparent 46.34146%, transparent 53.65854%, #ea1c0d 53.65854%, #ea1c0d 73.17073%, transparent 73.17073%, transparent 80.4878%, #F44336 80.4878%, #F44336 100%, transparent 100%);
  }
}

@media screen and (min-width: 20em) {
  body:before {
    background-image: -webkit-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 19.5122%, transparent 19.5122%, transparent 26.82927%, #F44336 26.82927%, #F44336 46.34146%, transparent 46.34146%, transparent 53.65854%, #ea1c0d 53.65854%, #ea1c0d 73.17073%, transparent 73.17073%, transparent 80.4878%, #F44336 80.4878%, #F44336 100%, transparent 100%);
    background-image: -moz-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 19.5122%, transparent 19.5122%, transparent 26.82927%, #F44336 26.82927%, #F44336 46.34146%, transparent 46.34146%, transparent 53.65854%, #ea1c0d 53.65854%, #ea1c0d 73.17073%, transparent 73.17073%, transparent 80.4878%, #F44336 80.4878%, #F44336 100%, transparent 100%);
    background-image: -ms-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 19.5122%, transparent 19.5122%, transparent 26.82927%, #F44336 26.82927%, #F44336 46.34146%, transparent 46.34146%, transparent 53.65854%, #ea1c0d 53.65854%, #ea1c0d 73.17073%, transparent 73.17073%, transparent 80.4878%, #F44336 80.4878%, #F44336 100%, transparent 100%);
    background-image: -o-linear-gradient(left, transparent 0, #ea1c0d 0, #ea1c0d 19.5122%, transparent 19.5122%, transparent 26.82927%, #F44336 26.82927%, #F44336 46.34146%, transparent 46.34146%, transparent 53.65854%, #ea1c0d 53.65854%, #ea1c0d 73.17073%, transparent 73.17073%, transparent 80.4878%, #F44336 80.4878%, #F44336 100%, transparent 100%);
    background-image: linear-gradient(to left, transparent 0, #ea1c0d 0, #ea1c0d 19.5122%, transparent 19.5122%, transparent 26.82927%, #F44336 26.82927%, #F44336 46.34146%, transparent 46.34146%, transparent 53.65854%, #ea1c0d 53.65854%, #ea1c0d 73.17073%, transparent 73.17073%, transparent 80.4878%, #F44336 80.4878%, #F44336 100%, transparent 100%);
  }
}

.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  fill: currentColor;
}

.icon--24x24 {
  height: 24px;
  width: 24px;
}

.icon--36x36 {
  height: 36px;
  width: 36px;
}

.icon--48x48 {
  height: 48px;
  width: 48px;
}

body {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 1.25;
  color: #4a4a4a;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6,
.txt-h1, .txt-h2, .txt-h3, .txt-h4, .txt-h5, .promo-cta-grid__item-title, .txt-h6 {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  padding: 0;
  line-height: 1.3;
}

h1, h2, h3 {
  margin-bottom: 12px;
  font-weight: 600;
}

h4, h5, h6 {
  margin-bottom: 0.5625rem;
  font-weight: 700;
}

h1,
.txt-h1 {
  font-size: 50px;
  line-height: 62px;
}

@media (max-width: 56.875em) {
  h1,
  .txt-h1 {
    font-size: 2.2rem;
  }
}

@media (max-width: 29.375em) {
  h1,
  .txt-h1 {
    font-size: 1.8rem;
    line-height: 1.1;
  }
}

h2,
.txt-h2 {
  font-size: 35px;
  line-height: 45px;
}

h3,
.txt-h3 {
  font-size: 27px;
  line-height: 37px;
}

h4,
.txt-h4 {
  font-size: 22px;
}

h5, .txt-h5, .promo-cta-grid__item-title {
  font-size: 20px;
}

h6,
.txt-h6 {
  font-size: 18px;
  letter-spacing: .02em;
  text-transform: uppercase;
}

p {
  margin: 0;
  padding: 0;
  margin-bottom: 18px;
  font-size: 18px;
  line-height: 1.6;
}

.txt-small {
  font-size: 14px;
}

body {
  margin: 0;
}

body::before {
  content: 'large';
  display: none;
}

@media (max-width: 56.875em) {
  body::before {
    content: 'medium';
  }
}

@media (max-width: 29.375em) {
  body::before {
    content: 'small';
  }
}

object,
video {
  display: block;
  max-width: 100%;
}

ol,
ul {
  padding: 0;
  margin-top: 0;
  margin-left: 60px;
  margin-bottom: 30px;
}

ol li,
ul li {
  margin-bottom: 10px;
}

ul ul,
ol ul,
ol ol,
ul ol {
  margin-top: 10px;
}

dl {
  padding: 0;
  margin-top: 0;
  margin-left: 60px;
  margin-bottom: 30px;
}

dl dd, dl dt {
  margin-bottom: 10px;
}

a {
  color: #0f6eb4;
  text-decoration: none;
  transition: color 0.28s cubic-bezier(0.4, 0, 0.2, 1), color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

a:hover {
  color: rgba(15, 110, 180, 0.7);
}

hr {
  border-bottom: 1px solid #757575;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: -1px 0 0 0;
  box-sizing: content-box;
}

figure {
  margin: 0;
}

table {
  border-collapse: collapse;
}

th, td {
  border: 1px solid #bdbdbd;
  padding: 10px;
  font-size: 16px;
}

th {
  background-color: #e0e0e0;
}

.btn {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  display: inline-block;
  padding: 8px 16px;
  color: #757575;
  text-decoration: none;
  border: 1px solid #c2c2c2;
  background-color: whitesmoke;
  line-height: 1;
  /* Prevent button from inheriting line-height */
  vertical-align: middle;
  transition: color 0.35s, background-color 0.35s, border-color 0.35s;
  cursor: pointer;
  font-size: .875rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .02em;
}

.btn:active, .btn:hover {
  outline: 0;
  color: #5c5c5c;
  background-color: #dbdbdb;
  border-color: #a8a8a8;
}

.btn:focus {
  outline: 0;
}

.btn--primary {
  color: #304958;
  border: 1px solid #f4ad3d;
  background-color: #f4ad3d;
}

.btn--primary:active, .btn--primary:hover {
  color: #f4ad3d;
  background-color: #304958;
  border-color: #f4ad3d;
}

.btn--primary.btn--disabled {
  opacity: 0.3;
}

.btn--primary.btn--ghost {
  color: #f4ad3d;
}

.btn--primary.btn--ghost:active, .btn--primary.btn--ghost:hover {
  color: #f4ad3d;
}

.btn--secondary {
  color: #fff;
  border: 1px solid #1970b1;
  background-color: #1970b1;
}

.btn--secondary:active, .btn--secondary:hover {
  color: #1970b1;
  background-color: #fff;
  border-color: #1970b1;
}

.btn--secondary.btn--disabled {
  opacity: 0.35;
}

.btn--link,
.btn--reset {
  border: 0;
  background-color: transparent;
  text-decoration: underline;
}

.btn--link:active, .btn--link:hover,
.btn--reset:active,
.btn--reset:hover {
  text-decoration: none;
  background-color: transparent;
}

.btn--link[disabled],
.btn--reset[disabled] {
  color: #bdbdbd;
  cursor: default;
  text-decoration: none;
}

.btn--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn--ghost {
  background-color: transparent;
}

.btn--ghost:active, .btn--ghost:hover {
  background-color: transparent;
}

.btn--tag {
  padding: 2px 4px;
  font-weight: bold;
}

.btn--tag .icon {
  height: 16px;
  width: 16px;
  margin-left: 4px;
  fill: #c2c2c2;
  transition: fill 0.35s;
}

.btn--tag:hover .icon {
  fill: #757575;
}

.btn--sort {
  border: 0;
  background-color: transparent;
  overflow: hidden;
}

.btn--icon-group {
  position: relative;
  padding-right: 24px;
}

.btn--icon-group .btn_icon-group {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.btn--icon-group .btn_icon-group .icon {
  position: absolute;
  right: 0;
  height: 1.846em;
  max-height: 24px;
  width: 1.846em;
  max-width: 24px;
  fill: currentColor;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.35s;
}

.btn--icon-group .btn_icon-group .icon:first-child {
  bottom: 35%;
  transform: translateY(35%);
}

.btn--icon-group .btn_icon-group .icon:last-child {
  top: 35%;
  transform: translateY(-35%);
}

.btn--link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #1970b1;
  font-size: 18px;
  letter-spacing: 1px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-top: 15px;
  text-decoration: none;
  text-transform: uppercase;
}

.btn--link:after {
  content: "→";
  color: #4a4a4a;
  padding-left: 10px;
  font-family: serif;
}

.container {
  position: relative;
  margin-bottom: 60px;
}

.container.is-adjacent {
  margin-bottom: 0;
}

@media (max-width: 56.875em) {
  .container {
    margin-bottom: 48px;
  }
}

.container--bleed {
  max-width: 2580px;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-position: center;
}

.container--bleed > .container__col {
  position: relative;
}

.container--bleed > .container__col .container:last-child {
  margin-bottom: 0;
}

.container--bleed.has-image, .container--bleed.has-color {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 56.9375em) {
  .container--bleed .container--100,
  .container--bleed .container--70-30,
  .container--bleed .container--50-50,
  .container--bleed .container--30-70 {
    margin-left: auto;
    margin-right: auto;
  }
}

.container--100,
.container--70-30,
.container--50-50,
.container--30-70 {
  max-width: 1290px;
  margin-left: auto;
  margin-right: auto;
}

.container--100::after,
.container--70-30::after,
.container--50-50::after,
.container--30-70::after {
  clear: both;
  content: "";
  display: block;
}

@media (max-width: 80.625em) {
  .container--100,
  .container--70-30,
  .container--50-50,
  .container--30-70 {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.container--70-30 > .container__col:first-child {
  float: left;
  display: block;
  margin-right: 2.32558%;
  width: 65.89147%;
  margin-right: 7.32558%;
}

.container--70-30 > .container__col:first-child:last-child {
  margin-right: 0;
}

.container--70-30 > .container__col:last-child {
  float: left;
  display: block;
  margin-right: 2.32558%;
  width: 31.78295%;
  width: 26.78295%;
}

.container--70-30 > .container__col:last-child:last-child {
  margin-right: 0;
}

@media screen and (max-width: 56.875em) {
  .container--70-30 > .container__col:first-child {
    float: left;
    display: block;
    margin-right: 3.52941%;
    width: 61.17647%;
  }
  .container--70-30 > .container__col:first-child:last-child {
    margin-right: 0;
  }
  .container--70-30 > .container__col:last-child {
    float: left;
    display: block;
    margin-right: 3.52941%;
    width: 35.29412%;
  }
  .container--70-30 > .container__col:last-child:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 29.375em) {
  .container--70-30 > .container__col {
    margin-bottom: 60px;
  }
  .container--70-30 > .container__col:first-child {
    float: left;
    display: block;
    margin-right: 7.31707%;
    width: 100%;
  }
  .container--70-30 > .container__col:first-child:last-child {
    margin-right: 0;
  }
  .container--70-30 > .container__col:last-child {
    float: left;
    display: block;
    margin-right: 7.31707%;
    width: 100%;
    margin-bottom: 0;
  }
  .container--70-30 > .container__col:last-child:last-child {
    margin-right: 0;
  }
}

.container--50-50--center {
  align-items: center;
  display: flex;
}

@media screen and (max-width: 56.875em) {
  .container--50-50--center {
    display: block;
  }
}

.container--50-50 .container__col {
  float: left;
  display: block;
  margin-right: 2.32558%;
  width: 48.83721%;
}

.container--50-50 .container__col:last-child {
  margin-right: 0;
}

@media screen and (max-width: 56.875em) {
  .container--50-50 .container__col {
    float: left;
    display: block;
    margin-right: 3.52941%;
    width: 100%;
  }
  .container--50-50 .container__col:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 29.375em) {
  .container--50-50 .container__col {
    float: left;
    display: block;
    margin-right: 7.31707%;
    width: 100%;
    margin-bottom: 60px;
  }
  .container--50-50 .container__col:last-child {
    margin-right: 0;
  }
  .container--50-50 .container__col:last-child {
    margin-bottom: 0;
  }
}

.container--30-70 > .container__col:first-child {
  float: left;
  display: block;
  margin-right: 2.32558%;
  width: 31.78295%;
  margin-right: 7.32558%;
  width: 26.78295%;
}

.container--30-70 > .container__col:first-child:last-child {
  margin-right: 0;
}

.container--30-70 > .container__col:last-child {
  float: left;
  display: block;
  margin-right: 2.32558%;
  width: 65.89147%;
}

.container--30-70 > .container__col:last-child:last-child {
  margin-right: 0;
}

@media screen and (max-width: 56.875em) {
  .container--30-70 > .container__col:first-child {
    float: left;
    display: block;
    margin-right: 3.52941%;
    width: 100%;
  }
  .container--30-70 > .container__col:first-child:last-child {
    margin-right: 0;
  }
  .container--30-70 > .container__col:last-child {
    float: left;
    display: block;
    margin-right: 3.52941%;
    width: 100%;
  }
  .container--30-70 > .container__col:last-child:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 29.375em) {
  .container--30-70 > .container__col {
    margin-bottom: 60px;
  }
  .container--30-70 > .container__col:first-child {
    float: left;
    display: block;
    margin-right: 7.31707%;
    width: 100%;
  }
  .container--30-70 > .container__col:first-child:last-child {
    margin-right: 0;
  }
  .container--30-70 > .container__col:last-child {
    float: left;
    display: block;
    margin-right: 7.31707%;
    width: 100%;
    margin-bottom: 0;
  }
  .container--30-70 > .container__col:last-child:last-child {
    margin-right: 0;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/assets/img/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("/assets/img/slick/fonts/slick.eot");
  src: url("/assets/img/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("/assets/img/slick/fonts/slick.woff") format("woff"), url("/assets/img/slick/fonts/slick.ttf") format("truetype"), url("/assets/img/slick/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: #4a4a4a;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: #4a4a4a;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: #4a4a4a;
  opacity: 0.75;
}

.PresetDateRangePicker_panel {
  padding: 0 22px 11px;
}

.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #00a699;
  color: #00a699;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

.PresetDateRangePicker_button:active {
  outline: 0;
}

.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #00a699;
}

.SingleDatePickerInput {
  display: inline-block;
  background-color: #fff;
}

.SingleDatePickerInput__withBorder {
  border: 1px solid #dbdbdb;
}

.SingleDatePickerInput__rtl {
  direction: rtl;
}

.SingleDatePickerInput__disabled {
  background-color: #f2f2f2;
}

.SingleDatePickerInput__block {
  display: block;
}

.SingleDatePickerInput__showClearDate {
  padding-right: 30px;
}

.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}

.SingleDatePickerInput_clearDate__small {
  padding: 6px;
}

.SingleDatePickerInput_clearDate__hide {
  visibility: hidden;
}

.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}

.SingleDatePickerInput_clearDate_svg__small {
  height: 9px;
}

.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px;
}

.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}

.SingleDatePicker {
  position: relative;
  display: inline-block;
}

.SingleDatePicker__block {
  display: block;
}

.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
}

.SingleDatePicker_picker__rtl {
  direction: rtl;
}

.SingleDatePicker_picker__directionLeft {
  left: 0;
}

.SingleDatePicker_picker__directionRight {
  right: 0;
}

.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff;
}

.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: #b0b3b4;
  text-decoration: none;
}

.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}

.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
}

.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0;
}

.DayPickerKeyboardShortcuts_show {
  width: 22px;
  position: absolute;
  z-index: 2;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  border-top: 26px solid transparent;
  border-right: 33px solid #00a699;
  bottom: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover {
  border-right: 33px solid #008489;
}

.DayPickerKeyboardShortcuts_show__topRight {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #00a699;
  top: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__topRight:hover {
  border-right: 33px solid #008489;
}

.DayPickerKeyboardShortcuts_show__topLeft {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #00a699;
  top: 0;
  left: 0;
}

.DayPickerKeyboardShortcuts_show__topLeft:hover {
  border-left: 33px solid #008489;
}

.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute;
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: -28px;
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: -28px;
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: -28px;
}

.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
}

.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px;
}

.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2;
}

.DayPickerKeyboardShortcuts_close:active {
  outline: 0;
}

.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a;
}

.CalendarDay {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.CalendarDay:active {
  outline: 0;
}

.CalendarDay__defaultCursor {
  cursor: default;
}

.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #565a5c;
  background: #fff;
}

.CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px double #e4e7e7;
  color: inherit;
}

.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #e4e7e7;
  color: inherit;
}

.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #565a5c;
}

.CalendarDay__blocked_minimum_nights {
  background: #fff;
  border: 1px solid #eceeee;
  color: #cacccd;
}

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd;
}

.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #565a5c;
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #565a5c;
}

.CalendarDay__selected_span {
  background: #66e2da;
  border: 1px solid #33dacd;
  color: #fff;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #33dacd;
  border: 1px solid #33dacd;
  color: #fff;
}

.CalendarDay__last_in_range {
  border-right: #00a699;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00a699;
  border: 1px solid #00a699;
  color: #fff;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b2f1ec;
  border: 1px solid #80e8e0;
  color: #007a87;
}

.CalendarDay__hovered_span:active {
  background: #80e8e0;
  border: 1px solid #80e8e0;
  color: #007a87;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd;
}

.CalendarMonth {
  background: #fff;
  text-align: center;
  padding: 0 13px;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0;
}

.CalendarMonth_caption {
  color: #565a5c;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: initial;
}

.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px;
}

.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0;
}

.CalendarMonthGrid__animating {
  z-index: 1;
}

.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px;
}

.CalendarMonthGrid__vertical {
  margin: 0 auto;
}

.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll;
}

.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%;
}

.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.CalendarMonthGrid_month__hidden {
  visibility: hidden;
}

.DayPickerNavigation_container {
  position: relative;
  z-index: 2;
}

.DayPickerNavigation_container__vertical {
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 52px;
  width: 100%;
}

.DayPickerNavigation_container__verticalScrollable {
  position: relative;
}

.DayPickerNavigation_button {
  cursor: pointer;
  line-height: .78;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575;
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4;
}

.DayPickerNavigation_button__default:active {
  background: #f2f2f2;
}

.DayPickerNavigation_button__horizontal {
  border-radius: 3px;
  padding: 6px 9px;
  top: 18px;
  position: absolute;
}

.DayPickerNavigation_leftButton__horizontal {
  left: 22px;
}

.DayPickerNavigation_rightButton__horizontal {
  right: 22px;
}

.DayPickerNavigation_button__vertical {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 50%;
}

.DayPickerNavigation_button__vertical__default {
  padding: 5px;
}

.DayPickerNavigation_nextButton__vertical__default {
  border-left: 0;
}

.DayPickerNavigation_nextButton__verticalScrollable {
  width: 100%;
}

.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
}

.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #565a5c;
}

.DayPicker {
  background: #fff;
  position: relative;
  text-align: left;
}

.DayPicker__horizontal {
  background: #fff;
}

.DayPicker__verticalScrollable {
  height: 100%;
}

.DayPicker__hidden {
  visibility: hidden;
}

.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
}

.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%;
}

.DayPicker_portal__vertical {
  position: initial;
}

.DayPicker_focusRegion {
  outline: 0;
}

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top;
}

.DayPicker_weekHeaders {
  position: relative;
}

.DayPicker_weekHeaders__horizontal {
  margin-left: 9px;
}

.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 62px;
  z-index: 2;
  padding: 0 13px;
  text-align: left;
}

.DayPicker_weekHeader__vertical {
  left: 50%;
}

.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
}

.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center;
}

.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}

.DayPicker_transitionContainer__horizontal {
  -webkit-transition: height .2s ease-in-out;
  -moz-transition: height .2s ease-in-out;
  transition: height .2s ease-in-out;
}

.DayPicker_transitionContainer__vertical {
  width: 100%;
}

.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
}

.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle;
}

.DateInput__small {
  width: 90px;
}

.DateInput__block {
  width: 100%;
}

.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb;
}

.DateInput_input {
  font-weight: 200;
  font-size: 18px;
  line-height: 24px;
  color: #565a5c;
  background-color: #fff;
  width: 100%;
  padding: 13px 12px 11px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
}

.DateInput_input__small {
  font-size: 14px;
  line-height: 18px;
  padding: 8px 8px 6px;
}

.DateInput_input__regular {
  font-weight: auto;
}

.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #008489;
  border-left: 0;
}

.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic;
}

.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2;
}

.DateInput_fangShape {
  fill: #fff;
}

.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent;
}

.DateRangePickerInput {
  background-color: #fff;
  display: inline-block;
}

.DateRangePickerInput__disabled {
  background: #f2f2f2;
}

.DateRangePickerInput__withBorder {
  border: 1px solid #cacccd;
}

.DateRangePickerInput__rtl {
  direction: rtl;
}

.DateRangePickerInput__block {
  display: block;
}

.DateRangePickerInput__showClearDates {
  padding-right: 30px;
}

.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
}

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #565a5c;
  height: 24px;
  width: 24px;
}

.DateRangePickerInput_arrow_svg__small {
  height: 19px;
  width: 19px;
}

.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.DateRangePickerInput_clearDates__small {
  padding: 6px;
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}

.DateRangePickerInput_clearDates__hide {
  visibility: hidden;
}

.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}

.DateRangePickerInput_clearDates_svg__small {
  height: 9px;
}

.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px;
}

.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}

.DateRangePicker {
  position: relative;
  display: inline-block;
}

.DateRangePicker__block {
  display: block;
}

.DateRangePicker_picker {
  z-index: 101;
  background-color: #fff;
  position: absolute;
}

.DateRangePicker_picker__rtl {
  direction: rtl;
}

.DateRangePicker_picker__directionLeft {
  left: 0;
}

.DateRangePicker_picker__directionRight {
  right: 0;
}

.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff;
}

.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: #b0b3b4;
  text-decoration: none;
}

.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}

.u-hidden {
  display: none !important;
  visibility: hidden;
}

.u-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.u-visuallyhidden.focusable:active,
.u-visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.u-invisible {
  visibility: hidden;
}

.u-clearfix:before,
.u-clearfix:after {
  content: " ";
  display: table;
}

.u-clearfix:after {
  clear: both;
}

.u-clearfix {
  *zoom: 1;
}

.u-wire-label {
  margin: 0;
  padding: 0;
  display: inline-block;
  padding: 10px;
  font-size: 0.8125rem;
  text-transform: uppercase;
  color: #9e9e9e;
  background: rgba(245, 245, 245, 0.75);
  border: 1px solid #f5f5f5;
}

.u-color-pair *:not(.u-color-pair) {
  color: inherit !important;
}

.g-outer-container {
  width: 100%;
}

.g-column-container {
  max-width: 1290px;
  margin-left: auto;
  margin-right: auto;
}

.g-column-container::after {
  clear: both;
  content: "";
  display: block;
}

.g-row {
  display: block;
  margin-bottom: 30px;
}

.g-row::after {
  clear: both;
  content: "";
  display: block;
}

.g-omega {
  margin-right: 0;
}

div .l-full, div .l-rte-full, section .l-full, section .l-rte-full {
  width: 100%;
}

div .l-pull-left, div .l-rte-pull-left, section .l-pull-left, section .l-rte-pull-left {
  float: left;
  margin-bottom: 30px;
  margin-right: 30px;
}

div .l-pull-right, div .l-rte-pull-right, section .l-pull-right, section .l-rte-pull-right {
  float: right;
  margin-bottom: 30px;
  margin-left: 30px;
}

.l-table {
  position: relative;
  display: table;
  border-collapse: collapse;
}

.l-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.l-left-centered {
  display: flex;
  align-items: center;
}

.l-left-bottom {
  display: flex;
  align-items: flex-end;
}

.l-center-top {
  margin-left: auto;
  margin-right: auto;
}

.l-center-bottom {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.l-right-top {
  margin-left: auto;
}

.l-right-centered {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.l-right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.accordion-list .scLooseFrameZone {
  display: block;
}

.accordion-list__toggle-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 45px;
}

.accordion-list__toggle-all {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  text-transform: none;
  border: 2px solid #0f6eb4;
  padding: 8px 20px;
}

.accordion-list__toggle-all::after {
  content: none;
  display: none;
}

.health-exec .accordion-list__toggle-all.btn--link {
  color: #0f6eb4;
  font-weight: 500;
  font-size: 16px;
}

.accordion-list__accordion {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;
  border-bottom: 2px solid #0f6eb4;
}

.accordion-list__accordion.is-open {
  background-color: #e7f0f7;
}

.accordion-list__accordion:not(:first-child) {
  padding-top: 24px;
}

.accordion-list__accordion.is-open .accordion-list__accordion-link:after {
  transform: rotate(180deg);
  top: 30%;
}

.accordion-list__accordion img {
  width: 100%;
  object-fit: contain;
}

.accordion-list__accordion-link {
  background-color: transparent;
  border: 0;
  padding: 0;
  text-align: left;
  position: relative;
}

.accordion-list__accordion-link h2 {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #0f6eb4;
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 0;
  line-height: 1.2;
  width: calc(100% - 20px);
}

.accordion-list__accordion-link:after {
  content: "";
  position: absolute;
  top: 33%;
  right: 0;
  border-left: solid 8px transparent;
  border-right: solid 8px transparent;
  border-top: solid 8px #0f6eb4;
}

.accordion-list__accordion-content {
  text-align: left;
}

.accordion-wrapper > .js-accordion-content {
  margin-top: 24px;
}

.accessibility--hidden {
  /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.ad {
  text-align: center;
  margin-bottom: 30px;
}

.ad img {
  max-width: 100%;
}

.ad:last-child {
  margin-bottom: 0;
}

.ad__content {
  display: inline-block;
  position: relative;
}

.ad__text {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  align-items: center;
  color: #9b9b9b;
  display: flex;
  font-size: 13px;
}

.ad__text:before, .ad__text:after {
  background-color: #9b9b9b;
  content: "";
  display: inline-block;
  flex-grow: 1;
  height: 1px;
  position: relative;
  vertical-align: middle;
}

.ad__text:before {
  margin-right: 10px;
}

.ad__text:after {
  margin-left: 10px;
}

.ad__close {
  background-color: #1970b1;
  border: 1px solid #9b9b9b;
  height: 15px;
  right: 0;
  top: 35px;
  width: 15px;
  position: absolute;
}

.ad__close svg {
  fill: #fff;
  height: 9px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 9px;
}

@media (max-width: 56.875em) {
  .ad--wide {
    display: none;
  }
}

.ad--mobile {
  display: none;
}

@media (max-width: 56.875em) {
  .ad--mobile {
    display: block;
  }
}

.ad--mobile-interstitial {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: none;
  left: 0;
  margin-bottom: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 101;
}

.ad--mobile-interstitial .ad__close {
  height: 20px;
  top: 0;
  width: 20px;
}

@media (max-width: 56.875em) {
  .ad--mobile-interstitial {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.connect a {
  font-weight: 600;
  text-decoration: none;
}

.connect__button {
  margin-bottom: 30px;
}

.connect__inner {
  align-items: center;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 56.875em) {
  .connect__inner {
    flex-wrap: wrap;
  }
}

@media (max-width: 29.375em) {
  .connect__inner {
    display: block;
  }
}

.connect__social {
  padding: 0 0 16px;
  order: 4;
  white-space: nowrap;
  /*@media (max-width: $bp-max-medium) {
    padding: 0 0 12px;
  }*/
}

@media (max-width: 570px) {
  .connect__social {
    padding: 0 0 36px;
    width: 100%;
  }
}

.connect__social a {
  display: inline-block;
  line-height: 1;
  margin-left: 10px;
  text-decoration: none;
}

.connect__social a:first-child {
  margin-left: 0;
}

.connect__phone {
  margin-bottom: 30px;
}

.connect__promo-statement {
  margin-bottom: 30px;
}

.connect__social-icon {
  fill: currentColor;
  height: 44px;
  width: 44px;
}

.connect__social-icon.--facebook {
  fill: #3b5998;
}

.connect__social-icon.--twitter {
  fill: #55acee;
}

.connect__social-icon.--linkedin {
  fill: #0976b4;
}

.connect__social-icon.--google {
  fill: #dd4b39;
}

.connect__social-icon.--instagram {
  fill: #a63d97;
  padding: 2px;
}

.connect__social-icon.--rss {
  fill: #f26522;
}

.content-grid {
  width: 100%;
  position: relative;
  background-color: #e7f0f7;
  padding: 10px 0;
}

.content-grid__list {
  margin-bottom: 30px;
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: repeat(4, 1fr);
  -ms-grid-rows: 1fr 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 10px auto;
  max-width: 1290px;
}

@media (max-width: 56.875em) {
  .content-grid__list {
    grid-template-columns: auto;
    grid-template-rows: repeat(4, 1fr);
    -ms-grid-rows: 1fr 1fr 1fr 1fr;
    -ms-grid-columns: 1fr 1fr 1fr;
  }
}

@supports (display: grid) {
  .content-grid__list {
    display: grid;
  }
  @media (max-width: 29.375em) {
    .content-grid__list {
      display: flex;
      flex-direction: column;
    }
  }
}

@media (max-width: 29.375em) {
  .content-grid__list {
    display: flex;
    flex-direction: column;
  }
}

.content-grid__item {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  hyphens: auto;
  justify-content: space-between;
  list-style-type: none;
  margin-bottom: 0;
  position: relative;
  padding: 20px;
  margin: 5px;
}

.content-grid__item .content-grid__content-type {
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
}

.content-grid__item .content-grid__title {
  font-size: 30px;
  display: block;
  flex-grow: 2;
}

.content-grid__item .btn {
  align-self: baseline;
}

.content-grid__item--featured {
  grid-column-start: span 2;
  grid-row-start: span 2;
  background-size: cover;
  padding: 0;
  -ms-grid-row-span: 2;
  -ms-grid-column-span: 2;
  overflow: hidden;
}

.content-grid__item--featured img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

@media (max-width: 29.375em) {
  .content-grid__item--featured img {
    width: 100%;
    height: auto;
    min-height: 1px;
    display: block;
  }
}

.content-grid__item--featured .content-grid__title {
  font-size: 40px;
  line-height: 48px;
}

.content-grid__item--featured .content-grid__featured-content {
  bottom: 0;
  color: #fff;
  background-color: #1970b1;
  position: absolute;
  width: 80%;
  padding: 40px;
}

.content-grid__item--featured .content-grid__featured-content a {
  color: #fff;
}

.content-grid__item--featured .content-grid__featured-content a:after {
  color: #fff;
}

@media (max-width: 56.875em) {
  .content-grid__item--featured .content-grid__featured-content {
    padding: 20px;
  }
}

@media (max-width: 29.375em) {
  .content-grid__item--featured .content-grid__featured-content {
    display: block;
    width: 100%;
    position: relative;
    padding: 20px;
  }
}

@media (max-width: 56.875em) {
  .content-grid__item--featured {
    grid-column-start: span 2;
    grid-row-start: span 1;
    -ms-grid-column-span: 2;
    -ms-grid-row-span: 1;
  }
}

@media (max-width: 29.375em) {
  .content-grid__item--featured {
    justify-content: flex-start;
  }
}

.content-grid__item--video {
  grid-column-start: span 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  -ms-grid-column-span: 2;
  -ms-grid-column: 3;
}

.content-grid__item--video > div {
  vertical-align: middle;
}

.content-grid__item--video .content-grid__video-image {
  position: relative;
  margin-right: 10px;
}

.content-grid__item--video .content-grid__video-image a {
  display: block;
  width: 100%;
  height: 100%;
}

.content-grid__item--video .content-grid__video-image img {
  object-fit: contain;
  height: auto;
  width: 100%;
}

@media (max-width: 29.375em) {
  .content-grid__item--video .content-grid__video-image .video-block__play-button {
    height: 80px;
    width: 80px;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    position: absolute;
  }
}

@media (max-width: 56.875em) {
  .content-grid__item--video .content-grid__video-image {
    width: 100%;
    margin-right: 0;
  }
}

.content-grid__item--video .content-grid__video-content {
  width: 60%;
}

@media (max-width: 56.875em) {
  .content-grid__item--video .content-grid__video-content {
    width: 100%;
    padding: 20px;
  }
}

@media (max-width: 56.875em) {
  .content-grid__item--video {
    grid-column-start: 3;
    grid-row-start: span 1;
    -ms-grid-column-span: 1;
    -ms-grid-column: 3;
    flex-direction: column-reverse;
    padding: 0;
    justify-content: space-around;
  }
}

@media (max-width: 29.375em) {
  .content-grid__item--video {
    grid-column-start: span 2;
    grid-row-start: 3;
  }
}

.content-grid__item:last-of-type {
  background-color: #1970b1;
  color: #fff;
}

.content-grid__item:last-of-type a {
  color: #fff;
}

.content-grid__item:last-of-type a:after {
  color: #fff;
}

@supports (display: grid) {
  .content-grid__item {
    margin: 0;
  }
}

.content-grid__item:nth-of-type(3) {
  -ms-grid-column: 3;
  -ms-grid-row: 2;
}

@media (max-width: 56.875em) {
  .content-grid__item:nth-of-type(3) {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
  }
}

.content-grid__item:nth-of-type(4) {
  -ms-grid-column: 4;
  -ms-grid-row: 2;
}

@media (max-width: 56.875em) {
  .content-grid__item:nth-of-type(4) {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
  }
}

.content-grid__item:nth-of-type(5) {
  -ms-grid-column: 1;
  -ms-grid-row: 3;
}

@media (max-width: 56.875em) {
  .content-grid__item:nth-of-type(5) {
    -ms-grid-column: 3;
    -ms-grid-row: 2;
  }
}

.content-grid__item:nth-of-type(6) {
  -ms-grid-column: 2;
  -ms-grid-row: 3;
}

@media (max-width: 56.875em) {
  .content-grid__item:nth-of-type(6) {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
  }
}

.content-grid__item:nth-of-type(7) {
  -ms-grid-column: 3;
  -ms-grid-row: 3;
}

.content-grid__item:nth-of-type(8) {
  -ms-grid-column: 4;
  -ms-grid-row: 3;
}

@media (max-width: 56.875em) {
  .content-grid__item:nth-of-type(8) {
    -ms-grid-column: 2;
    -ms-grid-row: 3;
  }
}

@media (max-width: 29.375em) {
  .content-grid__item {
    width: calc(100% - 20px);
    margin: 10px;
    position: relative;
  }
}

.content-detail-banner {
  background-color: #e7f0f7;
  padding: 40px 100px;
  width: 100%;
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@media (max-width: 56.875em) {
  .content-detail-banner {
    padding: 20px;
  }
}

@media (max-width: 29.375em) {
  .content-detail-banner {
    padding: 10px;
  }
}

.content-detail-banner .content-detail-banner__content-type {
  color: #737373;
  font-size: 20px;
  letter-spacing: 2px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.content-detail-banner .content-detail-banner__title {
  color: #4a4a4a;
  display: block;
  font-weight: 600;
  font-size: 45px;
  line-height: 51px;
  margin-bottom: 30px;
}

.content-detail-banner .content-detail-banner__subtitle {
  margin-bottom: 30px;
  display: block;
}

.content-detail-banner .content-detail-banner__byline {
  display: flex;
  font-size: 20px;
  font-weight: 500;
  color: #4a4a4a;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

@media (max-width: 56.875em) {
  .content-detail-banner .content-detail-banner__byline {
    flex-direction: column;
  }
}

.content-detail-banner .content-detail-banner__author {
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 10px;
}

@media (max-width: 56.875em) {
  .content-detail-banner .content-detail-banner__author {
    flex-basis: 1;
  }
}

.content-detail-banner .content-detail-banner__collection,
.content-detail-banner .content-detail-banner__topics {
  display: block;
  font-size: 20px;
  font-weight: 400;
}

.content-detail-banner .content-detail-banner__collection span,
.content-detail-banner .content-detail-banner__topics span {
  background-color: #fff;
  border-radius: 3px;
  padding: 4px 8px;
  color: #4a4a4a;
  margin-right: 15px;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  margin-bottom: 15px;
  display: inline-block;
}

.content-detail-banner .content-detail-banner__collection span a,
.content-detail-banner .content-detail-banner__topics span a {
  color: #4a4a4a;
}

.content-detail-banner .content-detail-banner__collection span:first-of-type,
.content-detail-banner .content-detail-banner__topics span:first-of-type {
  margin-left: 15px;
}

.content-detail-banner .scEnabledChrome {
  display: block;
}

.container--30-70 .content-detail-banner {
  margin-left: -3.52558%;
  width: calc(100% + 3.52558%);
}

@media (max-width: 56.875em) {
  .container--30-70 .content-detail-banner {
    margin-left: auto;
    width: 100%;
  }
}

.copyright {
  color: #4a4a4a;
  font-size: 18px;
  line-height: 1.778;
}

.error {
  max-width: 629px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.error__main {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #1970b1;
  font-size: 200px;
  margin-bottom: 50px;
  line-height: 110%;
}

@media (max-width: 56.875em) {
  .error__main {
    font-size: 100px !important;
  }
}

.error__sub {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #1970b1;
  font-size: 70px;
  line-height: 110%;
}

@media (max-width: 56.875em) {
  .error__sub {
    font-size: 50px !important;
  }
}

.error__message {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #1970b1;
  font-size: 45px;
  line-height: 110%;
  margin-top: 40px;
}

@media (max-width: 56.875em) {
  .error__message {
    font-size: 30px !important;
  }
}

.error__go-back {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #1970b1;
  font-size: 18px;
  letter-spacing: 1px;
  margin-top: 70px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-top: 15px;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
}

.error__go-back:before {
  content: "←";
  color: #4a4a4a;
  padding-right: 20px;
  font-family: serif;
}

.alert-banner {
  background-color: #f4ad3d;
  width: 100%;
  border-bottom: 3px solid #0f6eb4;
}

@media (max-width: 768px) {
  .alert-banner {
    margin-bottom: 8px;
    border-bottom: 3px solid #0f6eb4;
  }
}

.alert-banner .alert-banner__copy {
  width: 100%;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .alert-banner .alert-banner__copy {
    flex-direction: column;
  }
}

@media (max-width: 1124px) {
  .alert-banner .alert-banner__copy {
    padding: 0 8px;
    max-width: 100%;
  }
}

.alert-banner .alert-banner__title {
  border-right: #4a4a4a solid 1px;
  font-family: Helvetica, Arial, sans-serif;
  color: #4a4a4a;
  font-size: 25px;
  font-weight: 700;
  line-height: 27px;
  margin-left: 10px;
  padding-left: 20px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 768px) {
  .alert-banner .alert-banner__title {
    width: 100%;
    margin-left: 30px;
    padding: 12px 8px 12px 8px;
    border-right: 0;
    border-bottom: #4a4a4a solid 1px;
  }
}

.alert-banner .alert-banner__message {
  color: #4a4a4a;
  font-size: 18px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 27px;
  padding-left: 25px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 768px) {
  .alert-banner .alert-banner__message {
    width: 100%;
    margin-left: 30px;
    padding: 12px 8px 12px 8px;
  }
}

.global-header {
  font-size: 16px;
}

@media (max-width: 56.875em) {
  .global-header .container--bleed {
    background-color: transparent;
  }
}

.global-header .js-search-toggle {
  margin-right: 24px;
}

.global-header .search-box {
  width: 197px;
  float: right;
}

@media (max-width: 80.625em) {
  .global-header .search-box {
    width: 100%;
  }
}

@media print {
  .global-header .search-box {
    display: none;
  }
}

@media (max-width: 56.875em) {
  .global-header .search-box__input {
    border: 0;
  }
}

.global-header .container:not(.is-adjacent) {
  margin-bottom: 30px;
}

.global-header .container--bleed .container__col {
  max-width: 1290px;
  margin-left: auto;
  margin-right: auto;
}

.global-header .container--bleed .container__col::after {
  clear: both;
  content: "";
  display: block;
}

@media (max-width: 56.875em) {
  .global-header .container--bleed .container__col {
    position: static;
  }
}

@media (max-width: 56.875em) {
  .global-header .container {
    position: static;
  }
}

.global-header__top {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

@media (max-width: 56.875em) {
  .global-header__top {
    margin-bottom: calc( 1rem + 48px);
    padding-top: 10px;
    position: relative;
    text-align: center;
    width: 100%;
  }
}

.global-header__top-right {
  align-items: center;
  display: flex;
  padding-left: 20px;
}

.global-header__top-right .utility-nav {
  margin-right: 29px;
}

@media (max-width: 56.875em) {
  .global-header__top-right .utility-nav {
    display: none;
  }
}

.global-header__top-right .utility-nav__list {
  float: right;
}

.global-header__top-right .utility-callout {
  margin-right: 25px;
}

@media (max-width: 56.875em) {
  .global-header__top-right .utility-callout {
    display: none;
  }
}

.global-header__top-left {
  float: left;
}

@media (max-width: 56.875em) {
  .global-header__top-left {
    display: inline-block;
    float: none;
    margin-bottom: 20px;
  }
}

.global-header__bottom {
  display: flex;
  position: relative;
  padding-bottom: 18px;
  padding-top: 18px;
}

@media (max-width: 56.875em) {
  .global-header__bottom {
    position: static;
  }
}

@media (max-width: 56.875em) {
  .global-header__bottom .search-box {
    display: block;
    position: absolute;
    right: 0;
    top: 1.375rem;
  }
}

.global-header__search-form {
  margin-left: 0.4375rem;
}

@media (max-width: 740px) {
  .global-header__search-form {
    display: block;
    margin-left: 0;
    margin-bottom: 12px;
  }
}

@media (max-width: 560px) {
  .global-header__search-form {
    margin: 12px auto;
    width: 90%;
  }
}

.global-header__cta {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #1970b1;
  font-weight: 700;
  text-decoration: none;
}

@media (max-width: 56.875em) {
  .global-header__cta {
    color: #fff;
  }
}

.primary-nav {
  width: 100%;
  color: #1970b1;
}

.primary-nav li {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
}

.primary-nav li:first-child a {
  padding-left: 0;
}

.primary-nav li:last-child a {
  padding-right: 0;
}

@media (max-width: 510px) {
  .primary-nav li {
    display: block;
  }
}

.primary-nav a,
.primary-nav li > span {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  display: inline-block;
  padding: 10px 16px;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  line-height: 1.281;
  transition: color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  text-transform: uppercase;
}

@media (max-width: 80.625em) {
  .primary-nav a,
  .primary-nav li > span {
    padding: 10px 5px;
  }
}

@media (max-width: 56.875em) {
  .primary-nav a,
  .primary-nav li > span {
    color: #fff;
  }
}

@media (max-width: 56.875em) {
  .primary-nav li > span {
    padding-left: 0;
    padding-right: 0;
  }
}

.primary-nav .login-form {
  display: none;
}

@media (max-width: 56.875em) {
  .primary-nav .login-form {
    display: block;
  }
}

.primary-nav .login-form .login-form__list {
  margin: 0;
}

@media (max-width: 56.875em) {
  .primary-nav .login-form .login-form__trigger {
    display: none;
  }
}

@media (max-width: 56.875em) {
  .primary-nav .utility-nav {
    border-top: 1px solid #fff;
    margin-top: 20px;
    padding: 20px 0 0;
  }
}

.primary-nav .utility-nav__list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  display: none;
}

@media (max-width: 56.875em) {
  .primary-nav .utility-nav__list {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
}

.primary-nav .global-header__cta {
  display: none;
}

@media (max-width: 56.875em) {
  .primary-nav .global-header__cta {
    display: block;
    padding: 0 0 20px;
  }
}

.primary-nav .utility-nav__item {
  display: block;
  font-size: 0.875rem;
}

@media (max-width: 56.875em) {
  .primary-nav .utility-nav__item .cart-icon {
    filter: brightness(1000);
  }
}

.primary-nav .utility-nav__item a {
  padding-top: 6px;
  padding-bottom: 5px;
}

.primary-nav .utility-nav__item a:hover {
  color: #212121;
}

@media (max-width: 56.875em) {
  .primary-nav .utility-nav__item {
    font-size: 1rem;
  }
}

@media (max-width: 56.875em) {
  .primary-nav .cart-icon-mobile {
    margin-bottom: -18px;
    margin-top: -15px;
  }
}

.primary-nav__menu-wrap {
  display: block;
}

@media (max-width: 56.875em) {
  .primary-nav__menu-wrap {
    background: #1970b1;
    display: none;
    min-height: 100vh;
    left: 0;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 80px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
  }
}

.primary-nav__menu {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 56.875em) {
  .primary-nav__menu {
    flex-direction: column;
  }
}

.primary-nav__item {
  position: relative;
  transition: background-color 0.36s cubic-bezier(0.4, 0, 0.2, 1);
}

.primary-nav__item.is-open, .primary-nav__item.is-active, .primary-nav__item:hover {
  color: #4a4a4a;
}

.primary-nav__item.is-open > a, .primary-nav__item.is-active > a, .primary-nav__item:hover > a {
  color: #4a4a4a;
}

.primary-nav__item.is-open .icon, .primary-nav__item.is-active .icon, .primary-nav__item:hover .icon {
  fill: #4a4a4a;
}

@media (max-width: 56.875em) {
  .primary-nav__item.is-open .icon, .primary-nav__item.is-active .icon, .primary-nav__item:hover .icon {
    fill: #fff;
  }
}

.primary-nav__item.is-open .primary-nav__submenu {
  height: auto;
  opacity: 1;
}

.primary-nav__item.is-open .icon {
  transform: rotate(180deg);
}

.primary-nav__item .icon {
  margin-left: 8px;
  vertical-align: middle;
  transition: transform 0.28s;
}

.primary-nav__item .icon--left {
  display: none;
}

@media (max-width: 56.875em) {
  .primary-nav__item {
    width: 100%;
  }
  .primary-nav__item .icon {
    height: 2rem;
    margin-left: -2rem;
    margin-right: -4px;
    width: 2rem;
    display: none;
  }
  .primary-nav__item .icon--left {
    display: inline;
  }
}

@media (max-width: 56.875em) {
  .primary-nav__item a,
  .primary-nav__item > span {
    padding-left: 0;
    padding-right: 0;
  }
}

.primary-nav__submenu {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  position: absolute;
  display: block;
  background-color: #fff;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.4s ease-out;
  z-index: 100;
}

.primary-nav__submenu .menu-item-title {
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  line-height: 12px;
  margin-top: 10px;
  margin-bottom: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.primary-nav__submenu .menu-item-description {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 12px;
  text-transform: none;
  white-space: normal;
  font-weight: normal;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.primary-nav__submenu li {
  border-bottom: 1px solid #bdbdbd;
  display: block;
  font-size: 1rem;
}

.primary-nav__submenu li:last-child {
  border-bottom: 0;
}

@media (max-width: 56.875em) {
  .primary-nav__submenu {
    width: 100%;
    display: none;
    position: static;
    transition: none;
    height: auto;
    opacity: 1;
  }
}

.primary-nav__subitem {
  display: block;
  transition: background-color 0.36s cubic-bezier(0.4, 0, 0.2, 1);
}

.primary-nav__subitem a {
  color: #1970b1;
}

@media (max-width: 56.875em) {
  .primary-nav__subitem {
    width: 100%;
  }
}

.primary-nav__label-open,
.primary-nav__label-close {
  font-size: 0.75rem;
  text-transform: uppercase;
  padding-top: 4px;
  color: #fff;
}

.primary-nav__label-open {
  display: none;
}

@media (max-width: 56.875em) {
  .primary-nav__label-open {
    display: block;
  }
}

.primary-nav.is-open .primary-nav__label-open {
  display: none;
}

.primary-nav__label-close {
  display: none;
}

@media (max-width: 56.875em) {
  .primary-nav.is-open .primary-nav__label-close {
    display: block;
  }
}

.primary-nav__toggle {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  background: #1970b1;
  border: 0;
  cursor: pointer;
  display: none;
  margin-left: 20px;
  padding-bottom: 13px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 15px;
  position: relative;
  z-index: 101;
}

@media (max-width: 56.875em) {
  .primary-nav__toggle {
    display: block;
  }
}

.primary-nav__toggle.is-open {
  background-color: transparent;
  border-color: transparent;
}

.primary-nav__toggle-icon {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 35px;
  height: 24px;
}

.primary-nav__toggle-icon span {
  margin: 0 auto;
  position: relative;
  top: 10px;
  transition-duration: 0s;
  transition-delay: 0.2s;
}

.primary-nav__toggle-icon span::before, .primary-nav__toggle-icon span::after {
  position: absolute;
  content: "";
  transition-property: margin, transform;
  transition-duration: 0.2s;
  transition-delay: 0.2s, 0s;
}

.primary-nav__toggle-icon span, .primary-nav__toggle-icon span::before, .primary-nav__toggle-icon span::after {
  display: block;
  width: 30px;
  height: 3px;
  background-color: #fff;
}

.primary-nav__toggle-icon span::before {
  margin-top: -10px;
}

.primary-nav__toggle-icon span::after {
  margin-top: 10px;
}

.is-open .primary-nav__toggle-icon span {
  background-color: rgba(0, 0, 0, 0);
  transition-delay: 0.2s;
}

.is-open .primary-nav__toggle-icon span::before, .is-open .primary-nav__toggle-icon span::after {
  margin-top: 0;
  transition-duration: 0.2s;
  transition-delay: 0.2s, 0s;
}

.is-open .primary-nav__toggle-icon span::before {
  transform: rotate(45deg);
}

.is-open .primary-nav__toggle-icon span::after {
  transform: rotate(-45deg);
}

@media (min-width: 56.875em) {
  .primary-nav__item.js-nav-item .dropdown-enabled {
    color: #1970b1;
    font-family: futura-pt, sans-serif;
    font-weight: 550;
    font-style: normal;
    position: relative;
    background-color: white;
    width: 200px;
    left: 695px;
    top: 133px;
  }
  .primary-nav__item.js-nav-item span {
    padding: 0 !important;
  }
  .primary-nav__item.js-nav-item span a {
    width: auto !important;
  }
  .primary-nav__item.js-nav-item .dropdown-enabled:hover {
    color: #4a4a4a;
  }
  .primary-nav__item.js-nav-item .down-arrow {
    padding: 4px;
    font-size: 22px;
    position: relative;
    top: -4px;
  }
  .primary-nav__item.js-nav-item .nav-dropdown {
    display: flex;
    width: fit-content;
    height: auto;
  }
  .primary-nav__item.js-nav-item .nav-dropdown a {
    font-weight: normal;
    text-transform: none;
  }
  .primary-nav__item.js-nav-item .nav-dropdown li {
    border-bottom: none;
  }
  .primary-nav__item.js-nav-item a {
    color: inherit;
    text-decoration: none;
    font-style: normal;
    display: inline-block;
    padding: 0;
    cursor: pointer;
    width: 100%;
    line-height: normal;
    white-space: normal;
  }
  .primary-nav__item.js-nav-item h1 {
    font-family: futura-pt, sans-serif;
    font-size: 16px;
    line-height: 12px;
    margin-top: 10px;
    text-transform: uppercase;
  }
  .primary-nav__item.js-nav-item h2 {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
  .primary-nav__item.js-nav-item li {
    list-style-type: none;
    color: white;
    font-family: futura-pt, sans-serif;
    font-size: 16px;
    line-height: 34px;
    font-weight: 250;
  }
  .primary-nav__item.js-nav-item .column-2-list-item {
    margin-bottom: 0;
  }
  .primary-nav__item.js-nav-item .column-2-list-item:hover {
    font-weight: 400;
    color: #f1c40f;
  }
  .primary-nav__item.js-nav-item .column-1 {
    background-color: white;
  }
  .primary-nav__item.js-nav-item .column-1-menu-item {
    width: 286px;
    padding: 22px 25px;
    color: #4a4a4a;
    border-left: 5px solid white;
  }
  .primary-nav__item.js-nav-item .active {
    background-color: #1970b1;
    border-left: 5px solid #f4ad3d;
    color: white;
  }
  .primary-nav__item.js-nav-item .column-2 {
    width: 340px;
    background-color: #135285;
    padding: 3px 40px 3px 0px;
    display: none;
  }
  .primary-nav__item.js-nav-item .column-2 ul {
    padding-left: 40px;
    margin-left: 0;
  }
  .primary-nav__item.js-nav-item .column-2-list-1 {
    width: 300px;
    margin-left: 0;
    margin-bottom: 0;
  }
  .primary-nav__item.js-nav-item .column-2-list-2 {
    display: none;
  }
  .primary-nav__item.js-nav-item .column-2-list-3 {
    display: none;
  }
  .primary-nav__item.js-nav-item .column-3 {
    display: none;
    flex-direction: row;
    background-color: #d0e3f0;
    padding: 10px;
    max-width: 281px;
  }
  .primary-nav__item.js-nav-item .column-3 img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .primary-nav__item.js-nav-item .hero-image {
    width: 261px;
  }
  .primary-nav__item.js-nav-item .hero-button {
    display: inline-block;
    padding: 6px 16px;
    text-decoration: none;
    background: #1970b1;
    color: white;
    font-family: futura-pt, sans-serif;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    max-width: 50%;
    font-weight: 600;
    line-height: 34px;
  }
  .primary-nav__item.js-nav-item .hero-button:hover,
  .primary-nav__item.js-nav-item .hero-button:focus {
    background: #135285;
  }
  .primary-nav__item.js-nav-item .hero-button:active {
    transform: scale(0.99);
  }
  .mobile-version {
    display: none;
  }
}

@media (max-width: 56.875em) {
  .primary-nav__item.js-nav-item {
    padding-left: 30px;
    padding-right: 30px;
  }
  .primary-nav__item.js-nav-item span a {
    width: auto !important;
  }
  .primary-nav__item.js-nav-item.is-open {
    background-color: #135285;
    border-left: 2px solid #f1c40f;
    padding-top: 6px;
    padding-bottom: 12px;
  }
  .primary-nav__item.js-nav-item.is-open .mobile-version {
    padding-bottom: 10px;
  }
  .primary-nav__item.js-nav-item.is-open .mobile-version {
    display: flex;
    align-items: baseline;
  }
  .primary-nav__item.js-nav-item.is-open .mobile-version .dropdown-item {
    display: flex;
    align-items: center;
    flex-basis: 82%;
  }
  .primary-nav__item.js-nav-item.is-open .mobile-version .dropdown-item svg.icon {
    margin-left: 8px;
  }
  .primary-nav__item.js-nav-item.is-open .mobile-version .nav-item-link {
    display: flex;
    justify-content: flex-end;
    flex-basis: 18%;
  }
  .primary-nav__item.js-nav-item .primary-nav__submenu {
    background-color: #135285;
  }
  .primary-nav__item.js-nav-item .primary-nav__submenu h1 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
  }
  .primary-nav__item.js-nav-item .primary-nav__submenu h2 {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;
    text-transform: none;
    white-space: normal;
    font-weight: normal;
    font-family: Georgia, "Times New Roman", Times, serif;
    letter-spacing: 0.3px;
  }
  .primary-nav__item.js-nav-item .primary-nav__submenu .menu-item-title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .primary-nav__item.js-nav-item .primary-nav__submenu .menu-item-description {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;
    text-transform: none;
    white-space: normal;
    font-weight: normal;
    font-family: Georgia, "Times New Roman", Times, serif;
    letter-spacing: 0.3px;
  }
  .primary-nav__item.js-nav-item .primary-nav__submenu .column-2,
  .primary-nav__item.js-nav-item .primary-nav__submenu .column-3 {
    display: none !important;
  }
  .primary-nav__item.js-nav-item .icon {
    margin-left: 0;
  }
  .primary-nav__item.js-nav-item .mobile-version .nav-item-link {
    display: none;
  }
  .primary-nav__item.js-nav-item .mobile-version span {
    font-family: "futura-pt", Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-style: normal;
    display: inline-block;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    width: 100%;
    line-height: 1.281;
    transition: color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    text-transform: uppercase;
    color: white;
    padding: 10px 0;
  }
  .primary-nav__item.js-nav-item .desktop-version {
    display: none;
  }
}

.global-footer {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  padding-bottom: 30px;
  padding-top: 30px;
}

.global-footer a {
  font-weight: 600;
  text-decoration: none;
}

.global-footer .site-logo {
  margin-bottom: 35px;
}

.global-footer .has-border {
  border-top: 5px solid #f4ad3d;
  padding-top: 30px;
}

@media (max-width: 56.875em) {
  .global-footer .container--30-70 .container__col:first-child {
    margin-bottom: 0;
  }
}

@media (min-width: 56.9375em) {
  .global-footer .connect {
    flex-basis: 300px;
    flex-grow: 1;
    flex-shrink: 0;
  }
}

.global-footer .footer-top-nav {
  background-color: #1970b1;
  padding-top: 12px;
  padding-bottom: 12px;
}

@media (max-width: 56.875em) {
  .global-footer .footer-top-nav {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.global-footer .footer-top-nav .container {
  margin-bottom: 0;
}

.global-footer .footer-top-nav .footer-top-nav__menu {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 56.875em) {
  .global-footer .footer-top-nav .footer-top-nav__menu {
    flex-direction: column;
  }
}

.global-footer .footer-top-nav .footer-top-nav__menu .footer-top-nav__item {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
}

@media (max-width: 56.875em) {
  .global-footer .footer-top-nav .footer-top-nav__menu .footer-top-nav__item + .footer-top-nav__item {
    margin-top: 6px;
  }
}

.global-footer .footer-top-nav .footer-top-nav__menu .footer-top-nav__item a {
  color: #fff;
  display: inline-block;
  font-size: 16px;
  line-height: 1.6925;
  text-transform: uppercase;
  padding: 10px 16px;
  white-space: nowrap;
}

@media (max-width: 80.625em) {
  .global-footer .footer-top-nav .footer-top-nav__menu .footer-top-nav__item a {
    padding: 10px 5px;
  }
}

@media (max-width: 56.875em) {
  .global-footer .footer-top-nav .footer-top-nav__menu .footer-top-nav__item a {
    padding-left: 0;
    padding-right: 0;
  }
}

.global-footer .footer-top-nav .footer-top-nav__menu .footer-top-nav__item a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.global-footer .footer-top-nav .footer-top-nav__menu .footer-top-nav__item:first-child a {
  padding-left: 0;
}

.global-footer .footer-top-nav .footer-top-nav__menu .footer-top-nav__item:last-child a {
  padding-right: 0;
}

@media (max-width: 510px) {
  .global-footer .footer-top-nav .footer-top-nav__menu .footer-top-nav__item {
    display: block;
  }
}

.global-footer .view-sitemap {
  margin-bottom: 10px;
  text-align: right;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.778;
}

@media (max-width: 29.375em) {
  .global-footer .view-sitemap {
    text-align: left;
    margin-bottom: 20px;
  }
}

.global-footer .container--70-30 > .container__col {
  margin-bottom: 0;
}

.global-footer__inner {
  display: flex;
  justify-content: space-between;
  padding-top: 28px;
}

@media (max-width: 56.875em) {
  .global-footer__inner {
    padding-top: 33px;
    display: block;
  }
}

.global-footer__inner .copyright {
  flex-grow: 17;
}

.global-footer__inner .footer-links {
  flex-grow: 2;
}

.global-footer__inner .footer-links ul {
  text-align: left;
}

@media (max-width: 56.875em) {
  .global-footer__inner .footer-links {
    margin-top: 10px;
  }
  .global-footer__inner .footer-links li {
    margin-left: 0;
  }
}

.global-footer__column--connect {
  flex-basis: 300px;
  flex-grow: 0;
  flex-shrink: 0;
}

.global-footer__column--nav {
  flex-basis: 515px;
  flex-grow: 0;
  margin-right: 30px;
  flex-shrink: 1;
}

.global-footer__cta {
  font-size: 16px;
  margin-bottom: 45px;
  margin-top: 30px;
}

.global-footer__cta p {
  font-size: inherit;
}

@media (max-width: 56.875em) {
  .global-footer__cta {
    margin-bottom: 0;
  }
}

.footer-links ul {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  text-align: right;
}

@media (max-width: 56.875em) {
  .footer-links ul {
    text-align: center;
  }
}

.footer-links li {
  display: inline;
  margin-left: 16px;
}

.footer-nav {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  /*@include font(futura);
	display: flex;
	flex-wrap: wrap;

	@media(min-width: $bp-min-large) {
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 1;
	}

    @media(max-width: $bp-max-medium) {
    	display: block;
	}

	li {
		margin-bottom: 30px;
	}

	a {
		@include font(futura, demi);
		text-decoration: none;
	}*/
}

.footer-nav .footer-nav__menu {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.footer-nav .footer-nav__menu li {
  border-right: 2px solid #1970b1;
  display: inline-block;
  line-height: 1;
  margin-bottom: 16px;
  margin-top: 7px;
  margin-right: 15px;
  padding-right: 15px;
}

@media (max-width: 56.875em) {
  .footer-nav .footer-nav__menu li {
    margin-top: 0;
    margin-bottom: 15px;
  }
}

.footer-nav .footer-nav__menu li:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
}

.footer-nav .footer-nav__menu li a {
  font-size: 18px;
  line-height: 1;
}

/*
.footer-nav__section {
	flex-basis: 50%;
}

.footer-nav__col {
	list-style-type: none;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 15px;
}
*/
.hero {
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.hero__figure {
  margin: 0;
}

.hero__figure img {
  width: 100%;
  display: block;
  margin: 0 auto;
  object-fit: cover;
}

.hero__figure video {
  /* if object-fit is supported, we'll use that */
  width: 100%;
  display: block;
  object-fit: cover;
  margin: 0 auto;
}

.has-height .hero__figure {
  width: 100%;
  height: 100%;
}

.has-height .hero__figure picture {
  height: 100%;
}

.has-height .hero__figure img {
  height: 100%;
}

.has-height .hero__figure .video {
  height: 100%;
}

.hero__caption {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  right: 0;
  text-align: center;
}

.hero__title {
  font-size: 3.5rem;
}

@media (max-width: 56.875em) {
  .hero__title {
    font-size: 2.2rem;
  }
}

@media (max-width: 29.375em) {
  .hero__title {
    font-size: 1.8rem;
  }
}

.hero__subtitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

@media (max-width: 56.875em) {
  .hero__subtitle {
    font-size: 1rem;
  }
}

.hero__action {
  display: inline-block;
  font-size: 1.125rem;
  line-height: 1.5;
  transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1), color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #666666;
  text-decoration: none;
  padding: 10px 20px;
}

@media (max-width: 56.875em) {
  .hero__action {
    font-size: 1rem;
  }
}

.hero__action:hover {
  background-color: #8c8c8c;
}

.hero--simple figure {
  position: relative;
}

.hero--simple figure picture {
  display: block;
  line-height: 0;
}

.hero--simple .hero__caption {
  position: absolute;
  bottom: 0;
  color: #fff;
  left: 0;
  width: 80%;
  background-color: #1970b1;
  transform: none;
  padding: 40px;
  text-align: left;
  top: auto;
}

@media (max-width: 29.375em) {
  .hero--simple .hero__caption {
    position: relative;
    bottom: auto;
    display: block;
    width: 100%;
    padding: 10px;
  }
}

@media (max-width: 1278px) {
  .container--70-30 .container__col:first-child .hero--simple .hero__caption {
    position: relative;
    bottom: auto;
    display: block;
    width: 100%;
    padding: 20px;
  }
}

@media (max-width: 1278px) {
  .container--30-70 .container__col:last-child .hero--simple .hero__caption {
    position: relative;
    bottom: auto;
    display: block;
    width: 100%;
    padding: 20px;
  }
}

.hero--simple .hero__title {
  font-size: 45px;
}

.hero--simple .hero__subtitle {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

.hero__image {
  max-width: 100%;
}

.headline {
  margin-bottom: 30px;
  background-color: transparent;
  padding: 40px;
  padding-top: 0;
  text-align: center;
  margin-bottom: 0;
}

@media (max-width: 56.875em) {
  .headline {
    padding: 30px;
  }
}

.headline__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 900;
  font-style: normal;
  color: #4a4a4a;
  font-size: 45px;
  line-height: 1.2444;
  margin-bottom: 38px;
}

@media (max-width: 29.375em) {
  .headline__title {
    font-size: 35px;
    line-height: 1.1428;
  }
}

.headline__link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 900;
  font-style: normal;
  color: #1970b1;
  border: 2px solid #1970b1;
  font-size: 18px;
  line-height: 1.111;
  text-decoration: none;
  text-transform: uppercase;
  padding: 12px 29px 13px;
  display: inline-block;
}

@media (max-width: 56.875em) {
  .headline__link {
    display: inline-block;
  }
}

@media (max-width: 29.375em) {
  .headline__link {
    display: inline-block;
    font-size: 19px;
    min-width: 210px;
    max-width: 240px;
    text-align: left;
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
  }
  .headline__link span {
    display: block;
    width: calc(100% - 24px);
  }
}

.headline__link:after {
  content: "\2192";
  color: #424242;
  padding: 0 0 0 5px;
}

@media (max-width: 29.375em) {
  .headline__link:after {
    position: absolute;
  }
}

.headline__link:hover {
  background: #1970b1;
  color: #fff;
}

.headline__link:hover:after {
  color: #fff;
}

/*
.headline__link:after {
  content: "\2192";
  color: $fun-blue;
  padding: 0 5px;
}*/
.image-promo {
  margin-bottom: 30px;
}

.image-promo .image-promo__media {
  position: relative;
}

.image-promo .image-promo__media picture {
  display: block;
  line-height: 0;
}

.image-promo .image-promo__media picture img {
  width: 100%;
  height: auto;
}

.image-promo .image-promo__media figcaption {
  background-color: #1970b1;
  position: absolute;
  bottom: 0;
  color: #fff;
  padding: 40px;
  max-width: 75%;
}

.image-promo .image-promo__media figcaption a {
  color: inherit;
}

.image-promo .image-promo__media figcaption a:after {
  color: inherit;
}

.image-promo .image-promo__media figcaption .btn--link {
  font-weight: 500;
}

@media (max-width: 56.875em) {
  .image-promo .image-promo__media figcaption {
    bottom: auto;
    display: block;
    padding: 20px;
    position: relative;
    width: 100%;
    max-width: 100%;
  }
}

.image-promo .image-promo__title {
  font-weight: 500;
}

.image-promo .image-promo__metadata {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  display: block;
  margin-bottom: 15px;
}

.job-feed-container {
  width: 100%;
  position: relative;
}

.job-feed-container .slick-arrow {
  background: #f4ad3d;
  height: 50px;
  overflow: hidden;
  width: 50px;
  z-index: 1;
}

.job-feed-container .slick-arrow.slick-prev {
  left: 0;
}

.job-feed-container .slick-arrow.slick-next {
  right: 0;
}

@media (max-width: 1024px) {
  .job-feed-container .slick-arrow {
    height: 60px;
    width: 60px;
  }
}

@media (max-width: 768px) {
  .job-feed-container .slick-arrow {
    height: 40px;
    width: 40px;
  }
}

.job-feed-container .slick-arrow:before {
  display: none;
}

.job-feed-container .slick-arrow svg {
  height: 40px;
  width: 40px;
}

@media (max-width: 1024px) {
  .job-feed-container .slick-arrow svg {
    height: 50px;
    width: 50px;
  }
}

@media (max-width: 768px) {
  .job-feed-container .slick-arrow svg {
    height: 30px;
    width: 30px;
  }
}

.job-feed {
  margin: 0;
}

.job-feed,
.job-feed__slide-container {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 60px;
  margin: 0;
}

@media (min-width: 768px) and (max-width: 910px) {
  .job-feed,
  .job-feed__slide-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .job-feed,
  .job-feed__slide-container {
    grid-template-columns: 1fr;
  }
}

.job-feed.slick-initialized {
  display: block;
  padding-inline: 60px;
}

.job-feed__item {
  list-style-type: none;
  background-color: #fff;
}

.job-feed__item div {
  display: block;
}

.job-feed__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 22px;
}

.job-feed__description {
  color: #6f9702;
}

.job-feed__description,
.job-feed__location {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 18px;
  line-height: 1.31;
}

.logo-grid {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.logo-grid .image-block__media {
  filter: grayscale(100%);
  transition: filter 1s;
  margin: 0;
  max-width: 223px;
  max-height: 70px;
  margin: 0 auto;
}

@media (max-width: 29.375em) {
  .logo-grid .image-block__media {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 70px;
  }
}

.logo-grid .image-block__media picture {
  display: block;
  max-height: inherit;
}

.logo-grid .image-block__media img {
  margin: 0;
  object-fit: contain;
  max-height: inherit;
}

.logo-grid .image-block__media:hover {
  filter: grayscale(0%);
}

.logo-grid__block {
  width: 33%;
  margin-top: 0.5%;
  margin-bottom: 7%;
}

.logo-grid__block:last-child:nth-of-type(4), .logo-grid__block:last-of-type:nth-of-type(10), .logo-grid__block:last-of-type:nth-of-type(7) {
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 29.375em) {
  .logo-grid__block {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.is-page-editor .logo-grid {
  display: block;
}

.is-page-editor .logo-grid > div {
  display: block;
}

.is-page-editor .logo-grid .logo-grid__block {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
}

.is-page-editor .logo-grid .logo-grid__block .image-block__media img {
  width: auto !important;
  height: auto;
}

.promo-cta-grid {
  min-height: 30px;
}

.promo {
  margin-bottom: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 2.75rem;
}

.promo.is-left {
  flex-direction: row;
}

.promo.is-left .promo__media {
  margin-right: -50px;
  margin-bottom: 0;
  width: 50%;
  z-index: 1;
}

.promo.is-left .promo__cta {
  text-align: left;
}

.promo.is-right {
  flex-direction: row-reverse;
}

.promo.is-right .promo__media {
  margin-left: -50px;
  margin-bottom: 0;
  width: 50%;
  z-index: 1;
}

.promo img {
  width: 100%;
}

@media (max-width: 56.875em) {
  .promo.is-left, .promo.is-right {
    align-items: flex-start;
  }
}

@media (max-width: 56.875em) {
  .promo.is-left, .promo.is-right {
    flex-direction: column;
  }
  .promo.is-left .promo__media, .promo.is-right .promo__media {
    font-size: 0;
    margin: 0;
    width: 100%;
  }
}

.promo__description {
  font-size: 18px;
  line-height: 1.6;
  max-width: 800px;
}

@media (max-width: 56.875em) {
  .promo__description {
    font-size: 16px;
  }
}

.promo__media {
  margin-bottom: 1.125rem;
  margin-top: 50px;
}

.promo__content {
  background: #fff;
  box-shadow: 0 0 10px 1px rgba(74, 74, 74, 0.4);
  padding: 50px 45px;
  z-index: 2;
  flex: 1;
}

.promo__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 35px;
  line-height: 42px;
}

@media (max-width: 80.625em) {
  .promo__title {
    font-size: 40px;
  }
}

@media (max-width: 56.875em) {
  .promo__title {
    font-size: 36px;
  }
}

.promo__subtitle {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
}

.promo__footer {
  display: flex;
  justify-content: space-between;
}

.promo__cta {
  padding-bottom: 0;
}

.promo__cta--alt {
  color: #737373;
}

.promo__cta--alt:after {
  display: none;
}

.promo--small {
  display: block;
}

.container--70-30 .container__col:nth-of-type(2) .promo--small {
  margin-bottom: 38px;
}

.promo--small .promo__media {
  width: 100%;
}

.promo--small .promo__content {
  box-shadow: none;
  padding-left: 0;
  padding-top: 0;
}

.promo--small figure picture {
  display: block;
  line-height: 0;
}

.promo--small figure picture:after {
  content: "";
  background-color: #f1c40f;
  height: 5px;
  display: block;
  width: 72px;
}

.container--30-70 .container__col:first-child .promo {
  padding-right: 20px;
}

@media (max-width: 56.875em) {
  .container--30-70 .container__col:first-child .promo {
    padding-right: 0;
  }
}

.multi-image {
  margin-bottom: 30px;
}

.multi-image__title {
  margin-bottom: 30px;
}

.multi-image__items {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.multi-image__caption {
  max-width: 700px;
}

@media (max-width: 29.375em) {
  .multi-image__caption {
    display: none;
  }
}

.multi-image__image {
  max-width: 100%;
  margin-right: 1%;
}

.multi-image__image .image-block__media {
  border-bottom: none;
}

.multi-image__image:last-child {
  margin-right: 0;
}

@media (min-width: 56.9375em) {
  .multi-image__image:nth-last-child(n+4):nth-child(3n), .multi-image__image:nth-last-child(n+4) ~ .multi-image__image:nth-child(3n) {
    margin-right: 0;
  }
}

.multi-image__image .image-block {
  display: block;
  width: 100%;
}

.multi-image__image .image-block__title {
  display: none;
}

.multi-image__image .image-block__media figcaption {
  display: none;
}

@media (min-width: 29.4375em) {
  .multi-image__image {
    width: 49%;
  }
}

@media (min-width: 56.9375em) {
  .multi-image__image {
    width: 32.333%;
  }
  .multi-image__image:nth-last-child(3):first-child, .multi-image__image:nth-last-child(3):first-child ~ .multi-image__image {
    width: 32.333%;
  }
}

@media (max-width: 56.875em) {
  .multi-image__image:nth-last-child(3):first-child:nth-child(2n), .multi-image__image:nth-last-child(3):first-child ~ .multi-image__image:nth-child(2n) {
    margin-right: 0;
  }
  .multi-image__image:nth-last-child(3):first-child:last-child, .multi-image__image:nth-last-child(3):first-child ~ .multi-image__image:last-child {
    margin-right: 1%;
  }
}

@media (max-width: 600px) {
  .multi-image__image {
    width: 100%;
    margin-right: 0;
  }
  .multi-image__image .image-block__media figcaption {
    display: block;
  }
}

@media (max-width: 80.625em) {
  .container--70-30 .container__col:first-child .multi-image__image,
  .container--30-70 .container__col:last-child .multi-image__image {
    width: 100%;
  }
}

.container--70-30 .container__col:last-child .multi-image__image,
.container--30-70 .container__col:first-child .multi-image__image,
.container--50-50 .container__col .multi-image__image {
  width: 100%;
}

.multi-promo {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
}

.multi-promo .promo {
  display: block;
  width: 100%;
}

.multi-promo__promo {
  width: 100%;
  padding-right: 30px;
}

@media (min-width: 56.9375em) {
  .multi-promo__promo {
    width: 50%;
  }
  .multi-promo__promo:nth-last-child(3):first-child, .multi-promo__promo:nth-last-child(3):first-child ~ .multi-promo__promo {
    width: 33.333%;
  }
}

@media (min-width: 29.4375em) {
  .multi-promo__promo {
    width: 50%;
  }
}

@media (width: 600px) {
  .multi-promo__promo {
    width: 100%;
  }
}

@media (width: 80.625em) {
  .container--70-30 .container__col:first-child .multi-promo__promo,
  .container--30-70 .container__col:last-child .multi-promo__promo {
    width: 100%;
  }
}

.container--70-30 .container__col:last-child .multi-promo__promo,
.container--30-70 .container__col:first-child .multi-promo__promo,
.container--50-50 .container__col .multi-promo__promo {
  width: 100%;
}

.page-title {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.page-title__type {
  order: 1;
}

.page-title__title {
  order: 2;
}

.page-title__subtitle {
  order: 3;
}

.page-title__subtitle {
  font-size: 1.5rem;
}

.page-title__type {
  text-transform: uppercase;
  margin-bottom: 0;
  color: #9e9e9e;
}

.page-meta {
  margin-bottom: 30px;
}

.page-meta__byline {
  border-left: 1px solid #e0e0e0;
  margin-left: .5em;
  padding-left: .75em;
}

.page-meta__date {
  font-style: italic;
}

.page-banner {
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  color: #4a4a4a;
  display: flex;
}

.page-banner header {
  width: calc(100% - 96px);
}

@media (max-width: 29.375em) {
  .page-banner header {
    width: 100%;
  }
}

.page-banner:before {
  content: "";
  background-color: #6fb40f;
  width: 76px;
  height: 5px;
  display: block;
  margin-right: 20px;
  margin-top: 26px;
}

@media (max-width: 56.875em) {
  .page-banner:before {
    margin-top: 18px;
    width: 60px;
  }
}

@media (max-width: 29.375em) {
  .page-banner:before {
    display: none;
  }
}

@media (max-width: 29.375em) {
  .page-banner h1:before {
    background-color: #6fb40f;
    content: "";
    display: block;
    float: left;
    height: 5px;
    margin-top: 12px;
    margin-right: 20px;
    width: 23px;
  }
}

.page-banner__figure {
  margin: 0;
}

.page-banner__figure img {
  width: 100%;
  display: block;
  margin: 0 auto;
  object-fit: cover;
}

.page-banner__figure video {
  width: 100%;
  display: block;
  margin: 0 auto;
  object-fit: cover;
}

.has-height .page-banner__figure {
  width: 100%;
  height: 100%;
}

.has-height .page-banner__figure picture {
  height: 100%;
}

.has-height .page-banner__figure img {
  height: 100%;
}

.has-height .page-banner__figure video {
  height: 0;
}

.page-banner__caption {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 1290px;
  width: 100%;
}

.page-banner__caption-inner {
  position: absolute;
  bottom: 1.875rem;
  left: 0;
}

.page-banner__caption-inner p {
  margin-bottom: 0;
}

@media (max-width: 80.625em) {
  .page-banner__caption-inner {
    left: 1.875rem;
    bottom: 1.875rem;
  }
}

.page-banner__eyebrow {
  text-transform: uppercase;
  font-size: 1.125rem;
  margin-bottom: 0;
}

.page-banner__title {
  color: #4a4a4a;
  font-size: 50px;
}

@media (max-width: 56.875em) {
  .page-banner__title {
    font-size: 45px;
  }
}

.page-banner__subtitle {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 40px;
}

@media (max-width: 56.875em) {
  .page-banner__subtitle {
    font-size: 18px;
  }
}

.page-banner__action {
  display: inline-block;
  color: #fff;
  transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1), color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #e6e6e6;
  text-decoration: none;
  padding: 10px 20px;
}

.page-banner__action:hover {
  background-color: #8c8c8c;
  color: #fff;
}

.container--100 .page-banner__caption-inner {
  left: 1.875rem;
}

.profile-browser__header {
  margin-bottom: 50px;
}

.profile-browser__list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 18px;
  justify-content: space-around;
  list-style-type: none;
  margin: 0;
  padding: 0 0 10px;
}

.profile-browser__profile {
  border-bottom: 2px dashed #e6e7e8;
  list-style-type: none;
  padding-bottom: 40px;
  padding-top: 40px;
}

.profile-browser__profile article {
  overflow: hidden;
}

.profile-browser__profile header {
  overflow: hidden;
}

.profile-browser__profile h3 {
  margin-bottom: 10px;
  line-height: 100%;
}

.profile-browser__profile .profile-browser__img {
  width: 125px;
  float: left;
  margin-right: 30px;
  display: block;
}

.profile-browser__profile .profile-browser__img img {
  width: 100%;
  height: auto;
  max-width: 125px;
}

@media (max-width: 29.375em) {
  .profile-browser__profile .profile-browser__img {
    width: 30%;
  }
}

.profile-browser__profile .profile-browser__profile__content {
  float: right;
  width: calc(100% - 125px - 30px);
}

@media (max-width: 29.375em) {
  .profile-browser__profile .profile-browser__profile__content {
    width: calc(100% - 30% - 30px);
  }
}

.profile-browser__profile .profile-browser__profile__content .profile-browser__profile__name {
  font-size: 30px;
  color: #0f6eb4;
  line-height: 30px;
  font-weight: 700;
}

.profile-browser__profile .profile-browser__profile__content .profile-browser__profile__primary-title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #737373;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 10px;
}

.profile-browser__profile .profile-browser__profile__content .profile-browser__biography {
  line-height: 30px;
  font-size: 18px;
}

.profile-browser__profile .profile-browser__profile__content h3 {
  font-size: 30px;
}

.profile-browser .profile-browser__img {
  width: 125px;
  float: left;
  margin-right: 30px;
  display: block;
}

.profile-browser .profile-browser__img img {
  width: 100%;
  height: auto;
  max-width: 125px;
}

@media (max-width: 29.375em) {
  .profile-browser .profile-browser__img {
    width: 30%;
  }
}

.profile-browser address {
  font-style: normal;
  line-height: 30px;
  margin-top: 15px;
}

.profile-browser address .profile-browser__contact-header {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  display: block;
  text-transform: uppercase;
  color: #737373;
  font-size: 20px;
}

.people-details .people-details__header {
  color: #1970b1;
  font-size: 30px;
  margin-bottom: 0;
}

.people-details .people-details__section-header {
  color: #737373;
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 45px;
  margin-bottom: 15px;
}

.people-details .people-details__bio-role {
  color: #737373;
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 15px;
}

.people-details .people-details__address-photo {
  overflow: hidden;
  display: flex;
}

@media (max-width: 29.375em) {
  .people-details .people-details__address-photo {
    flex-direction: column;
  }
}

.people-details .people-details__section-body {
  line-height: 30px;
}

.people-details address {
  margin-top: 0;
}

.pull-quote {
  margin-bottom: 30px;
  display: inline-block;
  position: relative;
  margin-top: 0;
  padding: 0 40px;
  border-left: 5px solid #6fb40f;
}

@media (max-width: 56.875em) {
  .pull-quote {
    padding: 0 30px;
  }
}

.pull-quote:before {
  content: '\201C';
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 600;
  font-size: 125px;
  position: absolute;
  top: -30px;
  left: 40px;
  opacity: 0.3;
  height: 95px;
  overflow: hidden;
}

@media (max-width: 56.875em) {
  .pull-quote:before {
    left: 30px;
  }
}

.pull-quote__quote {
  margin: 0;
  padding: 0;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 20px;
  margin-top: 55px;
}

.pull-quote__quote p {
  font-size: 20px;
}

@media (max-width: 56.875em) {
  .pull-quote__quote p {
    font-size: 18px;
  }
}

.pull-quote__caption cite {
  display: block;
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
}

@media (max-width: 56.875em) {
  .pull-quote__caption cite {
    padding-left: 0;
  }
}

.breadcrumbs {
  margin-bottom: 30px;
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 10px;
}

.breadcrumbs__list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  font-size: 14px;
}

.breadcrumbs__item {
  display: inline-block;
  margin-bottom: 0;
  color: #4a4a4a;
}

.breadcrumbs__item a {
  color: #0f6eb4;
}

.breadcrumbs__divider {
  color: #9e9e9e;
}

.breadcrumbs__divider .icon {
  fill: currentColor;
}

.rich-text {
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 30px;
}

.rich-text > div:not(.rich-text__embed),
.rich-text blockquote,
.rich-text h1,
.rich-text h2,
.rich-text h3,
.rich-text h4,
.rich-text h5,
.rich-text h6,
.rich-text ol,
.rich-text p,
.rich-text table,
.rich-text ul {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.rich-text li,
.rich-text p {
  line-height: 30px;
}

.rich-text li a,
.rich-text p a {
  font-weight: bold;
}

.rich-text h1 {
  font-size: 80px;
  font-weight: bold;
  line-height: 80px;
}

.rich-text h2 {
  font-size: 45px;
  font-weight: bold;
  line-height: 50px;
}

.rich-text h3 {
  font-size: 36px;
  font-weight: 400;
  line-height: 46px;
}

.rich-text h4 {
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
}

.rich-text h5 {
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
}

.rich-text .pull-quote:before {
  overflow: visible;
  top: 30px;
}

@media (max-width: 29.375em) {
  .rich-text blockquote:not(:has(.pull-quote__quote)) {
    margin: 20px;
  }
}

.rich-text blockquote:not(:has(.pull-quote__quote)) cite {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-align: right;
  margin-top: 30px;
  display: block;
}

.rich-text ol,
.rich-text ul {
  margin-top: 45px;
  margin-bottom: 45px;
  list-style: none;
}

.rich-text ol ol,
.rich-text ol ul,
.rich-text ul ol,
.rich-text ul ul {
  margin-top: 10px;
  margin-bottom: 0;
}

.rich-text ol,
.rich-text ul {
  line-height: 1.6;
}

.rich-text ol li,
.rich-text ul li {
  margin-left: 25px;
  padding-left: 10px;
}

.rich-text ol li:before,
.rich-text ul li:before {
  color: #0f6eb4;
  display: inline-block;
  width: 40px;
  margin-left: -40px;
}

@media (max-width: 56.875em) {
  .rich-text ol li:before,
  .rich-text ul li:before {
    width: 26px;
    margin-left: -26px;
  }
}

.rich-text ol li ol ol li,
.rich-text ol li ul ul li,
.rich-text ul li ol ol li,
.rich-text ul li ul ul li {
  margin-left: 55px;
}

.rich-text ul li::before {
  font-size: 18px;
  vertical-align: text-bottom;
  line-height: 24px;
  content: "\25cf";
  font-weight: 600;
}

.rich-text ul li > ul li::before {
  content: "\2014";
  color: #1970B1;
  font-size: 22px;
  font-weight: 600;
  width: 30px;
}

.rich-text ul li > ul > ul li::before {
  content: "\25A0" !important;
  font-size: 16px;
  font-weight: 600;
  width: 30px;
}

.rich-text ol {
  counter-reset: li;
}

.rich-text ol li::before {
  content: counters(li, ".") " ";
  counter-increment: li;
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 500;
}

.rich-text ol ol ol {
  counter-reset: nested-li;
}

.rich-text ol ol ol li::before {
  content: counter(nested-li, upper-alpha);
  counter-increment: nested-li;
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 500;
  width: 30px;
}

.rich-text hr {
  margin-bottom: 18px;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e7f0f7;
  padding: 0;
}

.rich-text table {
  clear: both;
  margin-top: 45px;
  margin-bottom: 45px;
}

.rich-text table caption {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.rich-text table th {
  background-color: transparent;
  border-bottom: #4a4a4a solid 2px;
  border-top: none;
  border-left: none;
  border-right: none;
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
}

.rich-text table td {
  border-right: none;
  border-left: none;
  padding: 5px;
  border-bottom: 1.5px solid #eeeeee;
}

@media (max-width: 56.875em) {
  .rich-text table td {
    border: 0;
  }
}

@media (max-width: 56.875em) {
  .rich-text table tr + tr {
    border-top: 2px solid #e6e7e8;
  }
}

@media (max-width: 56.875em) {
  .rich-text table tr:nth-child(2) {
    border-top: 2px solid #737373;
  }
}

.rich-text table caption {
  margin-bottom: calc(30px / 2);
  font-weight: bold;
}

div .rich-text__embed:not(.l-rte-pull-left), div .rich-text__embed:not(.l-rte-pull-right),
section .rich-text__embed:not(.l-rte-pull-left),
section .rich-text__embed:not(.l-rte-pull-right) {
  display: flex;
  justify-content: center;
  max-width: 100%;
}

div .rich-text__embed:not(.l-rte-pull-left) h1,
div .rich-text__embed:not(.l-rte-pull-left) h2,
div .rich-text__embed:not(.l-rte-pull-left) h3,
div .rich-text__embed:not(.l-rte-pull-left) h4,
div .rich-text__embed:not(.l-rte-pull-left) h5,
div .rich-text__embed:not(.l-rte-pull-left) h6, div .rich-text__embed:not(.l-rte-pull-right) h1,
div .rich-text__embed:not(.l-rte-pull-right) h2,
div .rich-text__embed:not(.l-rte-pull-right) h3,
div .rich-text__embed:not(.l-rte-pull-right) h4,
div .rich-text__embed:not(.l-rte-pull-right) h5,
div .rich-text__embed:not(.l-rte-pull-right) h6,
section .rich-text__embed:not(.l-rte-pull-left) h1,
section .rich-text__embed:not(.l-rte-pull-left) h2,
section .rich-text__embed:not(.l-rte-pull-left) h3,
section .rich-text__embed:not(.l-rte-pull-left) h4,
section .rich-text__embed:not(.l-rte-pull-left) h5,
section .rich-text__embed:not(.l-rte-pull-left) h6,
section .rich-text__embed:not(.l-rte-pull-right) h1,
section .rich-text__embed:not(.l-rte-pull-right) h2,
section .rich-text__embed:not(.l-rte-pull-right) h3,
section .rich-text__embed:not(.l-rte-pull-right) h4,
section .rich-text__embed:not(.l-rte-pull-right) h5,
section .rich-text__embed:not(.l-rte-pull-right) h6 {
  max-width: none;
}

div .rich-text__embed:not(.l-rte-pull-left) .image-block__media, div .rich-text__embed:not(.l-rte-pull-right) .image-block__media,
section .rich-text__embed:not(.l-rte-pull-left) .image-block__media,
section .rich-text__embed:not(.l-rte-pull-right) .image-block__media {
  text-align: center;
}

div .rich-text__embed:not(.l-rte-pull-left) .image-block__media figcaption, div .rich-text__embed:not(.l-rte-pull-right) .image-block__media figcaption,
section .rich-text__embed:not(.l-rte-pull-left) .image-block__media figcaption,
section .rich-text__embed:not(.l-rte-pull-right) .image-block__media figcaption {
  text-align: left;
}

div .rich-text__embed:not(.l-rte-pull-left) .video-block, div .rich-text__embed:not(.l-rte-pull-right) .video-block,
section .rich-text__embed:not(.l-rte-pull-left) .video-block,
section .rich-text__embed:not(.l-rte-pull-right) .video-block {
  width: 100%;
}

div .rich-text__embed:not(.l-rte-pull-left) .video-block__media, div .rich-text__embed:not(.l-rte-pull-right) .video-block__media,
section .rich-text__embed:not(.l-rte-pull-left) .video-block__media,
section .rich-text__embed:not(.l-rte-pull-right) .video-block__media {
  width: 100%;
}

div .rich-text__embed.l-rte-pull-left, div .rich-text__embed.l-rte-pull-right,
section .rich-text__embed.l-rte-pull-left,
section .rich-text__embed.l-rte-pull-right {
  max-width: 40%;
}

div .rich-text__embed.l-rte-pull-left .image-block,
div .rich-text__embed.l-rte-pull-left .video-block, div .rich-text__embed.l-rte-pull-right .image-block,
div .rich-text__embed.l-rte-pull-right .video-block,
section .rich-text__embed.l-rte-pull-left .image-block,
section .rich-text__embed.l-rte-pull-left .video-block,
section .rich-text__embed.l-rte-pull-right .image-block,
section .rich-text__embed.l-rte-pull-right .video-block {
  margin-bottom: 0;
}

div .rich-text__embed.l-rte-pull-left .image-block__media figcaption,
div .rich-text__embed.l-rte-pull-left .video-block__caption, div .rich-text__embed.l-rte-pull-right .image-block__media figcaption,
div .rich-text__embed.l-rte-pull-right .video-block__caption,
section .rich-text__embed.l-rte-pull-left .image-block__media figcaption,
section .rich-text__embed.l-rte-pull-left .video-block__caption,
section .rich-text__embed.l-rte-pull-right .image-block__media figcaption,
section .rich-text__embed.l-rte-pull-right .video-block__caption {
  padding-bottom: 0;
}

div .rich-text__embed.l-rte-pull-left .image-block__media, div .rich-text__embed.l-rte-pull-right .image-block__media,
section .rich-text__embed.l-rte-pull-left .image-block__media,
section .rich-text__embed.l-rte-pull-right .image-block__media {
  border-bottom: 0;
}

div .rich-text__embed.l-rte-pull-left .video-block,
div .rich-text__embed.l-rte-pull-left .video-block__media, div .rich-text__embed.l-rte-pull-right .video-block,
div .rich-text__embed.l-rte-pull-right .video-block__media,
section .rich-text__embed.l-rte-pull-left .video-block,
section .rich-text__embed.l-rte-pull-left .video-block__media,
section .rich-text__embed.l-rte-pull-right .video-block,
section .rich-text__embed.l-rte-pull-right .video-block__media {
  min-width: 328px;
}

div .rich-text__embed.l-rte-pull-left .video-block__media-placeholder, div .rich-text__embed.l-rte-pull-right .video-block__media-placeholder,
section .rich-text__embed.l-rte-pull-left .video-block__media-placeholder,
section .rich-text__embed.l-rte-pull-right .video-block__media-placeholder {
  width: 328px;
}

@media (max-width: 56.875em) {
  div .rich-text__embed.l-rte-pull-left, div .rich-text__embed.l-rte-pull-right,
  section .rich-text__embed.l-rte-pull-left,
  section .rich-text__embed.l-rte-pull-right {
    float: none;
    width: 100%;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
  div .rich-text__embed.l-rte-pull-left .video-block,
  div .rich-text__embed.l-rte-pull-left .video-block__media, div .rich-text__embed.l-rte-pull-right .video-block,
  div .rich-text__embed.l-rte-pull-right .video-block__media,
  section .rich-text__embed.l-rte-pull-left .video-block,
  section .rich-text__embed.l-rte-pull-left .video-block__media,
  section .rich-text__embed.l-rte-pull-right .video-block,
  section .rich-text__embed.l-rte-pull-right .video-block__media {
    min-width: 100%;
  }
}

div .rich-text__embed .image-block__media img,
section .rich-text__embed .image-block__media img {
  max-width: 100%;
  width: auto;
}

.container--100 .rich-text {
  margin-left: auto;
  margin-right: auto;
}

.rich-text--breakout {
  max-width: none;
  width: 100%;
}

.rich-text--breakout > div:not(.rich-text__embed),
.rich-text--breakout blockquote,
.rich-text--breakout h1,
.rich-text--breakout h2,
.rich-text--breakout h3,
.rich-text--breakout h4,
.rich-text--breakout h5,
.rich-text--breakout h6,
.rich-text--breakout ol,
.rich-text--breakout p,
.rich-text--breakout table,
.rich-text--breakout ul {
  max-width: none;
}

.secondary-nav {
  background: #1970b1;
  color: #fff;
  padding-bottom: 45px;
  padding-right: 20px;
  padding-top: 45px;
  position: relative;
}

@media (max-width: 56.875em) {
  .secondary-nav {
    padding-left: 40px;
    width: 100%;
  }
}

@media (max-width: 29.375em) {
  .secondary-nav {
    padding: 20px;
  }
}

.secondary-nav:before {
  background: #1970b1;
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
}

@media (max-width: 56.875em) {
  .secondary-nav:before {
    display: none;
  }
}

.secondary-nav li {
  position: relative;
}

.container--70-30 .secondary-nav {
  padding-left: 30px;
}

.container--70-30 .secondary-nav:before {
  display: none;
  right: auto;
  left: 0;
}

.secondary-nav__label {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-transform: uppercase;
  opacity: .5;
}

@media (max-width: 29.375em) {
  .secondary-nav__label {
    display: none;
  }
}

.secondary-nav__toggle {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #fff;
  display: none;
  justify-content: space-between;
  text-align: center;
  text-transform: uppercase;
  transform: rotate(0deg);
  transition: transform 0.2s;
}

.secondary-nav__toggle:hover {
  color: #fff;
}

.secondary-nav__toggle .icon,
.secondary-nav__toggle svg {
  width: 32px;
  height: 24px;
}

@media (max-width: 29.375em) {
  .secondary-nav__toggle {
    display: flex;
  }
}

.secondary-nav__submenu-toggle {
  background: none;
  border: 0;
  font-size: 0;
  height: 30px;
  left: 20px;
  position: absolute;
  top: 2px;
  width: 35px;
  z-index: 1;
}

.secondary-nav__submenu-toggle svg {
  fill: #fff;
  pointer-events: none;
  transition: fill ease-in-out 0.3s;
}

.is-active .secondary-nav__submenu-toggle svg {
  fill: #1970b1;
}

.secondary-nav__submenu-toggle:hover svg {
  fill: #f4ad3d;
}

.is-open > .secondary-nav__submenu-toggle svg {
  transform: rotate(90deg);
}

.is-open .secondary-nav__toggle .icon {
  transform: rotate(180deg);
}

.secondary-nav__quatermenu,
.secondary-nav__submenu,
.secondary-nav__tertmenu {
  margin-top: 10px;
  margin-bottom: 10px;
}

.secondary-nav__menu {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  margin-left: -30px;
}

.container--70-30 .secondary-nav__menu {
  margin-left: 0;
}

@media (max-width: 29.375em) {
  .secondary-nav__menu {
    display: none;
    margin-left: 0;
    margin-top: 10px;
  }
}

.secondary-nav__menu ul {
  margin-left: 0;
}

.secondary-nav__item,
.secondary-nav__quateritem,
.secondary-nav__subitem,
.secondary-nav__tertitem {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 8px;
}

@media (max-width: 29.375em) {
  .secondary-nav__item,
  .secondary-nav__quateritem,
  .secondary-nav__subitem,
  .secondary-nav__tertitem {
    font-family: "futura-pt", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
  }
}

.secondary-nav__item > a,
.secondary-nav__item > .secondary-nav__link--empty,
.secondary-nav__quateritem > a,
.secondary-nav__quateritem > .secondary-nav__link--empty,
.secondary-nav__subitem > a,
.secondary-nav__subitem > .secondary-nav__link--empty,
.secondary-nav__tertitem > a,
.secondary-nav__tertitem > .secondary-nav__link--empty {
  align-items: center;
  display: flex;
  color: inherit;
  padding: 8px 15px 8px 30px;
  transition: color ease-in-out 0.2s, background ease-in-out 0.3s;
}

.secondary-nav__item > a:hover + button svg,
.secondary-nav__item > .secondary-nav__link--empty:hover + button svg,
.secondary-nav__quateritem > a:hover + button svg,
.secondary-nav__quateritem > .secondary-nav__link--empty:hover + button svg,
.secondary-nav__subitem > a:hover + button svg,
.secondary-nav__subitem > .secondary-nav__link--empty:hover + button svg,
.secondary-nav__tertitem > a:hover + button svg,
.secondary-nav__tertitem > .secondary-nav__link--empty:hover + button svg {
  fill: #1970b1;
}

.secondary-nav__item > a svg,
.secondary-nav__item > .secondary-nav__link--empty svg,
.secondary-nav__quateritem > a svg,
.secondary-nav__quateritem > .secondary-nav__link--empty svg,
.secondary-nav__subitem > a svg,
.secondary-nav__subitem > .secondary-nav__link--empty svg,
.secondary-nav__tertitem > a svg,
.secondary-nav__tertitem > .secondary-nav__link--empty svg {
  margin-right: 10px;
  fill: currentColor;
  transform: rotate(0deg);
  transition: transform ease-in-out 0.2s;
}

.secondary-nav__item > a svg path,
.secondary-nav__item > .secondary-nav__link--empty svg path,
.secondary-nav__quateritem > a svg path,
.secondary-nav__quateritem > .secondary-nav__link--empty svg path,
.secondary-nav__subitem > a svg path,
.secondary-nav__subitem > .secondary-nav__link--empty svg path,
.secondary-nav__tertitem > a svg path,
.secondary-nav__tertitem > .secondary-nav__link--empty svg path {
  fill: inherit;
}

.secondary-nav__item > a[href]:hover,
.secondary-nav__quateritem > a[href]:hover,
.secondary-nav__subitem > a[href]:hover,
.secondary-nav__tertitem > a[href]:hover {
  background: #fff;
  color: #1970b1;
  text-decoration: none;
}

.secondary-nav__item.is-active > a,
.secondary-nav__quateritem.is-active > a,
.secondary-nav__subitem.is-active > a,
.secondary-nav__tertitem.is-active > a {
  background: #fff;
  color: #1970b1;
}

@media (max-width: 29.375em) {
  .secondary-nav__item.is-current-parent,
  .secondary-nav__quateritem.is-current-parent,
  .secondary-nav__subitem.is-current-parent,
  .secondary-nav__tertitem.is-current-parent {
    display: block;
  }
}

.secondary-nav__item {
  width: 100%;
}

.secondary-nav__item > a,
.secondary-nav__item > .secondary-nav__link--empty {
  margin-left: 0;
  font-size: 30px;
  width: calc(100% - 0);
}

@media (max-width: 29.375em) {
  .secondary-nav__item > a,
  .secondary-nav__item > .secondary-nav__link--empty {
    font-size: inherit;
    padding-left: 0;
  }
}

.secondary-nav__submenu {
  width: 100%;
  letter-spacing: 1px;
}

.secondary-nav__submenu li {
  display: block;
}

.secondary-nav__subitem {
  clear: both;
  display: block;
  font-size: 15px;
  line-height: 1.21;
  text-transform: uppercase;
}

.secondary-nav__subitem:last-child {
  border-bottom: none;
}

.secondary-nav__subitem > a {
  width: calc(100% - 25px);
  margin-left: 25px;
}

.secondary-nav__subitem > ul {
  display: none;
}

.secondary-nav__subitem.is-open > ul {
  display: block;
}

.secondary-nav__tertmenu {
  clear: both;
}

.secondary-nav__tertitem {
  margin-left: 20px;
  text-transform: none;
}

.secondary-nav__tertitem > a {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  width: calc(100% - 20px);
  font-size: 16px;
  margin-left: 20px;
}

.secondary-nav__tertitem > ul {
  display: none;
}

.secondary-nav__tertitem.is-open > a svg {
  transform: rotate(90deg);
}

.secondary-nav__tertitem.is-open > ul {
  display: block;
}

.secondary-nav__tertitem .secondary-nav__submenu-toggle {
  top: 3px;
}

.secondary-nav__quatermenu {
  clear: both;
}

.secondary-nav__quateritem {
  margin-left: 40px;
  width: calc(100% - 40px);
  text-transform: none;
}

.search-box {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  width: 100%;
}

@media (max-width: 56.875em) {
  .search-box {
    bottom: -50px;
    height: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
  }
}

@media (max-width: 56.875em) {
  .search-box {
    height: 48px;
  }
}

.search-box__container {
  height: 300px;
}

.search-box__dropdown {
  display: none;
}

.search-box__dropdown__active {
  background-color: #e7f0f7;
  z-index: 2;
  position: relative;
  left: 2px;
  width: calc(100% - 194px);
  margin-right: 0px;
  box-shadow: 0px 3px 4px 0px rgba(25, 112, 177, 0.2);
}

.search-box__dropdown__active .suggested-links__text {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  margin: 0px 0px 12px 24px;
  padding-top: 12px;
  font-size: 16px;
}

.search-box__dropdown__active .suggested-links__list {
  list-style: none;
  margin: 4px 0px 4px 0;
  padding-bottom: 8px;
}

.search-box__dropdown__active .suggested-links__list li {
  padding: 4px 0 4px 24px;
}

.search-box__dropdown__active .suggested-links__list li a:hover.btn--link {
  color: #4a4a4a;
}

@media (max-width: 56.875em) {
  .search-box__dropdown__active {
    display: none;
  }
}

.search-box__input {
  position: relative;
}

.search-box__input input {
  background: #e6e7e8;
  box-shadow: none;
  font-size: 16px;
  padding: 15px 32px 15px 15px;
  width: 100%;
  border: 2px solid #1970b1;
  border-radius: 3px;
}

@media (max-width: 56.875em) {
  .search-box__input input {
    border: 0;
    border-radius: 0;
  }
}

.search__query-mount .search-box__input input {
  height: 70px;
}

@media (max-width: 56.875em) {
  .search__query-mount .search-box__input input {
    height: auto;
  }
}

.search-box__button {
  position: absolute;
  right: 15px;
  top: 6px;
  padding: 8px 0;
  border: 0;
  background: transparent;
  cursor: pointer;
}

@media print {
  .search-box__button {
    display: none;
  }
}

.search__query-mount .search-box__button,
.featured-search__search-bar .search-box__button {
  padding: 24px 65px;
  color: #fff;
  background: #1970b1;
  cursor: pointer;
  height: 70px;
  text-transform: uppercase;
  right: 0;
  top: 0;
  display: block;
}

@media (max-width: 56.875em) {
  .search__query-mount .search-box__button,
  .featured-search__search-bar .search-box__button {
    padding-top: 5px;
  }
}

@media (max-width: 29.375em) {
  .search__query-mount .search-box__button,
  .featured-search__search-bar .search-box__button {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.search-box__button svg {
  fill: currentColor;
  width: 20px;
  height: 20px;
}

.search-box__button--toggle {
  display: none;
  margin-right: 10px;
  position: static;
}

@media (max-width: 56.875em) {
  .search-box__button--toggle {
    display: block;
  }
}

.search-box__button--toggle svg {
  fill: #1970b1;
  width: 30px;
  height: 30px;
}

.search-box__button-null {
  position: absolute;
  right: 15px;
  top: 6px;
  padding: 8px 0;
  border: 0;
  background: transparent;
  cursor: pointer;
}

@media print {
  .search-box__button-null {
    display: none;
  }
}

.search__query-mount .search-box__button-null,
.featured-search__search-bar .search-box__button-null {
  padding: 24px 65px;
  color: #fff;
  background: #4a4a4a;
  cursor: pointer;
  height: 70px;
  text-transform: uppercase;
  right: 0;
  top: 0;
  display: block;
}

@media (max-width: 56.875em) {
  .search__query-mount .search-box__button-null,
  .featured-search__search-bar .search-box__button-null {
    padding-top: 5px;
  }
}

@media (max-width: 29.375em) {
  .search__query-mount .search-box__button-null,
  .featured-search__search-bar .search-box__button-null {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.search-box__button-null svg {
  fill: currentColor;
  width: 20px;
  height: 20px;
}

.search-box__button-null--toggle {
  display: none;
  margin-right: 10px;
  position: static;
}

@media (max-width: 56.875em) {
  .search-box__button-null--toggle {
    display: block;
  }
}

.search-box__button-null--toggle svg {
  fill: #1970b1;
  width: 30px;
  height: 30px;
}

.search-box__loader .search-results__spinner {
  position: absolute;
  top: 0;
  right: 45px;
  left: auto;
  text-align: left;
}

.search-box__loader .search-results__spinner img {
  margin-top: 4px;
  width: 32px;
  height: 32px;
}

.search-facets {
  background-color: #1970b1;
}

.search-facets input {
  color: #4a4a4a;
}

.search__query-mount {
  width: 65.89147%;
  float: right;
  margin-bottom: 0;
  height: 70px;
}

.search__query-mount::after {
  clear: both;
  content: "";
  display: block;
}

.search__query-mount .search-box {
  max-width: 900px;
}

@media (max-width: 56.875em) {
  .search__query-mount .search-box {
    max-width: 100%;
    width: 100%;
    float: none;
  }
}

.search__facets-wrapper {
  margin-top: -130px;
}

@media (max-width: 56.875em) {
  .search__facets-wrapper {
    position: relative;
    margin: 0;
  }
  .search__facets-wrapper.is-left, .search__facets-wrapper.is-right {
    position: absolute;
    background-color: #1970b1;
    width: 85vw;
    top: 0;
    transition: transform 0.3s ease-in;
    margin: 0;
    padding: 15px;
    z-index: 99;
  }
  .search__facets-wrapper.is-left.is-open, .search__facets-wrapper.is-right.is-open {
    transform: translateX(0%);
  }
  .search__facets-wrapper.is-left {
    left: -30px;
    transform: translateX(-100%);
  }
  .search__facets-wrapper.is-right {
    right: -30px;
    transform: translateX(100%);
    transition: transform 0.3s ease-in;
  }
  .search__facets-wrapper.is-open {
    border: 1px solid #bdbdbd;
    border-left: 0;
    box-shadow: -1px 4px 12px 1px #9e9e9e;
  }
}

.search-facets {
  padding-bottom: 50px;
}

.search-facets__group--publications .search-facets__group-heading, .search-facets__group--events .search-facets__group-heading {
  padding-bottom: 0;
}

.search-facets__values-wrapper {
  padding: 0 0px;
}

.search-facets__filter-close {
  position: absolute;
  top: -20px;
  left: 0;
  display: none;
  height: 40px;
  width: 100%;
}

.search-facets__filter-close button {
  padding: 8px 12px;
  margin: 0 auto;
  display: block;
  text-align: center;
}

.search-facets__filter-close .icon {
  color: #fff;
  margin-left: 6px;
  float: right;
}

.search-facets__filter-close .icon,
.search-facets__filter-close .icon svg {
  width: 24px;
  height: 24px;
}

@media (max-width: 56.875em) {
  .search-facets__filter-close {
    display: block;
  }
}

.search-facets__subheading {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  color: #86b6d9;
  float: left;
  font-size: 16px;
  padding-left: 0px;
}

.search-facets__header {
  padding: 20px 0 8px 0;
  margin: 0 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.search-facets__header .selected-filters {
  display: flex;
  width: inherit;
  flex-wrap: wrap;
}

.search-facets__header .selected-filters .search-facets__selected-filter {
  margin: 0px 12px 8px 0px;
  padding: 4px 6px;
  font-size: 14px;
  letter-spacing: 0.64px;
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  width: fit-content;
}

.search-facets__header .selected-filters .search-facets__selected-filter .button {
  border: none;
  padding: 0px;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  margin-left: 4px;
}

.search-facets__header .selected-filters .search-facets__selected-filter .facet-icon {
  height: 14px;
  width: 14px;
  position: relative;
  top: 2px;
}

.search-facets__header .search-facets__clear-all {
  display: flex;
  text-align: left;
  padding: 0px 0px 0px 0;
  clear: both;
  overflow: hidden;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.search-facets__header .search-facets__clear-all .search-facet__refine-label {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #fff;
  float: left;
  margin-bottom: 10px;
  padding-right: 10px;
}

.search-facets__header .search-facets__clear-all button {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  float: right;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  padding-right: 0;
  padding-left: 0;
}

.search-facets__header .search-facets__clear-all .none-selected {
  color: rgba(255, 255, 255, 0.4);
}

@media (max-width: 56.875em) {
  .search-facets__header .search-facets__clear-all {
    float: right;
  }
}

.search-facets__group {
  clear: both;
  margin: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.search-facets__group::after {
  clear: both;
  content: "";
  display: block;
}

.search-facets__group .search-facets__group-heading {
  display: flex;
  justify-content: flex-start;
}

.search-facets__group .search-facets__group-heading h4 {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  margin-bottom: 0;
  color: #fff;
}

.search-facets__group .search-facets__group-heading button {
  margin-top: -2px;
  margin-left: -10px;
  margin-right: -2px;
}

.search-facets__group .search-facets__group-heading .icon {
  width: 22px;
  height: 22px;
}

.search-facets__group.is-open .search-facets__group-heading {
  border-bottom: 0;
}

.search-facets__group .search-facets__group {
  margin-top: 0;
  margin-bottom: 0;
}

.search-facets__group .search-facets__group .search-facets__group-heading {
  padding-top: 19px;
}

.search-facets__group.search-facets__group--publications:not(.is-open) div:not(.search-facets__group-heading), .search-facets__group.search-facets__group--events:not(.is-open) div:not(.search-facets__group-heading) {
  display: none;
}

.search-facets__group-wrapper .search-facets__group-heading {
  background-color: transparent;
  border: 0;
}

.search-facets__group-wrapper .search-facets__group-heading h4 {
  font-size: 16px;
}

.search-facets__group-wrapper .search-facets__group:first-of-type {
  margin-top: 10px;
}

.search-facets__group-wrapper .search-facets__group:first-of-type .search-facets__group-heading {
  padding-top: 0;
}

.search-facets__group-more,
.search-facets__group-toggle,
.search-facets__value-expand {
  background: transparent;
  border: 0;
  margin-bottom: 20px;
  cursor: pointer;
  color: #fff;
}

.search-facets__group-toggle,
.search-facets__value-expand {
  transition: transform 0.2s;
  color: #fff;
}

.search-facets__group-toggle.is-open,
.search-facets__value-expand.is-open {
  transform: rotate(90deg);
}

.search-facets__group-selected {
  margin: 8px 0;
}

.search-facets__group-selected .search-facets__hr {
  width: calc(100% - 80px);
  border-top: solid 1px #fff;
  display: block;
  height: 1px;
  margin: 40px auto;
}

.search-facets__group-selected .search-facets__hr hr {
  opacity: 0;
}

.search-facets__group-values:empty + .search-facets__group-selected .search-facets__hr {
  display: none;
}

.search-facets__group-selected .search-facets__group-values {
  padding: 0 0px;
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #fff;
}

.search-facets__group-more {
  padding-bottom: 0px;
  padding-left: 0px;
}

.search-facets__group-more button {
  background: transparent;
  border: 0;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  padding: 0;
  text-decoration: underline;
  text-underline-offset: .2em;
}

.search-facets__group-more button:hover {
  color: #fff;
}

.search-facets__value-expand {
  float: right;
  width: 30px;
}

.search-facets__checkbox {
  height: 16px;
  width: 16px;
  display: inline-block;
  position: relative;
  z-index: 1;
  vertical-align: top;
  border: 1px solid #fff;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0);
}

.search-facets__checkbox:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #4a4a4a;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.search-facets__group-values {
  list-style: none;
  margin: 0;
}

.search-facets__group-values.is-level-1, .search-facets__group-values.is-level-2 {
  margin-left: 0px;
  margin-top: 20px;
}

.search-facets__group-values.is-level-1 li:last-of-type, .search-facets__group-values.is-level-2 li:last-of-type {
  margin-bottom: 0;
}

.search-facets__group-values li {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.74px;
  font-size: 12px;
  display: block;
  position: relative;
  color: #fff;
}

.search-facets__group-values li input {
  height: 16px;
  width: 16px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.search-facets__group-values li input ~ .search-facets__checkbox {
  border: 1px solid #fff;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0);
}

.search-facets__group-values li input:checked ~ .search-facets__checkbox {
  background-color: rgba(255, 255, 255, 0.8);
}

.search-facets__group-values li input:focus ~ .search-facets__checkbox {
  border: 2px solid #fff;
}

.search-facets__group-values li input:focus ~ .search-facets__checkbox:after {
  left: 4px;
  top: 1px;
}

.search-facets__group-values li input:checked ~ .search-facets__checkbox:after {
  display: block;
}

.search-facets__group-values li label {
  font-size: 12px;
  padding-left: 8px;
  width: calc(100% - 54px);
  line-height: 15px;
  vertical-align: middle;
  display: inline-flex;
}

@media (max-width: 56.875em) {
  .search-facets__group-values li label {
    width: calc(100% - 58px);
  }
}

.search-facets__group-values li input {
  display: inline-block;
  vertical-align: text-top;
}

.search-facets__count {
  font-weight: 300;
  text-align: right;
  margin-left: 10px;
}

.search-facets__group-body {
  transition: height 0.2s ease-in;
}

.search-facets__group-body .search-facets__group-body {
  padding-left: 0;
}

.search-facets__values-wrapper {
  overflow: hidden;
}

.search-facets__values-wrapper.has-no-values + .search-facets__group .search-facets__group-heading {
  padding-top: 0;
}

.search-facets__values-wrapper .search-facets__values-wrapper {
  padding-right: 0;
  padding-left: 0;
}

.search-facets__group-top {
  width: 100%;
  display: block;
  margin-top: 10px;
}

.search-facets__group-top .search-facets__group-clear {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-decoration: underline;
  background: none;
  color: #fff;
  border: 0;
  font-size: 16px;
  display: inline;
  padding: 0 0px;
}

.search-facets__typeahead .search-facets__group-top .search-facets__group-clear {
  padding: 0;
}

.search-facets__typeahead--open + .search-facets__group-selected + .search-facets__values-wrapper,
.search-facets__typeahead--open + .search-facets__values-wrapper {
  display: none;
}

.search-facets__typeahead--open + .search-facets__group-selected + .search-facets__values-wrapper + .search-facets__group-more,
.search-facets__typeahead--open + .search-facets__values-wrapper + .search-facets__group-more {
  display: none;
}

.search-facets__typeahead {
  display: block;
  position: relative;
  margin-bottom: 20px;
}

.search-facets__typeahead input {
  background-color: #e6e7e8;
  border: 0;
  color: #4a4a4a;
  font-size: 14px;
  padding-left: 10px;
  height: 36px;
  width: 100%;
}

.search-facets__typeahead .search-facets__typeahead__list {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.94px;
  height: 24px;
  position: relative;
  color: #fff;
  font-size: 14px;
  display: block;
  overflow: hidden;
  height: auto;
}

.search-facets__typeahead .search-facets__typeahead__list .search-facets__typeahead__list-item {
  margin-bottom: 2px;
  display: block;
  position: relative !important;
}

.search-facets__typeahead .search-facets__typeahead__list .search-facets__typeahead__list-item:first-of-type {
  margin-top: 10px;
}

.search-facets__typeahead .search-facets__typeahead__list .search-facets__checkbox--checked:after {
  display: block;
}

.search-facets__typeahead .search-facets__typeahead__list .search-facets__checkbox {
  margin-right: 10px;
}

.search-facets__checkbox--checked,
.search-facets__checkbox--hover,
li:hover > .search-facets__checkbox {
  background-color: #fff !important;
}

.featured-search .search-box__input input {
  height: 70px;
}

.featured-search .search-box__options {
  width: 100%;
  margin-top: 15px;
}

.featured-search .search-box__options fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.featured-search .search-box__options ul {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
}

.featured-search .search-box__options ul li {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  list-style-type: none;
  margin-right: 40px;
  position: relative;
}

.featured-search .search-box__options ul li input {
  position: absolute;
  opacity: 0;
  width: 25px;
  height: 25px;
}

.featured-search .search-box__options ul li label {
  margin-left: 35px;
}

.featured-search .search-box__options ul li .radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 2px solid #4a4a4a;
  border-radius: 100%;
  width: 25px;
  height: 25px;
}

.featured-search .search-box__options ul li:hover input ~ .radio {
  background-color: #f1c40f;
}

.featured-search .search-box__options ul li .radio:after {
  content: "";
  position: absolute;
  display: none;
  top: 2px;
  left: 2px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #1970b1;
}

.featured-search .search-box__options ul li input:checked ~ .radio {
  border-color: #1970b1;
}

.featured-search .search-box__options ul li input:checked ~ .radio:after {
  display: block;
}

.search-item {
  opacity: 0;
  transition: opacity 250ms;
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 2px dashed #e6e7e8;
  overflow: hidden;
}

.search-item.is-visible {
  opacity: 1;
}

.search-item a {
  transition: none;
}

.search-item__meta {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #787878;
  display: inline-block;
  line-height: 34px;
  height: 34px;
}

.search-item__meta:empty {
  display: none;
}

.search-item__category {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 0.008em;
}

.search-item__category:empty {
  display: none;
}

.search-item__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 30px;
  margin-bottom: 0;
}

.search-item__content-type {
  text-transform: uppercase;
}

.search-item__body {
  margin-bottom: 12px;
  margin-top: 20px;
  display: block;
}

.search-item__url {
  font-size: 14px;
}

.search-item__img {
  float: right;
  width: 125px;
  height: auto;
  display: block;
  margin-top: -30px;
  margin-left: 32px;
}

@media (max-width: 29.375em) {
  .search-item__img {
    float: none;
    margin: 20px 10px;
  }
}

.search-results.is-refreshing .search-results__item-wrapper * {
  color: #eeeeee !important;
}

.search-results__item-wrapper {
  position: relative;
  clear: both;
}

.search-results__items {
  min-height: 350px;
  margin-bottom: 30px;
}

.search-results__sort-bar {
  margin-bottom: 30px;
  letter-spacing: 1.8px;
  width: 50%;
  display: inline-block;
  vertical-align: text-top;
}

.search-results__sort-bar::after {
  clear: both;
  content: "";
  display: block;
}

@media (max-width: 29.375em) {
  .search-results__sort-bar {
    width: auto;
  }
}

.search-results__sort-bar > span {
  float: left;
}

.search-results__sort-bar ul {
  align-items: center;
  float: left;
  display: flex;
  list-style: none;
  margin: 0 0 0 20px;
}

@media (max-width: 29.375em) {
  .search-results__sort-bar ul {
    margin-left: 0;
  }
}

.search-results__sort-bar li {
  margin-bottom: 0;
  height: 30px;
}

@media (max-width: 29.375em) {
  .search-results__sort-bar li {
    margin-left: 0;
    display: block;
  }
}

@media (max-width: 415px) {
  .search-results__sort-bar > span {
    display: block;
    padding-bottom: 12px;
  }
}

@media (max-width: 414px) {
  .search-results__sort-bar ul {
    float: none;
    display: flex;
    justify-content: space-between;
  }
  .search-results__sort-bar li {
    clear: both;
    width: 100%;
    margin: 0 12px 12px;
  }
  .search-results__sort-bar li::after {
    clear: both;
    content: "";
    display: block;
  }
}

.search-results__sorter {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  cursor: pointer;
  margin-right: 0;
  vertical-align: text-top;
  height: 30px;
  padding-left: 20px;
  position: relative;
}

.search-results__sorter:first-of-type {
  padding-left: 0;
}

.search-results__sorter.is-active {
  font-weight: bold;
}

@media (max-width: 29.375em) {
  .search-results__sorter {
    padding-left: 0;
  }
}

.search-results__sort-head {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 20px;
  color: #1970b1;
}

.search-results__sort-label {
  font-size: 16px;
  color: #1970b1;
  padding-left: 10px;
}

@media (max-width: 29.375em) {
  .search-results__sort-label {
    padding-left: 0;
  }
}

.search-results__sort-label:before {
  content: "|";
  color: #1970b1;
  height: 30px;
  position: absolute;
  top: 0;
  left: 11px;
  font-size: 18px;
}

@media (max-width: 56.875em) {
  .search-results__sort-label:before {
    margin-right: 0;
  }
}

@media (max-width: 29.375em) {
  .search-results__sort-label:before {
    content: "";
  }
}

.search-results__sort-label svg {
  height: 24px;
  width: 24px;
}

.search-results__sorter--alphabetical {
  display: inline-block;
}

.search-results__sorter:first-of-type .search-results__sort-label {
  padding-left: 0;
}

.search-results__sorter:first-of-type .search-results__sort-label:before {
  content: "";
}

.search-results__sort-dir {
  float: left;
  position: relative;
  top: calc($search-results__sort-arrow-size / 4);
}

.search-results__sort-dir button,
.search-results__sort-dir svg {
  width: 24px;
  height: 24px;
}

.search-results__sort-dir button {
  display: block;
  text-align: center;
  background: transparent;
  border: 0;
  padding: 0;
  margin-top: -16px;
  padding-top: 3px;
}

.search-results__sort-dir button.is-selected {
  color: #00ccff;
}

.search-results__sort-dir svg {
  fill: currentColor;
}

.search-results__status {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  display: block;
  margin-bottom: 17px;
}

.search-results__status > span {
  padding-bottom: 16px;
}

.search__results-mount {
  margin-top: -40px;
}

@media (max-width: 56.875em) {
  .search__results-mount {
    margin-top: 0;
  }
}

.search-results__active-facets {
  display: block;
  margin-bottom: 31px;
}

.search-results__active-facets ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 0 -12px;
  padding: 0;
}

.search-results__active-facets li {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  border-radius: 5px;
  margin-bottom: 12px;
  margin-left: 12px;
  padding: 6px 8px;
  margin-top: -4px;
  background-color: #e7f0f7;
  line-height: 1;
}

.search-results__active-facets li span {
  padding-bottom: 0;
  height: 100%;
  display: inline-block;
  padding-top: 3px;
}

.search-results__active-facets li .icon {
  margin-bottom: 6px;
}

.search-results__active-facets button {
  float: right;
  margin-left: 8px;
  padding: 0;
  border: 0;
  background: #fff;
  cursor: pointer;
  height: 25px;
  width: 25px;
  cursor: pointer;
  border-radius: 25px;
}

.search-results__load-more {
  text-align: left;
  transition: all 0.2s;
}

.search-results__load-more .btn {
  font-size: 18px;
  padding: 12px 24px;
}

.search-results__load-more .search-results__spinner {
  position: static;
  display: inline;
}

.search-results__load-more .search-results__spinner img {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-left: 14px;
}

.search-results__pagination {
  text-align: right;
  width: 49%;
  display: inline-block;
}

.search-results__pagination__container .search-results__pagination {
  width: 100%;
}

@media (max-width: 29.375em) {
  .search-results__pagination {
    width: 100%;
    text-align: left;
  }
}

.search-results__pages {
  display: inline-block;
}

.search-results__filter-open {
  display: none;
  margin-bottom: 16px;
  padding: 8px 12px;
}

.search-results__filter-open .icon {
  color: #fff;
  margin-left: 6px;
}

@media (max-width: 56.875em) {
  .search-results__filter-open {
    display: block;
  }
}

.search-results__spinner {
  position: absolute;
  top: 100px;
  left: calc(50% - 88px);
  text-align: center;
}

.search-results__spinner img {
  width: 80px;
  height: 80px;
}

.btn--pagination:after {
  content: "";
}

.is-active .search-results__sort-label {
  color: #4a4a4a;
}

.btn--pagination.btn--next,
.btn--pagination.btn--prev {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  color: #37474f;
  text-transform: capitalize;
  vertical-align: baseline;
  padding-bottom: 0;
}

.btn--pagination.btn--next:after, .btn--pagination.btn--next:before,
.btn--pagination.btn--prev:after,
.btn--pagination.btn--prev:before {
  color: #37474f;
  font-size: 37px;
  font-family: helvetica;
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 5px;
}

.btn--pagination.btn--next:disabled,
.btn--pagination.btn--prev:disabled {
  display: none;
}

.btn--pagination {
  color: #787878;
  padding: 0 5px;
}

.btn--pagination:not(.btn--prev):not(.btn--next):disabled {
  color: #1970b1;
}

.btn--prev:before {
  content: "‹";
  padding-right: 10px;
}

.btn--next:after {
  content: "›";
  padding-left: 10px;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #1970b1;
  border: 1px solid #115c91;
}

.CalendarDay__selected_span {
  border: 1px solid #115c91;
  background: #115c91;
}

.CalendarDay__default:hover {
  background: #e6e7e8;
}

.DateRangePickerInput_arrow {
  display: none;
}

.search-facets__datepickers {
  width: 100%;
  display: block;
  padding-left: 62px;
  padding-right: 40px;
  margin-top: 20px;
}

.search-facets__datepickers h4 {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #fff;
  font-size: 16px;
}

.search-facets__datepickers .search-facets__datepickers__error {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #fff;
  font-size: 14px;
  margin: 15px 0;
  display: none;
}

.search-facets__datepickers .search-facets__datepickers__error.search-facets__datepickers__error--shown {
  display: flex;
}

.search-facets__datepickers .search-facets__datepickers__error.search-facets__datepickers__error--shown .icon {
  margin-right: 5px;
}

.DateRangePickerInput {
  background-color: transparent;
  bordeR: 0;
  width: 100%;
}

.CalendarMonth_caption {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #1970b1;
  letter-spacing: 1px;
  font-size: 16px;
}

.DayPickerNavigation_svg__horizontal {
  color: #1970b1;
  fill: #1970b1;
}

.CalendarDay,
.DayPicker_weekHeader_li {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.DateInput {
  margin: 0;
  padding: 0;
  height: 25px;
  vertical-align: bottom;
  width: 45%;
  display: inline-block;
  position: relative;
  height: 100%;
  background-color: #e6e7e8;
}

.DateInput input {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  width: 100%;
  color: #4a4a4a;
  background-color: transparent;
  border: 0;
  height: 50px;
  padding-left: 10px;
  font-size: 16px;
  position: relative;
  text-shadow: 2px 0 #e6e7e8;
  z-index: 1;
}

.DateInput input:not(:placeholder-shown) {
  background-color: #e6e7e8;
}

.DateInput input.DateInput_input__focused,
.search-facets__datepickers__populated-both .DateInput input,
.search-facets__datepickers__populated-end .DateInput input.DateInput_input_2,
.search-facets__datepickers__populated-start .DateInput input.DateInput_input_1 {
  background-color: #e6e7e8;
}

.DateInput:first-of-type {
  margin-right: 5%;
}

.DateInput:after {
  content: "";
  height: 30px;
  width: 30px;
  position: absolute;
  top: 10px;
  right: 5px;
  background-image: url("../../img/svg/calendar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: 0;
}

@media (max-width: 80.625em) {
  .DateInput:after {
    opacity: 0.6;
  }
}

@media (max-width: 56.875em) {
  .DateInput:after {
    display: none;
  }
}

.service-item {
  margin-bottom: 30px;
}

.service-item__header {
  color: #1970b1;
  font-size: 30px;
}

.service-item__sub-group {
  margin-top: 35px;
  border-bottom: #c6c6c6 1px dashed;
  display: block;
}

.service-item__sub-group .scLooseFrameZone {
  display: block;
}

.search-results__item-wrapper .service-item__sub-group {
  border-bottom: none;
}

.service-item__sub-group__title {
  font-size: 30px;
}

.service-item__sub-department {
  margin-left: 0;
  margin-top: 35px;
  justify-content: left;
}

.service-item__sub-department li {
  list-style-type: none;
  margin-bottom: 35px;
}

.service-item__sub-department--columns {
  column-gap: 84px;
  column-count: 2;
}

.service-item__sub-department--columns li {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  list-style-type: none;
  margin-bottom: 35px;
}

@media (max-width: 56.875em) {
  .service-item__sub-department--columns {
    columns: 1;
  }
}

.service-item__sub-department__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  letter-spacing: 2px;
  color: #737373;
  text-transform: uppercase;
}

.service-item__sub-department__meta {
  display: block;
  font-size: 18px;
  margin-bottom: 8px;
}

.site-logo {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
}

@media (max-width: 56.875em) {
  .site-logo {
    display: table;
  }
  .site-logo .global-footer {
    display: block;
  }
}

.site-logo a {
  text-decoration: none;
}

.site-logo__image {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 56.875em) {
  .site-logo__image {
    display: table-row;
    max-width: 164px;
    text-align: center;
  }
  .global-footer .site-logo__image {
    display: block;
  }
}

@media (max-width: 56.875em) {
  .site-logo__image img {
    width: 100%;
  }
}

@media (56.9375em) {
  .site-logo__title {
    padding-left: 20px;
  }
}

@media (max-width: 56.875em) {
  .site-logo__title {
    font-size: 0.95rem;
    text-align: center;
    display: table-row;
    padding-top: 10px;
  }
}

.slideshow .slideshow__slider {
  right: -1px;
  position: relative;
}

.slideshow .image-block {
  opacity: 0;
  margin-bottom: 0;
}

.slideshow .slick-current.image-block {
  opacity: 1;
}

.slideshow .image-block__title {
  display: none;
}

.slideshow .image-block__media {
  border-bottom: none;
}

.slideshow .image-block__media figcaption {
  border-bottom: 0;
  max-width: 700px;
  padding-top: 40px;
  padding-bottom: 35px;
}

@media (max-width: 29.375em) {
  .slideshow .image-block__media figcaption {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.slideshow .slick-next,
.slideshow .slick-prev {
  z-index: 100;
  display: block;
  transition: background-color 0.3s;
  background-color: #f1c40f;
  color: #4a4a4a;
  border-radius: 0 !important;
  top: calc(50% - 50px);
}

.slideshow .slick-next,
.slideshow .slick-next .icon,
.slideshow .slick-next svg,
.slideshow .slick-prev,
.slideshow .slick-prev .icon,
.slideshow .slick-prev svg {
  width: 90px;
  height: 90px;
}

@media (max-width: 56.875em) {
  .slideshow .slick-next,
  .slideshow .slick-next .icon,
  .slideshow .slick-next svg,
  .slideshow .slick-prev,
  .slideshow .slick-prev .icon,
  .slideshow .slick-prev svg {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 29.375em) {
  .slideshow .slick-next,
  .slideshow .slick-next .icon,
  .slideshow .slick-next svg,
  .slideshow .slick-prev,
  .slideshow .slick-prev .icon,
  .slideshow .slick-prev svg {
    top: calc(40% - 50px);
  }
}

.slideshow .slick-next::before,
.slideshow .slick-prev::before {
  content: "";
}

.slideshow .slick-next {
  right: 0;
}

@media (max-width: 56.875em) {
  .slideshow .slick-next {
    right: -15px;
  }
  .container--bleed .slideshow .slick-next {
    right: 0;
  }
}

.slideshow .slick-prev {
  left: 0;
}

@media (max-width: 56.875em) {
  .slideshow .slick-prev {
    left: -15px;
  }
  .container--bleed .slideshow .slick-prev {
    left: 0;
  }
}

@media (min-width: 769px) {
  .slideshow .slick-prev {
    display: none;
  }
}

.slideshow .slick-dots {
  position: static;
}

.slideshow .slick-dots li {
  margin: 0 8px;
}

.slideshow .slick-dots li button::before {
  content: "";
  display: none;
}

.slideshow .slick-dots li:focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #bdbdbd, 0 0 0 6px #5B9DD9;
}

.slideshow .slick-dots button,
.slideshow .slick-dots li {
  width: 45px;
  height: 5px;
}

.slideshow .slick-dots button {
  margin: 0 auto;
  padding: 4px;
  background-color: #bdbdbd;
  transition: background-color 0.3s;
}

.slideshow .slick-dots .slick-active button {
  background-color: #1970b1;
}

.text-banner {
  margin-bottom: 30px;
  padding: 30px 15px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 29.375em) {
  .text-banner {
    display: block;
  }
}

.text-banner__title {
  font-size: 35px;
  width: 50%;
}

.text-banner__title::before {
  content: " ";
  border-top: #0f6eb4 5px solid;
  width: 55px;
  height: 100%;
  display: inline-block;
  margin-top: 20px;
  margin-right: 15px;
  float: left;
}

@media (max-width: 29.375em) {
  .text-banner__title::before {
    margin-right: 5px;
    width: 23px;
    float: none;
    margin-top: -5px;
    vertical-align: middle;
  }
}

.text-banner__title > span {
  width: calc(100% - 70px);
  display: inline-block;
}

@media (max-width: 29.375em) {
  .text-banner__title > span {
    width: 100%;
    display: inline;
  }
}

@media (max-width: 29.375em) {
  .text-banner__title {
    width: 100%;
  }
}

.text-banner__details-container {
  margin-left: 15px;
  margin-top: 0;
  width: calc(50% - 15px);
}

@media (max-width: 29.375em) {
  .text-banner__details-container {
    width: calc(100% - 15px);
    margin: 0;
  }
}

.text-banner__description {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
}

.text-banner__cta {
  transition: opacity 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  padding-left: 0;
  font-size: 18px;
  color: #0f6eb4;
  display: block;
  margin-top: 10px;
}

.utility-nav > ul {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  align-items: center;
  display: flex;
}

.utility-nav > ul > li {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 24px;
}

@media (max-width: 56.875em) {
  .utility-nav > ul > li {
    color: #fff;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.utility-nav > ul > li:last-child {
  margin-right: 0;
}

.utility-nav > ul > li a {
  color: inherit;
  text-decoration: none;
}

.utility-nav > ul > li a:hover {
  color: #9e9e9e;
}

@media (max-width: 56.875em) {
  .utility-nav > ul > li a:not(.btn) {
    padding: 0;
  }
}

.utility-nav > ul > li.utility-nav__item--alt {
  color: #1970b1;
  font-weight: 700;
}

@media (max-width: 56.875em) {
  .utility-nav > ul > li.utility-nav__item--alt {
    color: #fff;
  }
}

.utility-nav .mobile-header-callout .header-callout-button {
  display: none;
}

@media (max-width: 56.875em) {
  .utility-nav .mobile-header-callout .header-callout-button {
    display: inline-block;
    margin-bottom: 10px;
    margin-left: 0;
  }
}

.image-block {
  margin-bottom: 30px;
}

.image-block__media {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 500;
  color: #737373;
  font-size: 16px;
}

.image-block__media img {
  display: block;
  width: 100%;
}

.image-block__media figcaption {
  padding-top: 20px;
  padding-bottom: 35px;
  font-size: 16px;
  line-height: 1.45;
  max-width: 700px;
}

.image-block__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 30px;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.video-block {
  margin-bottom: 30px;
}

.video-block__title {
  line-height: 1.2;
  margin-bottom: 20px;
  margin-top: 20px;
}

.video-block__play-button {
  align-items: center;
  background: rgba(74, 74, 74, 0.3);
  border-radius: 50%;
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  height: 109px;
  font-size: 0;
  justify-content: center;
  left: calc(50% - 54px);
  opacity: 1;
  padding: 0;
  position: absolute;
  bottom: calc(50% - 54px);
  width: 109px;
  z-index: 2;
}

.video-block__play-button.is-hidden {
  opacity: 0;
  width: 0px;
  height: 0px;
  overflow: hidden;
  left: 0px;
  bottom: 0px;
  transition: height cubic-bezier(0.7, 0.1, 0.1, 1) 0.6s, width cubic-bezier(0.7, 0.1, 0.1, 1) 0.6s, left cubic-bezier(0.7, 0.1, 0.1, 1) 0.6s, bottom cubic-bezier(0.7, 0.1, 0.1, 1) 0.6s, opacity cubic-bezier(0.7, 0.1, 0.1, 1) 0.6s;
}

.video-block__play-button.is-hidden svg {
  height: 45px;
  width: 44px;
  transition: height cubic-bezier(0.7, 0.1, 0.1, 1) 0.3s, width cubic-bezier(0.7, 0.1, 0.1, 1) 0.3s;
}

.video-block__play-button svg {
  height: 45px;
  width: 44px;
  margin-left: 10px;
}

.video-block__media-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.video-block__media-wrapper video,
.video-block__media-wrapper iframe,
.video-block__media-wrapper object,
.video-block__media-wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.video-block__media-placeholder {
  display: none;
  width: 100%;
}

.video-block__caption {
  padding-bottom: 35px;
  line-height: 1.45;
  max-width: 700px;
  margin-top: 35px;
}

.skip-link {
  position: absolute;
  font-weight: bold;
  text-decoration: none;
  top: -1000%;
  transition: top ease-in-out .1s;
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
}

.skip-link:focus {
  top: 5px;
}

.news-slideshow {
  background-color: #1970b1;
  background-image: url("../../img/event-listing-bg.svg");
  background-position: center right;
  background-repeat: no-repeat;
  color: #fff;
  display: inline-block;
  margin-bottom: 120px;
  width: 100%;
}

@media (max-width: 768px) {
  .news-slideshow {
    background-position: top right;
    position: relative;
  }
}

@media (max-width: 1291px) {
  .news-slideshow .container {
    padding-left: 30px;
  }
}

@media (max-width: 768px) {
  .news-slideshow .container {
    padding-left: 0;
    margin: 0;
  }
}

@media (max-width: 768px) {
  .news-slideshow:after {
    content: "";
    background: #fff;
    position: absolute;
    width: 100%;
    height: calc(40% - -2px);
    bottom: 0;
    left: 0;
    z-index: 0;
  }
}

.news-slideshow .slick-list {
  overflow: visible;
  position: relative;
  left: 0px;
  transition: left cubic-bezier(0.7, 0.1, 0.1, 1) 0.3s;
}

@media (max-width: 768px) {
  .news-slideshow .slick-list {
    overflow: hidden;
  }
}

.news-slideshow .slick-track {
  align-items: flex-end;
  display: flex;
}

@media (max-width: 768px) {
  .news-slideshow .slick-track {
    align-items: flex-start;
  }
}

.news-slideshow .slick-next,
.news-slideshow .slick-prev {
  background: #f4ad3d;
  height: 50px;
  overflow: hidden;
  top: calc(50% + 35px);
  width: 50px;
  z-index: 1;
}

@media (max-width: 768px) {
  .news-slideshow .slick-next,
  .news-slideshow .slick-prev {
    height: 40px;
    top: calc(45% + 35px);
    width: 40px;
  }
}

.news-slideshow .slick-next:before,
.news-slideshow .slick-prev:before {
  display: none;
}

.news-slideshow .slick-next svg,
.news-slideshow .slick-prev svg {
  height: 40px;
  width: 40px;
}

@media (max-width: 768px) {
  .news-slideshow .slick-next svg,
  .news-slideshow .slick-prev svg {
    height: 30px;
    width: 30px;
  }
}

.news-slideshow .slick-next {
  right: 0;
  transition: right cubic-bezier(0.7, 0.1, 0.1, 1) 0.3s;
}

@media (max-width: 1024px) {
  .news-slideshow .slick-next {
    right: 0;
  }
}

.news-slideshow .slick-prev {
  left: 0;
  opacity: 0;
  transition: opacity cubic-bezier(0.7, 0.1, 0.1, 1) 0.3s;
}

@media (max-width: 1024px) {
  .news-slideshow .slick-prev {
    opacity: 1;
  }
}

@media (min-width: 1024px) {
  .news-slideshow .slick-slide {
    visibility: hidden;
  }
  .news-slideshow .slick-active {
    visibility: visible;
  }
}

.news-slideshow__container {
  overflow: hidden;
  position: relative;
  top: 60px;
  z-index: 1;
  padding-left: 20px;
  padding-right: 20px;
}

.news-slideshow__eyebrow {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

@media (max-width: 1024px) {
  .news-slideshow__eyebrow {
    font-size: 15px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.news-slideshow__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 45px;
}

@media (max-width: 768px) {
  .news-slideshow__title {
    font-size: 34px;
  }
}

@media (max-width: 1024px) {
  .news-slideshow__title {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.news-slideshow__description {
  font-size: 20px;
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@media (max-width: 1024px) {
  .news-slideshow__description {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.news-slideshow__slider-wrapper {
  position: relative;
}

@media (max-width: 768px) {
  .news-slideshow__slider-wrapper {
    margin-left: 0;
  }
}

.news-slideshow__slider {
  position: static;
  width: 100%;
}

@media (min-width: 1025px) {
  .news-slideshow__slider.back-button-is-visible .slick-prev {
    left: 0;
    opacity: 1;
  }
}

@media (min-width: 1025px) {
  .news-slideshow__slider.back-button-is-visible .slick-next {
    right: 0;
  }
}

.news-item {
  margin-right: auto;
  margin-left: auto;
  max-width: 977px;
  position: relative;
  left: 0;
  padding-bottom: 15px;
}

@media (max-width: 1024px) {
  .news-item {
    max-width: 800px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.news-item__container {
  display: flex;
  min-height: 261px;
}

@media (max-width: 768px) {
  .news-item__container {
    flex-direction: column-reverse;
    min-height: auto;
  }
}

.news-item__details {
  background: #fff;
  box-shadow: 0 0 15px 1px rgba(74, 74, 74, 0.4);
  color: #4a4a4a;
  padding: 25px 60px;
  position: relative;
  margin-right: -60px;
  max-width: 100%;
}

.is-page-editor .news-item__details {
  margin-right: 0;
}

@media (max-width: 768px) {
  .news-item__details {
    margin-right: 0;
    max-width: 100%;
  }
}

.news-item__details .btn--link.news-item__cta:last-child,
.news-item__details p:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 10px;
}

.news-item__time {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  display: inline-flex;
  margin-bottom: 20px;
  margin-top: 20px;
  text-transform: uppercase;
}

.news-item__time__day {
  font-size: 36px;
  margin-right: 3px;
}

.news-item__time__group {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  line-height: 1;
}

.news-item__image {
  flex-basis: 42%;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  width: 42%;
  z-index: -1;
}

.is-page-editor .news-item__image {
  z-index: 0;
}

@media (max-width: 768px) {
  .news-item__image {
    flex-basis: auto;
    width: 100%;
    z-index: 0;
  }
}

.news-item__image img {
  bottom: 0;
  height: calc(100% + 60px);
  object-fit: cover;
  position: absolute;
  right: 0;
  width: 100%;
}

@media (max-width: 768px) {
  .news-item__image img {
    position: static;
    height: auto;
    max-height: 300px;
  }
}

.news-item__eyebrow {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #737373;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 10px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .news-item__eyebrow {
    font-size: 16px;
  }
}

.news-item__eyebrow:before {
  background: #6fb40f;
  content: "";
  height: 29px;
  left: 0;
  position: absolute;
  top: 25px;
  width: 5px;
}

.news-item__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 27px;
  line-height: 37px;
}

.news-item__description {
  font-size: 18px;
  line-height: 30px;
}

.exitlinks {
  position: relative;
  background-color: #0f6eb4;
}

.exitlinks:not(:only-child) {
  margin-top: 50px;
}

.exitlinks__bg {
  background-image: url("../../img/exit-links/pattern.png");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  opacity: 0.3;
}

.exitlinks__content {
  position: relative;
  top: 0;
  z-index: 9;
  padding: 50px 0;
}

.exitlinks__content .exitlinks__title {
  text-align: left;
  font-size: 35px;
  line-height: 45px;
  font-weight: 700;
  border-left: solid #f1c40f 5px;
  padding-left: calc(70px - 5px);
  padding-right: 70px;
  color: #fff;
}

@media (max-width: 56.875em) {
  .exitlinks__content .exitlinks__title {
    padding-left: calc(30px - 5px);
    padding-right: 30px;
  }
}

.exitlinks__content .exitlinks__description {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  padding: 0 70px;
  color: #fff;
  margin-bottom: 28px;
  font-size: 18px;
  line-height: 30px;
}

@media (max-width: 56.875em) {
  .exitlinks__content .exitlinks__description {
    padding: 0 30px;
  }
}

.exitlinks__content .exitlinks__cta-container {
  display: flex;
  padding: 0 70px;
  justify-content: space-between;
}

@media (max-width: 56.875em) {
  .exitlinks__content .exitlinks__cta-container {
    padding: 0 30px;
  }
}

@media (max-width: 29.375em) {
  .exitlinks__content .exitlinks__cta-container {
    flex-direction: column;
  }
}

.exitlinks__content .exitlinks__cta {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
}

.exitlinks__content .exitlinks__cta a {
  color: #fff;
  text-decoration: none;
}

.exitlinks__content .exitlinks__cta a:after {
  content: "\2192";
  padding-left: 5px;
}

@media (max-width: 29.375em) {
  .exitlinks__content .exitlinks__cta {
    margin-top: 20px;
  }
}

.login-form {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  /*margin-right: 30px;*/
}

@media (max-width: 56.875em) {
  .login-form {
    border-top: 3px solid #f4ad3d;
    display: none;
    /*margin-right: 0;*/
    padding-top: 15px;
  }
}

.login-form__dropdown-wrapper {
  position: relative;
}

.utility-nav + .login-form > .login-form__dropdown-wrapper:before {
  background: #f4ad3d;
  content: "";
  display: block;
  height: 50px;
  left: 0;
  position: absolute;
  top: -16px;
  width: 4px;
}

@media (max-width: 56.875em) {
  .utility-nav + .login-form > .login-form__dropdown-wrapper:before {
    display: none;
  }
}

.login-form--logged-out .utility-nav + .login-form > .login-form__dropdown-wrapper:before {
  display: none;
}

.login-form__member-id {
  color: #1970b1;
  position: absolute;
  top: 5px;
  right: 0;
}

@media (max-width: 56.875em) {
  .login-form__member-id {
    display: none;
  }
}

.login-form__item .login-form__member-id {
  color: #fff;
  display: block;
  position: static;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-right: 3px;
  padding-top: 10px;
  text-transform: uppercase;
}

@media (max-width: 56.875em) {
  .utility-nav .login-form--logged-out a.btn {
    display: inline;
  }
}

.utility-nav .login-form__list {
  border-top: 4px solid #f6ae2c;
  display: none;
  padding-top: 20px;
}

@media (max-width: 56.875em) {
  .utility-nav .login-form__list {
    display: flex;
  }
}

@media (max-width: 56.875em) {
  .utility-nav .login-form__list .login-form__item:nth-of-type(1) {
    float: left;
  }
}

@media (max-width: 56.875em) {
  .utility-nav .login-form__list .login-form__item:nth-of-type(2) {
    float: right;
    margin-right: 90px;
  }
}

@media (max-width: 29.375em) {
  .utility-nav .login-form__list .login-form__item:nth-of-type(2) {
    margin-right: auto;
  }
}

@media (max-width: 56.875em) {
  .utility-nav .login-form__list .login-form__item:nth-of-type(3) {
    clear: both;
  }
}

.login-form__trigger {
  color: #4a4a4a;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin-left: 30px;
  /*padding-right: 20px;*/
  white-space: nowrap;
  text-decoration: none;
}

.login-form__trigger .icon {
  transition: transform ease-in-out 0.2s;
  width: 23px;
  height: 25px;
  fill: #1970b1;
  color: #1970b1;
  background-size: 100% auto;
  margin-left: 9px;
}

.login-form.is-open .login-form__trigger .icon {
  transform: rotate(180deg);
}

@media (max-width: 56.875em) {
  .login-form__trigger .icon {
    display: none;
  }
}

@media (max-width: 56.875em) {
  .login-form__trigger {
    margin-left: 0;
    pointer-events: none;
  }
}

.login-form__list {
  background: #1970b1;
  display: none;
  right: -21px;
  list-style-type: none;
  margin-left: 0;
  position: absolute;
  top: 35px;
  z-index: 1;
}

.login-form.is-open .login-form__list {
  display: block;
}

@media (max-width: 56.875em) {
  .login-form__list {
    background: none;
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
    position: static;
  }
}

.login-form__list:before {
  content: "";
  border-bottom: 11px solid #1970b1;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  height: 24px;
  position: absolute;
  right: 42px;
  top: -24px;
  width: 20px;
}

@media (max-width: 56.875em) {
  .login-form__list:before {
    display: none;
  }
}

.login-form__item {
  border-bottom: 1px solid #f4ad3d;
  color: #fff;
  margin: 0;
  padding: 15px 20px;
}

@media (max-width: 56.875em) {
  .login-form__item {
    border-bottom: 0;
    flex-basis: 50%;
  }
}

.login-form__item:last-child {
  border-bottom: 0;
}

.login-form__item a {
  color: inherit;
  display: block;
  text-decoration: none;
}

@media (max-width: 56.875em) {
  .login-form__item a {
    margin-bottom: 10px;
    padding-left: 0;
  }
}

.login-form__item--mobile-only {
  display: none;
}

@media (max-width: 56.875em) {
  .login-form__item--mobile-only {
    display: block;
  }
}

.login-form__indent {
  padding-left: 35px;
}

.social-links ul {
  list-style: none;
  margin: 0;
  display: flex;
}

.social-links li {
  margin-right: 8px;
  margin-bottom: 0;
}

.social-links li:last-child {
  margin-right: 0;
}

.social-links a[href*="youtube"] {
  color: #e52d27;
}

.social-links a[href*="twitter"] {
  color: #55acee;
}

.social-links a[href*="instagram"] {
  color: #a63d97;
}

.social-links a[href*="facebook"] {
  color: #3b5998;
}

.social-links a[href*="google"] {
  color: #dd4b39;
}

.social-links a[href*="rss"] {
  color: #f26522;
}

.qna__section-header:after {
  background: #1970b1;
  content: "";
  display: block;
  height: 3px;
  margin-bottom: 50px;
  margin-top: 15px;
  width: 105px;
}

.qna__question-header {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #737373;
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.qna__question,
.rich-text .qna__question {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 20px;
  margin-bottom: 40px;
}

.qna__answer {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  display: block;
  border-bottom: 2px dashed #9b9b9b;
  padding-bottom: 40px;
  margin-bottom: 40px;
  font-size: 18px;
}

.link-grid-container {
  background-color: #e7f0f7;
  width: 100%;
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 80px;
}

.link-grid {
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (max-width: 29.375em) {
  .link-grid {
    display: block;
  }
}

.link-grid .list-item__primary-cta a {
  font-size: 16px;
}

.link-grid .list-item__description {
  font-size: 18px;
  line-height: 30px;
}

.link-grid--taxonomy a {
  color: #4a4a4a;
}

.link-grid--taxonomy a .btn.mobile-only {
  color: #1970b1;
  display: none;
}

@media (max-width: 29.375em) {
  .link-grid--taxonomy a .btn.mobile-only {
    display: block;
  }
}

.link-grid--taxonomy .link-grid__item:hover {
  background-color: #f1d302;
}

.link-grid__item {
  flex-grow: 1;
  flex-wrap: wrap;
  list-style-type: none;
  background-color: #fff;
  width: 31.1%;
  margin-right: 15px;
  padding: 30px;
}

.link-grid__item a,
.link-grid__item a:hover {
  text-decoration: none;
}

.link-grid__item .link-grid__img img {
  max-height: 225px;
  max-width: 100%;
  margin-right: auto;
  object-fit: contain;
  width: -webkit-fill-available;
}

.link-grid--taxonomy .link-grid__item {
  width: 45%;
  padding: 0;
}

.link-grid--taxonomy .link-grid__item a {
  display: block;
  padding: 30px;
}

@media (max-width: 29.375em) {
  .link-grid--taxonomy .link-grid__item {
    width: 100%;
  }
}

.is-page-editor .link-grid--taxonomy .link-grid__item {
  width: 100%;
}

@media (min-width: 29.4375em) and (max-width: 80.625em) {
  .link-grid__item {
    width: 45%;
  }
}

@media (max-width: 29.375em) {
  .link-grid__item {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.link-grid__title {
  font-size: 30px;
}

.link-grid--taxonomy .link-grid__title {
  font-weight: 700;
}

.link-grid__secondary_cta {
  margin-left: 15px;
}

.link-grid__secondary_cta li {
  list-style-type: none;
  width: 100%;
  margin-bottom: 20px;
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.link-grid__secondary_cta li:before {
  color: #6fb40f;
  display: inline-block;
  width: 20px;
  margin-left: -24px;
  content: "•";
  font-weight: 600;
}

.container--100 .link-grid-container {
  width: 100vw;
  position: relative;
  left: 49.3%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

@media (min-width: 29.4375em) {
  .container--100 .link-grid-container .link-grid {
    padding: 15px 0 15px 2.1%;
    max-width: 1290px;
    margin: 0 auto;
  }
}

@media (min-width: 56.875em) and (max-width: 80.625em) {
  .container--100 .link-grid-container {
    left: 0;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    right: 0;
  }
}

@media (max-width: 56.875em) {
  .container--100 .link-grid-container {
    left: auto;
    right: auto;
    margin-right: -30px;
    margin-left: -30px;
    width: calc(100% + 2 * 30px);
  }
}

@media (max-width: 29.375em) {
  .container--100 .link-grid {
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .container--100 .link-grid .link-grid__item {
    width: 95%;
    justify-content: space-between;
  }
  .container--100 .link-grid .link-grid__item:last-child {
    margin-bottom: 0;
  }
  .container--100 .link-grid-container {
    left: auto;
    right: auto;
    margin-right: -30px;
    margin-left: -30px;
    width: calc(100% + 2 * 30px);
  }
}

.container--70-30 .container__col:first-child .link-grid-container {
  width: calc(100% + 11.11%);
}

.container--70-30 .container__col:first-child .link-grid-container:before {
  background: #e7f0f7;
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: -1;
}

@media (max-width: 80.625em) {
  .container--70-30 .container__col:first-child .link-grid-container:before {
    display: none;
  }
}

@media (min-width: 56.9375em) and (max-width: 80.625em) {
  .container--70-30 .container__col:first-child .link-grid-container {
    width: calc(100% + 30px + 20px);
    margin-left: -25px;
  }
  .container--70-30 .container__col:first-child .link-grid-container .link-grid {
    padding-left: 15px;
  }
}

@media (max-width: 29.375em) {
  .container--70-30 .container__col:first-child .link-grid-container {
    width: calc(100% + 2 * 30px) !important;
    padding-left: 0 !important;
  }
}

@media (max-width: 56.875em) {
  .container--70-30 .container__col:first-child .link-grid-container {
    left: auto;
    right: auto;
    margin-right: -30px;
    margin-left: -30px;
    width: calc(100% + 2 * 30px);
  }
  .container--70-30 .container__col:first-child .link-grid {
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .container--70-30 .container__col:first-child .link-grid .link-grid__item {
    width: 95%;
    justify-content: space-between;
  }
  .container--70-30 .container__col:first-child .link-grid .link-grid__item:last-child {
    margin-bottom: 0;
  }
  .container--70-30 .container__col:first-child .link-grid-container {
    padding-left: 15px;
    width: calc(100% + 12%);
  }
}

.container--30-70 .container__col:last-child .link-grid-container:before {
  background: #e7f0f7;
  content: "";
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: calc((100vw - 1290px) / 2 + 99% + 11.11%);
  z-index: -1;
  display: block;
  left: -11.11%;
}

@media (max-width: 80.625em) {
  .container--30-70 .container__col:last-child .link-grid-container:before {
    display: none;
  }
}

@media (min-width: 29.4375em) and (max-width: 80.625em) {
  .container--30-70 .container__col:last-child .link-grid-container {
    width: calc(100% + 2 * 30px);
    margin-right: -30px;
    margin-left: -30px;
    right: 0;
    position: relative;
    padding: 30px 0px 30px 30px;
  }
}

@media (max-width: 29.375em) {
  .container--30-70 .container__col:last-child .link-grid-container {
    padding-left: 0;
  }
}

@media (max-width: 29.375em) {
  .container--30-70 .container__col:last-child .link-grid {
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .container--30-70 .container__col:last-child .link-grid .link-grid__item {
    width: 95%;
    justify-content: space-between;
  }
  .container--30-70 .container__col:last-child .link-grid .link-grid__item:last-child {
    margin-bottom: 0;
  }
  .container--30-70 .container__col:last-child .link-grid-container {
    left: auto;
    right: auto;
    margin-right: -30px;
    margin-left: -30px;
    width: calc(100% + 2 * 30px);
  }
}

.quick-links {
  margin-bottom: 30px;
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  display: flex;
  padding-bottom: 10px;
  font-size: 18px;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-top: 35px;
}

@media (max-width: 56.875em) {
  .quick-links {
    flex-direction: column;
  }
}

.quick-links__link {
  margin-bottom: 0;
  color: #0f6eb4;
  padding: 10px 20px;
  border: 2px solid #0f6eb4;
  font-size: 22px;
  text-align: center;
  margin-left: 20px;
}

.quick-links__link:first-child {
  margin-left: 0;
}

.quick-links__link:hover {
  color: white;
  background-color: #0f6eb4;
  text-decoration: none;
}

@media (max-width: 56.875em) {
  .quick-links__link {
    margin-bottom: 10px;
    margin-left: 0;
  }
}

.header-callout-button {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  font-family: futura-pt;
  font-weight: bold;
  font-size: 16px;
}

.header-callout-button__link {
  color: #0f6eb4;
  border: 2px solid #0f6eb4;
  text-align: center;
  padding: 8px 12px;
}

.header-callout-button__link:hover {
  color: white;
  background-color: #0f6eb4;
  text-decoration: none;
}

@media (max-width: 56.875em) {
  .header-callout-button__link {
    margin-bottom: 10px;
    min-width: 65px;
    min-height: 20px;
    padding: 10px 20px !important;
    margin-left: 0;
    border: 1px solid #fff;
  }
}

.table-responsive {
  width: 100%;
}

.table-responsive__mobile-label {
  display: none;
}

@media (max-width: 56.875em) {
  .table-responsive__mobile-label {
    font-family: "futura-pt", Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-style: normal;
    display: block;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 5px;
    text-transform: uppercase;
    width: 50%;
  }
}

@media (max-width: 56.875em) {
  .table-responsive__mobile-text {
    display: block;
    padding: 10px;
    width: 50%;
  }
}

.table-responsive td,
.table-responsive th {
  padding: 10px;
}

@media (max-width: 56.875em) {
  .table-responsive td,
  .table-responsive th {
    display: flex;
    padding: 0;
  }
  .table-responsive td:first-child,
  .table-responsive th:first-child {
    border-left-width: 0;
  }
  .table-responsive td:last-child,
  .table-responsive th:last-child {
    border-right-width: 0;
  }
}

@media (max-width: 56.875em) {
  .table-responsive tr:first-of-type {
    display: none;
  }
}

@media (max-width: 56.875em) {
  .table-responsive tr {
    display: block;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.microsite-nav {
  background: #4a4a4a;
  color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  position: relative;
}

@media (max-width: 56.875em) {
  .microsite-nav {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.microsite-nav:before {
  background: #4a4a4a;
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: -1;
}

@media (max-width: 56.875em) {
  .microsite-nav:before {
    display: none;
  }
}

.microsite-nav:after {
  background: #f1c40f;
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100px;
}

.container--70-30 .microsite-nav {
  padding-left: 33px;
  padding-right: 17px;
}

.container--70-30 .microsite-nav:before {
  display: none;
  right: auto;
  left: 0;
}

.microsite-nav__heading {
  font-size: 30px;
  color: #fff;
  margin-bottom: 37px;
  margin-top: -16px;
}

.microsite-nav__menu {
  list-style-type: none;
  margin-bottom: 0;
  margin-left: 0;
}

.microsite-nav__item {
  margin-bottom: 30px;
}

.microsite-nav__item:last-child {
  margin-bottom: 0;
}

.microsite-nav__item .microsite-nav__link,
.microsite-nav__item a {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #fff;
  font-size: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  transition: color ease-in-out 0.3s;
}

.microsite-nav__item .microsite-nav__link.is-active, .microsite-nav__item .microsite-nav__link:hover,
.microsite-nav__item a.is-active,
.microsite-nav__item a:hover {
  color: #f1c40f;
}

@media print {
  .microsite-nav__item .microsite-nav__link,
  .microsite-nav__item a {
    font-size: 10px;
  }
}

.microsite-nav__show-more {
  background-color: #0f6eb4;
  color: #fff;
  cursor: pointer;
  display: block;
  margin-left: -33px;
  padding: 12px;
  visibility: hidden;
  width: calc(100% + 33px + 17px);
}

.microsite-nav__show-more svg {
  display: block;
  height: 16px;
  margin: 0 auto;
  width: 10px;
}

.microsite-nav__show-more.is-open svg {
  transform: rotate(180deg);
}

.microsite-nav--books {
  padding-bottom: 0;
}

.microsite-nav--books:after {
  left: 30px;
}

.microsite-nav--books > .microsite-nav__menu li:nth-child(n+6) {
  display: none;
}

.book-details .container--100,
.book-details .container--70-30 {
  margin-right: 0;
  margin-left: 0;
}

@media (max-width: 56.875em) and (min-width: 20em) {
  .book-details {
    margin-top: 10px;
  }
}

.book-details__upper {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 29.375em) {
  .book-details__upper {
    flex-direction: column;
  }
}

.book-details__left {
  width: 220px;
  margin-bottom: 55px;
}

@media (max-width: 29.375em) {
  .book-details__left {
    width: 100%;
  }
}

.book-details__left .book-details__image {
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  min-height: 1px;
}

@media (max-width: 29.375em) {
  .book-details__left .book-details__image {
    max-width: 220px;
    margin: 0 auto 20px auto;
    display: block;
  }
}

.book-details__left .book-details__price {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 36px;
  margin-bottom: 20px;
}

.book-details__left .book-details__button {
  width: 100%;
  padding: 14px;
  text-align: center;
}

.book-details__left .book-details__button:hover {
  text-decoration: none;
}

.book-details__left .book-details__button--disabled {
  background-color: #757575;
  border-color: #757575;
}

.book-details__left .book-details__button--disabled:hover {
  color: #fff;
}

.book-details__left .book-details__exam-copy {
  padding-top: 14px;
}

.book-details__right {
  margin-left: 29px;
  width: calc(100% - 29px - 220px);
}

@media (max-width: 29.375em) {
  .book-details__right {
    width: 100%;
    margin: 0 auto;
  }
}

.book-details__right .book-details__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 45px;
  line-height: 51px;
  margin-top: -11px;
}

.book-details__right .book-details__pricing {
  margin: 30px 0;
}

.book-details__right .book-details__pricing ul {
  margin-left: 0;
  margin-bottom: 0;
}

.book-details__right .book-details__pricing ul li {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  list-style-type: none;
  font-size: 20px;
  margin: 0;
  line-height: 34px;
  padding: 0;
}

.book-details__right .book-details__author {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
  color: #4a4a4a;
  margin-bottom: 0;
}

.book-details__right .book-details__author span:after {
  content: ", ";
}

.book-details__right .book-details__author span:last-of-type:after {
  content: "";
}

.book-details__right .book-details__author a {
  color: #4a4a4a;
}

.book-details__right .book-details__detail {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: 34px;
}

@media print {
  .book-details__right {
    width: 100%;
    margin-left: 0;
  }
}

.book-details__labels {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.book-details__labels__label {
  color: #787878;
  font-size: 20px;
  padding-top: 4px;
  min-width: 80px;
}

.book-details__labels__list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  flex-grow: 2;
  margin-bottom: -8px;
}

.book-details__labels__list li {
  background-color: #e7f0f7;
  color: #4a4a4a;
  display: inline-block;
  font-size: 16px;
  margin: 0 8px 8px 8px;
  list-style-type: none;
  padding: 5px 11px;
  border-radius: 5px;
}

.book-details__labels__list li a {
  color: #4a4a4a;
}

.book-details__lower {
  margin-top: 40px;
}

.book-details__lower .book-details__description,
.book-details__lower .book-details__testimonial {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
}

.book-details__lower .book-details__description p,
.book-details__lower .book-details__testimonial p {
  font-size: inherit;
  line-height: inherit;
}

.book-details__lower .book-details__description__text {
  transition: all 1s;
  position: relative;
}

.book-details__lower .book-details__description__text:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-image: linear-gradient(rgba(255, 255, 255, 0) 70%, white);
}

@media print {
  .book-details__lower .book-details__description__text:before {
    display: none;
  }
}

.book-details__lower .book-details__read-more {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  display: block;
  margin: 0 auto;
  max-width: 200px;
  background-color: #e7f0f7;
  color: #4a4a4a;
  border: 0;
  padding: 10px;
  visibility: hidden;
}

.book-details__lower .book-details__read-more:hover, .book-details__lower .book-details__read-more:active, .book-details__lower .book-details__read-more:focus {
  color: #0f6eb4;
}

.book-details__lower .book-details__read-more .book-details__text--closed {
  display: inline;
}

.book-details__lower .book-details__read-more .book-details__text--open {
  display: none;
}

.is-open .book-details__description__text:before {
  display: none;
}

.is-open .book-details__read-more svg {
  transform: rotate(180deg);
}

.is-open .book-details__read-more .book-details__text--closed {
  display: none;
}

.is-open .book-details__read-more .book-details__text--open {
  display: inline;
}

.course-taxonomy .book-details__labels {
  margin-top: 30px;
}

.full-hero {
  position: relative;
  max-width: 2000px;
  margin: 0 auto;
}

.full-hero:before {
  background: #e7f0f7;
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
}

@media (max-width: 56.875em) {
  .full-hero .container--100 {
    margin: 0;
  }
}

.full-hero .full-hero__inner {
  display: flex;
  flex-direction: row-reverse;
}

@media (max-width: 1300px) {
  .full-hero .full-hero__inner {
    padding-left: 30px;
  }
}

@media (max-width: 56.875em) {
  .full-hero .full-hero__inner {
    display: block;
    padding-right: 30px;
  }
}

.full-hero__details {
  flex-basis: calc(100% - 380px);
  /*align-self: center;*/
  padding-right: 30px;
  padding-bottom: 81px;
  padding-top: 82px;
}

@media (max-width: 56.875em) {
  .full-hero__details {
    padding-bottom: 56px;
    padding-top: 56px;
    padding-right: 0;
    flex-basis: auto;
  }
}

.full-hero__details .full-hero__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: 0;
  padding: 0;
  font-size: 35px;
  line-height: 1.286;
  margin-bottom: 8px;
}

.full-hero__details .full-hero__description {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  line-height: 1.455;
  margin-bottom: 24px;
}

.full-hero__details .full-hero__cta {
  font-size: 16px;
  line-height: 1.375;
  margin-right: 22px;
}

.full-hero__details .full-hero__link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 1.112;
}

@media (max-width: 56.875em) {
  .full-hero__details .full-hero__link {
    padding-top: 20px;
    padding-right: 0;
    letter-spacing: 0;
  }
}

.full-hero__image {
  flex-basis: 380px;
  width: 380px;
  position: relative;
}

.full-hero__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (max-width: 56.875em) {
  .full-hero__image {
    flex-basis: auto;
    /*height: auto;*/
    margin-left: -30px;
    margin-right: -30px;
    width: calc(100% + 60px);
    z-index: 0;
  }
  .full-hero__image img {
    position: static;
    /*height: auto;*/
    max-height: 386px;
  }
}

@media (min-width: 1300px) {
  .full-hero__image {
    right: calc(654px - 50vw);
  }
}

@media (min-width: 2000px) {
  .full-hero__image {
    right: -355px;
  }
}

@media (max-width: 600px) {
  .related-resources .resource {
    flex-direction: column;
  }
}

.related-resources .slick-next,
.related-resources .slick-prev {
  background: #f4ad3d;
  height: 80px;
  width: 80px;
  z-index: 1;
}

@media (max-width: 56.875em) {
  .related-resources .slick-next,
  .related-resources .slick-prev {
    height: 40px;
    width: 40px;
  }
}

.related-resources .slick-next:before,
.related-resources .slick-prev:before {
  display: none;
}

.related-resources .slick-next svg,
.related-resources .slick-prev svg {
  height: 100%;
  width: 100%;
}

@media print {
  .related-resources .slick-next,
  .related-resources .slick-prev {
    display: none;
  }
}

.related-resources .slick-next {
  right: 0;
}

.related-resources .slick-prev {
  left: 0;
}

@media print {
  .related-resources {
    max-width: 100%;
  }
  .related-resources .slick-slide {
    display: none !important;
  }
  .related-resources .slick-slide.slick-active {
    display: block !important;
  }
  .related-resources .slick-list {
    height: auto !important;
  }
  .related-resources .slick-track {
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }
  .related-resources .slick-track .slick-slide {
    width: auto !important;
  }
}

.resource {
  display: flex;
  background: #e7f1f8;
  padding-bottom: 40px;
  padding-left: 110px;
  padding-right: 110px;
  padding-top: 40px;
  align-items: center;
}

@media (max-width: 56.875em) {
  .resource {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media print {
  .resource {
    max-width: 100%;
    padding: 0;
  }
}

.resource__content-type {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 1px;
  margin-bottom: 16px;
  line-height: 1;
}

@media (max-width: 29.375em) {
  .resource__content-type {
    font-size: 18px;
  }
}

.resource__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 30px;
  margin-bottom: 20px;
}

@media (max-width: 29.375em) {
  .resource__title {
    font-size: 20px;
    line-height: 24px;
  }
}

.resource__title a {
  color: inherit;
}

.resource__author {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
}

@media (max-width: 29.375em) {
  .resource__author {
    font-size: 16px;
  }
}

.resource__author:after {
  content: ',';
}

.resource__author:last-of-type:after {
  content: '';
}

.resource__left {
  max-width: 50%;
  flex-shrink: 0;
}

.resource__left a {
  width: 100%;
  display: block;
}

.resource__left a img {
  max-width: 100%;
}

@media (max-width: 600px) {
  .resource__left {
    max-width: none;
  }
}

.resource__right {
  padding-left: 20px;
  padding-top: 10px;
}

.resource-search__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 45px;
}

.resource-search__description {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 1.6;
  margin-bottom: 75px;
}

.related-resource {
  border-bottom: 2px dashed #e6e7e8;
  padding-bottom: 30px;
  margin-top: 30px;
}

.related-resource__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 30px;
}

.related-resource__meta {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #737373;
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.related-resource__collections {
  display: flex;
  list-style-type: none;
  margin-bottom: 20px;
  margin-left: 0;
  flex-wrap: wrap;
}

.related-resource__collection {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  background: #e7f0f7;
  border-radius: 5px;
  color: #4a4a4a;
  padding: 8px 15px;
  font-size: 16px;
  margin-right: 20px;
  display: block;
}

.related-resource__summary {
  font-size: 16px;
}

.tabs {
  padding-bottom: 75px;
}

@media (min-width: 81.625em) {
  .tabs {
    padding-left: 75px;
    padding-right: 75px;
  }
}

.tabs__navigation {
  border-bottom: 1px solid #e6e7e8;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-left: 0;
}

.tabs__navigation li {
  align-items: flex-end;
  display: flex;
  margin-bottom: 0;
}

@media (max-width: 720px) {
  .tabs__navigation {
    flex-direction: column;
    line-height: 100%;
  }
}

.related-resource__ee-only {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  display: none;
  border-bottom: 1px solid #e6e7e8;
  letter-spacing: 1px;
  position: relative;
  text-transform: uppercase;
}

.is-page-editor .related-resource__ee-only {
  display: block;
}

.tabs__tab {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #4a4a4a;
  display: block;
  font-size: 20px;
  letter-spacing: 1px;
  padding: 25px 20px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.is-open .tabs__tab {
  display: block;
}

@media (max-width: 720px) {
  .tabs__tab {
    background: #fff;
    display: none;
    flex-basis: 100%;
    text-align: left;
    transition: background ease-in-out 0.3s;
  }
}

@media (max-width: 720px) {
  .tabs__tab.is-active, .tabs__tab:hover {
    background: #1970b1;
    color: #fff;
    display: block;
  }
}

@media (min-width: 721px) {
  .tabs__tab.is-active:before, .tabs__tab:hover:before {
    background: #6fb40f;
    bottom: 0;
    content: "";
    height: 10px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

@media (max-width: 720px) {
  .tabs .is-active:after {
    background-image: url("../../img/svg/chevron-down.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top -7px right 0;
    content: "";
    color: #fff;
    float: right;
    height: 15px;
    width: 34px;
  }
}

.tab__content {
  display: none;
}

.is-page-editor .tab__content {
  display: block;
}

.tab__content.is-visible {
  display: block;
}

@media print {
  div.tab__content {
    display: block;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 60px;
  }
  div.tab__content:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.tabs .scLooseFrameZone {
  display: block;
}

form[data-wffm] {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: #4a4a4a;
}

form[data-wffm] .page-header {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  color: #4a4a4a;
}

form[data-wffm] .page-header h1 {
  font-size: 20px;
}

form[data-wffm] legend {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  border-bottom: none;
}

form[data-wffm] input,
form[data-wffm] select {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  border: 2px solid #1970b1;
  border-radius: 0;
  color: #4a4a4a;
}

form[data-wffm] input[type=file],
form[data-wffm] input[type=submit] {
  background-color: #1970b1;
  color: #fff;
  padding: 7px 29px;
  border-radius: 0;
  text-transform: uppercase;
  border: 0;
  position: relative;
}

form[data-wffm] input[type=file]:after {
  content: "choose File";
  background-color: #1970b1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 7px 10px;
  width: 143px;
}

form[data-wffm] select {
  background-image: url("../../img/wffm/icon---selector.png");
  background-repeat: no-repeat;
  background-position: center right 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (max-width: 56.875em) {
  form[data-wffm] select {
    width: 100%;
  }
}

form[data-wffm] option {
  border-bottom: #1970b1 2px solid;
  position: relative;
  font-size: 18px;
}

form[data-wffm] option:last-child {
  border-bottom: 0;
}

form[data-wffm] option[selected=selected] {
  color: #1970b1;
}

form[data-wffm] option[selected=selected]:after {
  content: "";
  position: absolute;
  right: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #1970b1;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

form[data-wffm] .help-block {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}

form[data-wffm] label {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #4a4a4a;
  font-size: 18px;
}

form[data-wffm] .required-field .checkbox:after,
form[data-wffm] .required-field .control-label:before {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #4a4a4a;
  content: "*";
}

form[data-wffm] .required-field .radio,
form[data-wffm] .required-field .checkbox {
  padding-left: 20px;
}

form[data-wffm] .required-field .radio label,
form[data-wffm] .required-field .checkbox label {
  padding-left: 0;
  margin-right: 20px;
}

form[data-wffm] .js-wffm__radio {
  background-color: #fff;
  border: 2px solid #1970b1;
  cursor: pointer;
  height: 20px;
  width: 20px;
  display: inline-block;
  position: relative;
  border-radius: 50%;
  vertical-align: sub;
  margin-right: 5px;
}

form[data-wffm] .js-wffm__radio:after {
  border-radius: 50%;
  background: #1970b1;
  display: none;
  content: "";
  top: 3px;
  left: 3.5px;
  width: 9px;
  height: 9px;
  position: absolute;
}

form[data-wffm] .js-wffm__checkbox {
  background-color: #fff;
  border: 2px solid #1970b1;
  height: 20px;
  width: 20px;
  display: inline-block;
  position: relative;
  vertical-align: sub;
  cursor: pointer;
  margin-right: 5px;
}

form[data-wffm] .js-wffm__checkbox:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

form[data-wffm] input[type=checkbox],
form[data-wffm] input[type=radio] {
  height: 24px;
  width: 24px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

form[data-wffm] input[type=checkbox]:checked ~ .js-wffm__checkbox {
  background-color: #1970b1;
}

form[data-wffm] input[type=checkbox]:checked ~ .js-wffm__checkbox:after,
form[data-wffm] input[type=radio]:checked ~ .js-wffm__radio:after {
  display: block;
}

form[data-wffm] .col-md-12 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

form[data-wffm] .form-group {
  margin-bottom: 61px;
  width: 100%;
}

form[data-wffm] .thirdAvailableWidth {
  width: 32%;
}

form[data-wffm] td,
form[data-wffm] th {
  border: 0;
}

form[data-wffm] tr:not(:last-child) {
  margin-bottom: 25px;
}

.agenda .agenda__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 36px;
  text-align: center;
}

.agenda .agenda__navigation {
  justify-content: space-between;
  margin-top: 86px;
  flex-wrap: nowrap;
}

.agenda .agenda__navigation .agenda__tab {
  display: block;
  padding: 25px 20px;
  position: relative;
}

.agenda .agenda__navigation .agenda__tab:hover {
  cursor: pointer;
}

@media (max-width: 720px) {
  .agenda .agenda__navigation .agenda__tab.is-active, .agenda .agenda__navigation .agenda__tab:hover {
    background: #1970b1;
    color: #fff;
    display: block;
  }
}

@media (min-width: 721px) {
  .agenda .agenda__navigation .agenda__tab.is-active:before, .agenda .agenda__navigation .agenda__tab:hover:before {
    background: #6fb40f;
    bottom: 0;
    content: "";
    height: 10px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.agenda .agenda__navigation .agenda__tab .agenda__tab__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #4a4a4a;
  display: block;
  font-size: 20px;
  letter-spacing: 1px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.agenda .agenda__navigation .agenda__tab .agenda__tab__end-date,
.agenda .agenda__navigation .agenda__tab .agenda__tab__start-date {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #4a4a4a;
  display: inline;
  font-size: 16px;
  letter-spacing: 1px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.agenda .agenda__navigation .agenda__tab.is-open {
  display: block;
}

@media print {
  .agenda .agenda__navigation {
    flex-direction: row;
  }
}

@media (max-width: 720px) {
  .agenda .is-active .agenda__tab__title,
  .agenda .is-active .agenda__tab__end-date,
  .agenda .is-active .agenda__tab__start-date,
  .agenda .agenda__tab:hover .agenda__tab__start-date,
  .agenda .agenda__tab:hover .agenda__tab__end-date .agenda__tab:hover .agenda__tab__title {
    color: #fff !important;
  }
}

.agenda .agenda__session__start + .agenda__session__end:before {
  content: " - ";
  display: inline;
}

.agenda .agenda__session__meta + .agenda__session__body {
  margin-left: 67px;
  width: calc(100% - 167px - 67px);
}

@media (max-width: 720px) {
  .agenda .agenda__session__meta + .agenda__session__body {
    margin-left: 0 !important;
    width: 100% !important;
  }
}

.agenda .agenda__session {
  margin-top: 81px;
  display: flex;
}

@media (max-width: 720px) {
  .agenda .agenda__session {
    flex-direction: column;
  }
}

.agenda .agenda__session .agenda__session__room {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  display: block;
  font-size: 18px;
}

.agenda .agenda__session .agenda__session__meta {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  width: 160px;
  text-align: right;
}

@media (max-width: 720px) {
  .agenda .agenda__session .agenda__session__meta {
    width: 100% !important;
    text-align: left !important;
  }
}

.agenda .agenda__session .agenda__session__meta .agenda__session__time {
  width: inherit;
  font-size: 24px;
  margin-bottom: 12px;
}

.agenda .agenda__session .agenda__session__meta .agenda__session__time span {
  display: inline;
}

.agenda .agenda__session .agenda__session__body h4 {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
  margin-bottom: 12px;
}

.agenda .agenda__session .agenda__session__body p {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.agenda .agenda__session .agenda__session__body .agenda__session__room {
  margin-bottom: 12px;
}

@media print {
  .agenda .agenda__navigation .agenda__tab.is-active {
    background: transparent;
    color: #4a4a4a;
  }
  .agenda .agenda__navigation .agenda__tab.is-active .agenda__tab__start-date,
  .agenda .agenda__navigation .agenda__tab.is-active .agenda__tab__title {
    color: #4a4a4a !important;
  }
  .agenda .agenda__tab.is-active:before {
    display: none;
  }
}

.addthis_inline_share_toolbox {
  clear: both;
  overflow: hidden;
}

.addthis_inline_share_toolbox .at-resp-share-element {
  float: right;
}

@media (max-width: 56.875em) {
  .container--30-70 .container__col:last-child .addthis_inline_share_toolbox {
    margin-top: 5px;
  }
}

.promo-cta-grid {
  margin-bottom: 30px;
  overflow: hidden;
  text-align: center;
}

.promo-cta-grid__list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

@media (min-width: 29.4375em) {
  .promo-cta-grid__list {
    display: flex;
    flex-wrap: wrap;
    margin: -30px;
  }
}

.promo-cta-grid__item {
  margin-bottom: 30px;
  /* This is hacky, but about the only way to make this work... */
}

@media (min-width: 29.4375em) {
  .promo-cta-grid__item {
    margin: 30px;
    width: calc(50% - (2 * 30px));
  }
}

@media (min-width: 56.9375em) {
  .promo-cta-grid__item {
    width: calc((100% / 3) - (2 * 30px));
  }
}

@media (min-width: 80.625em) {
  .container--100 > .container__inner > .promo-cta-grid .promo-cta-grid__item {
    width: calc(25% - (2 * 30px));
  }
}

.promo-cta-grid__item-title {
  margin-bottom: 10px;
}

.promo-cta-grid__image {
  display: block;
  margin-bottom: 18px;
  width: 100%;
}

.promo-cta-grid__image picture,
.promo-cta-grid__image img {
  height: auto;
  max-width: 100%;
}

.promo-cta-grid__desc {
  text-align: left;
}

.ad-container {
  overflow: hidden;
}

.ad-container .broadstreet-label-container {
  padding-bottom: 5px;
}

.ad-container--medium-rectangle {
  max-width: 300px;
  max-height: 275px;
}

.ad-container--large-rectangle {
  max-width: 300px;
  max-height: 625px;
}

.ad-container--medium-leaderboard {
  max-width: 728px;
  max-height: 115px;
  display: none;
  margin: auto;
}

@media (min-width: 29.4375em) {
  .ad-container--medium-leaderboard {
    display: block;
  }
}

.ad-container--large-square {
  max-width: 800px;
  max-height: 625px;
  display: none;
}

@media (min-width: 56.9375em) {
  .ad-container--large-square {
    display: block;
  }
}

.ad-container--large-leaderboard {
  max-width: 970px;
  max-height: 275px;
  display: none;
  margin: auto;
}

@media (min-width: 56.9375em) {
  .ad-container--large-leaderboard {
    display: block;
  }
}

.separating-rule {
  border: 0;
  border-top: 5px solid #e6e7e8;
  margin: 80px auto;
  max-width: 1290px;
}

@media (max-width: 29.375em) {
  .separating-rule {
    margin: 55px auto;
    width: calc(100% - 60px);
  }
}

.promo-cards .promo-cards__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.promo-cards .promo-cards__wrapper .promo-cards__col {
  width: 33.3333%;
  padding-left: 15px;
  padding-right: 15px;
}

.promo-cards .promo-cards__wrapper .promo-cards__col:last-child {
  padding-left: 30px;
  padding-right: 0;
}

.promo-cards .promo-cards__wrapper .promo-cards__col:first-child {
  padding-left: 0px;
  padding-right: 30px;
}

@media (max-width: 56.875em) {
  .promo-cards .promo-cards__wrapper .promo-cards__col {
    width: 50%;
    margin: 0 auto;
    padding-right: 0;
  }
  .promo-cards .promo-cards__wrapper .promo-cards__col:first-child {
    padding-right: 15px;
  }
  .promo-cards .promo-cards__wrapper .promo-cards__col:last-child .promo-cards__card {
    margin-bottom: 0;
  }
}

.promo-cards.is-overlap {
  margin-top: -105px;
}

@media (max-width: 56.875em) {
  .promo-cards.is-overlap {
    margin-top: 0;
  }
}

.promo-cards__card {
  background: #1970b1;
  color: #fff;
  height: 100%;
  display: block;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media (max-width: 56.875em) {
  .promo-cards__card {
    min-height: 80px;
    display: flex;
    height: auto;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto 15px;
  }
}

@media (min-width: 56.9375em) {
  .promo-cards__card > *:nth-last-child(2) {
    padding-bottom: 30px;
  }
}

.promo-cards__card .promo-cards__card-title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: 0;
  padding: 0;
  font-size: 50px;
  line-height: 1.3;
  text-transform: uppercase;
  text-align: center;
  padding: 30px 0 0;
}

@media (max-width: 56.875em) {
  .promo-cards__card .promo-cards__card-title {
    font-size: 35px;
    line-height: 1.143;
    padding: 0;
  }
}

.promo-cards__card .promo-cards__card-description {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  line-height: 1.455;
  text-align: center;
  width: calc(100% - 50px);
  margin: 0 auto;
}

@media (max-width: 1199px) {
  .promo-cards__card .promo-cards__card-description {
    font-size: 19px;
  }
}

@media (max-width: 56.875em) {
  .promo-cards__card .promo-cards__card-description {
    display: none;
  }
}

.promo-cards__card .promo-cards__card-cta {
  background: #115c91;
  padding: 16px 32px;
  width: 100%;
  margin-top: auto;
}

.promo-cards__card .promo-cards__card-cta .btn--link {
  color: #fff;
  padding-top: 0;
  padding-bottom: 0;
}

.promo-cards__card .promo-cards__card-cta .btn--link::after {
  color: #fff;
}

@media (max-width: 1199px) {
  .promo-cards__card .promo-cards__card-cta .btn--link {
    font-size: 16px;
  }
}

@media (max-width: 56.875em) {
  .promo-cards__card .promo-cards__card-cta {
    display: none;
  }
}

.promo-cards__card:hover {
  color: #fff;
}

.promo-cards__card:hover .promo-cards__card-cta {
  background: #4a90e2;
}

@media (max-width: 56.875em) {
  .promo-cards__card:hover {
    background: #4a90e2;
  }
}

.sitemap {
  background: #e7f0f7;
  padding: 80px 0;
}

.sitemap .container {
  margin-bottom: 0;
}

.sitemap .sitemap__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}

@media (max-width: 56.875em) {
  .sitemap .sitemap__wrapper {
    display: flex;
    flex-direction: column;
  }
}

.sitemap__item {
  background: #fff;
  padding: 40px 32px 40px 48px;
}

.sitemap__item .sitemap__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: 0;
  padding: 0;
  color: #1970b1;
  font-size: 30px;
  line-height: 1.5;
  margin-bottom: 8px;
  position: relative;
}

.sitemap__item .sitemap__title:before {
  background: #f6ae2c;
  content: " ";
  height: 45px;
  left: -48px;
  position: absolute;
  top: 0;
  width: 5px;
}

.sitemap__item .sitemap__links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sitemap__item .sitemap__links li {
  margin: 0;
  padding: 0;
}

.sitemap__item .sitemap__links li + li {
  margin-top: 16px;
}

.sitemap__item .sitemap__links li a {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  font-size: 22px;
  line-height: 1.455;
  text-decoration: underline;
}

.sitemap__item .sitemap__links li a:hover {
  text-decoration: none;
}

.promo-slideshow .slick-slide:not(.slick-current) {
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease;
}

.promo-slideshow .slick-dots {
  position: static;
  margin-top: 24px;
}

.promo-slideshow .slick-dots li {
  margin: 0 8px;
}

.promo-slideshow .slick-dots li button::before {
  content: "";
  display: none;
}

.promo-slideshow .slick-dots li:focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #bdbdbd, 0 0 0 6px #5b9dd9;
}

.promo-slideshow .slick-dots button,
.promo-slideshow .slick-dots li {
  width: 45px;
  height: 5px;
}

.promo-slideshow .slick-dots button {
  margin: 0 auto;
  padding: 4px;
  background-color: #bdbdbd;
  transition: background-color 0.3s;
}

.promo-slideshow .slick-dots .slick-active button {
  background-color: #1970b1;
}

@media (min-width: 56.9375em) and (max-width: 80.625em) {
  .promo-slideshow {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media (max-width: 56.875em) {
  .promo-slideshow .container__col {
    margin-bottom: 0;
  }
  .promo-slideshow .separating-rule {
    display: none;
  }
}

.promo-item__media .video-block {
  margin-bottom: 0;
}

.promo-item__media .promo-item__image {
  /*position: relative;*/
}

.promo-item__media .promo-item__image img {
  /*position: absolute;
      top: 0;
      right: 0;*/
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (max-width: 56.875em) {
  .promo-item__media {
    margin-left: -30px;
    margin-right: -30px;
  }
}

.promo-item__details .promo-item__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  margin: 0;
  padding: 0;
  font-size: 45px;
  line-height: 1.2445;
  margin-bottom: 15px;
  margin-top: 46px;
  text-transform: capitalize;
}

@media (max-width: 56.875em) {
  .promo-item__details .promo-item__title {
    font-size: 35px;
    line-height: 1.143;
    margin-top: 36px;
  }
}

.promo-item__details .promo-item__description {
  line-height: 1.7778;
  margin-bottom: 18px;
}

.promo-item__details .promo-item__cta {
  font-size: 16px;
  line-height: 1.375;
  margin-right: 22px;
}

.promo-item__details .promo-item__link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 1.112;
}

@media (max-width: 56.875em) {
  .promo-item__details .promo-item__link {
    padding-top: 20px;
    padding-right: 0;
    letter-spacing: 0;
  }
}

.homepage-hero__promos {
  max-width: 1290px;
  margin-left: auto;
  margin-right: auto;
}

.homepage-hero__promos::after {
  clear: both;
  content: "";
  display: block;
}

@media (max-width: 80.625em) {
  .homepage-hero__promos {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.homepage-hero__promos--top {
  margin-bottom: 48px;
}

@media (min-width: 56.9375em) {
  .homepage-hero__promos--top {
    display: none;
  }
}

@media (max-width: 56.875em) {
  .homepage-hero__promos--bottom {
    display: none;
  }
}

.homepage-hero__hero {
  margin-bottom: 60px;
  /*&.is-overlap .full-hero__details {
    @media (min-width: $bp-min-large) {
      padding-top: $container-bottom-space * 2;
      padding-bottom: $container-bottom-space * 2;
    }
  }*/
}

.homepage-hero__hero .container {
  margin-bottom: 0;
}

.highlight-content-duo {
  max-width: 856px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .highlight-content-duo {
    margin-bottom: 40px;
  }
}

.highlight-content-duo__header {
  margin-bottom: 30px;
}

.highlight-content-duo__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .highlight-content-duo__header-wrapper {
    flex-direction: column;
    align-items: normal;
    margin-bottom: 16px;
  }
}

.highlight-content-duo__header-title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 8px;
}

.highlight-content-duo__header-link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #1970b1;
  cursor: pointer;
}

.highlight-content-duo__header-link:after {
  content: "\2192";
  padding-left: 8px;
  color: #4a4a4a;
}

.highlight-content-duo__header-content {
  font-size: 18px;
  line-height: 30px;
}

.highlight-content-duo__items-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}

@media (max-width: 767px) {
  .highlight-content-duo__items-wrapper {
    grid-template-columns: 1fr;
  }
}

.highlight-content-duo__image {
  width: 100%;
  height: auto;
  margin-bottom: 38px;
}

.highlight-content-duo__image img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

@media (max-width: 767px) {
  .highlight-content-duo__image {
    margin-bottom: 16px;
  }
}

.highlight-content-duo__title {
  font-size: 25px;
  line-height: 29px;
  font-weight: 600;
  color: #1970b1;
  margin-bottom: 15px;
}

.highlight-content-duo__description {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  display: block;
  margin-bottom: 15px;
}

.highlight-content-duo__button {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  border: 2px #0f6eb4 solid;
  font-size: 16px;
  padding: 10px 18px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  letter-spacing: 1px;
  background-color: #0f6eb4;
  color: #e7f0f7;
  display: block;
  width: fit-content;
}

.highlight-content-duo__button:hover {
  color: #e7f0f7;
}

.single-events {
  max-width: 850px;
  margin: 0 auto;
  margin-bottom: 70px;
}

.single-events__header {
  margin-bottom: 30px;
}

.single-events__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .single-events__header-wrapper {
    flex-direction: column;
    align-items: normal;
    margin-bottom: 16px;
  }
}

.single-events__header-title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 0;
}

.single-events__header-link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #1970b1;
  cursor: pointer;
}

.single-events__header-link:after {
  content: "\2192";
  padding-left: 8px;
  color: #4a4a4a;
}

.single-events__header-content {
  font-size: 18px;
  line-height: 30px;
}

.single-events__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

@media (max-width: 767px) {
  .single-events__wrapper {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.single-event {
  height: 327px;
  display: flex;
  border: 1px solid #d9d9d9;
}

.single-event__event-content {
  padding: 38px 66px 38px 38px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 767px) {
  .single-event__event-content {
    padding: 20px 32px 32px 30px;
  }
}

.single-event__event-type {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.08em;
  display: block;
}

.single-event__event-title {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  color: #1970b1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

@media (max-width: 767px) {
  .single-event__event-title {
    -webkit-line-clamp: 4;
  }
}

.single-event__event-date {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  display: block;
  margin-bottom: 15px;
}

.single-event__event-footer {
  display: flex;
}

.single-event__event-footer-side {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

.single-event__event-tag {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  opacity: 0.8;
}

.single-event__event-value {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

.seminar-line-up {
  max-width: 850px;
  margin: 0 auto;
  margin-bottom: 70px;
}

.seminar-line-up__header {
  margin-bottom: 30px;
}

.seminar-line-up__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .seminar-line-up__header-wrapper {
    flex-direction: column;
    align-items: normal;
    margin-bottom: 16px;
  }
}

.seminar-line-up__header-title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 0;
}

.seminar-line-up__header-link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #1970b1;
  cursor: pointer;
}

.seminar-line-up__header-link:after {
  content: "\2192";
  padding-left: 8px;
  color: #4a4a4a;
}

.seminar-line-up__header-content {
  font-size: 18px;
  line-height: 28px;
}

.seminar-line-up__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 28px 40px;
}

@media (max-width: 767px) {
  .seminar-line-up__wrapper {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.seminar-line-up__button {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 500;
  border: 2px #0f6eb4 solid;
  font-size: 16px;
  display: block;
  max-width: 310px;
  padding: 10px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  letter-spacing: 1px;
  background-color: #1970b1;
  color: #fff;
}

.seminar-line-up__button:hover {
  color: #fff;
}

.single-event {
  height: 327px;
  display: flex;
  border: 1px solid #d9d9d9;
}

.single-event__event-content {
  padding: 38px 46px 38px 38px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 767px) {
  .single-event__event-content {
    padding: 20px 32px 32px 30px;
  }
}

.single-event__event-type {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.08em;
  display: block;
}

.single-event__event-title {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  color: #1970b1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

@media (max-width: 767px) {
  .single-event__event-title {
    -webkit-line-clamp: 4;
  }
}

.single-event__event-date {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.single-event__start-date {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  font-weight: 500;
}

.single-event__end-date {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  font-weight: 500;
}

.single-event__event-footer {
  display: flex;
}

.single-event__event-footer-side {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

.single-event__event-tag {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  opacity: 0.8;
}

.single-event__event-value {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

.seminar-line-up-component {
  max-width: 850px;
  margin: 0 auto;
  margin-bottom: 70px;
}

.seminar-line-up-component__header {
  margin-bottom: 30px;
}

.seminar-line-up-component__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .seminar-line-up-component__header-wrapper {
    flex-direction: column;
    align-items: normal;
    margin-bottom: 16px;
  }
}

.seminar-line-up-component__header-title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 0;
}

.seminar-line-up-component__header-link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #1970b1;
  cursor: pointer;
}

.seminar-line-up-component__header-link:after {
  content: "\2192";
  padding-left: 8px;
  color: #4a4a4a;
}

.seminar-line-up-component__header-content {
  font-size: 18px;
  line-height: 28px;
}

.seminar-line-up-component__filters {
  margin-bottom: 5px;
}

.seminar-line-up-component__filter {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #4a4a4a;
  text-transform: uppercase;
}

.seminar-line-up-component__filter:first-child {
  margin-right: 26px;
}

.seminar-line-up-component__filter.active {
  color: #1970b1;
  padding-bottom: 10px;
  border-bottom: 3px solid #1970b1;
}

.seminar-line-up-component__date {
  font-size: 25px;
  line-height: 45px;
  margin-bottom: 16px;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .seminar-line-up-component__date {
    font-size: 18px;
  }
}

.seminar-line-up-component__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 28px 40px;
}

@media (max-width: 767px) {
  .seminar-line-up-component__wrapper {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.single-event {
  height: 327px;
  display: flex;
  border: 1px solid #d9d9d9;
}

.single-event__event-content {
  padding: 38px 46px 38px 38px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 767px) {
  .single-event__event-content {
    padding: 20px 32px 32px 30px;
  }
}

.single-event__event-type {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.08em;
  display: block;
}

.single-event__event-title {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  color: #1970b1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

@media (max-width: 767px) {
  .single-event__event-title {
    -webkit-line-clamp: 4;
  }
}

.single-event__event-date {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.single-event__start-date {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  font-weight: 500;
}

.single-event__end-date {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  font-weight: 500;
}

.single-event__event-footer {
  display: flex;
}

.single-event__event-footer-side {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

.single-event__event-tag {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  opacity: 0.8;
}

.single-event__event-value {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

.featured-events {
  width: 100%;
  max-width: 849px;
  margin: 0 auto;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .featured-events {
    margin-bottom: 40px;
  }
}

.featured-events__header {
  margin-bottom: 30px;
}

.featured-events__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .featured-events__header-wrapper {
    flex-direction: column;
    align-items: normal;
    margin-bottom: 16px;
  }
}

.featured-events__header-title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 8px;
}

.featured-events__header-link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #1970b1;
  cursor: pointer;
}

.featured-events__header-link:after {
  content: "\2192";
  padding-left: 8px;
  color: #4a4a4a;
}

.featured-events__header-content {
  font-size: 18px;
  line-height: 30px;
}

.featured-events__row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}

@media (max-width: 767px) {
  .featured-events__row {
    grid-template-columns: 1fr;
    grid-gap: 35px;
  }
}

.featured-events__event {
  height: 327px;
  display: flex;
  border: 1px solid #d9d9d9;
}

.featured-events__event-content {
  padding: 38px 46px 38px 38px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
}

@media (max-width: 767px) {
  .featured-events__event-content {
    padding: 20px 32px 32px 30px;
  }
}

.featured-events__event-type {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.08em;
  display: block;
}

.featured-events__event-title {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  color: #1970b1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  padding-right: 16px;
}

@media (max-width: 767px) {
  .featured-events__event-title {
    -webkit-line-clamp: 4;
  }
}

.featured-events__event-date {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.featured-events__start-date {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  font-weight: 500;
}

.featured-events__end-date {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  font-weight: 500;
}

.featured-events__event-footer {
  display: flex;
}

.featured-events__event-footer-side {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

.featured-events__event-tag {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  font-size: 16px;
  line-height: 22px;
  opacity: 0.8;
}

.featured-events__event-value {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

.featured-events__event-button {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 10px 18px;
  color: #fff;
  background-color: #1970b1;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.featured-events__event-button:hover {
  color: white;
}

.featured-events__event--cluster {
  background-color: #1970b1;
  color: #fff;
  position: relative;
}

.featured-events__event--cluster:after {
  content: "";
  height: 52px;
  width: 6px;
  background-color: #f4ad3d;
  position: absolute;
  top: 42px;
  left: 0;
  z-index: 8;
}

.featured-events__event--cluster .featured-events__event-bg {
  background-image: url("/assets/img/exit-links/pattern.png");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.featured-events__event--cluster .featured-events__event-type {
  color: #fff;
}

.featured-events__event--cluster .featured-events__event-title {
  color: #fff;
  -webkit-line-clamp: 2;
}

.featured-events__event--cluster .featured-events__start-date {
  color: #fff;
}

.featured-events__event--cluster .featured-events__end-date {
  color: #fff;
}

.featured-events__event--cluster .featured-events__event-description {
  display: block;
  margin-top: 15px;
  font-size: 18px;
  line-height: 24.5px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.featured-events__event--cluster .featured-events__event-button {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 10px 18px;
  color: #1970b1;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.featured-events__event--cluster .featured-events__event-button:hover {
  color: #1970b1;
}

.featured-events__special-event {
  background-color: #1970b1;
  height: 327px;
  display: flex;
  margin-bottom: 35px;
}

@media (max-width: 767px) {
  .featured-events__special-event {
    flex-direction: column;
    height: auto;
  }
}

.featured-events__special-event--purple {
  background-color: #2a1853;
}

.featured-events__special-event-image {
  flex-basis: 49%;
}

.featured-events__special-event-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 767px) {
  .featured-events__special-event-image img {
    height: 300px;
  }
}

@media (max-width: 510px) {
  .featured-events__special-event-image img {
    height: 147px;
  }
}

.featured-events__special-event-content {
  padding: 38px 82px 38px 32px;
  color: #fff;
  flex-basis: 51%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 767px) {
  .featured-events__special-event-content {
    padding: 30px;
  }
}

.featured-events__special-event-type {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.08em;
  display: block;
}

.featured-events__special-event-title {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  padding-right: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

@media (max-width: 767px) {
  .featured-events__special-event-title {
    padding-right: 0;
    -webkit-line-clamp: 4;
  }
}

.featured-events__special-event-date {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  display: block;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
}

.featured-events__special-event-date .featured-events__start-date,
.featured-events__special-event-date .featured-events__end-date {
  color: #fff;
}

.featured-events__special-event-footer {
  display: flex;
}

.featured-events__special-event-button {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 10px 18px;
  color: #1970b1;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.featured-events__special-event-button:hover {
  color: #1970b1;
}

.single-books {
  max-width: 850px;
  margin: 0 auto;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .single-books {
    margin-bottom: 40px;
  }
}

.single-books__header {
  margin-bottom: 30px;
}

.single-books__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .single-books__header-wrapper {
    flex-direction: column;
    align-items: normal;
    margin-bottom: 16px;
  }
}

.single-books__header-title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 0;
}

.single-books__header-link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #1970b1;
  cursor: pointer;
}

.single-books__header-link:after {
  content: "\2192";
  padding-left: 8px;
  color: #4a4a4a;
}

.single-books__header-content {
  font-size: 18px;
  line-height: 30px;
}

.single-books__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

@media (max-width: 767px) {
  .single-books__wrapper {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.single-book {
  padding: 40px 40px 30px 40px;
  max-width: 405px;
  border: 1px solid #d9d9d9;
}

@media (max-width: 767px) {
  .single-book {
    padding: 30px;
    max-width: 100%;
  }
}

.single-book__image {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 36px;
}

.single-book__image img {
  width: auto;
  height: 172px;
}

@media (max-width: 767px) {
  .single-book__image img {
    width: auto;
    height: 134px;
  }
}

.single-book__header {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .single-book__header {
    margin-bottom: 48px;
  }
}

.single-book__collection {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  display: block;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
  min-height: 20px;
}

.single-book__title {
  font-size: 18px;
  line-height: 25px;
  height: 75px;
  font-weight: 500;
  color: #1970b1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

@media (max-width: 767px) {
  .single-book__title {
    height: auto;
  }
}

.single-book__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.single-book__footer-side {
  display: flex;
  flex-direction: column;
}

.single-book__tag {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: #767676;
}

.single-book__tag-value {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #4a4a4a;
}

.single-book__footer-button {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  border: 2px #0f6eb4 solid;
  font-size: 16px;
  display: block;
  max-width: 310px;
  padding: 10px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  letter-spacing: 1px;
  background-color: #0f6eb4;
  color: #e7f0f7;
}

.single-book__footer-button:hover {
  color: #e7f0f7;
}

.author-spotlight {
  max-width: 850px;
  margin: 0 auto;
  margin-bottom: 60px;
}

.author-spotlight__title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 32px;
}

.author-spotlight__author-wrapper {
  display: flex;
  margin-bottom: 32px;
}

@media (max-width: 767px) {
  .author-spotlight__author-wrapper {
    display: block;
  }
}

.author-spotlight__author-image {
  margin-right: 32px;
}

.author-spotlight__author-image img {
  height: 102px;
  width: 73px;
  object-fit: cover;
}

@media (max-width: 767px) {
  .author-spotlight__author-image {
    text-align: center;
    margin-bottom: 16px;
  }
}

.author-spotlight__author-title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: #1970b1;
  display: block;
  margin-bottom: 8px;
}

.author-spotlight__author-description {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #4a4a4a;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
  /*safari*/
}

@supports (-webkit-backdrop-filter: blur(1px)) {
  .author-spotlight__author-description {
    -webkit-box-orient: horizontal !important;
    margin-bottom: 8px !important;
  }
}

.author-spotlight__author-link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  font-weight: 600;
}

.author-spotlight__button {
  width: 100%;
  display: flex;
  justify-content: center;
}

.author-spotlight__button a {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
  text-transform: uppercase;
  color: #1970b1;
  font-size: 18px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  margin-top: 32px;
}

.author-spotlight__button a:after {
  background-image: url("/assets/img/plus-icon.png");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  color: black;
  height: 16px;
  width: 16px;
  margin-left: 10px;
}

@media (max-width: 767px) {
  .author-spotlight__button a {
    font-size: 14px;
  }
}

.single-books {
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 70px;
}

.single-books__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

@media (max-width: 767px) {
  .single-books__wrapper {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.single-books__wrapper.showMoreBooks {
  margin-top: 40px;
}

@media (max-width: 767px) {
  .single-books__wrapper.showMoreBooks {
    margin-top: 20px;
  }
}

.single-book {
  padding: 40px 40px 30px 40px;
  max-width: 405px;
  border: 1px solid #d9d9d9;
}

@media (max-width: 767px) {
  .single-book {
    padding: 30px;
    max-width: 100%;
  }
}

.single-book__image {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 36px;
}

.single-book__image img {
  width: auto;
  height: 172px;
}

@media (max-width: 767px) {
  .single-book__image img {
    width: auto;
    height: 134px;
  }
}

.single-book__header {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .single-book__header {
    margin-bottom: 48px;
  }
}

.single-book__collection {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  display: block;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.single-book__title {
  font-size: 18px;
  line-height: 25px;
  height: 75px;
  font-weight: 500;
  color: #1970b1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

@media (max-width: 767px) {
  .single-book__title {
    height: auto;
  }
}

.single-book__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.single-book__footer-side {
  display: flex;
  flex-direction: column;
}

.single-book__tag {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: #767676;
}

.single-book__tag-value {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #4a4a4a;
}

.single-book__footer-button {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  border: 2px #0f6eb4 solid;
  font-size: 16px;
  display: block;
  max-width: 310px;
  padding: 10px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  letter-spacing: 1px;
  background-color: #0f6eb4;
  color: #e7f0f7;
}

.single-book__footer-button:hover {
  color: #e7f0f7;
}

.highlight-book-topics {
  max-width: 856px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .highlight-book-topics {
    margin-bottom: 40px;
  }
}

.highlight-book-topics__header {
  margin-bottom: 30px;
}

.highlight-book-topics__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .highlight-book-topics__header-wrapper {
    flex-direction: column;
    align-items: normal;
    margin-bottom: 16px;
  }
}

.highlight-book-topics__header-title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 8px;
}

.highlight-book-topics__header-link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #1970b1;
  cursor: pointer;
}

.highlight-book-topics__header-link:after {
  content: "\2192";
  padding-left: 8px;
  color: #4a4a4a;
}

.highlight-book-topics__header-content {
  font-size: 18px;
  line-height: 30px;
}

.highlight-book-topics__items-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
}

@media (max-width: 767px) {
  .highlight-book-topics__items-wrapper {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}

.highlight-book-topics__item {
  background-color: #e6f1f7;
  height: 137px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.highlight-book-topics__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
  color: #044a7e;
  line-height: 29px;
  font-weight: 500;
  width: 69%;
  padding-left: 24px;
  padding-right: 8px;
}

@media (max-width: 767px) {
  .highlight-book-topics__title {
    font-size: 18px;
  }
}

.highlight-book-topics__image {
  height: 100%;
  width: 80px;
  object-fit: cover;
}

.book-teaser {
  max-width: 856px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .book-teaser {
    margin-bottom: 40px;
  }
}

.book-teaser__header {
  margin-bottom: 30px;
}

.book-teaser__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .book-teaser__header-wrapper {
    flex-direction: column;
    align-items: normal;
    margin-bottom: 16px;
  }
}

.book-teaser__header-title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 8px;
}

.book-teaser__header-link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #1970b1;
  cursor: pointer;
}

.book-teaser__header-link:after {
  content: "\2192";
  padding-left: 8px;
  color: #4a4a4a;
}

.book-teaser__header-content {
  font-size: 18px;
  line-height: 30px;
}

.book-teaser__item {
  width: 100%;
  height: 287px;
  display: grid;
  grid-template-columns: 0.97fr 1.03fr;
}

@media (max-width: 767px) {
  .book-teaser__item {
    grid-template: none;
    height: auto;
  }
}

.book-teaser__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 767px) {
  .book-teaser__image {
    height: 147px;
  }
}

.book-teaser__content {
  padding: 40px 34px;
  color: #fff;
  background-color: #1970b1;
}

@media (max-width: 767px) {
  .book-teaser__content {
    padding: 28px 30px 38px 30px;
  }
}

.book-teaser__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
  line-height: 29px;
  font-weight: 500;
  margin-bottom: 15px;
  display: block;
}

@media (max-width: 767px) {
  .book-teaser__title {
    font-size: 20px;
    line-height: 23px;
  }
}

.book-teaser__button {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  padding: 10px 18px;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #f4ad3d;
  display: inline-block;
}

.at-a-glance-sidebar {
  max-width: 341px;
  width: 100%;
  padding: 40px 30px 35px 30px;
  background-color: #f3f6fb;
  border-top: 5px solid #1970b1;
  margin-bottom: 38px;
}

.at-a-glance-sidebar__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  display: block;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
}

.at-a-glance-sidebar__text-bottom {
  padding-bottom: 18px;
  border-bottom: 1px solid rgba(25, 112, 177, 0.51);
  margin-bottom: 24px;
}

.at-a-glance-sidebar__subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #767676;
  line-height: 22px;
  display: block;
  margin-top: 22px;
}

.at-a-glance-sidebar__tag {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

.at-a-glance-sidebar__value {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #1970b1;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

.at-a-glance-sidebar__icons-details {
  margin-bottom: 20px;
}

.at-a-glance-sidebar__details {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.at-a-glance-sidebar__details.date-image {
  align-items: baseline;
}

.at-a-glance-sidebar__details-value {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin-left: 8px;
  padding-right: 56px;
}

.at-a-glance-sidebar__buttons a {
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding: 10px 20px;
  margin-bottom: 10px;
  max-width: 100%;
}

.at-a-glance-sidebar__buttons a:last-child {
  margin-bottom: 0;
}

.at-a-glance-sidebar__buttons a:after {
  display: none;
}

.at-a-glance-sidebar__buttons a.headline__link {
  border: 1px solid #1970b1;
}

.at-a-glance-sidebar__buttons a.disabled {
  background-color: #4A4A4A;
  opacity: 0.5;
}

.at-a-glance-sidebar__buttons a.disabled:hover {
  color: white;
  cursor: not-allowed;
}

.at-a-glance-content {
  max-width: 834px;
  width: 100%;
  padding: 22px 30px 22px 38px;
  background-color: #f3f6fb;
  border-top: 5px solid #1970b1;
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .at-a-glance-content {
    margin-bottom: 40px;
    padding: 24px 16px;
  }
}

.at-a-glance-content__row {
  display: flex;
  gap: 12px;
}

@media (max-width: 768px) {
  .at-a-glance-content__row {
    display: block;
  }
}

.at-a-glance-content__row--second {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .at-a-glance-content__row--second {
    margin-top: 0;
  }
}

.at-a-glance-content__row--second .at-a-glance-content__item {
  width: 25%;
}

@media (max-width: 768px) {
  .at-a-glance-content__row--second .at-a-glance-content__item {
    width: 100%;
  }
}

.at-a-glance-content__item {
  width: 50%;
}

@media (max-width: 768px) {
  .at-a-glance-content__item {
    width: 100%;
    margin-bottom: 10px;
  }
  .at-a-glance-content__item:last-child {
    margin-bottom: 0;
  }
}

.at-a-glance-content__subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #767676;
  line-height: 22px;
  display: block;
}

.at-a-glance-content__content {
  display: flex;
}

@media (max-width: 768px) {
  .at-a-glance-content__content {
    display: block;
  }
}

.at-a-glance-content__text {
  display: flex;
  align-items: center;
}

.at-a-glance-content__text:first-child {
  margin-right: 16px;
}

.at-a-glance-content__text img {
  margin-right: 10px;
}

.at-a-glance-content__tag {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

.at-a-glance-content__value {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #1970b1;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin-left: 2px;
}

.at-a-glance-content__value:first-child {
  margin-right: 12px;
}

.at-a-glance-content__icons-details {
  margin-bottom: 20px;
}

.at-a-glance-content__details {
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
}

.at-a-glance-content__details-value {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin-left: 8px;
  padding-right: 56px;
}

.events-three-grid {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;
}

.events-three-grid__header {
  margin-bottom: 30px;
}

.events-three-grid__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .events-three-grid__header-wrapper {
    flex-direction: column;
    align-items: normal;
    margin-bottom: 16px;
  }
}

.events-three-grid__header-title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 8px;
}

.events-three-grid__header-link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #1970b1;
  cursor: pointer;
}

.events-three-grid__header-link:after {
  content: "\2192";
  padding-left: 8px;
  color: #4a4a4a;
}

.events-three-grid__header-content {
  font-size: 18px;
  line-height: 30px;
}

.events-three-grid__row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 44px;
}

@media (max-width: 1024px) {
  .events-three-grid__row {
    grid-template-columns: 1fr 1fr;
    grid-gap: 35px;
  }
}

@media (max-width: 767px) {
  .events-three-grid__row {
    grid-template-columns: 1fr;
    grid-gap: 35px;
  }
}

.single-event {
  max-width: 405px;
  height: 327px;
  display: flex;
  border: 1px solid #d9d9d9;
}

.single-event__event-content {
  padding: 38px 66px 38px 38px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .single-event__event-content {
    padding: 20px 32px 32px 30px;
  }
}

.single-event__event-type {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.08em;
  display: block;
}

.single-event__event-title {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  color: #1970b1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

@media (max-width: 767px) {
  .single-event__event-title {
    -webkit-line-clamp: 4;
  }
}

.single-event__event-date {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  display: block;
  margin-bottom: 15px;
}

.single-event__event-footer {
  display: flex;
}

.single-event__event-footer-side {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

.single-event__event-tag {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  opacity: 0.8;
}

.single-event__event-value {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

.accordion-component {
  max-width: 865px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .accordion-component {
    margin-bottom: 40px;
  }
}

.accordion-component__header {
  margin-bottom: 30px;
}

.accordion-component__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .accordion-component__header-wrapper {
    flex-direction: column;
    align-items: normal;
    margin-bottom: 16px;
  }
}

.accordion-component__header-title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 8px;
}

.accordion-component__header-link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #1970b1;
  cursor: pointer;
}

.accordion-component__header-link:after {
  content: "\2192";
  padding-left: 8px;
  color: #4a4a4a;
}

.accordion-component__header-content {
  font-size: 18px;
  line-height: 30px;
}

.accordion-component__accordion {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  border-top: 1px solid #d9d9d9;
}

.accordion-component__accordion:last-child {
  border-bottom: 1px solid #d9d9d9;
}

.accordion-component__accordion.is-open .accordion-component__accordion-link span:after {
  background-image: url("/assets/img/minus-icon.png");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 2px;
  width: 16px;
  margin-left: 12px;
}

.accordion-component__accordion.is-open .accordion-component__toggle-text.closed {
  display: none;
}

.accordion-component__accordion.is-open .accordion-component__toggle-text.opened {
  display: flex;
}

.accordion-component__accordion img {
  width: 100%;
  object-fit: contain;
}

.accordion-component__accordion-link {
  background-color: transparent;
  border: 0;
  padding: 0;
  text-align: left;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-component__accordion-link h2 {
  color: #4a4a4a;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 0;
  line-height: 30px;
}

@media (max-width: 767px) {
  .accordion-component__accordion-link h2 {
    font-size: 16px;
    line-height: 18px;
  }
}

.accordion-component__accordion-link span:after {
  background-image: url("/assets/img/plus-icon-blue.png");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 16px;
  width: 16px;
  margin-left: 12px;
}

.accordion-component__toggle-text {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #1970b1;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .accordion-component__toggle-text {
    font-size: 14px;
    line-height: 16px;
  }
}

.accordion-component__toggle-text.opened {
  display: none;
}

.accordion-component__accordion-content {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  margin-top: 10px !important;
}

@media (max-width: 767px) {
  .accordion-component__accordion-content {
    font-size: 14px;
    line-height: 16px;
  }
}

.bookstore-search-filters {
  margin-top: 80px;
}

.bookstore-search-filters .search-facets {
  padding-bottom: 20px;
}

.search-facets__group--publications .bookstore-search-filters .search-facets__group-heading,
.search-facets__group--events .bookstore-search-filters .search-facets__group-heading {
  padding-bottom: 0;
}

.bookstore-search-filters .search-facets__values-wrapper {
  padding: 0 0px;
}

.bookstore-search-filters .search-facets__filter-close {
  position: absolute;
  top: -20px;
  left: 0;
  display: none;
  height: 40px;
  width: 100%;
}

.bookstore-search-filters .search-facets__filter-close button {
  padding: 8px 12px;
  margin: 0 auto;
  display: block;
  text-align: center;
}

.bookstore-search-filters .search-facets__filter-close .icon {
  color: #fff;
  margin-left: 6px;
  float: right;
}

.bookstore-search-filters .search-facets__filter-close .icon,
.bookstore-search-filters .search-facets__filter-close .icon svg {
  width: 24px;
  height: 24px;
}

@media (max-width: 56.875em) {
  .bookstore-search-filters .search-facets__filter-close {
    display: block;
  }
}

.bookstore-search-filters .search-facets__subheading {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  color: #86b6d9;
  float: left;
  font-size: 16px;
  padding-left: 0px;
}

.bookstore-search-filters .search-facets__header {
  padding: 20px 0 8px 0;
  margin: 0 20px;
  height: 54px;
}

@media (min-width: 910px) {
  .bookstore-search-filters .search-facets__header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
}

.bookstore-search-filters .search-facets__header .selected-filters .search-facets__selected-filter {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 4px 6px;
  border-radius: 6px;
}

.bookstore-search-filters .search-facets__header .search-facets__clear-all {
  display: flex;
  text-align: left;
  padding: 0px 0px 0px 0;
  clear: both;
  overflow: hidden;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.bookstore-search-filters .search-facets__header .search-facets__clear-all .search-facet__refine-label {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #fff;
  float: left;
  margin-bottom: 10px;
  padding-right: 10px;
  opacity: 1;
  text-transform: none;
}

.bookstore-search-filters .search-facets__header .search-facets__clear-all button {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  float: right;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  padding-right: 0;
  padding-left: 0;
}

.bookstore-search-filters .search-facets__header .search-facets__clear-all .none-selected {
  color: rgba(255, 255, 255, 0.4);
}

@media (max-width: 56.875em) {
  .bookstore-search-filters .search-facets__header .search-facets__clear-all {
    float: left;
  }
}

.bookstore-search-filters .search-facets__group {
  clear: both;
  margin: 20px 20px 0 20px;
}

.bookstore-search-filters .search-facets__group::after {
  clear: both;
  content: "";
  display: block;
}

.bookstore-search-filters .search-facets__group .search-facets__group-heading {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0 18px 0;
}

.bookstore-search-filters .search-facets__group .search-facets__group-heading h4 {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  margin-bottom: 0;
  color: #fff;
}

.bookstore-search-filters .search-facets__group .search-facets__group-heading button {
  margin-top: -2px;
  margin-left: -10px;
  margin-right: -2px;
}

.bookstore-search-filters .search-facets__group .search-facets__group-heading .icon {
  width: 22px;
  height: 22px;
}

.bookstore-search-filters .search-facets__group.is-open .search-facets__group-heading {
  border-bottom: 0;
}

.bookstore-search-filters .search-facets__group .search-facets__group {
  margin-top: 0;
  margin-bottom: 0;
}

.bookstore-search-filters .search-facets__group .search-facets__group .search-facets__group-heading {
  padding-top: 19px;
}

.bookstore-search-filters .search-facets__group.search-facets__group--publications:not(.is-open) div:not(.search-facets__group-heading), .bookstore-search-filters .search-facets__group.search-facets__group--events:not(.is-open) div:not(.search-facets__group-heading) {
  display: none;
}

.bookstore-search-filters .search-facets__group-filters {
  padding-bottom: 18px;
  margin-bottom: 18px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.bookstore-search-filters .search-facets__group-filters:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.bookstore-search-filters .search-facets__group-wrapper .search-facets__group-heading {
  background-color: transparent;
  border: 0;
}

.bookstore-search-filters .search-facets__group-wrapper .search-facets__group-heading h4 {
  font-size: 16px;
}

.bookstore-search-filters .search-facets__group-wrapper .search-facets__group:first-of-type {
  margin-top: 10px;
}

.bookstore-search-filters .search-facets__group-wrapper .search-facets__group:first-of-type .search-facets__group-heading {
  padding-top: 0;
}

.bookstore-search-filters .search-facets__group-more,
.bookstore-search-filters .search-facets__group-toggle,
.bookstore-search-filters .search-facets__value-expand {
  background: transparent;
  border: 0;
  margin-bottom: 20px;
  cursor: pointer;
  color: #fff;
}

.bookstore-search-filters .search-facets__group-toggle,
.bookstore-search-filters .search-facets__value-expand {
  transition: transform 0.2s;
  color: #fff;
}

.bookstore-search-filters .search-facets__group-toggle.is-open,
.bookstore-search-filters .search-facets__value-expand.is-open {
  transform: rotate(90deg);
}

.bookstore-search-filters .search-facets__group-selected {
  margin: 8px 0;
}

.bookstore-search-filters .search-facets__group-selected .search-facets__hr {
  width: calc(100% - 80px);
  border-top: solid 1px #fff;
  display: block;
  height: 1px;
  margin: 40px auto;
}

.bookstore-search-filters .search-facets__group-selected .search-facets__hr hr {
  opacity: 0;
}

.search-facets__group-values:empty + .bookstore-search-filters .search-facets__group-selected .search-facets__hr {
  display: none;
}

.bookstore-search-filters .search-facets__group-selected .search-facets__group-values {
  padding: 0 0px;
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #fff;
}

.bookstore-search-filters .search-facets__group-more {
  padding-bottom: 0px;
  padding-left: 0px;
}

.bookstore-search-filters .search-facets__group-more button {
  background: transparent;
  border: 0;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  padding: 0;
  text-decoration: underline;
  text-underline-offset: 0.2em;
}

.bookstore-search-filters .search-facets__group-more button:hover {
  color: #fff;
}

.bookstore-search-filters .search-facets__value-expand {
  float: right;
  width: 30px;
}

.bookstore-search-filters input:checked ~ .search-facets__checkbox:after {
  display: block;
}

.bookstore-search-filters li label {
  font-size: 12px;
  padding-left: 8px;
  width: calc(100% - 54px);
  line-height: 15px;
  vertical-align: middle;
  display: inline-flex;
}

@media (max-width: 56.875em) {
  .bookstore-search-filters li label {
    width: calc(100% - 58px);
  }
}

.bookstore-search-filters li input {
  display: inline-block;
  vertical-align: text-top;
}

.bookstore-search-filters .search-facets__group-values {
  list-style: none;
  margin: 0;
}

.bookstore-search-filters .search-facets__group-values.is-level-1, .bookstore-search-filters .search-facets__group-values.is-level-2 {
  margin-left: 0px;
  margin-top: 20px;
}

.bookstore-search-filters .search-facets__group-values.is-level-1 li:last-of-type, .bookstore-search-filters .search-facets__group-values.is-level-2 li:last-of-type {
  margin-bottom: 0;
}

.bookstore-search-filters .search-facets__group-values li {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.74px;
  font-size: 12px;
  display: block;
  position: relative;
  color: #fff;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.bookstore-search-filters .search-facets__group-values li input {
  height: 20px;
  width: 20px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.bookstore-search-filters .search-facets__group-values li input ~ .search-facets__checkbox {
  border: 1px solid #fff;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0) !important;
}

.bookstore-search-filters .search-facets__group-values li input:checked ~ .search-facets__checkbox {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.bookstore-search-filters .search-facets__group-values li input:checked ~ .search-facets__checkbox:after {
  display: block;
}

.bookstore-search-filters .search-facets__group-values li label {
  font-size: 15px;
  padding-left: 8px;
  width: calc(100% - 54px);
  line-height: 15px;
  vertical-align: middle;
  display: inline-flex;
}

@media (max-width: 56.875em) {
  .bookstore-search-filters .search-facets__group-values li label {
    width: calc(100% - 58px);
  }
}

.bookstore-search-filters .search-facets__group-values li input {
  display: inline-block;
  vertical-align: text-top;
}

.bookstore-search-filters .search-facets__count {
  font-weight: 300;
  text-align: right;
  margin-left: 10px;
}

.bookstore-search-filters .search-facets__group-wrapper {
  margin-left: 0;
}

.bookstore-search-filters .search-facets__group-body {
  transition: height 0.2s ease-in;
}

.bookstore-search-filters .search-facets__group-body .search-facets__group-body {
  padding-left: 0;
}

.bookstore-search-filters .search-facets__values-wrapper {
  overflow: hidden;
}

.bookstore-search-filters .search-facets__values-wrapper.has-no-values + .search-facets__group .search-facets__group-heading {
  padding-top: 0;
}

.bookstore-search-filters .search-facets__values-wrapper .search-facets__values-wrapper {
  padding-right: 0;
  padding-left: 0;
}

.bookstore-search-filters .search-facets__group-top {
  width: 100%;
  display: block;
  margin-top: 10px;
}

.bookstore-search-filters .search-facets__group-top .search-facets__group-clear {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-decoration: underline;
  background: none;
  color: #fff;
  border: 0;
  font-size: 16px;
  display: inline;
  padding: 0 0px;
}

.search-facets__typeahead .bookstore-search-filters .search-facets__group-top .search-facets__group-clear {
  padding: 0;
}

.bookstore-search-filters .search-facets__typeahead--open + .search-facets__group-selected + .search-facets__values-wrapper,
.bookstore-search-filters .search-facets__typeahead--open + .search-facets__values-wrapper {
  display: none;
}

.bookstore-search-filters .search-facets__typeahead--open + .search-facets__group-selected + .search-facets__values-wrapper + .search-facets__group-more,
.bookstore-search-filters .search-facets__typeahead--open + .search-facets__values-wrapper + .search-facets__group-more {
  display: none;
}

.bookstore-search-filters .search-facets__typeahead {
  display: block;
  position: relative;
  margin-bottom: 20px;
}

.bookstore-search-filters .search-facets__typeahead input {
  background-color: #e6e7e8;
  border: 0;
  color: #4a4a4a;
  font-size: 14px;
  padding-left: 10px;
  height: 36px;
  width: 100%;
}

.bookstore-search-filters .search-facets__typeahead .search-facets__typeahead__list {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.94px;
  height: 24px;
  position: relative;
  color: #fff;
  font-size: 14px;
  display: block;
  overflow: hidden;
  height: auto;
}

.bookstore-search-filters .search-facets__typeahead .search-facets__typeahead__list .search-facets__typeahead__list-item {
  margin-bottom: 2px;
  display: block;
  position: relative !important;
}

.bookstore-search-filters .search-facets__typeahead .search-facets__typeahead__list .search-facets__typeahead__list-item:first-of-type {
  margin-top: 10px;
}

.bookstore-search-filters .search-facets__typeahead .search-facets__typeahead__list .search-facets__checkbox--checked:after {
  display: block;
}

.bookstore-search-filters .search-facets__typeahead .search-facets__typeahead__list .search-facets__checkbox {
  margin-right: 10px;
}

.bookstore-search-filters .search-facets__checkbox--checked,
.bookstore-search-filters .search-facets__checkbox--hover,
.bookstore-search-filters li:hover > .search-facets__checkbox {
  background-color: #fff !important;
}

.bookstore-search-filters .search__facets-wrapper {
  margin-top: 0;
}

.bookstore-search-filters__pagination {
  display: flex;
  justify-content: end;
  margin-top: 70px;
}

.bookstore-search-filters__pagination-prev, .bookstore-search-filters__pagination-next {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 500;
  margin-right: 30px;
  color: #767676;
  cursor: pointer;
}

.bookstore-search-filters__pagination-prev:hover, .bookstore-search-filters__pagination-next:hover {
  color: #1970b1;
}

.bookstore-search-filters__pagination-next {
  margin-right: 0;
}

.bookstore-search-filters__pagination-list {
  list-style: none;
  display: flex;
  margin-left: 0;
  margin-bottom: 0;
}

.bookstore-search-filters__pagination-list li {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-right: 30px;
  font-size: 18px;
  line-height: 22px;
  color: #767676;
  cursor: pointer;
  font-weight: 500;
}

.bookstore-search-filters__pagination-list li:hover {
  color: #1970b1;
}

.bookstore-search-filters__pagination-list li.active {
  color: #1970b1;
}

.single-books {
  max-width: 850px;
  margin: 0 auto;
  margin-bottom: 70px;
}

.single-books__header {
  margin-bottom: 30px;
}

.single-books__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .single-books__header-wrapper {
    flex-direction: column;
    align-items: normal;
    margin-bottom: 16px;
  }
}

.single-books__header-title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 0;
}

.single-books__header-link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #1970b1;
  cursor: pointer;
}

.single-books__header-link:after {
  content: "\2192";
  padding-left: 8px;
  color: #4a4a4a;
}

.single-books__header-content {
  font-size: 18px;
  line-height: 30px;
}

.single-books__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

@media (max-width: 767px) {
  .single-books__wrapper {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.single-book {
  padding: 40px 40px 30px 40px;
  max-width: 405px;
  border: 1px solid #d9d9d9;
}

@media (max-width: 767px) {
  .single-book {
    padding: 30px;
    max-width: 100%;
  }
}

.single-book__image {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 36px;
}

.single-book__image img {
  width: auto;
  height: 172px;
}

@media (max-width: 767px) {
  .single-book__image img {
    height: 134px;
  }
}

.single-book__header {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .single-book__header {
    margin-bottom: 48px;
  }
}

.single-book__collection {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  display: block;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
  min-height: 20px;
}

.single-book__title {
  font-size: 18px;
  line-height: 25px;
  height: 75px;
  font-weight: 500;
  color: #1970b1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

@media (max-width: 767px) {
  .single-book__title {
    height: auto;
  }
}

.single-book__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.single-book__footer-side {
  display: flex;
  flex-direction: column;
}

.single-book__tag {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: #767676;
}

.single-book__tag-value {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #4a4a4a;
}

.single-book__footer-button {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  border: 2px #0f6eb4 solid;
  font-size: 16px;
  display: block;
  max-width: 310px;
  padding: 10px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  letter-spacing: 1px;
  background-color: #0f6eb4;
  color: #e7f0f7;
}

.single-book__footer-button:hover {
  color: #e7f0f7;
}

.page-section {
  width: 100%;
  max-width: 849px;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .page-section {
    margin-bottom: 40px;
  }
}

.page-section__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .page-section__header {
    flex-direction: column;
    align-items: normal;
  }
}

.page-section__header-title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 0;
}

.page-section__header-link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #1970b1;
  cursor: pointer;
}

.page-section__header-link:after {
  content: "\2192";
  padding-left: 8px;
  color: #4a4a4a;
}

.page-section__content {
  font-size: 18px;
  line-height: 30px;
}

.credits-component {
  max-width: 874px;
  margin: 0 auto;
  margin-bottom: 70px;
}

.credits-component__header {
  margin-bottom: 30px;
}

.credits-component__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .credits-component__header-wrapper {
    flex-direction: column;
    align-items: normal;
    margin-bottom: 16px;
  }
}

.credits-component__header-title {
  font-size: 25px;
  line-height: 45px;
  margin-bottom: 0;
}

.credits-component__header-link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #1970b1;
  cursor: pointer;
}

.credits-component__header-link:after {
  content: "\2192";
  padding-left: 8px;
  color: #4a4a4a;
}

.credits-component__header-content {
  font-size: 18px;
  line-height: 28px;
}

.credits-component__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

@media (max-width: 767px) {
  .credits-component__wrapper {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.credits-component__body {
  padding: 6px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .credits-component__body {
    display: block;
  }
}

.credits-component__body-img {
  margin-right: 24px;
  display: flex;
  flex-basis: 27%;
}

@media (max-width: 767px) {
  .credits-component__body-img {
    max-width: 100%;
    margin-right: 0;
  }
}

.credits-component__body-img img {
  width: 132px;
  height: auto;
  object-fit: cover;
}

@media (max-width: 767px) {
  .credits-component__body-img img {
    width: 100%;
  }
}

.credits-component__body-description {
  font-size: 16px;
  line-height: 28px;
}

.credits-component__body-description a {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 17px;
  color: #1970b1;
  line-height: 28px;
  font-weight: 600;
}

.sessions-sidebar {
  margin-bottom: 30px;
  max-width: 100%;
}

.sessions-sidebar__title {
  font-size: 25px;
  line-height: 45px;
  margin-bottom: 8px;
}

.sessions-sidebar__description {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 22px;
}

.sessions-sidebar__session {
  padding: 20px;
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
}

.sessions-sidebar__session:last-child {
  margin-bottom: 0;
}

.sessions-sidebar__session-title {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 12px;
}

.sessions-sidebar__session-date {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  margin-right: 8px;
}

.sessions-sidebar__session-date:last-child {
  margin-right: 0;
  margin-left: 8px;
}

.related-book-sidebar {
  margin-bottom: 30px;
  max-width: 100%;
}

.related-book-sidebar__title {
  font-size: 25px;
  line-height: 45px;
  margin-bottom: 8px;
}

.related-book-sidebar__description {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 22px;
}

.related-book-sidebar .single-book {
  padding: 40px 40px 30px 40px;
  max-width: 100%;
  border: 1px solid #d9d9d9;
}

@media (max-width: 767px) {
  .related-book-sidebar .single-book {
    padding: 30px;
    max-width: 100%;
  }
}

.related-book-sidebar .single-book__image {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 36px;
}

.related-book-sidebar .single-book__image img {
  width: auto;
  height: 172px;
}

@media (max-width: 767px) {
  .related-book-sidebar .single-book__image img {
    height: 134px;
  }
}

.related-book-sidebar .single-book__header {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .related-book-sidebar .single-book__header {
    margin-bottom: 48px;
  }
}

.related-book-sidebar .single-book__collection {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  display: block;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
  min-height: 20px;
}

.related-book-sidebar .single-book__title {
  font-size: 18px;
  line-height: 25px;
  height: 75px;
  font-weight: 500;
  color: #1970b1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

@media (max-width: 767px) {
  .related-book-sidebar .single-book__title {
    height: auto;
  }
}

.related-book-sidebar .single-book__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.related-book-sidebar .single-book__footer-side {
  display: flex;
  flex-direction: column;
}

.related-book-sidebar .single-book__tag {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: #767676;
}

.related-book-sidebar .single-book__tag-value {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #4a4a4a;
}

.health-exec {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 35px;
}

.health-exec .txt-h1,
.health-exec .txt-h2,
.health-exec .txt-h3,
.health-exec .txt-h4,
.health-exec .txt-h5,
.health-exec .promo-cta-grid__item-title,
.health-exec .txt-h6,
.health-exec h1:not(.headline__title),
.health-exec h2:not(.headline__title),
.health-exec h3:not(.headline__title),
.health-exec h4:not(.headline__title),
.health-exec h5:not(.headline__title),
.health-exec h6:not(.headline__title) {
  font-family: "adobe-garamond-pro", Times, serif;
  font-weight: 700;
  font-style: normal;
}

.health-exec .btn--link {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-style: bold;
  color: #282e53;
}

.health-exec .content-detail-banner {
  background-color: #e7f0f7;
  padding: 40px 100px;
  width: 100%;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@media (max-width: 56.875em) {
  .health-exec .content-detail-banner {
    padding: 20px;
  }
}

@media (max-width: 29.375em) {
  .health-exec .content-detail-banner {
    padding: 10px;
  }
}

.health-exec .content-detail-banner .content-detail-banner__content-type {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  font-style: semibold;
  color: #a63c63;
  font-size: 16px;
  letter-spacing: .5px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.health-exec .content-detail-banner .content-detail-banner__title {
  color: #282e53;
}

.health-exec .content-detail-banner .content-detail-banner__byline {
  display: flex;
  font-size: 20px;
  font-weight: 500;
  color: #4a4a4a;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

@media (max-width: 56.875em) {
  .health-exec .content-detail-banner .content-detail-banner__byline {
    flex-direction: column;
  }
}

.health-exec .content-detail-banner .content-detail-banner__author {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  font-style: semibold;
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 10px;
  color: #0f6eb4;
}

@media (max-width: 56.875em) {
  .health-exec .content-detail-banner .content-detail-banner__author {
    flex-basis: 1;
  }
}

.health-exec .content-detail-banner .content-detail-banner__collection,
.health-exec .content-detail-banner .content-detail-banner__topics {
  font-size: 16px;
  font-weight: 600;
  color: #282e53;
}

.health-exec .content-detail-banner .content-detail-banner__collection span,
.health-exec .content-detail-banner .content-detail-banner__topics span {
  background-color: #fff;
  border-radius: 5px;
  padding: 2px 15px;
  color: #4a4a4a;
  margin-right: 15px;
  font-weight: 600;
  font-size: 13px;
  white-space: nowrap;
  margin-bottom: 15px;
  display: inline-block;
}

.health-exec .content-detail-banner .content-detail-banner__collection span a,
.health-exec .content-detail-banner .content-detail-banner__topics span a {
  color: #4a4a4a;
}

.health-exec .content-detail-banner .content-detail-banner__collection span:first-of-type,
.health-exec .content-detail-banner .content-detail-banner__topics span:first-of-type {
  margin-left: 15px;
}

.health-exec .content-detail-banner .scEnabledChrome {
  display: block;
}

.health-exec .container--30-70 .content-detail-banner {
  margin-left: -3.52558%;
  width: calc(100% + 3.52558%);
}

@media (max-width: 56.875em) {
  .health-exec .container--30-70 .content-detail-banner {
    margin-left: auto;
    width: 100%;
  }
}

.headline.scEnabledChrome {
  overflow: visible;
}

.health-exec {
  /*.headline {
    font-size: 0;
    text-align: left;
    //
    &:before {
      content: "";
      background-color: $rouge;
      display: inline-block;
      height: 5px;
      width: $health-bar;
      margin-right: $health-bar-margin;
      @media(max-width: $bp-max-small) {
        display: none;
      }
    }

    .is-page-editor & {
      font-size: inherit;
    }
  }

  .headline__title {
    @include font(garamond,bold);
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 0;
    font-size: 45px;
    @media(max-width: $bp-max-small) {
      display: block;
    }
  }

  .headline__link {
    @include font(open-sans, bold);
    display: block;
    color: $chetwode-blue;
    font-size: 18px;
    margin-left: calc(80px + 25px);

    &:after {
      padding: 0 5px 6px;
      display: inline-block;
      vertical-align: middle;
    }
    @media(max-width: $bp-max-small) {
      margin-left: 0;
    }
  }*/
}

.is-page-editor .health-exec .scTextWrapper.scEnabledChrome {
  display: block;
}

.hero-full {
  position: relative;
}

.hero-full:before {
  background: #1970b1;
  position: absolute;
  height: calc(100% - 60px);
  width: 100%;
  content: '';
}

@media (max-width: 56.875em) {
  .hero-full:before {
    display: none;
  }
}

@media (max-width: 56.875em) {
  .hero-full .container--100 {
    margin: 0;
  }
}

.hero-full__image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-right: 80px;
}

@media (max-width: 56.875em) {
  .hero-full__image {
    width: 100%;
    height: auto;
    min-height: 1px;
  }
}

.hero-full__details {
  background: #fff;
  bottom: 0;
  -webkit-box-shadow: 0 0 15px 1px rgba(74, 74, 74, 0.4);
  box-shadow: 0 0 15px 1px rgba(74, 74, 74, 0.4);
  max-width: 580px;
  padding: 40px 45px;
  position: absolute;
  right: 0;
}

@media (max-width: 80.625em) {
  .hero-full__details {
    right: 15px;
  }
}

@media (max-width: 56.875em) {
  .hero-full__details {
    bottom: auto;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: -100px;
    max-width: 100%;
    padding: 40px;
    position: relative;
    right: auto;
    z-index: 1;
  }
}

.hero-full__meta {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #737373;
  font-size: 20px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.hero-full__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #4a4a4a;
  font-size: 45px;
  margin-bottom: 25px;
  line-height: 50px;
}

@media (max-width: 56.875em) {
  .hero-full__title {
    font-size: 36px;
    line-height: 42px;
  }
}

.hero-full__description {
  font-size: 18px;
  line-height: 30px;
}

.hero-full__cta {
  line-height: 1.3;
  margin-top: 10px;
  padding-bottom: 0;
}

.health-exec .hero-full:before {
  display: none;
}

.health-exec .hero-full__details {
  background: #e7f0f7;
}

.health-exec .hero-full__image {
  width: 100%;
  max-width: 100%;
}

.health-exec .hero-full__meta {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  font-style: semibold;
  color: #a63c63;
  text-transform: uppercase;
  font-size: 16px;
  height: 22px;
}

.health-exec .hero-full__meta {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.health-exec .hero-full__title {
  font-family: "adobe-garamond-pro", Times, serif;
  font-weight: 700;
  font-style: normal;
  color: #282e53;
  margin-bottom: 10px;
}

.health-exec .hero-full__cta {
  padding-top: 0;
}

.health-exec .image-block .image-block__title {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-style: bold;
  font-size: 24px;
}

.health-exec .image-block figcaption {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  max-width: 100%;
  font-size: 20px;
}

.health-exec .pull-quote {
  border-left: 5px solid #a63c63;
}

.health-exec .pull-quote:before {
  color: #6D81D3;
  opacity: 1;
  top: 30px;
  overflow: visible;
}

.health-exec .pull-quote__quote {
  font-family: "adobe-garamond-pro", Times, serif;
  font-weight: 400;
  font-style: normal;
  color: #282e53;
}

.health-exec .pull-quote__caption cite {
  display: block;
  color: #787878;
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-family: "Open Sans", Arial, sans-serif;
}

.health-exec .image-block .image-block__title {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-style: bold;
  font-size: 24px;
}

.health-exec .image-block figcaption {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  max-width: 100%;
  font-size: 20px;
}

.health-exec .pull-quote {
  border-left: 5px solid #a63c63;
}

.health-exec .pull-quote:before {
  color: #6d81d3;
  opacity: 1;
  top: 30px;
  overflow: visible;
}

.health-exec .pull-quote__quote {
  font-family: "adobe-garamond-pro", Times, serif;
  font-weight: 400;
  font-style: normal;
  color: #282e53;
}

.health-exec .pull-quote__caption cite {
  display: block;
  color: #787878;
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-family: "Open Sans", Arial, sans-serif;
}

@media (max-width: 56.875em) {
  .health-exec .pull-quote__caption cite {
    padding-left: 0;
  }
}

.text-promo {
  display: block;
  margin-bottom: 30px;
  margin-top: 30px;
  overflow: hidden;
  padding: 30px 40px;
  position: relative;
  width: 100%;
}

.text-promo, .text-promo--sky-blue {
  background-color: #e7f0f7;
}

.text-promo a, .text-promo--sky-blue a {
  color: #282e53;
}

.text-promo--periwinkle {
  background-color: #f4f6fb;
}

.text-promo--periwinkle a {
  color: #a63c63;
}

.text-promo__border {
  background-color: #a63c63;
  height: 30px;
  position: absolute;
  top: 35px;
  left: 0;
  width: 10px;
}

.text-promo__title {
  font-family: "proxima-nova", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #282e53;
  font-size: 30px;
}

.text-promo__content {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  color: #4a4a4a;
}

.text-promo__content ul {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  font-style: semibold;
  list-style-type: none;
  margin-left: 0;
}

.health-exec .video-block__title {
  color: #4a4a4a;
  font-size: 24px;
}

.health-exec .video-block__caption {
  color: #787878;
  font-size: 20px;
}

.health-exec .microsite-nav {
  background: #282e53;
  margin-bottom: 30px;
}

.health-exec .microsite-nav:before {
  background: #282e53;
}

.health-exec .microsite-nav:after {
  background: #e7f0f7;
  left: 20px;
}

.health-exec .container--70-30 .microsite-nav:after {
  left: 33px;
}

.health-exec .microsite-nav__heading {
  font-family: "adobe-garamond-pro", Times, serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.health-exec .microsite-nav__item {
  font-size: 18px;
}

.health-exec .microsite-nav__item .microsite-nav__link,
.health-exec .microsite-nav__item a {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-style: bold;
  letter-spacing: 0;
}

.health-exec .microsite-nav__item .microsite-nav__link.is-active, .health-exec .microsite-nav__item .microsite-nav__link:hover,
.health-exec .microsite-nav__item a.is-active,
.health-exec .microsite-nav__item a:hover {
  color: #e7f0f7;
}

.health-exec .image-promo {
  margin-bottom: 30px;
}

.health-exec .image-promo .image-promo__media {
  position: relative;
}

.health-exec .image-promo .image-promo__media figcaption {
  background-color: #a63c63;
  padding: 40px 42px;
}

.health-exec .image-promo .image-promo__media figcaption .btn--link {
  font-family: "adobe-garamond-pro", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 0;
  margin-top: 31px;
}

.health-exec .image-promo .image-promo__title {
  font-family: "adobe-garamond-pro", Times, serif;
  font-weight: 700;
  font-style: normal;
  line-height: 100%;
  margin-bottom: 0;
}

.health-exec .image-promo .image-promo__metadata {
  font-family: "adobe-garamond-pro", Times, serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 0;
}

.health-exec .people-details {
  font-size: 18px;
}

.health-exec .people-details .people-details__header {
  color: #282e53;
}

.health-exec .people-details .people-details__section-header {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  font-style: semibold;
  color: #4a4a4a;
}

.health-exec .people-details .people-details__bio-role {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  font-style: semibold;
  color: #a63c63;
  margin-top: 0;
}

.content-list {
  margin-bottom: 30px;
}

.content-list__heading {
  font-family: "adobe-garamond-pro", Times, serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 30px;
  font-size: 45px;
  color: #282e53;
}

.content-list__heading:before {
  content: "";
  background-color: #a63c63;
  display: inline-block;
  height: 5px;
  width: 80px;
  margin-right: 25px;
}

@media (max-width: 29.375em) {
  .content-list__heading:before {
    display: none;
  }
}

.content-list__overflow {
  display: none;
}

.content-list__list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.content-list__item {
  overflow: hidden;
  margin-bottom: 45px;
}

.content-list__more {
  text-align: center;
}

.blog-list {
  margin-bottom: 30px;
}

.blog-list .list-item__eyebrow {
  color: #6fb40f !important;
}

.blog-list__heading {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  margin-bottom: 30px;
  font-size: 45px;
  color: #282e53;
}

.blog-list__heading:before {
  content: "";
  background-color: #6fb40f;
  display: inline-block;
  height: 5px;
  width: 80px;
  margin-right: 25px;
}

@media (max-width: 29.375em) {
  .blog-list__heading:before {
    display: none;
  }
}

.blog-list__overflow {
  display: none;
}

.blog-list__list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.blog-list__item {
  overflow: hidden;
  margin-bottom: 45px;
}

.blog-list__more {
  text-align: center;
}

.blog-list__more .btn {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  display: block;
  margin: auto;
  max-width: 200px;
  background-color: #e7f0f7;
  color: #4a4a4a;
  border: 0;
  padding: 16px;
  font-size: 16px;
}

.blog-list__more:hover {
  color: #0f6eb4;
}

.health-exec .list-item {
  display: flex;
  align-items: left;
  flex-direction: column;
}

.health-exec .list-item.is-left, .health-exec .list-item.is-right {
  align-items: flex-start;
}

.health-exec .list-item.is-left {
  flex-direction: row;
}

.health-exec .list-item.is-left .list-item__media {
  margin-right: 30px;
}

.health-exec .list-item.is-right {
  flex-direction: row-reverse;
}

.health-exec .list-item.is-right .list-item__media {
  margin-left: 30px;
  margin-bottom: 0;
}

@media (max-width: 56.875em) {
  .health-exec .list-item.is-left, .health-exec .list-item.is-right {
    flex-direction: column;
  }
  .health-exec .list-item.is-left .list-item__media, .health-exec .list-item.is-right .list-item__media {
    margin-bottom: 30px;
    margin-left: 0;
    margin-right: 0;
  }
}

.health-exec .list-item__media {
  line-height: 0;
  position: relative;
  margin-bottom: 30px;
}

.health-exec .list-item__media:after {
  content: "";
  background-color: #a63c63;
  bottom: -3px;
  height: 3px;
  left: 0;
  position: absolute;
  width: 138px;
  z-index: 3;
}

.health-exec .list-item__content {
  flex: 1;
}

.health-exec .list-item__eyebrow {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #a63c63;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 45px;
}

.health-exec .list-item__title {
  font-family: "adobe-garamond-pro", Times, serif;
  font-weight: 700;
  font-style: normal;
  color: #282e53;
  font-size: 35px;
  margin-bottom: 5px;
  line-height: 37px;
}

.health-exec .list-item__title a {
  font-family: "adobe-garamond-pro", Times, serif;
  font-weight: 700;
  font-style: normal;
  color: #282e53;
}

.health-exec .list-item__subtitle {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-style: bold;
  color: #4a4a4a;
  font-size: 18px;
  line-height: 1.3;
}

.health-exec .list-item__description {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  font-size: 20px;
  margin-top: 19px;
  max-width: 800px;
}

.health-exec .list-item__meta {
  line-height: 1.5;
  margin-bottom: 30px;
}

.health-exec .list-item__meta::after {
  clear: both;
  content: "";
  display: block;
}

.health-exec .list-item__date {
  font-style: italic;
}

.health-exec .list-item__byline {
  border-left: 1px solid #e0e0e0;
  margin-left: 0.5em;
  padding-left: 0.75em;
}

.health-exec .list-item__cta {
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #282e53;
  display: block;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  font-size: 18px;
}

.health-exec .list-item__cta::after {
  content: ' →';
  display: inline-block;
  vertical-align: bottom;
  padding-bottom: 2px;
  margin-left: 10px;
}

.blog-list .list-item {
  display: flex;
  align-items: left;
  flex-direction: column;
}

.blog-list .list-item.is-left, .blog-list .list-item.is-right {
  align-items: flex-start;
}

.blog-list .list-item.is-left {
  flex-direction: row;
}

.blog-list .list-item.is-left .list-item__media {
  margin-right: 30px;
  width: 240px;
  height: 240px;
}

.blog-list .list-item.is-left .list-item__media img {
  width: 240px;
  height: 240px;
  object-fit: cover;
}

.blog-list .list-item.is-right {
  flex-direction: row-reverse;
}

.blog-list .list-item.is-right .list-item__media {
  margin-left: 30px;
  margin-bottom: 0;
}

@media (max-width: 56.875em) {
  .blog-list .list-item.is-left, .blog-list .list-item.is-right {
    flex-direction: column;
  }
  .blog-list .list-item.is-left .list-item__media, .blog-list .list-item.is-right .list-item__media {
    margin-bottom: 30px;
    margin-left: 0;
    margin-right: 0;
  }
}

.blog-list .list-item__media {
  line-height: 0;
  position: relative;
  margin-bottom: 30px;
  width: 240px;
  height: 240px;
}

.blog-list .list-item__media:after {
  content: "";
  background-color: #f1c40f;
  bottom: -3px;
  height: 3px;
  left: 0;
  position: absolute;
  width: 138px;
  z-index: 3;
}

.blog-list .list-item__content {
  flex: 1;
}

.blog-list .list-item__eyebrow {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #f1c40f;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 10px;
}

.blog-list .list-item__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif !important;
  color: #282e53;
  font-size: 35px;
  margin-bottom: 5px;
}

.blog-list .list-item__title a {
  font-family: "futura-pt", Helvetica, Arial, sans-serif !important;
  color: #282e53;
}

.blog-list .list-item__subtitle {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-style: bold;
  color: #4a4a4a;
  font-size: 18px;
}

.blog-list .list-item__description {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  font-size: 20px;
  margin-top: 19px;
  max-width: 800px;
}

.blog-list .list-item__meta {
  line-height: 1.5;
  margin-bottom: 30px;
}

.blog-list .list-item__meta::after {
  clear: both;
  content: "";
  display: block;
}

.blog-list .list-item__date {
  font-style: italic;
}

.blog-list .list-item__byline {
  border-left: 1px solid #e0e0e0;
  margin-left: 0.5em;
  padding-left: 0.75em;
}

.blog-list .list-item__cta {
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #282e53;
  display: block;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  font-size: 18px;
}

.blog-list .list-item__cta::after {
  content: ' →';
  display: inline-block;
  vertical-align: bottom;
  padding-bottom: 2px;
  margin-left: 10px;
}

.event-hero {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  padding: 55px 92px 45px 0;
  position: relative;
  background-color: #e7f0f7;
}

.event-hero:before {
  content: "";
  background-color: #e7f0f7;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: -1;
}

@media (max-width: 56.875em) {
  .event-hero {
    padding: 20px;
  }
  .event-hero:before {
    content: "";
    display: none;
  }
}

.event-hero .event-hero__name {
  font-size: 45px;
  font-weight: 600;
  line-height: 51px;
  margin-bottom: 8px;
  max-width: 766px;
  display: block;
}

.event-hero .event-hero__tagline {
  color: #787878;
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  max-width: 766px;
  display: block;
}

.event-hero .event-hero__links {
  position: relative;
  display: flex;
  margin-top: 31px;
}

@media (max-width: 56.875em) {
  .event-hero .event-hero__links {
    flex-direction: column;
  }
}

.event-hero .event-hero__cta {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  border: 2px solid #0f6eb4;
  color: #0f6eb4;
  font-weight: 600;
  display: block;
  padding: 11px 22px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 18px;
  margin-right: 90px;
}

@media (max-width: 56.875em) {
  .event-hero .event-hero__cta {
    padding: 11px 22px;
  }
}

.event-hero .event-event-hero__register {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #0f6eb4;
  font-weight: 600;
  display: block;
  padding: 10px 22px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 18px;
  height: 23px;
}

.event-hero .event-hero__locations {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 70px;
  margin-left: 0;
  margin-bottom: -38px;
}

@media (max-width: 56.875em) {
  .event-hero .event-hero__locations {
    flex-direction: column;
  }
}

.event-hero .event-hero__locations li {
  width: 33.33%;
  border-left: 2px #4a4a4a solid;
  padding-left: 6%;
  padding-right: 6%;
  margin-bottom: 38px;
}

@media (max-width: 56.875em) {
  .event-hero .event-hero__locations li {
    border-left: none;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.event-hero .event-hero__locations li .event-hero__location {
  line-height: 28px;
  font-size: 24px;
  font-weight: 700;
  color: #4a4a4a;
  letter-spacing: 1px;
  margin-bottom: 8px;
  display: block;
  min-height: 28px;
}

.event-hero .event-hero__locations li .event-hero__dates {
  line-height: 105%;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 600;
  display: block;
}

.event-hero .event-hero__locations li:first-of-type, .event-hero .event-hero__locations li:nth-of-type(4) {
  border-left: none;
  padding-left: 0;
}

.event-hero .event-hero__locations li:last-of-type {
  padding-right: 0;
}

.events-pricing {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #f4f6fb;
  padding: 42px 32px 32px 30px;
  color: #4a4a4a;
  margin-bottom: 38px;
  position: relative;
}

.events-pricing:before {
  content: "";
  background-color: #4a90e2;
  display: block;
  height: 5px;
  width: 125px;
  position: absolute;
  top: 0;
  left: 0;
}

.events-pricing h2,
.events-pricing h3 {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 20px;
}

.events-pricing .events-pricing__list {
  font-weight: 600;
  list-style-type: none;
  line-height: 36px;
  margin-left: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.events-pricing .events-pricing__list li {
  margin-bottom: 0;
}

.events-pricing .events-pricing__price {
  font-weight: 600;
  color: #a63c63;
}

.session-info {
  margin-top: 20px;
}

.session-info:first-of-type {
  margin-top: 0;
}

.session-heading h1 {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 7px;
}

.session-heading .session-heading__meta {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  display: inline;
  color: #787878;
  height: 40px;
  line-height: 34px;
  font-size: 20px;
  font-weight: 500;
}

.session-heading .session-heading__meta span:empty {
  display: none;
}

.session-heading .session-heading__meta span:empty {
  display: none;
}

.session-heading .session-heading__meta span:before {
  content: " - ";
}

.session-heading .session-heading__meta span:first-of-type:before {
  content: "";
}

.session-heading .session-heading__meta .session-heading__start-date:before {
  content: "";
}

.event-other-sessions {
  background-color: #e7f0f7;
  padding: 42px 32px 32px 30px;
  color: #4a4a4a;
  margin-bottom: 38px;
  position: relative;
}

.event-other-sessions:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  width: 125px;
  background-color: #f1c40f;
}

.event-other-sessions .event-other-sessions__header {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #4a4a4a;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 0;
}

.event-other-sessions .event-other-sessions__sessions {
  margin-top: 29px;
  padding: 0;
  margin-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.event-other-sessions .event-other-sessions__sessions li {
  margin-bottom: 28px;
}

.event-other-sessions .event-other-sessions__sessions li:last-of-type {
  margin-bottom: 0;
}

.event-other-sessions .event-other-sessions__location {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  font-size: 16px;
  display: block;
  text-transform: uppercase;
  width: 100%;
  letter-spacing: 1px;
}

.event-other-sessions .event-other-sessions__time {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  font-size: 20px;
  display: block;
  margin-bottom: 5px;
  line-height: 28px;
  letter-spacing: 1px;
}

.event-other-sessions .event-other-sessions__time span {
  display: inline;
}

.event-other-sessions .event-other-sessions__link {
  display: block;
  margin-top: 16px;
  padding: 0;
  font-size: 16px;
}

.event-register {
  background-color: #e7f0f7;
  padding: 35px;
  position: relative;
  margin-bottom: 38px;
}

.event-register:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  width: 125px;
}

.event-register .event-register__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #4a4a4a;
  font-size: 30px;
  line-height: 36px;
}

.event-register .event-register__sub-title {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  color: #4a4a4a;
  font-size: 18px;
  line-height: 30px;
}

.event-register .event-register__cta {
  margin-top: 38px;
}

.event-register .event-register__cta__link {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  border: 2px #0f6eb4 solid;
  font-size: 16px;
  display: block;
  min-height: 43px;
  margin: 0 auto 15px auto;
  max-width: 310px;
  padding: 11px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  letter-spacing: 1px;
}

.event-register .event-register__cta__link:first-of-type {
  background-color: #0f6eb4;
  color: #e7f0f7;
}

.event-register .event-register__cta__link:last-of-type {
  margin-bottom: 0;
}

@media (max-width: 29.375em) {
  .related-book .book-details__upper {
    flex-direction: column;
  }
}

.related-book .book-details__left {
  margin-bottom: 0;
}

.related-book .book-details__left a {
  display: block;
}

.related-book .book-details__left .book-details__image {
  margin-bottom: 0;
}

@media (max-width: 29.375em) {
  .related-book .book-details__left .book-details__image {
    max-width: 200px;
    margin: 0 auto;
    display: block;
  }
}

@media (max-width: 29.375em) {
  .related-book .book-details__left {
    width: 100%;
  }
}

.related-book .book-details__right a {
  color: #4a4a4a;
}

.related-book .book-details__right a.btn--link {
  color: #1970b1;
}

.related-book .book-details__right .book-details__title {
  font-size: 36px;
  line-height: 45px;
}

.related-book .book-details__right .book-details__description {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.related-book .book-details__right .book-details__description p {
  font-size: inherit;
  line-height: inherit;
}

@media (max-width: 29.375em) {
  .related-book .book-details__right {
    width: 100%;
    margin-left: 0;
  }
}

.location-info {
  background-color: #e7f0f7;
  padding: 35px;
  position: relative;
  margin-bottom: 38px;
}

.location-info .location-info__heading {
  text-align: center;
}

.location-info div {
  width: 100%;
  display: block;
  margin-top: 20px;
}

.location-info div.location-info__address {
  display: flex;
  flex-wrap: wrap;
}

.location-info div.location-info__address .location-info__label {
  margin-right: 15px;
}

.location-info div.location-info__address span.location-info__address__address {
  width: auto;
}

.location-info .location-info__label {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-weight: 700;
  margin-right: 10px;
}

.location-info .location-info__label:after {
  content: ":";
  display: inline;
}

.location-info .location-info__label + span {
  width: calc(100% - 110px);
}

.location-info .btn {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  border: 2px #0f6eb4 solid;
  font-size: 16px;
  display: block;
  min-height: 43px;
  margin: 38px auto 38px auto;
  max-width: 310px;
  padding: 11px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  letter-spacing: 1px;
  background-color: #0f6eb4;
  color: #e7f0f7;
}

.location-info .btn:hover {
  background-color: #e7f0f7;
  color: #0f6eb4;
}

.location-info .location-info__more-info {
  margin: 38px auto 15px auto;
  color: #0f6eb4;
  background-color: transparent;
}

.location-info .location-info__more-info:hover {
  background-color: #0f6eb4;
  color: #e7f0f7;
}
