.credits-component {
	max-width: 874px;
	margin: 0 auto;
	margin-bottom: 70px;

	&__header {
		margin-bottom: 30px;
	}

	&__header-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;

		@media (max-width: 767px) {
			flex-direction: column;
			align-items: normal;
			margin-bottom: 16px;
		}
	}

	&__header-title {
		font-size: 25px;
		line-height: 45px;
		margin-bottom: 0;
	}

	&__header-link {
		@include font(futura);
		text-transform: uppercase;
		font-weight: 600;
		font-size: 18px;
		color: $fun-blue;
		cursor: pointer;

		&:after {
			content: "\2192";
			padding-left: 8px;
			color: $black;
		}
	}

	&__header-content {
		font-size: 18px;
		line-height: 28px;
	}

	&__wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 40px;

		@media (max-width: 767px) {
			grid-template-columns: 1fr;
			gap: 20px;
		}
	}

	&__body {
		padding: 6px;
		border-top: 1px solid #d9d9d9;
		border-bottom: 1px solid #d9d9d9;
		display: flex;
		align-items: center;

		@media (max-width: 767px) {
			display: block;
		}
	}

	&__body-img {
		margin-right: 24px;
		display: flex;
		flex-basis: 27%;
		@media (max-width: 767px) {
			max-width: 100%;
			margin-right: 0;
		}
		img {
			width: 132px;
			height: auto;
			object-fit: cover;
			@media (max-width: 767px) {
				width: 100%;
			}
		}
	}

	&__body-description {
		font-size: 16px;
		line-height: 28px;

		a {
			@include font(futura, demi);
			font-size: 17px;
			color: $fun-blue;
			line-height: 28px;
			font-weight: 600;
		}
	}
}
