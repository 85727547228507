// video-block.scss
// define video styles here
$transition: cubic-bezier(.7,.1,.1,1);

// base
.video-block{
    @include component-whitespace();
}

// title
.video-block__title {
    line-height: 1.2;
    margin-bottom: 20px;
    margin-top: 20px;
}

// media
.video-block__media {}

.video-block__play-button {
    align-items: center;
    background: rgba($black, 0.3);
    border-radius: 50%;
    border: 1px solid $white;
    color: $white;
    display: flex;
    height: 109px;
    font-size: 0;
    justify-content: center;
    left: calc(50% - 54px);
    opacity: 1;
    padding: 0;
    position: absolute;
    bottom: calc(50% - 54px);
    width: 109px;
    z-index: 2;

    &.is-hidden {
        opacity: 0;
        width: 0px;
        height: 0px;
        overflow: hidden;
        left: 0px;
        bottom: 0px;
        transition: height $transition .6s, width $transition .6s, left $transition .6s, bottom $transition .6s, opacity $transition .6s;

        svg {
          height: 45px;
          width: 44px;
            transition: height $transition .3s, width $transition .3s;
        }
    }

    svg {
      height: 45px;
      width: 44px;
      margin-left: 10px;
    }
}

// media wrapper
// Intrinsic Ratios - http://alistapart.com/article/creating-intrinsic-ratios-for-video
.video-block__media-wrapper{
    position: relative;
    padding-bottom: 56.25%; // 16x9 Aspect Ratio
    height: 0;
    overflow: hidden;
    max-width: 100%;

    // video, iframe, object and embed
    video,
    iframe,
    object,
    embed{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin-bottom: 20px;
    }

    &.is-paused {

    }
}

// media placeholder
// we position this appropriately but hide it be default.
// only sitecore authors should see this display.
.video-block__media-placeholder{
    display: none;
    width: 100%;
}

// figcaption
.video-block__caption{
    padding-bottom: 35px;
    line-height: 1.45;
    max-width: 700px;
    margin-top: 35px;
}
