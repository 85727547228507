$search-facets__basic-padding: 0px;
$search-facets__values-indent: 0px;

.bookstore-search-filters {
	margin-top: 80px;

	.search-facets {
		padding-bottom: 20px;
	}

	.search-facets__group-heading {
		.search-facets__group--publications &,
		.search-facets__group--events & {
			padding-bottom: 0;
		}
	}

	.search-facets__values-wrapper {
		padding: 0 $search-facets__basic-padding;
	}

	.search-facets__filter-close {
		position: absolute;
		top: -20px;
		left: 0;
		display: none;
		height: 40px;
		width: 100%;

		button {
			padding: 8px 12px;
			margin: 0 auto;
			display: block;
			text-align: center;
		}

		.icon {
			color: $white;
			margin-left: 6px;
			float: right;
		}

		.icon,
		.icon svg {
			width: 24px;
			height: 24px;
		}
		@media (max-width: $bp-max-medium) {
			display: block;
		}
	}

	.search-facets__subheading {
		@include font(futura, demi);
		text-transform: uppercase;
		color: $half-baked-blue;
		float: left;
		font-size: 16px;
		padding-left: $search-facets__basic-padding;
	}

	.search-facets__header {
		padding: 20px 0 8px 0;
		margin: 0 20px;
		height : 54px;
		@media (min-width: 910px) {
			border-bottom: 1px solid rgba(255, 255, 255, 0.5);
		}

		.selected-filters {
			// display: flex;
			// width: inherit;
			// flex-wrap: wrap;

			.search-facets__selected-filter {
				color: $white;
				font-size: 15px;
				font-weight: 600;
				background-color: rgba(255, 255, 255, 0.3);
				padding: 4px 6px;
				border-radius: 6px;
			}
		}

		.search-facets__clear-all {
			display: flex;
			text-align: left;
			padding: $search-facets__basic-padding $search-facets__basic-padding $search-facets__basic-padding 0;
			clear: both;
			overflow: hidden;
			justify-content: space-between;
			flex-wrap: wrap;
			align-items: flex-start;

			.search-facet__refine-label {
				@include font(futura, demi);
				// letter-spacing: 2.13px;
				color: $white;
				float: left;
				margin-bottom: 10px;
				padding-right: 10px;
				opacity: 1;
				text-transform: none;
			}

			button {
				@include font(futura, demi);
				background: transparent;
				border: none;
				color: $white;
				cursor: pointer;
				float: right;
				font-size: 16px;
				font-weight: 600;
				text-decoration: underline;
				padding-right: 0;
				padding-left: 0;
			}

			.none-selected {
				color: rgba($color: $white, $alpha: 0.4);
			}

			@media (max-width: $bp-max-medium) {
				float: left;
			}
		}
	}

	.search-facets__group {
		@include clearfix;
		clear: both;
		margin: 20px 20px 0 20px;

		// Root Group Heading
		.search-facets__group-heading {
			display: flex;
			justify-content: flex-start;
			padding: 10px 0 18px 0;

			h4 {
				@include font(futura, heavy);
				font-size: 20px;
				margin-bottom: 0;
				color: $white;
			}

			button {
				margin-top: -2px;
				margin-left: -10px;
				margin-right: -2px;
			}

			.icon {
				width: 22px;
				height: 22px;
			}
		}

		&.is-open {
			.search-facets__group-heading {
				border-bottom: 0;
			}

			.search-facets__group-wrapper {
				.is-open.search-facets__group {
					.search-facets__group-heading {
						// border-bottom: 1px solid $grey-400;
					}
				}
			}
		}

		.search-facets__group {
			margin-top: 0;
			margin-bottom: 0;

			.search-facets__group-heading {
				padding-top: 19px;
			}
		}

		&.search-facets__group--publications,
		&.search-facets__group--events {
			&:not(.is-open) div:not(.search-facets__group-heading) {
				display: none;
			}
		}
	}

	.search-facets__group-filters {
		padding-bottom: 18px;
		margin-bottom: 18px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.5);

		&:last-child {
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}
	// Child Group headings

	.search-facets__group-wrapper {
		.search-facets__group-heading {
			background-color: transparent;
			border: 0;

			h4 {
				font-size: 16px;
			}
		}

		.search-facets__group:first-of-type {
			margin-top: 10px;

			.search-facets__group-heading {
				padding-top: 0;
			}
		}
	}

	.search-facets__group-more,
	.search-facets__group-toggle,
	.search-facets__value-expand {
		background: transparent;
		border: 0;
		margin-bottom: 20px;
		cursor: pointer;
		color: $white;
	}

	.search-facets__group-toggle,
	.search-facets__value-expand {
		transition: transform 0.2s;
		color: $white;

		&.is-open {
			transform: rotate(90deg);
		}
	}

	.search-facets__group-selected {
		margin: 8px 0;

		.search-facets__hr {
			width: calc(100% - 80px);
			border-top: solid 1px $white;
			display: block;
			height: 1px;
			margin: 40px auto;

			hr {
				opacity: 0;
			}

			.search-facets__group-values:empty + & {
				display: none;
			}
		}

		.search-facets__group-values {
			padding: 0 $search-facets__basic-padding;
			@include font(futura, demi);
			color: $white;
		}
	}

	.search-facets__group-more {
		padding-bottom: $search-facets__basic-padding;
		padding-left: $search-facets__basic-padding;

		button {
			background: transparent;
			border: 0;
			cursor: pointer;
			color: rgba($color: $white, $alpha: 0.8);
			font-size: 15px;
			padding: 0;
			text-decoration: underline;
			text-underline-offset: 0.2em;
		}

		button:hover {
			color: $white;
		}
	}

	.search-facets__value-expand {
		float: right;
		width: 30px;
	}

	
    input:checked~.search-facets__checkbox:after {
		display: block;
	  }
  
	li {
	  label {
		// display: inline-block;
		font-size: 12px;
		padding-left: 8px;
		// overflow: hidden;
		width: calc(100% - 54px);
		line-height: 15px;
		vertical-align: middle;
		display: inline-flex;
  
		@media (max-width: $bp-max-medium) {
		  width: calc(100% - 58px);
		}
	  }
  
	  input {
		display: inline-block;
		vertical-align: text-top;
	  }
	}
  

	.search-facets__group-values {
		list-style: none;
		margin: 0;

		&.is-level-1,
		&.is-level-2 {
			margin-left: $search-facets__values-indent;
			margin-top: 20px;

			li:last-of-type {
				margin-bottom: 0;
			}
		}

		li {
			@include font(futura, demi);
			letter-spacing: 0.74px;
			font-size: 12px;
			display: block;
			position: relative;
			color: $white;
			margin-bottom: 12px;
			display: flex;
			align-items: center;

			input {
				height: 20px;
				width: 20px;
				position: absolute;
				opacity: 0;
				cursor: pointer;
				z-index: 2;
			}

			input ~ .search-facets__checkbox {
				border: 1px solid $white;
				border-radius: 2px;
				background-color: rgba($color: #000000, $alpha: 0) !important;
			}

			input:checked ~ .search-facets__checkbox {
				background-color: rgba($color: $white, $alpha: 0.8) !important;
			}

			input:checked ~ .search-facets__checkbox:after {
				display: block;
			}
		}

		li {
			label {
				font-size: 15px;
				padding-left: 8px;
				width: calc(100% - 54px);
				line-height: 15px;
				vertical-align: middle;
				display: inline-flex;

				@media (max-width: $bp-max-medium) {
					width: calc(100% - 58px);
				}
			}

			input {
				display: inline-block;
				vertical-align: text-top;
			}
		}
	}

	.search-facets__count {
		font-weight: 300;
		// display: inline-block;
		text-align: right;
		// float: right;
		margin-left: 10px;
	}

	.search-facets__group-wrapper {
		margin-left: 0;
	}

	.search-facets__group-body {
		transition: height 0.2s ease-in;

		.search-facets__group-body {
			padding-left: 0;
		}
	}

	.search-facets__values-wrapper {
		overflow: hidden;
		// when a group has no direct values, but does have childGroups
		// we want to remove the top-padding from the first childGroup
		&.has-no-values + .search-facets__group {
			.search-facets__group-heading {
				padding-top: 0;
			}
		}

		.search-facets__values-wrapper {
			padding-right: 0;
			padding-left: 0;
		}
	}

	.search-facets__group-top {
		width: 100%;
		display: block;
		margin-top: 10px;
		// margin-bottom: 20px;

		.search-facets__group-clear {
			@include font(futura, demi);
			text-decoration: underline;
			background: none;
			color: $white;
			border: 0;
			font-size: 16px;
			display: inline;
			padding: 0 $search-facets__basic-padding;

			.search-facets__typeahead & {
				padding: 0;
			}
		}
	}

	.search-facets__typeahead--open + .search-facets__group-selected + .search-facets__values-wrapper,
	.search-facets__typeahead--open + .search-facets__values-wrapper {
		display: none; //super hack-y way to hide the facet list when we use the typeahead beep boop

		& + .search-facets__group-more {
			display: none;
		}
	}

	.search-facets__typeahead {
		display: block;
		position: relative;
		margin-bottom: 20px;

		input {
			background-color: $iron;
			border: 0;
			color: $tundora;
			font-size: 14px;
			padding-left: 10px;
			height: 36px;
			width: 100%;
			// margin-bottom: 10px;
		}

		.search-facets__typeahead__list {
			@include font(futura, demi);
			letter-spacing: 0.94px;
			height: 24px;
			position: relative;
			color: $white;
			font-size: 14px;
			display: block;
			overflow: hidden;
			height: auto;

			.search-facets__typeahead__list-item {
				margin-bottom: 2px;
				display: block;
				position: relative !important;

				&:first-of-type {
					margin-top: 10px;
				}
			}

			.search-facets__checkbox--checked:after {
				display: block;
			}

			.search-facets__checkbox {
				margin-right: 10px;
			}
		}
	}

	.search-facets__checkbox--checked,
	.search-facets__checkbox--hover,
	
	li:hover > .search-facets__checkbox {
		background-color: $white !important;
	}

	.search {
		&__facets-wrapper {
			margin-top: 0;
		}
	}

	&__pagination {
		display: flex;
		justify-content: end;
		margin-top: 70px;
	}

	&__pagination-prev,
	&__pagination-next {
		@include font(futura);
		font-weight: 500;
		margin-right: 30px;
		color: #767676;
		cursor: pointer;

		&:hover {
			color: $fun-blue;
		}
	}

	&__pagination-next {
		margin-right: 0;
	}

	&__pagination-list {
		list-style: none;
		display: flex;
		margin-left: 0;
		margin-bottom: 0;

		li {
			@include font(futura);
			margin-right: 30px;
			font-size: 18px;
			line-height: 22px;
			color: #767676;
			cursor: pointer;
			font-weight: 500;

			&:hover {
				color: $fun-blue;
			}

			&.active {
				color: $fun-blue;
			}
		}
	}
}

.single-books {
	max-width: 850px;
	margin: 0 auto;
	margin-bottom: 70px;
	&__header {
		margin-bottom: 30px;
	}
	&__header-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
		@media (max-width: 767px) {
			flex-direction: column;
			align-items: normal;
			margin-bottom: 16px;
		}
	}
	&__header-title {
		font-size: 40px;
		line-height: 50px;
		margin-bottom: 0;
	}
	&__header-link {
		@include font(futura);
		text-transform: uppercase;
		font-weight: 600;
		font-size: 18px;
		color: $fun-blue;
		cursor: pointer;
		&:after {
			content: "\2192";
			padding-left: 8px;
			color: $black;
		}
	}
	&__header-content {
		font-size: 18px;
		line-height: 30px;
	}
	&__wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 40px;
		@media (max-width: 767px) {
			grid-template-columns: 1fr;
			gap: 20px;
		}
	}
}
.single-book {
	padding: 40px 40px 30px 40px;
	max-width: 405px;
	border: 1px solid #d9d9d9;
	@media (max-width: 767px) {
		padding: 30px;
		max-width: 100%;
	}
	&__image {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: center;
		margin-bottom: 36px;
		img {
			width: auto;
			height: 172px;
			@media (max-width: 767px) {
				height: 134px;
			}
		}
	}
	&__header {
		margin-bottom: 20px;
		@media (max-width: 767px) {
			margin-bottom: 48px;
		}
	}
	&__collection {
		@include font(futura);
		font-size: 14px;
		font-weight: 600;
		display: block;
		line-height: 20px;
		text-transform: uppercase;
		margin-bottom: 10px;
		min-height: 20px;
	}

	&__title {
		font-size: 18px;
		line-height: 25px;
		height: 75px;
		font-weight: 500;
		color: $fun-blue;
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box !important;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		white-space: normal;
		@media (max-width: 767px) {
			height: auto;
		}
	}
	&__footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__footer-side {
		display: flex;
		flex-direction: column;
	}
	&__tag {
		font-size: 16px;
		font-weight: 400;
		line-height: 21px;
		color: #767676;
	}
	&__tag-value {
		@include font(futura);
		font-size: 18px;
		font-weight: 500;
		line-height: 22px;
		color: $tundora;
	}
	&__footer-button {
		@include font("futura", demi);
		border: 2px $denim-blue solid;
		font-size: 16px;
		display: block;
		max-width: 310px;
		padding: 10px;
		text-transform: uppercase;
		text-align: center;
		width: 100%;
		letter-spacing: 1px;
		background-color: $denim-blue;
		color: $black-squeeze-blue;
		&:hover {
			color: $black-squeeze-blue;
		}
	}
}
