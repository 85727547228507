.session-heading {
  h1 {
    @include font(futura, demi);
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 7px;
  }

  .session-heading__meta {
    @include font(futura, regular);
    display: inline;
    color: $boulder;
    height: 40px;
    line-height: 34px;
    font-size: 20px;
    font-weight: 500;
    span:empty {
      display: none;
    }
    span {
      &:empty {
        display:none;
        
      }
      &:before {
        content: " - ";
      }

      &:first-of-type {
        &:before {
          content: "";
        }
      }
    }
    .session-heading__start-date {
      &:before {
        content: "";
      }
    }
  }
}
