.full-hero {
  position: relative;
  max-width: 2000px;
  margin: 0 auto;

  &:before {
    background: $black-squeeze-blue;
    position: absolute;
    height: 100%;
    width: 100%;
    content: "";
  }

  .container--100 {
    @media (max-width: $bp-max-medium) {
      margin: 0;
    }
  }

  .full-hero__inner {
    display: flex;
    flex-direction: row-reverse;

    @media (max-width: 1300px) {
      padding-left: 30px;
    }

    @media (max-width: $bp-max-medium) {
      display: block;
      padding-right: 30px;
    }
  }
}

.full-hero__details {
  flex-basis: calc(100% - 380px);
  /*align-self: center;*/
  padding-right: 30px;
  padding-bottom: 81px;
  padding-top: 82px;

  @media (max-width: $bp-max-medium) {
    padding-bottom: 56px;
    padding-top: 56px;
    padding-right: 0;
    flex-basis: auto;
  }

  .full-hero__title {
    @include font(futura, heavy);
    @include zero;
    font-size: 35px;
    line-height: 1.286;
    margin-bottom: 8px;
  }

  .full-hero__description {
    @include font(futura, regular);
    font-size: 22px;
    line-height: 1.455;
    margin-bottom: 24px;
  }

  .full-hero__cta {
    font-size: 16px;
    line-height: 1.375;
    margin-right: 22px;
  }

  .full-hero__link {
    @include font(futura, heavy);
    line-height: 1.112;

    @media (max-width: $bp-max-medium) {
      padding-top: 20px;
      padding-right: 0;
      letter-spacing: 0;
    }
  }
}

.full-hero__image {
  flex-basis: 380px;
  width: 380px;
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (max-width: $bp-max-medium) {
    flex-basis: auto;
    /*height: auto;*/
    margin-left: -30px;
    margin-right: -30px;
    width: calc(100% + 60px);
    z-index: 0;

    img {
      position: static;
      /*height: auto;*/
      max-height: 386px;
    }
  }

  @media (min-width: 1300px) {
    right: calc(654px - 50vw);
  }
  @media (min-width: 2000px) {
    right: -355px;
  }
}
