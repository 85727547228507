.accordion-component {
	max-width: 865px;
	width: 100%;
	margin: 0 auto;
	margin-bottom: 60px;

	@media (max-width: 767px) {
		margin-bottom: 40px;
	}

	&__header {
		margin-bottom: 30px;
	}

	&__header-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 767px) {
			flex-direction: column;
			align-items: normal;
			margin-bottom: 16px;
		}
	}

	&__header-title {
		font-size: 40px;
		line-height: 50px;
		margin-bottom: 8px;
	}

	&__header-link {
		@include font(futura);
		text-transform: uppercase;
		font-weight: 600;
		font-size: 18px;
		color: $fun-blue;
		cursor: pointer;

		&:after {
			content: "\2192";
			padding-left: 8px;
			color: $black;
		}
	}

	&__header-content {
		font-size: 18px;
		line-height: 30px;
	}

	&__accordion {
		display: flex;
		flex-direction: column;
		padding: 20px 0px;
		border-top: 1px solid #d9d9d9;

		&:last-child {
			border-bottom: 1px solid #d9d9d9;
		}

		&.is-open {
			.accordion-component__accordion-link {
				span {
					&:after {
						background-image: url("/assets/img/minus-icon.png");
						background-repeat: no-repeat;
						background-size: cover;
						content: "";
						height: 2px;
						width: 16px;
						margin-left: 12px;
					}
				}
			}

			.accordion-component__toggle-text.closed {
				display: none;
			}

			.accordion-component__toggle-text.opened {
				display: flex;
			}
		}

		img {
			width: 100%;
			object-fit: contain;
		}
	}

	&__accordion-link {
		background-color: transparent;
		border: 0;
		padding: 0;
		text-align: left;
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;

		h2 {
			color: $tundora;
			font-weight: 400;
			font-size: 18px;
			margin-bottom: 0;
			line-height: 30px;

			@media (max-width: 767px) {
				font-size: 16px;
				line-height: 18px;
			}
		}

		span {
			&:after {
				background-image: url("/assets/img/plus-icon-blue.png");
				background-repeat: no-repeat;
				background-size: cover;
				content: "";
				height: 16px;
				width: 16px;
				margin-left: 12px;
			}
		}
	}

	&__toggle-text {
		@include font(futura);
		font-size: 18px;
		font-weight: 500;
		line-height: 28px;
		color: $fun-blue;
		display: flex;
		align-items: center;

		@media (max-width: 767px) {
			font-size: 14px;
			line-height: 16px;
		}

		&.opened {
			display: none;
		}
	}

	&__accordion-content {
		font-size: 16px;
		line-height: 28px;
		font-weight: 400;
		margin-top: 10px !important;

		@media (max-width: 767px) {
			font-size: 14px;
			line-height: 16px;
		}
	}
}
