.table-responsive {
	width: 100%;
}

.table-responsive__mobile-label {
	display: none;

	@media (max-width: $bp-max-medium) {
		@include font(futura, demi);
		display: block;
		font-weight: bold;
		letter-spacing: 2px;
		padding: 5px;
		text-transform: uppercase;
		width: 50%;
	}
}

.table-responsive__mobile-text {
	@media (max-width: $bp-max-medium) {
		display: block;
		padding: 10px;
		width: 50%;
	}
}

.table-responsive td,
.table-responsive th {
	padding: 10px;

	@media (max-width: $bp-max-medium) {
		display: flex;
		padding: 0;

		&:first-child {
			border-left-width: 0;
		}

		&:last-child {
			border-right-width: 0;
		}
	}
}

.table-responsive tr:first-of-type {
	@media (max-width: $bp-max-medium) {
		display: none;
	}
}

.table-responsive tr {
	@media (max-width: $bp-max-medium) {
		display: block;
		padding-bottom: 20px;
		padding-top: 20px;
	}
}