$slideshow-dots-size: 45px;
$container-outer-gutter: 30px;

.promo-slideshow {

  .slick-slide {
  }

  .slick-slide:not(.slick-current) {
    visibility: hidden;
    opacity: 0;
    transition: all 300ms ease;
  }

  .slick-dots {
    position: static;
    margin-top: 24px;

    li {
      margin: 0 8px;
      // match specificy in origin css
      button {
        &::before {
          content: "";
          display: none;
        }
      }

      &:focus {
        // add another box shadow to show
        // rounded outline
        box-shadow: 0 0 0 2px $white, 0 0 0 4px $base-border-color,
          0 0 0 6px #5b9dd9;
      }
    }

    button,
    li {
      width: $slideshow-dots-size;
      height: 5px;
    }

    button {
      // width: 10px;
      // height: 10px;
      margin: 0 auto;
      padding: 4px;
      background-color: $grey-400;
      transition: background-color 0.3s;
    }

    .slick-active {
      button {
        background-color: $fun-blue;
      }
    }
  }

  @media (min-width: $bp-min-large) and (max-width: $bp-max-large) {
    margin-left: $container-outer-gutter;
    margin-right: $container-outer-gutter;
  }

  @media (max-width: $bp-max-medium) {
    .container__col {
      margin-bottom: 0;
    }
    .separating-rule {
      display: none;
    }
  }
}