.health-exec {
  .pull-quote {
    border-left: 5px solid $rouge;
    &:before {
      color: $moody-blue;
      opacity: 1;
      top: 30px;
      overflow: visible;
    }
  }
.pull-quote__quote {
  @include font(garamond, regular);
  color: $rhino;
}
  .pull-quote__caption {
    cite {
      display: block;
      color: $boulder;
      font-size: 16px;
      font-style: normal;
      text-align: left;
      font-family: $open-sans;
      @media(max-width: $bp-max-medium) {
        padding-left: 0;
      }
    }
  }
}
