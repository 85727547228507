$multi-image__bp-stack: 600px;
$multi-image__promo-space: 1%;

.multi-image {
	@include component-whitespace();
}

.multi-image__title {
	margin-bottom: 30px;
}

.multi-image__items {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.multi-image__caption {
	max-width: 700px;

	@media(max-width: $bp-max-small) {
		display: none;
	}
}

.multi-image__image {
	max-width: 100%;
	margin-right: $multi-image__promo-space;

	.image-block__media {
		border-bottom: none;
	}

	&:last-child {
		margin-right: 0;
	}

	// for multi-row images, we need
	// to make some spacing adjustments
	@include at-least(4) {

		&:nth-child {
			@media(min-width: $bp-min-medium) {
			}
			@media(min-width: $bp-min-large) {
			}
		}
		&:nth-child(3n) {
			@media(min-width: $bp-min-large) {
				margin-right: 0;
			}
		}
	}

	// fix IE flexbox bug to prevent text overflow
	.image-block {
		display: block;
		width: 100%;

	}

	.image-block__title {
		display: none;
	}

	.image-block__media {
		// hide captions in larger viewports
		figcaption {
			display: none;
		}
	}

	@media(min-width: $bp-min-medium) {
		width: 50% - $multi-image__promo-space;
	}

	@media(min-width: $bp-min-large) {
		width: 33.333% - $multi-image__promo-space;

		@include exactly(3) {
			width: 33.333% - $multi-image__promo-space;
		}
	}


	// for 3 across, apply the same spacing
	// adjustments we do for 4 and 5 items,
	// but only at this breakpoint, since
	// 3-across is single-row at larger sizes
	@media(max-width: $bp-max-medium) {
		@include exactly(3) {
			&:nth-child(2n) {
				margin-right: 0;
			}

			// re-add space back to last child or
			// flexbox won't calc space between right
			&:last-child {
				margin-right: $multi-image__promo-space;
			}
		}

	}

	@media(max-width: $multi-image__bp-stack) {
		width: 100%;
		margin-right: 0;

		.image-block__media {
			figcaption {
				display: block;
			}
		}
	}
}

// 70-column
.container--70-30 .container__col:first-child,
.container--30-70 .container__col:last-child {

	.multi-image__image {
		@media(max-width: $bp-max-large) {
			width: 100%;
		}
	}
}

// 30-column and 50-column
.container--70-30 .container__col:last-child,
.container--30-70 .container__col:first-child,
.container--50-50 .container__col {
	.multi-image__image {
		width: 100%;
	}
}
