.sitemap {
  background: $black-squeeze-blue;
  padding: 80px 0;

  .container {
    margin-bottom: 0;
  }

  .sitemap__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;

    @media (max-width: $bp-max-medium) {
      display: flex;
      flex-direction: column;
    }
  }
}

.sitemap__item {
  background: #fff;
  padding: 40px 32px 40px 48px;

  .sitemap__title {
    @include font(futura, heavy);
    @include zero;
    color: $fun-blue;
    font-size: 30px;
    line-height: 1.5;
    margin-bottom: 8px;
    position: relative;

    &:before {
      background: $sea-buckthorn-orange;
      content: " ";
      height: 45px;
      left: -48px;
      position: absolute;
      top: 0;
      width: 5px;
    }
  }

  .sitemap__links {
    list-style: none;
    @include zero;

    li {
      @include zero;

      & + li {
        margin-top: 16px;
      }

      a {
        @include font(futura, regular);
        color: $tundora;
        font-size: 22px;
        line-height: 1.455;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
