// image-block.scss
// base

.image-block {
  @include component-whitespace();
}
// image media

.image-block__media {
  // image
  // border-bottom: 1px solid $grey-300;
  @include font(futura);
  font-weight: 500;
  color: $dove-gray;
  font-size: 16px;

  img {
    display: block;
    width: 100%;
  }
  // figcaption
  figcaption {
    padding-top: 20px;
    padding-bottom: 35px;
    font-size: 16px;
    line-height: 1.45;
    max-width: 700px;
  }
}

.image-block__title {
  @include font(futura, demi);
  font-size: 30px;
  color: $tundora;
  margin-bottom: 20px;
}
