.events-pricing {
  @include font("futura", regular);
  background-color: $white-lilac;
  padding: 42px 32px 32px 30px;
  color: $tundora;
  margin-bottom: 38px;
  position: relative;
  &:before {
    content: "";
    background-color: $havelock-blue;
    display: block;
    height: 5px;
    width: 125px;
    position: absolute;
    top: 0;
    left: 0;
  }
  h2,
  h3 {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 20px;
  }

  .events-pricing__list {
    font-weight: 600;
    list-style-type: none;
    line-height: 36px;
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 0;

    li {
      margin-bottom: 0;
    }
  }

  .events-pricing__price {
    font-weight: 600;
    color: $rouge;
  }
}
