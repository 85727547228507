$micro-nav-left: 30px;
$micro-nav-right: 17px;
$micro-nav-seventy-left: 33px;
.microsite-nav {
	background: $tundora;
	color: $white;
	padding-bottom: 40px;
	padding-top: 40px;
	position: relative;
	@media(max-width: $bp-max-medium) {
		padding-left: 20px;
		padding-right: 20px;
	}

	&:before {
		background: $tundora;
		content: "";
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		width: 100vw;
		z-index: -1;
		@media(max-width: $bp-max-medium) {
			display: none;
		}
	}

	&:after {
		background: $buttercup-yellow;
		content: "";
		height: 5px;
		left: 0;
		position: absolute;
		top: 0;
		width: 100px;
	}

	.container--70-30 & {
		padding-left: 33px;
		padding-right: 17px;

		&:before {
			display: none;
			right: auto;
			left: 0;
		}
	}
}

.microsite-nav__heading {
	@include font(futura, bold);
	font-size: 30px;
	color: $white;
	margin-bottom: 37px;
	margin-top: -16px;
}

.microsite-nav__menu {
	list-style-type: none;
	margin-bottom: 0;
	margin-left: 0;
}

.microsite-nav__item {
	margin-bottom: 30px;

	&:last-child {
		margin-bottom: 0;
	}

	.microsite-nav__link,
	a {
		@include font(futura, demi);
		color: $white;
		font-size: 18px;
		letter-spacing: 2px;
		text-transform: uppercase;
		transition: color ease-in-out 0.3s;

		&.is-active,
		&:hover {
			color: $buttercup-yellow;
		}
		@media print {
			font-size: 10px;
		}
	}
}
//this is used on book detail page

.microsite-nav__show-more {
	background-color: $denim-blue;
	color: $white;
	cursor: pointer;
	display: block;
	margin-left: -$micro-nav-seventy-left;
	padding: 12px;
	visibility: hidden; // display none by default, show with js if number of list items is > 5
	width: calc(100% + #{$micro-nav-seventy-left} + #{$micro-nav-right});

	svg {
		display: block;
		height: 16px;
		margin: 0 auto;
		width: 10px;
	}

	&.is-open {
		svg {
			transform: rotate(180deg);
		}
	}
}

.microsite-nav--books {
	padding-bottom: 0;

	&:after {
		left: $micro-nav-left;
	}

	& > .microsite-nav__menu {
		li:nth-child(n+6) {
			display: none; //hide all but first five on page load
		}
	}
}
