.health-exec {
  .image-block {
    .image-block__title {
      @include font(open-sans, bold);
      font-size: 24px;
    }

    figcaption {
      @include font(open-sans, regular);
      max-width: 100%;
      font-size: 20px;
    }
  }
}
