.utility-nav {
  > ul {
    @include zero-list;
    align-items: center;
    display: flex;
  }

  > ul > li {
    @include font(futura, demi);
    color: $tundora;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 24px;

    @media (max-width: $bp-max-medium) {
      color: $white;
      margin-right: 0;
      margin-bottom: 20px;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: $grey-500;
      }

      &:not(.btn) {
        @media (max-width: $bp-max-medium) {
          padding: 0;
        }
      }
    }

    &.utility-nav__item--alt {
      color: $fun-blue;
      font-weight: 700;

      @media (max-width: $bp-max-medium) {
        color: $white;
      }
    }
  }

  .mobile-header-callout {
    .header-callout-button {
      display: none;

      @media (max-width: $bp-max-medium) {
        display: inline-block;
        margin-bottom: 10px;
        margin-left: 0;
      }
    }
  }
}
