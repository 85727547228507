$rich-text__bottom-space: 30px;
$rich-text__font-size: 18px;
$rich-text__video-width: 328px;
$rich-text__child-list: 30px;

.rich-text {
	@include component-whitespace;
	font-size: $rich-text__font-size;
	line-height: 30px;
	// all textual element on the page should receive a max-width
	// and be centered. Embeds should continue to be 100%;
	& > div:not(.rich-text__embed),
	blockquote,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	ol,
	p,
	table,
	ul {
		max-width: 850px;
        margin-left: auto;
		margin-right: auto;
	}

	li,
	p {
		// font-size: inherit;
		line-height: 30px;

		& a {
			font-weight: bold;
		}
	}

	h1 {
		font-size: 80px;
		font-weight: bold;
		line-height: 80px;
	}

	h2 {
		font-size: 45px;
		font-weight: bold;
		line-height: 50px;
	}

	h3 {
		font-size: 36px;
		font-weight: 400;
		line-height: 46px;
	}

	h4 {
		font-size: 30px;
		font-weight: 600;
		line-height: 38px;
	}

	h5 {
		font-size: 20px;
		font-weight: 600;
		line-height: 34px;
	}
	.pull-quote:before {
		overflow: visible;
		top: 30px;
	}
	blockquote:not(:has(.pull-quote__quote)) {

		@media(max-width: $bp-max-small) {
			margin: 20px;
		}

		cite {
			@include font(futura, demi);
			text-align: right;
			margin-top: 30px;
			display: block;
		}
	}

	ol,
	ul {
		margin-top: 45px;
		margin-bottom: 45px;
		list-style: none;

		ol,
		ul {
			margin-top: 10px;
			margin-bottom: 0;
		}
	}

	ol,
	ul {
		line-height: 1.6;

		li {
			margin-left: 25px;
			padding-left: 10px;

			&:before {
				color: $denim-blue;
				display: inline-block;
				width: 40px;
				margin-left: -40px;
				@media(max-width: $bp-max-medium) {
					width: 26px;
					margin-left: -26px;
				}
			}

			ol ol li,
			ul ul li {
				margin-left: 55px;
			}
		}
	}
	//color overrides
	ul {
		li {
			&::before {
				font-size: 18px;
				vertical-align: text-bottom;
				line-height: 24px;
				content: "\25cf";
				font-weight: 600;
			}

			& > ul {
				li {
					&::before {
						//first child
						content: "\2014";
						color: #1970B1;
						font-size: 22px;
						font-weight: 600;
						width: $rich-text__child-list;
					}
				}

				& > ul li {
					&::before {
						//second child
						content: "\25A0" !important;
						font-size: 16px;
						font-weight: 600;
						width: $rich-text__child-list;
					}
				}
			}
		}
	}

	ol {
		counter-reset: li;

		li {
			&::before {
				content: counters(li, ".") " ";
				counter-increment: li;
				@include font(futura);
				font-weight: 500;
			}
		}

		& ol ol {
			counter-reset: nested-li;

			& li::before {
				content: counter(nested-li, upper-alpha);
				counter-increment: nested-li;
				@include font(futura);
				font-weight: 500;
				width: $rich-text__child-list;
			}
		}
	}

	hr {
		margin-bottom: $rich-text__font-size;
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid $black-squeeze-blue;
		padding: 0;
	}

	table {
		clear: both;
		margin-top: 45px;
		margin-bottom: 45px;

		caption {
			@include font(futura);
		}

		th {
			background-color: transparent;
			border-bottom: $tundora solid 2px;
			border-top: none;
			border-left: none;
			border-right: none;
			@include font(futura);
			text-transform: uppercase;
			font-weight: 600;
		}

		td {
			border-right: none;
			border-left: none;
			padding: 5px;
			border-bottom: 1.5px solid $grey-200;
			@media (max-width: $bp-max-medium) {
				border: 0;
			}
		}

		tr {
			&+tr {
				@media (max-width: $bp-max-medium) {
					border-top: 2px solid $iron;
				}
			}

			&:nth-child(2) {
				@media (max-width: $bp-max-medium) {
					border-top: 2px solid $dove-gray;
				}
			}
		}

		caption {
			margin-bottom: calc(#{$rich-text__bottom-space} / 2);
			font-weight: bold;
		}
	}
}

div,
section {
	.rich-text__embed {
		&:not(.l-rte-pull-left),
		&:not(.l-rte-pull-right) {
			display: flex;
			justify-content: center;
			max-width: 100%;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				max-width: none;
			}

			.image-block__media {
				text-align: center;

				figcaption {
					text-align: left;
				}
			}

			.video-block {
				width: 100%;
			}

			.video-block__media {
				width: 100%;
			}
		}

		&.l-rte-pull-left,
		&.l-rte-pull-right {
			max-width: 40%;

			.image-block,
			.video-block {
				margin-bottom: 0;
			}

			.image-block__media figcaption,
			.video-block__caption {
				padding-bottom: 0;
			}

			.image-block__media {
				border-bottom: 0;
			}

			.video-block,
			.video-block__media {
				// don't use percentages here
				// embed parent has no explicit width
				min-width: $rich-text__video-width;
			}

			.video-block__media-placeholder {
				// for consistency, restrain
				// placeholder image to video width
				width: $rich-text__video-width;
			}
			@media(max-width: $bp-max-medium) {
				float: none;
				width: 100%;
				max-width: none;
				margin-left: 0;
				margin-right: 0;

				.video-block,
				.video-block__media {
					min-width: 100%;
				}
			}
		}

		.image-block__media {
			img {
				max-width: 100%;
				width: auto;
			}
		}
	}
}

.container--100 {
	.rich-text {
		margin-left: auto;
		margin-right: auto;
	}
}

.rich-text--breakout {
	max-width: none;
	width: 100%;
	// all textual element on the page should receive a max-width
	// and be centered. Embeds should continue to be 100%;
	& > div:not(.rich-text__embed),
	blockquote,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	ol,
	p,
	table,
	ul {
		max-width: none;
	}
}
