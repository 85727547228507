$list-item-media-margin: 30px;

.health-exec {
	.list-item {
		display: flex;
		align-items: left;
		flex-direction: column;

		&.is-left,
		&.is-right {
			align-items: flex-start;
		}

		&.is-left {
			flex-direction: row;

			.list-item__media {
				margin-right: $list-item-media-margin;
			}
		}

		&.is-right {
			flex-direction: row-reverse;

			.list-item__media {
				margin-left: $list-item-media-margin;
				margin-bottom: 0;
			}
		}
		@media(max-width: $bp-max-medium) {
			&.is-left,
			&.is-right {
				flex-direction: column;

				.list-item__media {
					margin-bottom: $list-item-media-margin;
					margin-left: 0;
					margin-right: 0;
				}
			}
		}
	}

	.list-item__media {
		line-height: 0;
		position: relative;
		margin-bottom: $list-item-media-margin;

		&:after {
			content: "";
			background-color: $rouge;
			bottom: -3px;
			height: 3px;
			left: 0;
			position: absolute;
			width: 138px;
			z-index: 3;
		}
	}

	.list-item__content {
		flex: 1;
	}

	.list-item__eyebrow {
		@include font(open-sans, regular);
		color: $rouge;
		text-transform: uppercase;
		font-size: 16px;
		margin-bottom: 10px;
		line-height: 45px;
	}

	.list-item__title {
		@include font(garamond, bold);
		color: $rhino;
		font-size: 35px;
		margin-bottom: 5px;
		line-height: 37px;

		a {
			@include font(garamond, bold);
			color: $rhino;
		}
	}

	.list-item__subtitle {
		@include font(open-sans, bold);
		color: $tundora;
		font-size: 18px;
		line-height: 1.3;
	}

	.list-item__description {
		@include font(open-sans, regular);
		color: $tundora;
		font-size: 20px;
		margin-top: 19px;
		max-width: 800px;
	}

	.list-item__meta {
		@include clearfix;
		line-height: 1.5;
		margin-bottom: $list-item-media-margin;
	}

	.list-item__date {
		font-style: italic;
	}

	.list-item__byline {
		border-left: 1px solid $grey-300;
		margin-left: 0.5em;
		padding-left: 0.75em;
	}

	.list-item__cta {
		text-transform: uppercase;
		@include font(open-sans, regular);
		color: $rhino;
		display: block;
		font-weight: bold;
		text-align: left;
		text-transform: uppercase;
		font-size: 18px;

		&::after {
			content: ' →';
			display: inline-block;
			vertical-align: bottom;
			padding-bottom: 2px;
			margin-left: 10px;
		}
	}
}

.blog-list {
    .list-item {
        display: flex;
        align-items: left;
        flex-direction: column;

        &.is-left,
        &.is-right {
            align-items: flex-start;
        }

        &.is-left {
            flex-direction: row;

            .list-item__media {
                margin-right: $list-item-media-margin;
								width: 240px;
								height: 240px;
            }

						.list-item__media img{
							width: 240px;
							height: 240px;
							object-fit: cover;
            }
        }

        &.is-right {
            flex-direction: row-reverse;

            .list-item__media {
                margin-left: $list-item-media-margin;
                margin-bottom: 0;
            }
        }

        @media(max-width: $bp-max-medium) {
            &.is-left,
            &.is-right {
                flex-direction: column;

                .list-item__media {
                    margin-bottom: $list-item-media-margin;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }

    .list-item__media {
        line-height: 0;
        position: relative;
        margin-bottom: $list-item-media-margin;
				width: 240px;
				height: 240px;
        &:after {
            content: "";
            background-color: $buttercup-yellow;
            bottom: -3px;
            height: 3px;
            left: 0;
            position: absolute;
            width: 138px;
            z-index: 3;
        }
    }

    .list-item__content {
        flex: 1;
    }

    .list-item__eyebrow {
        @include font(open-sans, regular);
        color: $buttercup-yellow;
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: 10px;
    }

    .list-item__title {
        font-family: "futura-pt", Helvetica, Arial, sans-serif !important;
        color: $rhino;
        font-size: 35px;
        margin-bottom: 5px;

        a {
            font-family: "futura-pt", Helvetica, Arial, sans-serif !important;
            color: $rhino;
        }
    }

    .list-item__subtitle {
        @include font(open-sans, bold);
        color: $tundora;
        font-size: 18px;
    }

    .list-item__description {
        @include font(open-sans, regular);
        color: $tundora;
        font-size: 20px;
        margin-top: 19px;
        max-width: 800px;
    }

    .list-item__meta {
        @include clearfix;
        line-height: 1.5;
        margin-bottom: $list-item-media-margin;
    }

    .list-item__date {
        font-style: italic;
    }

    .list-item__byline {
        border-left: 1px solid $grey-300;
        margin-left: 0.5em;
        padding-left: 0.75em;
    }

    .list-item__cta {
        text-transform: uppercase;
        @include font(open-sans, regular);
        color: $rhino;
        display: block;
        font-weight: bold;
        text-align: left;
        text-transform: uppercase;
        font-size: 18px;

        &::after {
            content: ' →';
            display: inline-block;
            vertical-align: bottom;
            padding-bottom: 2px;
            margin-left: 10px;
        }
    }
}
