.featured-events {
	width: 100%;
	max-width: 849px;
	margin: 0 auto;
	margin-bottom: 60px;

	@media (max-width: 767px) {
		margin-bottom: 40px;
	}

	&__header {
		margin-bottom: 30px;
	}

	&__header-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 767px) {
			flex-direction: column;
			align-items: normal;
			margin-bottom: 16px;
		}
	}

	&__header-title {
		font-size: 40px;
		line-height: 50px;
		margin-bottom: 8px;
	}

	&__header-link {
		@include font(futura);
		text-transform: uppercase;
		font-weight: 600;
		font-size: 18px;
		color: $fun-blue;
		cursor: pointer;
		&:after {
			content: "\2192";
			padding-left: 8px;
			color: $black;
		}
	}

	&__header-content {
		font-size: 18px;
		line-height: 30px;
	}

	&__row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 40px;

		@media (max-width: 767px) {
			grid-template-columns: 1fr;
			grid-gap: 35px;
		}
	}

	// default event

	&__event {
		height: 327px;
		display: flex;
		border: 1px solid #d9d9d9;
	}

	&__event-content {
		padding: 38px 46px 38px 38px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		z-index: 1;
		width: 100%;

		@media (max-width: 767px) {
			padding: 20px 32px 32px 30px;
		}
	}

	&__event-type {
		@include font(futura);
		color: $tundora;
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 15px;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 0.08em;
		display: block;
	}

	&__event-title {
		font-size: 25px;
		font-weight: 500;
		line-height: 30px;
		color: $fun-blue;
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box !important;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		white-space: normal;
		padding-right: 16px;

		@media (max-width: 767px) {
			-webkit-line-clamp: 4;
		}
	}

	&__event-date {
		font-size: 18px;
		line-height: 22px;
		font-weight: 500;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 15px;
	}

	&__start-date {
		@include font(futura);
		color: $tundora;
		font-weight: 500;
	}

	&__end-date {
		@include font(futura);
		color: $tundora;
		font-weight: 500;
	}

	&__event-footer {
		display: flex;
	}

	&__event-footer-side {
		display: flex;
		flex-direction: column;
		flex-basis: 50%;
	}

	&__event-tag {
		@include font(georgia);
		color: $tundora;
		font-size: 16px;
		line-height: 22px;
		opacity: 0.8;
	}

	&__event-value {
		@include font(futura);
		color: $tundora;
		font-size: 18px;
		line-height: 22px;
		font-weight: 500;
	}

	&__event-button {
		@include font(futura);
		padding: 10px 18px;
		color: $white;
		background-color: $fun-blue;
		font-size: 16px;
		font-weight: 500;
		text-transform: uppercase;

		&:hover {
			color: white;
		}
	}

	// cluster event

	&__event--cluster {
		background-color: $fun-blue;
		color: $white;
		position: relative;

		&:after {
			content: "";
			height: 52px;
			width: 6px;
			background-color: #f4ad3d;
			position: absolute;
			top: 42px;
			left: 0;
			z-index: 8;
		}

		.featured-events__event-bg {
			background-image: url("/assets/img/exit-links/pattern.png");
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0.3;
		}

		.featured-events__event-type {
			color: $white;
		}

		.featured-events__event-title {
			color: $white;
			-webkit-line-clamp: 2;
		}

		.featured-events__start-date {
			color: $white;
		}

		.featured-events__end-date {
			color: $white;
		}

		.featured-events__event-description {
			display: block;
			margin-top: 15px;
			font-size: 18px;
			line-height: 24.5px;
			font-weight: 400;
			text-overflow: ellipsis;
			overflow: hidden;
			display: -webkit-box !important;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			white-space: normal;
		}

		.featured-events__event-button {
			@include font(futura);
			padding: 10px 18px;
			color: $fun-blue;
			background-color: $white;
			font-size: 16px;
			font-weight: 500;
			text-transform: uppercase;

			&:hover {
				color: $fun-blue;
			}
		}
	}

	// special event

	&__special-event {
		background-color: $fun-blue;
		height: 327px;
		display: flex;
		margin-bottom: 35px;

		@media (max-width: 767px) {
			flex-direction: column;
			height: auto;
		}

		&--purple {
			background-color: #2a1853;
		}
	}

	&__special-event-image {
		flex-basis: 49%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;

			@media (max-width: 767px) {
				height: 300px;
			}

			@media (max-width: 510px) {
				height: 147px;
			}
		}
	}

	&__special-event-content {
		padding: 38px 82px 38px 32px;
		color: $white;
		flex-basis: 51%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;

		@media (max-width: 767px) {
			padding: 30px;
		}
	}

	&__special-event-type {
		@include font(futura);
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 15px;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 0.08em;
		display: block;
	}

	&__special-event-title {
		font-size: 25px;
		font-weight: 500;
		line-height: 30px;
		padding-right: 16px;
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box !important;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		white-space: normal;

		@media (max-width: 767px) {
			padding-right: 0;
			-webkit-line-clamp: 4;
		}
	}

	&__special-event-date {
		@include font(futura);
		font-size: 18px;
		line-height: 22px;
		font-weight: 500;
		display: block;
		margin-bottom: 15px;
		display: flex;
		flex-wrap: wrap;

		.featured-events__start-date,
		.featured-events__end-date {
			color: $white;
		}
	}

	&__special-event-footer {
		display: flex;
	}

	&__special-event-button {
		@include font(futura);
		padding: 10px 18px;
		color: $fun-blue;
		background-color: $white;
		font-size: 16px;
		font-weight: 500;
		text-transform: uppercase;

		&:hover {
			color: $fun-blue;
		}
	}
}
