$location-margin: 38px;
.event-hero {
  @include font(futura, demi);
  padding: 55px 92px 45px 0;
  position: relative;
  background-color: $black-squeeze-blue;
  &:before {
    content: "";
    background-color: $black-squeeze-blue;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: -1;
  }
  @media (max-width: $bp-max-medium) {
    padding: 20px;
    &:before {
      content: "";
      display: none;
    }
  }

  .event-hero__name {
    font-size: 45px;
    font-weight: 600;
    line-height: 51px;
    margin-bottom: 8px;
    max-width: 766px;
    display: block;
  }
  .event-hero__tagline {
    color: $boulder;
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    max-width: 766px;
    display: block;
  }
  .event-hero__links {
    position: relative;
    display: flex;
    margin-top: 31px;
    @media (max-width: $bp-max-medium) {
      flex-direction: column;
    }
  }
  .event-hero__cta {
    @include font(futura, regular);
    border: 2px solid $denim-blue;
    color: $denim-blue;
    font-weight: 600;
    display: block;
    padding: 11px 22px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 18px;
    margin-right: 90px;
    @media (max-width: $bp-max-medium) {
      padding: 11px 22px;
    }
  }
  .event-event-hero__register {
    @include font(futura, demi);
    color: $denim-blue;
    font-weight: 600;
    display: block;
    padding: 10px 22px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 18px;
    height: 23px;
  }
  .event-hero__locations {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 70px;
    margin-left: 0;
    margin-bottom: -$location-margin; // cancel out li margin for bottom row
    @media (max-width: $bp-max-medium) {
      flex-direction: column;
    }
    li {
      width: 33.33%;
      border-left: 2px $tundora solid;
      padding-left: 6%;
      padding-right: 6%;

      margin-bottom: $location-margin;
      @media (max-width: $bp-max-medium) {
        border-left: none;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
      .event-hero__location {
        line-height: 28px;
        font-size: 24px;
        font-weight: 700;
        color: $tundora;
        letter-spacing: 1px;
        margin-bottom: 8px;
        display: block;
        min-height: 28px;
      }
      .event-hero__dates {
        line-height: 105%;
        letter-spacing: 1px;
        font-size: 18px;
        font-weight: 600;
        display: block;
      }

      &:first-of-type,
      &:nth-of-type(4) {
        border-left: none;
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}
