$headline-fill: transparent;

.headline {
  @include component-whitespace();
  background-color: $headline-fill;
  padding: 40px;
  padding-top: 0;
  text-align: center;
  margin-bottom: 0;

  @media (max-width: $bp-max-medium) {
    padding: 30px;
  }
}

.headline__title {
  @include font("futura", extra-bold);
  color: $tundora;
  font-size: 45px;
  line-height: 1.2444;
  margin-bottom: 38px;

  @media (max-width: $bp-max-small) {
    font-size: 35px;
    line-height: 1.1428;
  }
}

.headline__link {
  @include font("futura", extra-bold);
  color: $fun-blue;
  border: 2px solid $fun-blue;
  font-size: 18px;
  line-height: 1.111;
  text-decoration: none;
  text-transform: uppercase;
  padding: 12px 29px 13px;
  display: inline-block;

  @media (max-width: $bp-max-medium) {
    display: inline-block;
  }

  @media (max-width: $bp-max-small) {
    display: inline-block;
    font-size: 19px;
    min-width: 210px;
    max-width: 240px;
    text-align: left;
    position: relative;
    padding-left: 8px;
    padding-right: 8px;

    span {
      display: block;
      width: calc(100% - 24px);
    }
  }

  &:after {
    content: "\2192";
    color: $grey-800;
    padding: 0 0 0 5px;

    @media (max-width: $bp-max-small) {
      position: absolute;
    }
  }

  &:hover {
    background: $fun-blue;
    color: #fff;

    &:after {
      color: #fff;
    }
  }
}
/*
.headline__link:after {
  content: "\2192";
  color: $fun-blue;
  padding: 0 5px;
}*/
