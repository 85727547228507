.book-teaser {
	max-width: 856px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	margin-bottom: 60px;

	@media (max-width: 767px) {
		margin-bottom: 40px;
	}

	&__header {
		margin-bottom: 30px;
	}

	&__header-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 767px) {
			flex-direction: column;
			align-items: normal;
			margin-bottom: 16px;
		}
	}

	&__header-title {
		font-size: 40px;
		line-height: 50px;
		margin-bottom: 8px;
	}

	&__header-link {
		@include font(futura);
		text-transform: uppercase;
		font-weight: 600;
		font-size: 18px;
		color: $fun-blue;
		cursor: pointer;

		&:after {
			content: "\2192";
			padding-left: 8px;
			color: $black;
		}
	}

	&__header-content {
		font-size: 18px;
		line-height: 30px;
	}

	&__item {
		width: 100%;
		height: 287px;
		display: grid;
		grid-template-columns: 0.97fr 1.03fr;

		@media (max-width: 767px) {
			grid-template: none;
			height: auto;
		}
	}

	&__image {
		height: 100%;
		width: 100%;
		object-fit: cover;

		@media (max-width: 767px) {
			height: 147px;
		}
	}

	&__content {
		padding: 40px 34px;
		color: $white;
		background-color: $fun-blue;

		@media (max-width: 767px) {
			padding: 28px 30px 38px 30px;
		}
	}

	&__title {
		@include font(futura);
		font-size: 25px;
		line-height: 29px;
		font-weight: 500;
		margin-bottom: 15px;
		display: block;

		@media (max-width: 767px) {
			font-size: 20px;
			line-height: 23px;
		}
	}

	&__button {
		@include font(futura);
		color: $tundora;
		padding: 10px 18px;
		font-size: 16px;
		line-height: 20px;
		text-transform: uppercase;
		font-weight: 600;
		background-color: $saffron;
		display: inline-block;
	}
}
