// connect variables
$connect-icon-size: 44px !default;
$connect-icon-spacing: 10px !default;
$connect-icon-fill: currentColor !default;
$connect-phone-size: 24px !default;
$connect-promo-size: 24px !default;
$connect-bp-small: 570px !default;

.connect {
  a {
    font-weight: 600;
    text-decoration: none;
  }
}

.connect__button {
  margin-bottom: 30px;
}

.connect__inner {
  align-items: center;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: $bp-max-medium) {
    flex-wrap: wrap;
  }

  @media (max-width: $connect-bp-small) {
  }

  @media (max-width: $bp-max-small) {
    display: block;
  }
}

.connect__social {
  padding: 0 0 16px;
  order: 4;
  white-space: nowrap;

  /*@media (max-width: $bp-max-medium) {
    padding: 0 0 12px;
  }*/

  @media (max-width: $connect-bp-small) {
    padding: 0 0 36px;
    width: 100%;
  }

  a {
    display: inline-block;
    line-height: 1;
    margin-left: $connect-icon-spacing;
    text-decoration: none;

    &:first-child {
      margin-left: 0;
    }
  }
}

.connect__phone {
  margin-bottom: 30px;
}

.connect__promo-statement {
  margin-bottom: 30px;
}

.connect__social-icon {
  fill: $connect-icon-fill;
  height: $connect-icon-size;
  width: $connect-icon-size;

  &.--facebook {
    fill: $facebook;
  }
  &.--twitter {
    fill: $twitter;
  }
  &.--linkedin {
    fill: $linkedin;
  }
  &.--google {
    fill: $google-plus;
  }
  &.--instagram {
    fill: $instagram;
    padding: 2px;
  }
  &.--rss {
    fill: $rss;
  }
}
