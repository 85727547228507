.content-grid {
  width: 100%;
  position: relative;
  background-color: $black-squeeze-blue;
  padding: 10px 0;
}

.content-grid__list {
  @include component-whitespace;
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: repeat(4, 1fr);
  -ms-grid-rows: 1fr 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 10px auto;
  max-width: $max-width;
  @media (max-width: $bp-max-medium) {
    grid-template-columns: auto;
    grid-template-rows: repeat(4, 1fr);
    -ms-grid-rows: 1fr 1fr 1fr 1fr; //IE11
    -ms-grid-columns: 1fr 1fr 1fr; //IE11
  }

  @supports (display: grid) {
    display: grid;
    @media (max-width: $bp-max-small) {
      display: flex;
      flex-direction: column;
    }
  }
  @media (max-width: $bp-max-small) {
    display: flex;
    flex-direction: column;
  }
}

.content-grid__item {
  @include font(futura);
  background-color: $white;
  display: flex;
  flex-direction: column;
  hyphens: auto;
  justify-content: space-between;
  list-style-type: none;
  margin-bottom: 0;
  position: relative;
  padding: 20px;

  & a:focus {
    // for testing tabbing
    // border: red 3px solid;
  }

  .content-grid__content-type {
    text-transform: uppercase;
    display: block;
    margin-bottom: 10px;
  }

  .content-grid__title {
    font-size: 30px;
    display: block;
    flex-grow: 2;
  }

  .btn {
    align-self: baseline;
  }

  &--featured {
    grid-column-start: span 2;
    grid-row-start: span 2;
    background-size: cover;
    padding: 0;
    -ms-grid-row-span: 2; //IE11
    -ms-grid-column-span: 2; //IE11
    overflow: hidden; //IE11

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      @media (max-width: $bp-max-small) {
        // object-fit: contain;
        width: 100%;
        height: auto;
        min-height: 1px; //IE11
        display: block;
      }
    }

    .content-grid__title {
      font-size: 40px;
      line-height: 48px;
    }

    .content-grid__featured-content {
      bottom: 0;
      color: $white;
      background-color: $fun-blue;
      position: absolute;
      width: 80%;
      padding: 40px;

      a {
        color: $white;

        &:after {
          color: $white;
        }
      }
      @media (max-width: $bp-max-medium) {
        padding: 20px;
      }
      @media (max-width: $bp-max-small) {
        display: block;
        width: 100%;
        position: relative;
        padding: 20px;
      }
    }
    @media (max-width: $bp-max-medium) {
      grid-column-start: span 2;
      grid-row-start: span 1;
      -ms-grid-column-span: 2; //IE11
      -ms-grid-row-span: 1; //IE11
    }
    @media (max-width: $bp-max-small) {
      justify-content: flex-start;
    }
  }

  &--video {
    grid-column-start: span 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    -ms-grid-column-span: 2; //IE11
    -ms-grid-column: 3; //IE11

    & > div {
      vertical-align: middle;
    }

    .content-grid__video-image {
      position: relative;
      margin-right: 10px;

      a {
        display: block;
        width: 100%;
        height: 100%;
      }

      img {
        object-fit: contain;
        height: auto;
        width: 100%;
      }
      .video-block__play-button {
        @media (max-width: $bp-max-small) {
          height: 80px;
          width: 80px;
          top: calc(50% - 40px);
          left: calc(50% - 40px);
          position: absolute;
        }
      }
      // .content-grid__video-button {
      //   position: absolute;
      //   top: 10%;
      //   left: 20%;
      //   height: 150px;
      //   width: 150px;
      //   border-radius: 50%;
      //   border: 1px solid $white;
      //   svg {
      //     fill: $white;
      //   }
      //   @media (max-width: $bp-max-medium) and (min-width: $bp-min-medium) {
      //     top: 15%;
      //     left: 25%;
      //     height: 100px;
      //     width: 100px;
      //   }
      // }
      @media (max-width: $bp-max-medium) {
        width: 100%;
        margin-right: 0;
      }
    }

    .content-grid__video-content {
      width: 60%;
      @media (max-width: $bp-max-medium) {
        width: 100%;
        padding: 20px;
      }
    }
    @media (max-width: $bp-max-medium) {
      grid-column-start: 3;
      grid-row-start: span 1;
      -ms-grid-column-span: 1; //IE11
      -ms-grid-column: 3; //IE11
      flex-direction: column-reverse;
      padding: 0;
      justify-content: space-around;
    }
    @media (max-width: $bp-max-small) {
      grid-column-start: span 2;
      grid-row-start: 3;
    }
  }

  &:last-of-type {
    background-color: $fun-blue;
    color: $white;

    a {
      color: $white;

      &:after {
        color: $white;
      }
    }
  }
  //It's grid item IE11 fix time (╯°□°）╯︵ ┻━┻
  margin: 5px;
  @supports (display: grid) {
    margin: 0;
  }

  &:nth-of-type(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 2;
    @media (max-width: $bp-max-medium) {
      -ms-grid-column: 1;
      -ms-grid-row: 2;
    }
  }

  &:nth-of-type(4) {
    -ms-grid-column: 4;
    -ms-grid-row: 2;
    @media (max-width: $bp-max-medium) {
      -ms-grid-column: 2;
      -ms-grid-row: 2;
    }
  }

  &:nth-of-type(5) {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    @media (max-width: $bp-max-medium) {
      -ms-grid-column: 3;
      -ms-grid-row: 2;
    }
  }

  &:nth-of-type(6) {
    -ms-grid-column: 2;
    -ms-grid-row: 3;
    @media (max-width: $bp-max-medium) {
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }
  }

  &:nth-of-type(7) {
    -ms-grid-column: 3;
    -ms-grid-row: 3;
  }

  &:nth-of-type(8) {
    -ms-grid-column: 4;
    -ms-grid-row: 3;
    @media (max-width: $bp-max-medium) {
      -ms-grid-column: 2;
      -ms-grid-row: 3;
    }
  }
  @media (max-width: $bp-max-small) {
    width: calc(100% - 20px);
    margin: 10px;
    position: relative;
  }
}
