.page-section {
	width: 100%;
	max-width: 849px;
	margin-bottom: 60px;

	@media (max-width: 767px) {
		margin-bottom: 40px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;

		@media (max-width: 767px) {
			flex-direction: column;
			align-items: normal;
		}
	}

	&__header-title {
		font-size: 40px;
		line-height: 50px;
		margin-bottom: 0;
	}

	&__header-link {
		@include font(futura);
		text-transform: uppercase;
		font-weight: 600;
		font-size: 18px;
		color: $fun-blue;
		cursor: pointer;

		&:after {
			content: "\2192";
			padding-left: 8px;
			color: $black;
		}
	}

	&__content {
		font-size: 18px;
		line-height: 30px;
	}
}
