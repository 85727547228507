@mixin sub-heading {
  @include font("futura", bold);
  color: $dove-gray;
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.people-details {
  .people-details__header {
    @include font("futura", bold);
    color: $fun-blue;
    font-size: 30px;
    margin-bottom: 0;
  }

  .people-details__section-header {
    @include sub-heading;
    margin-top: 45px;
    margin-bottom: 15px;
  }

  .people-details__bio-role {
    @include sub-heading;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .people-details__address-photo {
    overflow: hidden;
    display: flex;
    @media(max-width: $bp-max-small) {
      flex-direction: column;
    }
  }
  .people-details__section-body {
    line-height: 30px;
  }

  address {
    margin-top: 0;
  }
}
