.site-logo {
	@include font(georgia, bold);
	font-size: 1.2rem;
	@media(max-width: $bp-max-medium) {
		display: table;

		.global-footer {
			display: block;
		}
	}

	a {
		text-decoration: none;
	}
}

.site-logo__image {
	display: inline-block;
	vertical-align: middle;
	@media(max-width: $bp-max-medium) {
		display: table-row;
		max-width: 164px;
		text-align: center;

		.global-footer & {
			display: block;
		}
	}

	img {
		@media(max-width: $bp-max-medium) {
			width: 100%;
		}
	}
}

.site-logo__title {
	@media($bp-min-large) {
		padding-left: 20px;
	}
	@media(max-width: $bp-max-medium) {
		font-size: 0.95rem;
		text-align: center;
		display: table-row;
		padding-top: 10px;
	}
}
