$tabs-mobile-breakpoint: 720px; // this is used in Tabs.js as well

.tabs {
  padding-bottom: 75px;
  @media(min-width: $bp-max-large + 1) {
    padding-left: 75px;
    padding-right: 75px;
  }
}

.tabs__navigation {
  border-bottom: 1px solid $iron;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-left: 0;

  li {
    align-items: flex-end;
    display: flex;
    margin-bottom: 0;
  }
  @media (max-width: $tabs-mobile-breakpoint) {
    flex-direction: column;
    line-height: 100%;
  }
}

.related-resource__ee-only {
  // we only display this for EE for stacked visual organization
  @include font(futura, demi);
  display: none;
  border-bottom: 1px solid $iron;
  letter-spacing: 1px;
  position: relative;
  text-transform: uppercase;

  .is-page-editor & {
    display: block;
  }
}

.tabs__tab {
  @include font(futura, demi);
  color: $tundora;
  display: block;
  font-size: 20px;
  letter-spacing: 1px;
  padding: 25px 20px;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  .is-open & {
    display: block;
  }
  @media (max-width: $tabs-mobile-breakpoint) {
    background: $white;
    display: none;
    flex-basis: 100%;
    text-align: left;
    transition: background ease-in-out 0.3s;
  }

  &.is-active,
  &:hover {
    @media (max-width: $tabs-mobile-breakpoint) {
      background: $fun-blue;
      color: $white;
      display: block;
    }

    &:before {
      @media (min-width: $tabs-mobile-breakpoint + 1) {
        background: $christi-green;
        bottom: 0;
        content: "";
        height: 10px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }
}

.tabs {
  .is-active:after {
    @media (max-width: $tabs-mobile-breakpoint) {
      background-image: url("../../img/svg/chevron-down.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top -7px right 0;
      content: "";
      color: $white;
      float: right;
      height: 15px;
      width: 34px;
    }
  }
}

.tab__content {
  display: none;

  .is-page-editor & {
    display: block;
  }

  &.is-visible {
    display: block;
  }
}

@media print {
  div.tab__content {
    display: block;
    border-bottom: 1px solid $grey-300;
    padding-bottom: 60px;
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

.tabs .scLooseFrameZone {
  display: block;
}
