// buttons.scss
// Define button styles here
// color variables
// base
$base-btn-color: $grey-600 !default;
$base-btn-bg: lighten($base-btn-color, 50%) !default;
$base-btn-border: darken($base-btn-bg, 20%) !default;
$base-btn-color--active: darken($base-btn-color, 10%) !default;
$base-btn-bg--active: darken($base-btn-bg, 10%) !default;
$base-btn-border--active: darken($base-btn-border, 10%) !default;
// primary
$primary-btn-color: $pickled-bluewood !default;
$primary-btn-bg: $saffron !default;
$primary-btn-border: $saffron !default;
$primary-btn-color--active: $saffron !default;
$primary-btn-bg--active: $pickled-bluewood !default;
$primary-btn-border--active: $saffron !default;
// secondary
$secondary-btn-color: $white !default;
$secondary-btn-bg: $fun-blue !default;
$secondary-btn-border: $fun-blue !default;
$secondary-btn-color--active: $fun-blue !default;
$secondary-btn-bg--active: $white !default;
$secondary-btn-border--active: $fun-blue !default;

// base
.btn {
    @include font(futura, demi);
    display: inline-block;
    padding: 8px 16px;
    color: $base-btn-color;
    text-decoration: none;
    border: 1px solid $base-btn-border;
    background-color: $base-btn-bg;
    line-height: 1;
    /* Prevent button from inheriting line-height */
    vertical-align: middle;
    transition: color 0.35s, background-color 0.35s, border-color 0.35s;
    cursor: pointer;
    font-size: .875rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .02em;

    &:active,
    &:hover {
        outline: 0;
        color: $base-btn-color--active;
        background-color: $base-btn-bg--active;
        border-color: $base-btn-border--active;
    }

    &:focus {
        outline: 0;
    }
}

// modifiers
// primary
.btn--primary {
    color: $primary-btn-color;
    border: 1px solid $primary-btn-border;
    background-color: $primary-btn-bg;

    &:active,
    &:hover {
        color: $primary-btn-color--active;
        background-color: $primary-btn-bg--active;
        border-color: $primary-btn-border--active;
    }
}

// primary and disabled
.btn--primary.btn--disabled {
    opacity: 0.3;
}

// primary and ghost
.btn--primary.btn--ghost {
    color: $primary-btn-border;

    &:active,
    &:hover {
        color: $primary-btn-border--active;
    }
}

// secondary
.btn--secondary {
    color: $secondary-btn-color;
    border: 1px solid $secondary-btn-border;
    background-color: $secondary-btn-bg;

    &:active,
    &:hover {
        color: $secondary-btn-color--active;
        background-color: $secondary-btn-bg--active;
        border-color: $secondary-btn-border--active;
    }
}

// secondary and disabled
.btn--secondary.btn--disabled {
    opacity: 0.35;
}

// link and reset
.btn--link,
.btn--reset {
    border: 0;
    background-color: transparent;
    text-decoration: underline;

    &:active,
    &:hover {
        text-decoration: none;
        background-color: transparent;
    }

    &[disabled] {
        color: $grey-400;
		cursor: default;
        text-decoration: none;
    }
}

// disabled
.btn--disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

// ghost
.btn--ghost {
    background-color: transparent;

    &:active,
    &:hover {
        background-color: transparent;
    }
}
// btn tags

.btn--tag {
    padding: 2px 4px;
    font-weight: bold;

    .icon {
        height: 16px;
        width: 16px;
        margin-left: 4px;
        fill: $base-btn-border;
        transition: fill 0.35s;
    }

    &:hover {
        .icon {
            fill: $base-btn-color;
        }
    }
}

// btn sorts
.btn--sort {
    border: 0;
    background-color: transparent;
    overflow: hidden;
}
// btn icon group

.btn--icon-group {
    position: relative;
    padding-right: 24px;

    .btn_icon-group {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        .icon {
            position: absolute;
            right: 0;
            height: 1.846em;
            max-height: 24px;
            width: 1.846em;
            max-width: 24px;
            fill: currentColor;
            opacity: 1;
            visibility: visible;
            transition: opacity 0.35s;

            &:first-child {
                bottom: 35%;
                transform: translateY(35%);
            }

            &:last-child {
                top: 35%;
                transform: translateY(-35%);
            }
        }
    }
}

.btn--link {
    @include font(futura, demi);
    color: $fun-blue;
    font-size: 18px;
    letter-spacing: 1px;
    padding-bottom: 15px;
    padding-left: 0;
    padding-top: 15px;
    text-decoration: none;
    text-transform: uppercase;

    &:after {
        content: "→";
        color: $black;
        padding-left: 10px;
        font-family: serif;
    }


}
