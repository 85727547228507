.ad-container {
  overflow: hidden;
  
  .broadstreet-label-container {
    padding-bottom: 5px;
  }
}

.ad-container--medium-rectangle {
  max-width: 300px;
  max-height: 275px;
}

.ad-container--large-rectangle {
  max-width: 300px;
  max-height: 625px;
}

.ad-container--medium-leaderboard {
  max-width: 728px;
  max-height: 115px;
  display: none;
  margin: auto;
  
  @media (min-width: $bp-min-medium) {
    display: block;
  }
}

.ad-container--large-square {
  max-width: 800px;
  max-height: 625px;
  display: none;
  
  @media (min-width: $bp-min-large) {
    display: block;
  }
}

.ad-container--large-leaderboard {
  max-width: 970px;
  max-height: 275px;
  display: none;
  margin: auto;
  
  @media (min-width: $bp-min-large) {
    display: block;
  }
}