// Settings here will override settings
// in slick-theme.scss
$slick-font-path: "/assets/img/slick/fonts/";
$slick-font-family: "slick";
$slick-loader-path: "/assets/img/slick/";
$slick-arrow-color: $black;
$slick-dot-color: $base-body-color;
$slick-dot-color-active: $slick-dot-color;
$slick-prev-character: "\2190";
$slick-next-character: "\2192";
$slick-dot-character: "\2022";
$slick-dot-size: 6px;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;
