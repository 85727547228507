$box-shadow-padding: 15px;
$inner-offset: 60px;
$news-slideshow-breakpoint-large: 1291px;
$news-slideshow-breakpoint-medium: 1024px; // this value is used in EventSlider.js, and needs to be changed in both places
$news-slideshow-breakpoint-small: 768px;
$transition: cubic-bezier(.7,.1,.1,1);

.news-slideshow {
	background-color: $fun-blue;
	background-image: url("../../img/event-listing-bg.svg");
	background-position: center right;
	background-repeat: no-repeat;
	color: $white;
	display: inline-block;
	margin-bottom: $inner-offset * 2;
	// margin-top: $inner-offset;
	width: 100%;
	@media(max-width: $news-slideshow-breakpoint-small) {
		background-position: top right;
		position: relative;
	}

	.container {
		@media(max-width: $news-slideshow-breakpoint-large) {
			padding-left: 30px;

		}
		@media(max-width: $news-slideshow-breakpoint-small){
			padding-left: 0;
			margin: 0;
		}
	}

	&:after {
		@media(max-width: $news-slideshow-breakpoint-small) {
			content: "";
			background: $white;
			position: absolute;
			width: 100%;
			height: calc(40% - -2px);
			bottom: 0;
			left: 0;
			z-index: 0;
		}
	}

	.slick-list {
		overflow: visible;
		position: relative;
		left: 0px;
		transition: left $transition .3s;

		@media(max-width: $news-slideshow-breakpoint-small){
			overflow: hidden;
		}
	}

	.slick-track {
		align-items: flex-end;
		display: flex;
		@media (max-width: $news-slideshow-breakpoint-small) {
			align-items: flex-start;
		}
	}

	.slick-next,
	.slick-prev {
		background: $saffron;
		height: 50px;
		overflow: hidden;
		top: calc(50% + 35px);
		width: 50px;
		z-index: 1;
		@media(max-width: $news-slideshow-breakpoint-small) {
			height: 40px;
			top: calc(45% + 35px);
			width: 40px;
		}

		&:before {
			display: none;
		}

		svg {
			height: 40px;
			width: 40px;
			@media(max-width: $news-slideshow-breakpoint-small) {
				height: 30px;
				width: 30px;
			}
		}
	}

	.slick-next {
		right: 0;
		transition: right $transition .3s;

		@media(max-width: $news-slideshow-breakpoint-medium) {
			right: 0;
		}
	}

	.slick-prev {
		left: 0;
		opacity: 0;
		transition: opacity $transition .3s;

		@media(max-width: $news-slideshow-breakpoint-medium) {
			opacity: 1;
			// left: -30px;
		}

		@media(min-width: $news-slideshow-breakpoint-large) {
			// left: -3%;
		}
	}

	@media (min-width: $news-slideshow-breakpoint-medium) {

		.slick-slide {
			visibility: hidden;
		}
	
		.slick-active {
			visibility: visible;
		}
	}
}

.news-slideshow__container {
	overflow: hidden;
	position: relative;
	top: $inner-offset;
	z-index: 1;
	padding-left: 20px;
	padding-right: 20px;
}

.news-slideshow__eyebrow {
	@include font(futura, heavy);
	font-size: 16px;
	letter-spacing: 2px;
	margin-bottom: 10px;
	text-transform: uppercase;
	@media(max-width: $news-slideshow-breakpoint-medium) {
		font-size: 15px;
		padding-left: 30px;
		padding-right: 30px;
	}

}

.news-slideshow__title {
	@include font(futura, heavy);
	font-size: 45px;
	@media(max-width: $news-slideshow-breakpoint-small) {
		font-size: 34px;
	}
	@media(max-width: $news-slideshow-breakpoint-medium){
		padding-left: 30px;
		padding-right: 30px;
	}
}

.news-slideshow__description {
	font-size: 20px;
	@include font(futura);
	@media(max-width: $news-slideshow-breakpoint-medium){
		padding-left: 30px;
		padding-right: 30px;
	}
}

.news-slideshow__slider-wrapper {
	position: relative;
	@media(max-width: $news-slideshow-breakpoint-small) {
		margin-left: 0;
	}
}

.news-slideshow__slider {
	position: static;
	width: 100%;
	@media(max-width: $news-slideshow-breakpoint-medium) {
		// width: 100%;
	}

	&.back-button-is-visible {
		.slick-prev {
			@media(min-width: $news-slideshow-breakpoint-medium + 1) {
				left: 0;
				opacity: 1;
			}
		}

		.slick-next {
			@media(min-width: $news-slideshow-breakpoint-medium + 1) {
				right: 0;
			}
		}

		.slick-list {
			@media(min-width: $news-slideshow-breakpoint-medium + 1) {
				// left: 60px;
			}
		}
	}
}
