.addthis_inline_share_toolbox {
  clear: both;
  overflow: hidden;

  .at-resp-share-element {
    float: right;
  }
}
.container--30-70 {
  @media(max-width: $bp-max-medium) {
    .container__col:last-child {
      .addthis_inline_share_toolbox {
        margin-top: 5px;
      }
    }
  }  
}
