//initial styles imported from vendor _datepicker.scss file in ACHE.scss. Here are our custom overrides.

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
	background: $fun-blue;
	border: 1px solid $tory-blue;
}

.CalendarDay__selected_span {
	border: 1px solid $tory-blue;
	background: $tory-blue;
}

.CalendarDay__default:hover {
	background: $iron;
}

.DateRangePickerInput_arrow {
	display: none;
}

.search-facets__datepickers {
	width: 100%;
	display: block;
	padding-left: 62px;
	padding-right: 40px;
	margin-top: 20px;

	h4 {
		@include font(futura, demi);
		color: $white;
		font-size: 16px;
	}

	.search-facets__datepickers__error {
		@include font(futura, demi);
		color: $white;
		font-size: 14px;
		margin: 15px 0;
		display: none;

		&.search-facets__datepickers__error--shown {
			display: flex;

			.icon {
				margin-right: 5px;
			}
		}
	}
}

.DateRangePickerInput {
	background-color: transparent;
	bordeR: 0;
	width: 100%;
}

.CalendarMonth_caption {
	@include font(futura, demi);
	color: $fun-blue;
	letter-spacing: 1px;
	font-size: 16px;
}

.DayPickerNavigation_svg__horizontal {
	color: $fun-blue;
	fill: $fun-blue;
}

.CalendarDay,
.DayPicker_weekHeader_li {
	@include font(futura);
}

.DateInput {
	margin: 0;
	padding: 0;
	height: 25px;
	vertical-align: bottom;
	width: 45%;
	display: inline-block;
	position: relative;
	height: 100%;
	background-color: $iron;

	input {
		@include font(futura, light);
		width: 100%;
		color: $tundora;
		background-color: transparent;
		border: 0;
		height: 50px;
		padding-left: 10px;
		font-size: 16px;
		position: relative;
		text-shadow: 2px 0 $iron;
		z-index: 1;

		&:not(:placeholder-shown) {
			background-color: $iron;
		}

		&.DateInput_input__focused,
		.search-facets__datepickers__populated-both &,
		.search-facets__datepickers__populated-end &.DateInput_input_2,
		.search-facets__datepickers__populated-start &.DateInput_input_1 {
			background-color: $iron;
		}
	}

	&:first-of-type {
		margin-right: 5%;
	}

	&:after {
		content: "";
		height: 30px;
		width: 30px;
		position: absolute;
		top: 10px;
		right: 5px;
		background-image: url("../../img/svg/calendar.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100% 100%;
		z-index: 0;
		@media(max-width: $bp-max-large) {
			opacity: 0.6;
		}
		@media(max-width: $bp-max-medium) {
			display: none;
		}
	}
}
