.page-title {
	@include component-whitespace();
	display: flex;
	flex-direction: column;
}

// For the benefit of screen readers,
// the source order of the titles should go:
// 1) Title 2) Subtitle 3) Content Type
// Visually, the Content Type should be first
.page-title__type 		{ order: 1; }
.page-title__title 		{ order: 2; }
.page-title__subtitle	{ order: 3; }

.page-title__subtitle {
	@include font(montserrat);
	font-size: 1.5rem;
}
.page-title__type {
	text-transform: uppercase;
	margin-bottom: 0;
	color: $grey-500;
	@include font(montserrat);
}
