.content-detail-banner {
  background-color: $black-squeeze-blue;
  padding: 40px 100px;
  width: 100%;
  @include font(futura);
  @media(max-width: $bp-max-medium) {
    padding: 20px;
  }
  @media(max-width: $bp-max-small) {
    padding: 10px;
  }

  .content-detail-banner__content-type {
    color: $dove-gray;
    font-size: 20px;
    letter-spacing: 2px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  .content-detail-banner__title {
    color: $tundora;
    display: block;
    font-weight: 600;
    font-size: 45px;
    line-height: 51px;
    margin-bottom: 30px;
  }

  .content-detail-banner__subtitle {
    margin-bottom: 30px;
    display: block;
  }

  .content-detail-banner__byline {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    color: $tundora;
    margin-bottom: 30px;
    flex-wrap: wrap;
    @media(max-width: $bp-max-medium) {
      flex-direction: column;
    }
  }

  .content-detail-banner__author {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 10px;
    @media(max-width: $bp-max-medium) {
      flex-basis: 1;
    }
  }

  .content-detail-banner__collection,
  .content-detail-banner__topics {
    display: block;
    // margin: 20px 0;
    font-size: 20px;
    font-weight: 400;

    span {
      background-color: $white;
      border-radius: 3px;
      padding: 4px 8px;
      color: $tundora;
      margin-right: 15px;
      font-weight: 500;
      font-size: 16px;
      white-space: nowrap;
      margin-bottom: 15px;
      display: inline-block;

      a {
        color: $tundora;
      }

      &:first-of-type {
        margin-left: 15px;
      }
    }
  }

  .scEnabledChrome {
    // fix for EE
    display: block;
  }
}

.container--30-70 {
  .content-detail-banner {
    margin-left: -3.52558%;
    width: calc(100% + 3.52558%);
    @media(max-width: $bp-max-medium) {
      margin-left: auto;
      width: 100%;
    }
  }
}
