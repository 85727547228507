// grid-settings.scss
// Grid Settings for Neat

@import "neat-helpers";

/// Neat visual grid settings
/// @author Thoughbot
/// @link http://thoughtbot.github.io/neat-docs/latest/#visual-grid
///
/// 1. Uncomment this to show the visual grid
/// 2. Sets the visual grid color
/// 3. Sets the `z-index` property of the visual grid. Can be `back` (behind content) or `front` (in front of content).
/// 4. Sets the opacity property of the visual grid

$visual-grid: true !global; /* 1 */
$visual-grid-color: #F44336 !global; /* 2 */
$visual-grid-index: front !global; /* 3 */
$visual-grid-opacity: .08 !global; /* 4 */

/// Neat variable overrides
/// @author Andrew Rodgers, Shelby Staab, Thoughtbot
/// @link http://thoughtbot.github.io/neat-docs/latest/#column
///
/// 1. Sets the relative width of a single grid column. The unit used should be the same one used to define `$gutter`
/// 2. Sets the relative width of a single grid gutter. The unit used should be the same one used to define `$column`
/// 3. Sets the total number of columns in the grid. Its value can be overridden inside a media query using the media() mixin
/// 4. Sets the max-width property of the element that includes outer-container(). This should be the total width edge-to-edge from the left-most column to the right-most column. This should not include margins/gutters on the sides.
/// NOTE: adjust this to fit your grid-system.

$column: 80px !global; /* 1 */
$gutter: 30px !global; /* 2 */
$grid-columns: 12 !global; /* 3 */
$max-width: 1290px !global; /* 4 */

// Global Breakpoint Variables.
// Use in media queries across components.
// One-off breakpoints should be defined at the component-level.
// Units are in ems for maximum compatibility and accessibility,
// See: https://zellwk.com/blog/media-query-units/

$bp-max-large: em($max-width);
$bp-min-large: em(911);
$bp-max-medium: em(910);
$bp-min-medium: em(471);
$bp-max-small:  em(470);
$bp-min-small: em(320);


// Grid-aware breakpoints for working with Neat
// http://thoughtbot.github.io/neat-docs/latest/#new-breakpoint
$bp-g-max-large: new-breakpoint(max-width $bp-max-large 12);
$bp-g-min-large: new-breakpoint(min-width ($bp-min-large) 12);
$bp-g-max-medium: new-breakpoint(max-width $bp-max-medium 8);
$bp-g-min-medium: new-breakpoint(min-width ($bp-min-medium) 8);
$bp-g-max-small: new-breakpoint(max-width $bp-max-small 4);
$bp-g-min-small: new-breakpoint(min-width ($bp-min-small) 4);
