.quick-links {
  @include component-whitespace();
  @include font(futura,demi);
  display: flex;
  padding-bottom: 10px;
  font-size: 18px;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-top: 35px;
  @media(max-width: $bp-max-medium) {
    flex-direction: column;
  }
}
// quick link

.quick-links__link {
  margin-bottom: 0;
  color: $denim-blue;
  padding: 10px 20px;
  border: 2px solid $denim-blue;
  font-size: 22px;
  text-align: center;
  margin-left: 20px;
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    color: white;
    background-color: $denim-blue;
    text-decoration: none;
  }
  @media(max-width: $bp-max-medium) {
    margin-bottom: 10px;
    margin-left: 0;
  }
}
