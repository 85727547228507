$desktop_padding: 70px;
$mobile_padding: 30px;

.exitlinks {
  position: relative;
  background-color: $denim-blue; //default, will be overwritten by author-defined bgcolor

  &:not(:only-child) {
    margin-top:50px;
  }
}

.exitlinks__bg {
  background-image: url("../../img/exit-links/pattern.png");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  opacity: 0.3;
}

.exitlinks__content {
  position: relative;
  top: 0;
  z-index: 9;
  padding: 50px 0;

  .exitlinks__title {
    text-align: left;
    font-size: 35px;
    line-height: 45px;
    font-weight: 700;
    border-left: solid $buttercup-yellow 5px;
    padding-left: calc(#{$desktop_padding} - 5px);
    padding-right: $desktop_padding;
    @media(max-width: $bp-max-medium) {
      padding-left: calc(#{$mobile_padding} - 5px);
      padding-right: $mobile_padding;
    }
    color: $white;
  }

  .exitlinks__description {
    @include font(georgia);
    padding: 0 $desktop_padding;
    color: $white;
    margin-bottom: 28px;
    font-size: 18px;
    line-height: 30px;
    @media(max-width: $bp-max-medium) {
      padding: 0 $mobile_padding;
    }
  }

  .exitlinks__cta-container {
    display: flex;
    padding: 0 $desktop_padding;
    justify-content: space-between;
    @media(max-width: $bp-max-medium) {
      padding: 0 $mobile_padding;
    }
    @media(max-width: $bp-max-small) {
      flex-direction: column;
    }
  }

  .exitlinks__cta {
    @include font(futura);
    text-transform: uppercase;
    font-weight: 600;

    a {
      color: $white;
      text-decoration: none;

      &:after {
        content: "\2192";
        padding-left: 5px;
      }
    }
    @media(max-width: $bp-max-small) {
      margin-top: 20px;
    }
  }
}
