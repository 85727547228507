.hero-full {
  position: relative;
}

.hero-full:before {
  background: #1970b1;
  position: absolute;
  height: calc(100% - 60px);
  width: 100%;
  content: '';
}

@media (max-width: 56.875em) {
  .hero-full:before {
    display: none;
  }
}

@media (max-width: 56.875em) {
  .hero-full .container--100 {
    margin: 0;
  }
}

.hero-full__image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-right: 80px;
}

@media (max-width: 56.875em) {
  .hero-full__image {
    width: 100%;
    height: auto;
    min-height: 1px;
  }
}

.hero-full__details {
  background: #fff;
  bottom: 0;
  -webkit-box-shadow: 0 0 15px 1px rgba(74, 74, 74, 0.4);
          box-shadow: 0 0 15px 1px rgba(74, 74, 74, 0.4);
  max-width: 580px;
  padding: 40px 45px;
  position: absolute;
  right: 0;
}

@media (max-width: 80.625em) {
  .hero-full__details {
    right: 15px;
  }
}

@media (max-width: 56.875em) {
  .hero-full__details {
    bottom: auto;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: -100px;
    max-width: 100%;
    padding: 40px;
    position: relative;
    right: auto;
    z-index: 1;
  }
}

.hero-full__meta {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #737373;
  font-size: 20px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.hero-full__title {
  font-family: "futura-pt", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #4a4a4a;
  font-size: 45px;
  margin-bottom: 25px;
  line-height: 50px;
}

@media (max-width: 56.875em) {
  .hero-full__title {
    font-size: 36px;
    line-height: 42px;
  }
}

.hero-full__description {
  font-size: 18px;
  line-height: 30px;
}

.hero-full__cta {
  line-height: 1.3;
  margin-top: 10px;
  padding-bottom: 0;
}

.health-exec{
  .hero-full:before {
    display: none;
  }

  .hero-full__details {
    background: #e7f0f7;
  }

  .hero-full__image {
    width: 100%;
    max-width: 100%;
  }

  .hero-full__meta {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 600;
    font-style: semibold;
    color: #a63c63;
    text-transform: uppercase;
    font-size: 16px;
    height: 22px;
  }

  .hero-full__meta {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .hero-full__title {
    font-family: "adobe-garamond-pro", Times, serif;
    font-weight: 700;
    font-style: normal;
    color: #282e53;
    margin-bottom: 10px;
  }

  .hero-full__cta {
    padding-top: 0;
  }

  .image-block .image-block__title {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 700;
    font-style: bold;
    font-size: 24px;
  }

  .image-block figcaption {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    max-width: 100%;
    font-size: 20px;
  }

  .pull-quote {
    border-left: 5px solid #a63c63;
  }

  .pull-quote:before {
    color: #6D81D3;
    opacity: 1;
    top: 30px;
    overflow: visible;
  }

  .pull-quote__quote {
    font-family: "adobe-garamond-pro", Times, serif;
    font-weight: 400;
    font-style: normal;
    color: #282e53;
  }

  .pull-quote__caption cite {
    display: block;
    color: #787878;
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-family: "Open Sans", Arial, sans-serif;
  }
}
