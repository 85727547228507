.event-register {
  background-color: $black-squeeze-blue;
  padding: 35px;
  position: relative;
  margin-bottom: 38px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    width: 125px;
  }
  .event-register__title {
    @include font("futura", heavy);
    color: $tundora;
    font-size: 30px;
    line-height: 36px;
  }
  .event-register__sub-title {
    @include font("georgia", regular);
    color: $tundora;
    font-size: 18px;
    line-height: 30px;
  }
  .event-register__cta {
    margin-top: 38px;
  }
  .event-register__cta__link {
    @include font("futura", demi);
    border: 2px $denim-blue solid;
    font-size: 16px;
    display: block;
    min-height: 43px;
    margin: 0 auto 15px auto;
    max-width: 310px;
    padding: 11px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    letter-spacing: 1px;
    &:first-of-type {
      background-color: $denim-blue;
      color: $black-squeeze-blue;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
