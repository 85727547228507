$secondary-nav__parent-indent: 0;
$secondary-nav__child-indent: 25px;
$secondary-nav__grandchild-indent: 20px;
$secondary-nav__great-grandchild-indent: 40px;
$secondary-nav__item-vert-padding: 8px;
$secondary-nav__item-vert-margin: 8px;
$secondary-nav__list-vert-margin: 10px;

.secondary-nav {
	background: $fun-blue;
	color: $white;
	padding-bottom: 45px;
	padding-right: 20px;
	padding-top: 45px;
	position: relative;
	@media(max-width: $bp-max-medium) {
		padding-left: 40px;
		width: 100%;
	}
	@media(max-width: $bp-max-small) {
		padding: 20px;
	}

	&:before {
		background: $fun-blue;
		content: "";
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		width: 100vw;
		// z-index: -1;
		@media(max-width: $bp-max-medium) {
			display: none;
		}
	}

	li {
		position: relative;
	}

	.container--70-30 & {
		padding-left: 30px;

		&:before {
			display: none;
			right: auto;
			left: 0;
		}
	}
}

.secondary-nav__label {
	@include font(futura, demi);
	font-size: 16px;
	letter-spacing: 2px;
	margin-bottom: 10px;
	text-transform: uppercase;
	opacity: .5;
	@media(max-width: $bp-max-small) {
		display: none;
	}
}

.secondary-nav__toggle {
	@include font(futura, demi);
	color: $white;
	display: none;
	justify-content: space-between;
	text-align: center;
	text-transform: uppercase;
	transform: rotate(0deg);
	transition: transform 0.2s;

	&:hover {
		color: $white;
	}

	.icon,
	svg {
		width: 32px;
		height: 24px;
	}
	@media(max-width: $bp-max-small) {
		display: flex;
	}
}

.secondary-nav__submenu-toggle {
	background: none;
	border: 0;
	font-size: 0;
	height: 30px;
	left: 20px;
	position: absolute;
	top: 2px;
	width: 35px;
	z-index: 1;

	svg {
		fill: $white;
		pointer-events: none;
		transition: fill ease-in-out 0.3s;

		.is-active & {
			fill: $fun-blue;
		}

		.is-open > & {}
	}

	&:hover svg {
		fill: $saffron;
	}
}

.is-open > .secondary-nav__submenu-toggle svg {
	transform: rotate(90deg);
}

.is-open .secondary-nav__toggle .icon {
	transform: rotate(180deg);
}

.secondary-nav__quatermenu,
.secondary-nav__submenu,
.secondary-nav__tertmenu {
	margin-top: $secondary-nav__list-vert-margin;
	margin-bottom: $secondary-nav__list-vert-margin;
}

.secondary-nav__menu {
	@include zero-list;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	margin-bottom: 0;
	margin-left: -30px;

	.container--70-30 & {
		margin-left: 0;
	}
	@media(max-width: $bp-max-small) {
		display: none;
		margin-left: 0;
		margin-top: 10px;
	}

	ul {
		margin-left: 0;
	}
}

.secondary-nav__item,
.secondary-nav__quateritem,
.secondary-nav__subitem,
.secondary-nav__tertitem {
	@include font(futura, demi);
	margin-bottom: $secondary-nav__item-vert-margin;
	@media(max-width: $bp-max-small) {
		@include font(futura, regular);
	}

	& > a ,
	& > .secondary-nav__link--empty {
		align-items: center;
		// box-shadow: 0 0 0 0 $black;
		display: flex;
		color: inherit;
		padding: $secondary-nav__item-vert-padding 15px $secondary-nav__item-vert-padding 30px;
		transition: color ease-in-out 0.2s, background ease-in-out 0.3s;

		&:hover {
			&+button svg {
				fill: $fun-blue;
			}
		}

		svg {
			margin-right: 10px;
			fill: currentColor;
			transform: rotate(0deg);
			transition: transform ease-in-out 0.2s;

			path {
				fill: inherit;
			}
		}
	}

	& > a[href] {
		&:hover {
			// box-shadow: 0 2px 3px 1px rgba($black, 0.5);
			background: $white;
			color: $fun-blue;
			text-decoration: none;
		}
	}

	&.is-active > a {
		// box-shadow: 0 2px 3px 1px rgba($black, 0.5);
		background: $white;
		color: $fun-blue;
	}
	@media(max-width: $bp-max-small) {
		// hide all items except the
		// active parent. But hide
		// the text inside the active parent
		// link
		// display: none;
		&.is-current-parent {
			display: block;
		}

		& > a {
			// display: none;
		}
	}
}

.secondary-nav__item {
	width: 100%;

	& > a,
	& > .secondary-nav__link--empty {
		margin-left: $secondary-nav__parent-indent;
		font-size: 30px;
		width: calc(100% - #{$secondary-nav__parent-indent});
		@media(max-width: $bp-max-small) {
			font-size: inherit;
			padding-left: 0;
		}
	}
}

.secondary-nav__submenu {
	width: 100%;
	letter-spacing: 1px;

	li {
		display: block;
	}
}

.secondary-nav__subitem {
	clear: both;
	display: block;
	font-size: 15px;
	line-height: 1.21;
	text-transform: uppercase;
	&:last-child {
		border-bottom: none;
	}

	& > a {
		width: calc(100% - #{$secondary-nav__child-indent});
		margin-left: $secondary-nav__child-indent;
	}

	& > ul {
		display: none;
	}

	&.is-open {
		& > a {}

		& > ul {
			display: block;
		}
	}
}

.secondary-nav__tertmenu {
	clear: both;
}

.secondary-nav__tertitem {
	margin-left: $secondary-nav__grandchild-indent;
	text-transform: none;

	& > a {
		@include font(futura);
		width: calc(100% - #{$secondary-nav__grandchild-indent});
		font-size: 16px;
		margin-left: $secondary-nav__grandchild-indent;
	}

	& > ul {
		display: none;
	}

	&.is-open {
		& > a {
			svg {
				transform: rotate(90deg);
			}
		}

		& > ul {
			display: block;
		}
	}

	.secondary-nav__submenu-toggle {
		top: 3px;
	}
}

.secondary-nav__quatermenu {
	clear: both;
}

.secondary-nav__quateritem {
	margin-left: $secondary-nav__great-grandchild-indent;
	width: calc(100% - #{$secondary-nav__great-grandchild-indent});
	text-transform: none;
}
