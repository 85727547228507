$container-bottom-space: 60px;
$container-bleed-max: 2580px;
$container-outer-gutter: 30px; // shared by link-grid
$container-mobile-bottom-space: 48px;

.container {
  position: relative;
  margin-bottom: $container-bottom-space;

  &.is-adjacent {
    margin-bottom: 0;
  }

  @media (max-width: $bp-max-medium) {
    margin-bottom: $container-mobile-bottom-space;
  }
}

.container--bleed {
  max-width: $container-bleed-max;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-position: center;

  & > .container__col {
    position: relative;

    @media (max-width: $bp-max-large) {
    }

    .container:last-child {
      margin-bottom: 0;
    }
  }

  &.has-image,
  &.has-color {
    padding-top: $container-bottom-space;
    padding-bottom: $container-bottom-space;
  }

  // outer padding is only on the outer
  // container now
  .container--100,
  .container--70-30,
  .container--50-50,
  .container--30-70 {
    @media (min-width: $bp-min-large) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.container--100,
.container--70-30,
.container--50-50,
.container--30-70 {
  @include outer-container();
  @media (max-width: $bp-max-large) {
    margin-left: $container-outer-gutter;
    margin-right: $container-outer-gutter;
  }
}

.container--70-30 {
  & > .container__col:first-child {
    @include span-columns(8);
    margin-right: 7.32558%;
  }

  & > .container__col:last-child:not(:only-child) {
    @include span-columns(4);
    width: 26.78295%;
  }

  @include media($bp-g-max-medium) {
    & > .container__col:first-child {
      @include span-columns(5);
    }

    & > .container__col:last-child:not(:only-child) {
      @include span-columns(3);
    }
  }

  @include media($bp-g-max-small) {
    & > .container__col {
      margin-bottom: $container-bottom-space;
    }

    & > .container__col:first-child {
      @include span-columns(4);
    }

    & > .container__col:last-child:not(:only-child) {
      @include span-columns(4);
      margin-bottom: 0;
    }
  }
}

.container--50-50 {
  &--center {
    align-items: center;
    display: flex;

    @include media($bp-g-max-medium) {
      display: block;
    }
  }

  .container__col {
    @include span-columns(6);
  }

  @include media($bp-g-max-medium) {
    .container__col {
      @include span-columns(8);
    }
  }

  @include media($bp-g-max-small) {
    .container__col {
      @include span-columns(4);
      margin-bottom: $container-bottom-space;
    }

    .container__col:last-child {
      margin-bottom: 0;
    }
  }
}

.container--30-70 {
  & > .container__col:first-child {
    @include span-columns(4);
    margin-right: 7.32558%;
    width: 26.78295%;
  }

  & > .container__col:last-child {
    @include span-columns(8);
    // width: 61.89147%;
  }

  @include media($bp-g-max-medium) {
    & > .container__col:first-child {
      @include span-columns(
        8
      ); //These 3 col layouts were not breaking soon enough
    }

    & > .container__col:last-child {
      @include span-columns(
        8
      ); //These 3 col layouts were not breaking soon enough
    }
  }

  @include media($bp-g-max-small) {
    & > .container__col {
      margin-bottom: $container-bottom-space;
    }
    & > .container__col:first-child {
      @include span-columns(4);
    }

    & > .container__col:last-child {
      @include span-columns(4);
      margin-bottom: 0;
    }
  }
}
