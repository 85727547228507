// $container-outer-gutter referenced from container sass file
//$max-width referenced from grid sass file
$half-gutter: $gutter/2; // full bleeds are weird  ¯\_(ツ)_/¯
$link-grid-bp: 56.875em;
// $seventy-gutter: 3.45558%;
$seventy-gutter: 11.11%;
@mixin small-link-grid-bp-container {
  //called small but can be pulled out at medium breakpoint depending on which content container size the grid is in.
  .link-grid-container {
    left: auto;
    right: auto;
    margin-right: -$container-outer-gutter;
    margin-left: -$container-outer-gutter;
    width: calc(100% + 2 * #{$container-outer-gutter});
  }
}
@mixin small-link-grid-bp-list {
  //called small but can be pulled out at medium breakpoint depending on which content container size the grid is in.
  .link-grid {
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    padding-top: $half-gutter;
    padding-bottom: $half-gutter;

    .link-grid__item {
      width: 95%;
      justify-content: space-between;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
// get to the actual component code already geez

.link-grid-container {
  background-color: $black-squeeze-blue;
  width: 100%;
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 80px;
}

.link-grid {
  @include component-whitespace;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding-top: $half-gutter;
  padding-bottom: $half-gutter;
  @media (max-width: $bp-max-small) {
    display: block;
  }

	.list-item__primary-cta a {
		font-size: 16px;
  }
  .list-item__description {
    font-size: 18px;
    line-height: 30px;
  }
}

.link-grid--taxonomy a {
  color: $tundora;
  .btn.mobile-only {
    color: $fun-blue;
    display: none;
    @media (max-width: $bp-max-small) {
      display: block;
    }
  }

}
.link-grid--taxonomy {
	.link-grid__item {
		&:hover {
		  background-color: $gold;
		}
	  }
}
.link-grid__item {
  flex-grow: 1;
  flex-wrap: wrap;
  list-style-type: none;
  background-color: $white;
  width: 31.1%;
  margin-right: $half-gutter;
  padding: 30px;

  a,
  a:hover {
    text-decoration: none;
  }

  .link-grid__img
  {
    img {
      max-height: 225px;
      max-width: 100%;
      margin-right: auto;
      object-fit: contain;
      width: -webkit-fill-available;
    }
  }

  .link-grid--taxonomy & {
    width: 45%;
    padding: 0;
    a {
      display: block;
      padding: 30px;
    }
    @media (max-width: $bp-max-small) {
      width: 100%;
    }

    .is-page-editor & {
      width: 100%;
    }
  }
  @media (min-width: $bp-min-medium) and (max-width: $bp-max-large) {
    width: 45%;
  }
  @media (max-width: $bp-max-small) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.link-grid__title {
  font-size: 30px;

  .link-grid--taxonomy & {
    font-weight: 700;
  }


}

.link-grid__secondary_cta {
  margin-left: 15px;

  li {
    list-style-type: none;
    width: 100%;
    margin-bottom: 20px;
    @include font(futura, demi);

    &:before {
      color: $christi-green;
      display: inline-block;
      width: 20px;
      margin-left: -24px;
      content: "•";
      font-weight: 600;
    }
  }
}
//full bleed cheats

.container--100 {
  .link-grid-container {
    width: 100vw;
    position: relative;
    left: 49.3%; // full bleeds are weird ¯\_(ツ)_/¯
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    .link-grid {
      @media (min-width: $bp-min-medium) {
        padding: $half-gutter 0 $half-gutter 2.1%; // full bleeds are weird  ¯\_(ツ)_/¯
        max-width: $max-width;
        margin: 0 auto;
      }
    }
    @media (min-width: $link-grid-bp) and (max-width: $bp-max-large) {
      // margin-right: -$container-outer-gutter;
      // margin-left: -$container-outer-gutter;
      // left: 0;
      // right: 0;
      // width: calc(100% + #{$container-outer-gutter} * 2);
      left: 0;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      right: 0;
    }
  }
  @media (max-width: $link-grid-bp) {
    @include small-link-grid-bp-container;
  }
  @media (max-width: $bp-max-small) {
    @include small-link-grid-bp-list;
    @include small-link-grid-bp-container;
  }
}
//if it's in the 70% container on the left

.container--70-30 .container__col:first-child {
  .link-grid-container {
    width: calc(100% + #{$seventy-gutter});
    &:before {
      background: $black-squeeze-blue;
      content: "";
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 100vw;
      z-index: -1;
      @media (max-width: $bp-max-large) {
        display: none;
      }
    }
    @media (min-width: $bp-min-large) and (max-width: $bp-max-large) {
      // width: 100%;
      width: calc(100% + #{$container-outer-gutter} + 20px);
      // margin-left: -$container-outer-gutter;
      margin-left: -25px;

      .link-grid {
        padding-left: $half-gutter;
      }
    }
    @media (max-width: $bp-max-small) {
      width: calc(100% + 2 * #{$gutter}) !important;
      padding-left: 0 !important; //couldn't figure out how to avoid this one sorry. x_x
    }
  }
  @media (max-width: $bp-max-medium) {
    @include small-link-grid-bp-container;
    @include small-link-grid-bp-list;

    .link-grid-container {
      padding-left: 15px; // need to have padding-left for just medium view until it gets down to small/mobile
      // width: calc(100% + 2 * (#{$container-outer-gutter} - 20px));
      width: calc(100% + 12%);
    }
  }
}
//if it's in the 70% container on the right

.container--30-70 .container__col:last-child {
  .link-grid-container {
    &:before {
      background: $black-squeeze-blue;
      content: "";
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: calc((100vw - #{$max-width}) / 2 + 99% + #{$seventy-gutter});
      z-index: -1;
      display: block;
      left: -$seventy-gutter;
      @media (max-width: $bp-max-large) {
        display: none;
      }
    }

    .link-grid {
      // padding-left: $half-gutter;
    }
    @media (min-width: $bp-min-medium) and (max-width: $bp-max-large) {
      width: calc(100% + 2 * #{$container-outer-gutter});
      margin-right: -$container-outer-gutter;
      margin-left: -$container-outer-gutter;
      right: 0;
      position: relative;
      padding: 30px 0px 30px 30px;
    }
    @media (max-width: $bp-max-small) {
      padding-left: 0;
    }
  }
  @media (max-width: $bp-max-small) {
    @include small-link-grid-bp-list;
    @include small-link-grid-bp-container;
  }
}
