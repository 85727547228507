﻿.alert-banner {
    background-color: $saffron;
    width: 100%;
    border-bottom: 3px solid $denim-blue;

    @media(max-width: 768px) {
        margin-bottom: 8px;
        border-bottom: 3px solid $denim-blue;
    }

    .alert-banner__copy {
        width: 100%;
        display: flex;
        align-items: center;

        @media(max-width: 768px) {
            flex-direction: column;
        }

        @media(max-width: 1124px) {
            padding: 0 8px;
            max-width: 100%;
        }
    }

    .alert-banner__title {
        border-right: $tundora solid 1px;
        font-family: Helvetica, Arial, sans-serif;
        color: $tundora;
        font-size: 25px;
        font-weight: 700;
        line-height: 27px;
        margin-left: 10px;
        padding-left: 20px;
        padding-right: 25px;
        padding-top: 10px;
        padding-bottom: 10px;

        @media(max-width: 768px) {
            width: 100%;
            margin-left: 30px;
            padding: 12px 8px 12px 8px;
            border-right: 0;
            border-bottom: $tundora solid 1px;
        }
    }

    .alert-banner__message {
        color: $tundora;
        font-size: 18px;
        font-family: Helvetica, Arial, sans-serif;
        font-weight: 400;
        line-height: 27px;
        padding-left: 25px;
        padding-right: 5px;
        padding-top: 10px;
        padding-bottom: 10px;

        @media(max-width: 768px) {
            width: 100%;
            margin-left: 30px;
            padding: 12px 8px 12px 8px;
        }
    }
}
