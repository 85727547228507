$resource-column-break: 600px;

.related-resources {

	.resource {
		@media(max-width: $resource-column-break) {
			flex-direction: column;
		}
	}
	.slick-next,
	.slick-prev {
		background: $saffron;
		height: 80px;
		width: 80px;
		z-index: 1;

		@media(max-width: $bp-max-medium) {
			height: 40px;
			width: 40px;
		}

		&:before {
			display: none;
		}

		svg {
			height: 100%;
			width: 100%;
		}
		@media print {
			display: none;
		}
	}

	.slick-next {
		right: 0;
	}

	.slick-prev {
		left: 0;
	}
	@media print {
		max-width: 100%;
		// Show Only Active Slide
		.slick-slide {
			display: none !important;
			&.slick-active {
				display: block !important;
			}
		}
	
		// Reset adaptiveHeight
		.slick-list {
			height: auto !important;
		}
	
		// Remove Scrollbars
		.slick-track {
			width: auto !important;
			height: auto !important;
			transform: none !important;
			.slick-slide {
				width: auto !important;
			}
		}
	}
}


.resource {
	display: flex;
	background: $link-water;
	padding-bottom: 40px;
	padding-left: 110px;
	padding-right: 110px;
	padding-top: 40px;
	align-items: center;

	@media(max-width: $bp-max-medium) {
		padding-left: 60px;
		padding-right: 60px;
	}
	@media print {
		max-width: 100%;
		padding: 0;
	}
}

.resource__content-type {
	@include font(futura, demi);
	text-transform: uppercase;
	font-size: 20px;
	letter-spacing: 1px;
	margin-bottom: 16px;
	line-height: 1;

	@media(max-width: $bp-max-small) {
		font-size: 18px;
	}
}

.resource__title {
	@include font(futura, demi);
	font-size: 30px;
	margin-bottom: 20px;

	@media(max-width: $bp-max-small) {
		font-size: 20px;
		line-height: 24px;
	}

	a {
		color: inherit;
	}
}

.resource__author {
	@include font(futura, demi);
	font-size: 20px;

	@media(max-width: $bp-max-small) {
		font-size: 16px;
	}

	&:after {
		content: ',';
	}

	&:last-of-type:after {
		content: '';
	}
}

.resource__left {
	max-width: 50%;
	flex-shrink: 0;
	a {
		width: 100%;
		display: block;
		img {
			max-width: 100%;
		}
	}
	@media (max-width: $resource-column-break) {
		max-width: none;
	}
}

.resource__right {
	padding-left: 20px;
	padding-top: 10px;
}
