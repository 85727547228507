.page-meta {
	@include component-whitespace();
}

.page-meta__byline {
	border-left: 1px solid $grey-300;
	margin-left: .5em;
	padding-left: .75em;
}

.page-meta__date {
	font-style: italic;
}
