$margin: 35px;

.service-item {
	margin-bottom: 30px;
}

.service-item__header {
	color: $fun-blue;
	font-size: 30px;
}

.service-item__sub-group {
	margin-top: $margin;
	border-bottom: $silver 1px dashed;
	display: block;

.scLooseFrameZone {
	display: block; //prevents weird ee behavior.
}
	.search-results__item-wrapper & {
		border-bottom: none;
	}
}

.service-item__sub-group__title {
	font-size: 30px;
}

.service-item__sub-department {
	margin-left: 0;
	margin-top: $margin;
	justify-content: left;

	li {
		list-style-type: none;
		margin-bottom: $margin;
	}
}

.service-item__sub-department--columns {
	column-gap: 84px;
	column-count: 2;

	li {
		-webkit-column-break-inside: avoid; //Chrome, Safari, Opera
		page-break-inside: avoid; //Firefox
		break-inside: avoid; //IE 10+
		list-style-type: none;
		margin-bottom: $margin;
	}
	@media(max-width: $bp-max-medium) {
		columns: 1;
	}
}

.service-item__sub-department__title {
	@include font(futura, demi);
	font-size: 20px;
	letter-spacing: 2px;
	color: $dove-gray;
	text-transform: uppercase;
}

.service-item__sub-department__meta {
	display: block;
	font-size: 18px;
	margin-bottom: 8px;
}
