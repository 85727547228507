.resource-search {

}

.resource-search__title {
  @include font(futura, heavy);
  font-size: 45px;
}

.resource-search__description {
  @include font(futura, regular);
	letter-spacing: 1px;
	line-height: 1.6;
	margin-bottom: 75px;

}

.related-resource {
	border-bottom: 2px dashed $iron;
	padding-bottom: 30px;
	margin-top: 30px;
}

.related-resource__title {
	@include font(futura, heavy);
	font-size: 30px;
}

.related-resource__meta {
	@include font(futura, demi);
	color: $dove-gray;
	font-size: 16px;
	letter-spacing: 1px;
	margin-bottom: 25px;
	text-transform: uppercase;
}

.related-resource__collections {
	display: flex;
	list-style-type: none;
	margin-bottom: 20px;
	margin-left: 0;
  flex-wrap: wrap;
}

.related-resource__collection { //links
	@include font(futura, regular);
	background: $black-squeeze-blue;
	border-radius: 5px;
	color: $tundora;
	padding: 8px 15px;
	font-size: 16px;
	margin-right: 20px;
	display: block;
}

.related-resource__summary {
	font-size: 16px;
}
