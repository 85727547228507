.related-book-sidebar {
	margin-bottom: 30px;
	max-width: 100%;

	&__title {
		font-size: 25px;
		line-height: 45px;
		margin-bottom: 8px;
	}

	&__description {
		font-size: 18px;
		line-height: 28px;
		margin-bottom: 22px;
	}

	.single-book {
		padding: 40px 40px 30px 40px;
		max-width: 100%;
		border: 1px solid #d9d9d9;

		@media (max-width: 767px) {
			padding: 30px;
			max-width: 100%;
		}

		&__image {
			width: 100%;
			height: auto;
			display: flex;
			justify-content: center;
			margin-bottom: 36px;

			img {
				width: auto;
				height: 172px;

				@media (max-width: 767px) {
					height: 134px;
				}
			}
		}

		&__header {
			margin-bottom: 20px;

			@media (max-width: 767px) {
				margin-bottom: 48px;
			}
		}

		&__collection {
			@include font(futura);
			font-size: 14px;
			font-weight: 600;
			display: block;
			line-height: 20px;
			text-transform: uppercase;
			margin-bottom: 10px;
			min-height: 20px;
		}

		&__title {
			font-size: 18px;
			line-height: 25px;
			height: 75px;
			font-weight: 500;
			color: $fun-blue;
			text-overflow: ellipsis;
			overflow: hidden;
			display: -webkit-box !important;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			white-space: normal;

			@media (max-width: 767px) {
				height: auto;
			}
		}

		&__footer {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&__footer-side {
			display: flex;
			flex-direction: column;
		}

		&__tag {
			font-size: 16px;
			font-weight: 400;
			line-height: 21px;
			color: #767676;
		}

		&__tag-value {
			@include font(futura);
			font-size: 18px;
			font-weight: 500;
			line-height: 22px;
			color: $tundora;
		}
	}
}
