$simple-hero-bp: 1278px; //this is the breakpoint where simple-hero starts to look out of control in the 70% container.
@mixin small-hero-breakpoint {
  //properties we want to give to simple-hero at small breakpoint and when it's in the 70% container at $simple-hero-bp
  position: relative;
  bottom: auto;
  display: block;
  width: 100%;
}

.hero {
  @include component-whitespace();
  overflow: hidden;
  position: relative;
}

.hero__figure {
  margin: 0;

  img {
    width: 100%;
    display: block;
    margin: 0 auto;
    object-fit: cover;
  }

  video {
    /* if object-fit is supported, we'll use that */
    width: 100%;
    display: block;
    object-fit: cover;
    margin: 0 auto;
  }
}

.has-height .hero__figure {
  width: 100%;
  height: 100%;

  picture {
    height: 100%;
  }

  img {
    height: 100%;
  }

  .video {
    height: 100%;
  }
}

.hero__caption {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  right: 0;
  text-align: center;
}

.hero__title {
  font-size: 3.5rem;
  @media(max-width: $bp-max-medium) {
    font-size: 2.2rem;
  }
  @media(max-width: $bp-max-small) {
    font-size: 1.8rem;
  }
}

.hero__subtitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  @media(max-width: $bp-max-medium) {
    font-size: 1rem;
  }
}

.hero__action {
  display: inline-block;
  font-size: 1.125rem;
  line-height: 1.5;
  transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1), color 0.28s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In
  background-color: darken($white, 60);
  text-decoration: none;
  padding: 10px 20px;
  @media(max-width: $bp-max-medium) {
    font-size: 1rem;
  }

  &:hover {
    background-color: darken($white, 45);
  }
}
// Simple Hero Variation

.hero--simple {
  figure {
    position: relative;

    picture {
      display: block;
      line-height: 0;
    }
  }

  .hero__caption {
    position: absolute;
    bottom: 0;
    color: $white;
    left: 0;
    width: 80%;
    background-color: $fun-blue;
    transform: none;
    padding: 40px;
    text-align: left;
    top: auto;
    @media(max-width: $bp-max-small) {
      @include small-hero-breakpoint;
      padding: 10px;
    }
    //prevent caption from overwhelming image on smaller screens when it's in the 70% container
    .container--70-30 .container__col:first-child & {
      @media(max-width: $simple-hero-bp) {
        @include small-hero-breakpoint;
        padding: 20px;
      }
    }
    //prevent caption from overwhelming image on smaller screens when it's in the 70% container
    .container--30-70 .container__col:last-child & {
      @media(max-width:$simple-hero-bp) {
        @include small-hero-breakpoint;
        padding: 20px;
      }
    }
  }

  .hero__title {
    font-size: 45px;
  }

  .hero__subtitle {
    @include font(futura);
    font-size: 20px;
  }
}
.hero__image {
  max-width: 100%; //fix for IE11
}
