.location-info {
  background-color: $black-squeeze-blue;
  padding: 35px;
  position: relative;
  margin-bottom: 38px;
  .location-info__heading {
    text-align:center;
  }
  div {
    width: 100%;
    display: block;
    margin-top: 20px;
  }
  div.location-info__address {
    display: flex;
    flex-wrap: wrap;
    .location-info__label {
      margin-right: 15px;
    }
    span.location-info__address__address {
      width: auto;
    }
  }
  .location-info__label {
    @include font("futura", demi);
    font-weight: 700;
    margin-right: 10px;
    &:after {
      content: ":";
      display:inline;
    }
  }
  .location-info__label + span {
    width: calc(100% - 110px);
  }
  .btn {
    @include font("futura", demi);
    border: 2px $denim-blue solid;
    font-size: 16px;
    display: block;
    min-height: 43px;
    margin: 38px auto 38px auto;
    max-width: 310px;
    padding: 11px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    letter-spacing: 1px;
    background-color: $denim-blue;
    color: $black-squeeze-blue;
    &:hover {
      background-color: $black-squeeze-blue;
      color: $denim-blue;
    }
  }
  .location-info__more-info {
    margin: 38px auto 15px auto;
    color: $denim-blue;
    background-color: transparent;
    &:hover {
      background-color: $denim-blue;
      color: $black-squeeze-blue
    }
  }
}
