// this inherits a lot of styles from search-box.scss
.featured-search {
  .search-box__input {
    input {
      height: 70px;
    }
  }
  .search-box__options {
    width: 100%;
    margin-top: 15px;
    fieldset {
      border: 0;
      margin: 0;
      padding: 0;
    }
    ul {
      display: flex;
      margin: 0;
      padding: 0;
      justify-content: flex-start;
      li {
        @include font(futura, regular);
        font-size: 20px;
        list-style-type: none;
        margin-right: 40px;
        position: relative;
        input {
          position: absolute;
          opacity: 0;
          width: 25px;
          height: 25px;
        }
        label {
          margin-left: 35px;
        }
        .radio {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          border: 2px solid $tundora;
          border-radius: 100%;
          width: 25px;
          height: 25px;
        }
        &:hover input ~ .radio {
          background-color: $buttercup-yellow;
        }
        .radio:after {
          content: "";
          position: absolute;
          display: none;
          top: 2px;
          left: 2px;
          width: 17px;
          height: 17px;
          border-radius: 50%;
          background: $fun-blue;
        }
        input:checked ~ .radio {
          border-color: $fun-blue;
        }
        input:checked ~ .radio:after {
          display: block;
        }
      }
    }
  }
}
