.pull-quote {
  @include component-whitespace();
  display: inline-block;
  position: relative;
  margin-top: 0;
  padding: 0 40px;
  border-left: 5px solid $christi-green;
  @media(max-width: $bp-max-medium) {
    padding: 0 30px;
  }

  &:before {
    // @include font(georgia, demi);
    content: '\201C';
    font-family: $georgia;
    font-weight: 600;
    font-size: 125px;
    position: absolute;
    top: -30px;
    left: 40px;
    opacity: 0.3;
    height: 95px;
    overflow: hidden;
    @media(max-width: $bp-max-medium) {
      left: 30px;
    }
  }
}
// blockquote

.pull-quote__quote {
  // display:inline-block;
  @include zero;
  font-family: $georgia;
  font-size: 20px;
  margin-top: 55px;

  p {
    font-size: 20px;
    @media(max-width: $bp-max-medium) {
      font-size: 18px;
    }
  }
}

.pull-quote__caption {
  cite {
    display: block;
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-family: $futura;
    @media(max-width: $bp-max-medium) {
      padding-left: 0;
    }
  }
}
