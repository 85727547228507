$search-results__sort-arrow-size: 24px;

.search-results {
	&.is-refreshing {
		.search-results__item-wrapper * {
			color: $grey-200 !important;
		}
	}
}

.search-results__item-wrapper {
	position: relative;
	clear: both;
}

.search-results__items {
	min-height: 350px;
	margin-bottom: 30px;
}

.search-results__sort-bar {
	@include clearfix;
	margin-bottom: 30px;
	letter-spacing: 1.8px;
	width: 50%;
	display: inline-block;
	vertical-align: text-top;
	@media(max-width: $bp-max-small) {
		width: auto;
	}

	& > span {
		float: left;
		// margin-right: 16px;
	}

	ul {
		align-items: center;
		float: left;
		display: flex;
		list-style: none;
		margin: 0 0 0 20px;
		@media(max-width: $bp-max-small) {
			margin-left: 0;
		}
	}

	li {
		// margin-right: 15px;
		margin-bottom: 0;
		height: 30px;
		@media(max-width: $bp-max-small) {
			margin-left: 0;
			display: block;
		}
	}
	@media(max-width: 415px) {
		& > span {
			display: block;
			padding-bottom: 12px;
		}
	}
	@media(max-width: 414px) {
		ul {
			float: none;
			display: flex;
			justify-content: space-between;
		}

		li {
			@include clearfix;
			clear: both;
			width: 100%;
			// margin-bottom: 12px;
			margin: 0 12px 12px;
		}
	}
}

.search-results__sorter {
	@include font(futura, demi);
	cursor: pointer;
	margin-right: 0;
	vertical-align: text-top;
	height: 30px;
	padding-left: 20px;
	position: relative;

	&:first-of-type {
		padding-left: 0;
	}

	&.is-active {
		font-weight: bold;
	}
	@media(max-width: $bp-max-small) {
		padding-left: 0;
	}
}

.search-results__sort-head {
	@include font(futura, demi);
	text-transform: uppercase;
	font-size: 20px;
	color: $fun-blue;
}

.search-results__sort-label {
	// float: left;
	font-size: 16px;
	color: $fun-blue;
	padding-left: 10px;
	@media(max-width: $bp-max-small) {
		padding-left: 0;
	}

	&:before {
		content: "|";
		color: $fun-blue;
		height: 30px;
		position: absolute;
		top: 0;
		left: 11px;
		font-size: 18px;
		@media(max-width: $bp-max-medium) {
			margin-right: 0;
		}
		@media(max-width: $bp-max-small) {
			content: "";
		}
	}

	svg {
		height: 24px;
		width: 24px;
	}
}

.search-results__sorter--alphabetical {
	display: inline-block;
	// color: $fun-blue;
}

.search-results__sorter:first-of-type .search-results__sort-label {
	padding-left: 0;

	&:before {
		content: "";
	}
}

.search-results__sort-dir {
	float: left;
	position: relative;
	top: calc($search-results__sort-arrow-size / 4);

	button,
	svg {
		width: $search-results__sort-arrow-size;
		height: $search-results__sort-arrow-size;
	}

	button {
		display: block;
		text-align: center;
		background: transparent;
		border: 0;
		padding: 0;
		margin-top: -16px;
		padding-top: 3px;

		&.is-selected {
			color: #00ccff;
		}
	}

	svg {
		fill: currentColor;
	}
}

.search-results__status {
	@include font(futura, medium);
	font-size: 20px;
	display: block;
	margin-bottom: 17px;

	& > span {
		padding-bottom: 16px;
	}
}

.search__results-mount {
	margin-top: -40px;
	@media(max-width: $bp-max-medium) {
		margin-top: 0;
	}
}

.search-results__active-facets {
	display: block;
	margin-bottom: 31px;

	ul {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		margin: 20px 0 0 -12px;
		padding: 0;
	}

	li {
		@include font(futura, medium);
		border-radius: 5px;
		margin-bottom: 12px;
		margin-left: 12px;
		padding: 6px 8px;
		margin-top: -4px;
		background-color: $black-squeeze-blue;
		line-height: 1;

		span {
			padding-bottom: 0;
			height: 100%;
			display: inline-block;
			padding-top: 3px;
		}

		.icon {
			margin-bottom: 6px;
		}
	}

	button {
		float: right;
		margin-left: 8px;
		padding: 0;
		border: 0;
		background: $white;
		cursor: pointer;
		height: 25px;
		width: 25px;
		cursor: pointer;
		border-radius: 25px;
	}
}

.search-results__load-more {
	text-align: left;
	transition: all 0.2s;

	.btn {
		font-size: 18px;
		padding: 12px 24px;
	}

	.search-results__spinner {
		position: static;
		display: inline;

		img {
			width: 24px;
			height: 24px;
			vertical-align: middle;
			margin-left: 14px;
		}
	}
}

.search-results__pagination {
	text-align: right;
	width: 49%;
	display: inline-block;

	.search-results__pagination__container & {
		width: 100%;
	}
	@media(max-width: $bp-max-small) {
		width: 100%;
		text-align: left;
	}
}

.search-results__pages {
	display: inline-block;
}

.search-results__filter-open {
	display: none;
	margin-bottom: 16px;
	padding: 8px 12px;

	.icon {
		color: $white;
		margin-left: 6px;
	}
	@media(max-width: $bp-max-medium) {
		display: block;
	}
}

.search-results__spinner {
	position: absolute;
	top: 100px;
	left: calc(50% - 88px); // subtract half width of spinner icon
	text-align: center;

	img {
		width: 80px;
		height: 80px;
	}
}

.btn--pagination:after {
	content: "";
}
.is-active .search-results__sort-label {
	color: $tundora;
}
.btn--pagination.btn--next,
.btn--pagination.btn--prev {
	@include font(futura,demi);
	font-size: 14px;
	color: $limed-spruce;
	text-transform: capitalize;
	vertical-align: baseline;
	padding-bottom: 0;

	&:after,
	&:before {
		color: $limed-spruce;
		font-size: 37px;
		font-family: helvetica;
		display: inline-block;
		vertical-align: middle;
		padding-bottom: 5px;
	}

	&:disabled {
		display: none;
	}
}

.btn--pagination {
	color: $boulder;
	padding: 0 5px;
}

.btn--pagination:not(.btn--prev):not(.btn--next):disabled {
	color: $fun-blue;
}

.btn--prev:before {
	content: "‹";
	padding-right: 10px;
}

.btn--next:after {
	content: "›";
	padding-left: 10px;
}
