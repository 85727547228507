.promo-item__media {
  .video-block {
    margin-bottom: 0;
  }
  .promo-item__image {
    /*position: relative;*/
    img {
      /*position: absolute;
      top: 0;
      right: 0;*/
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: $bp-max-medium) {
    margin-left: -30px;
    margin-right: -30px;
  }
}

.promo-item__details {
  .promo-item__title {
    @include font(futura, demi);
    @include zero;
    font-size: 45px;
    line-height: 1.2445;
    margin-bottom: 15px;
    margin-top: 46px;
    text-transform: capitalize;

    @media (max-width: $bp-max-medium) {
      font-size: 35px;
      line-height: 1.143;
      margin-top: 36px;
    }
  }

  .promo-item__description {
    line-height: 1.7778;
    margin-bottom: 18px;
  }

  .promo-item__cta {
    font-size: 16px;
    line-height: 1.375;
    margin-right: 22px;
  }

  .promo-item__link {
    @include font(futura, heavy);
    line-height: 1.112;

    @media (max-width: $bp-max-medium) {
      padding-top: 20px;
      padding-right: 0;
      letter-spacing: 0;
    }
  }
}
