.skip-link {
	position: absolute;
	font-weight: bold;
	text-decoration: none;
	top: -1000%;
	transition: top ease-in-out .1s;
	font-family:  "futura-pt", Helvetica, Arial, sans-serif;
	&:focus {
		top: 5px;
	}
}
