.events-three-grid {
	width: 100%;
	margin: 0 auto;
	margin-bottom: 60px;

	&__header {
		margin-bottom: 30px;
	}

	&__header-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 767px) {
			flex-direction: column;
			align-items: normal;
			margin-bottom: 16px;
		}
	}

	&__header-title {
		font-size: 40px;
		line-height: 50px;
		margin-bottom: 8px;
	}

	&__header-link {
		@include font(futura);
		text-transform: uppercase;
		font-weight: 600;
		font-size: 18px;
		color: $fun-blue;
		cursor: pointer;
		&:after {
			content: "\2192";
			padding-left: 8px;
			color: $black;
		}
	}

	&__header-content {
		font-size: 18px;
		line-height: 30px;
	}

	&__row {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 44px;

		@media (max-width: 1024px) {
			grid-template-columns: 1fr 1fr;
			grid-gap: 35px;
		}

		@media (max-width: 767px) {
			grid-template-columns: 1fr;
			grid-gap: 35px;
		}
	}
}

.single-event {
	max-width: 405px;
	height: 327px;
	display: flex;
	border: 1px solid #d9d9d9;

	&__event-content {
		padding: 38px 66px 38px 38px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media (max-width: 767px) {
			padding: 20px 32px 32px 30px;
		}
	}

	&__event-type {
		@include font(futura);
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 15px;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 0.08em;
		display: block;
	}

	&__event-title {
		font-size: 25px;
		font-weight: 500;
		line-height: 30px;
		color: $fun-blue;
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box !important;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		white-space: normal;

		@media (max-width: 767px) {
			-webkit-line-clamp: 4;
		}
	}

	&__event-date {
		@include font(futura);
		font-size: 18px;
		line-height: 22px;
		font-weight: 500;
		display: block;
		margin-bottom: 15px;
	}

	&__event-footer {
		display: flex;
	}

	&__event-footer-side {
		display: flex;
		flex-direction: column;
		flex-basis: 50%;
	}

	&__event-tag {
		@include font(georgia);
		font-size: 16px;
		line-height: 22px;
		opacity: 0.8;
	}

	&__event-value {
		@include font(futura);
		font-size: 18px;
		line-height: 22px;
		font-weight: 500;
	}
}
