.login-form {
  @include font(futura, heavy);
  /*margin-right: 30px;*/
  @media (max-width: $bp-max-medium) {
    border-top: 3px solid $saffron;
    display: none;
    /*margin-right: 0;*/
    padding-top: 15px;
  }
}

.login-form__dropdown-wrapper {
  position: relative;

  .utility-nav + .login-form > &:before {
    //only display yellow bar if utility nav exists
    background: $saffron;
    content: "";
    display: block;
    height: 50px;
    left: 0;
    position: absolute;
    top: -16px;
    width: 4px;
    @media (max-width: $bp-max-medium) {
      display: none;
    }

    .login-form--logged-out & {
      display: none;
    }
  }
}

.login-form__member-id {
  color: $fun-blue;
  position: absolute;
  top: 5px;
  right: 0;

  @media (max-width: $bp-max-medium) {
    display: none;
  }

  .login-form__item & {
    color: $white;
    display: block;
    position: static;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-right: 3px;
    padding-top: 10px;
    text-transform: uppercase;
  }
}

.utility-nav {
  .login-form--logged-out {
    a.btn {
      //login button
      @media (max-width: $bp-max-medium) {
        display: inline;
      }
    }
  }

  .login-form__list {
    border-top: 4px solid $sea-buckthorn-orange;
    display: none;
    padding-top: 20px;
    @media (max-width: $bp-max-medium) {
      display: flex;
    }

    .login-form__item:nth-of-type(1) {
      @media (max-width: $bp-max-medium) {
        float: left;
      }
    }

    .login-form__item:nth-of-type(2) {
      @media (max-width: $bp-max-medium) {
        float: right;
        margin-right: 90px;
      }
      @media (max-width: $bp-max-small) {
        margin-right: auto;
      }
    }

    .login-form__item:nth-of-type(3) {
      @media (max-width: $bp-max-medium) {
        clear: both;
      }
    }
  }
}

.login-form__trigger {
  color: $black;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin-left: 30px;
  /*padding-right: 20px;*/
  white-space: nowrap;
  text-decoration: none;

  .icon {
    // margin-left: 10px;
    // transform: rotate(0deg);
    transition: transform ease-in-out 0.2s;
    // position: absolute;
    // top: 5px;
    // right: 0;
    //
    width: 23px;
    height: 25px;
    fill: $fun-blue;
    color: $fun-blue;
    background-size: 100% auto;
    margin-left: 9px;

    .login-form.is-open & {
      transform: rotate(180deg);
    }
    @media (max-width: $bp-max-medium) {
      display: none;
    }
  }
  @media (max-width: $bp-max-medium) {
    margin-left: 0;
    pointer-events: none;
  }
}

.login-form__list {
  background: $fun-blue;
  display: none;
  right: -21px;
  list-style-type: none;
  margin-left: 0;
  position: absolute;
  top: 35px;
  z-index: 1;

  .login-form.is-open & {
    display: block;
  }
  @media (max-width: $bp-max-medium) {
    background: none;
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
    position: static;
  }

  &:before {
    content: "";
    border-bottom: 11px solid $fun-blue;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    height: 24px;
    position: absolute;
    right: 42px;
    top: -24px;
    width: 20px;

    @media (max-width: $bp-max-medium) {
      display: none;
    }
  }
}

.login-form__item {
  border-bottom: 1px solid $saffron;
  color: $white;
  margin: 0;
  padding: 15px 20px;
  @media (max-width: $bp-max-medium) {
    border-bottom: 0;
    flex-basis: 50%;
  }

  &:last-child {
    border-bottom: 0;
  }

  a {
    color: inherit;
    display: block;
    text-decoration: none;
    @media (max-width: $bp-max-medium) {
      margin-bottom: 10px;
      padding-left: 0;
    }
  }

  &--mobile-only {
    display: none;

    @media (max-width: $bp-max-medium) {
      display: block;
    }
  }
}

.login-form__indent {
  padding-left: 35px;
}
