$meta-margin: 5px;

.search-item {
	opacity: 0;
	transition: opacity 250ms;
	margin-bottom: 32px;
	padding-bottom: 32px;
	border-bottom: 2px dashed $iron;
	overflow: hidden;

	&.is-visible {
		opacity: 1;
	}

	a {
		// remove transitions when graying out results
		// while search results refreshing
		transition: none;
	}
}

.search-item__meta {
	@include font(futura, medium);
	font-size: 20px;
	color: $boulder;
	display: inline-block;
	line-height: 34px;
	height: 34px;

	&:empty {
		display: none;
	}
}

.search-item__category {
	@include font(futura, heavy);
	font-size: 14px;
	color: $tundora;
	margin-bottom: 20px;
	text-transform: uppercase;
	letter-spacing: 0.008em;

  &:empty {
		display: none;
  }
}

.search-item__title {
	@include font(futura, heavy);
	font-size: 30px;
	margin-bottom: 0;
}

.search-item__content-type {
	text-transform: uppercase;
}

.search-item__body {
	margin-bottom: 12px;
	margin-top: 20px;
	display: block;
}

.search-item__url {
	@include font(futura, bold);
	font-size: 14px;
}

.search-item__img {
	float: right;
	width: 125px;
	height: auto;
	display: block;
	margin-top: -30px;
	margin-left: 32px;
	@media(max-width: $bp-max-small) {
		float: none;
		margin: 20px 10px;
	}
}
